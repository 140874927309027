import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import { SuiTypographyTs } from "components/SuiTypography";

import TicketsTable from "components/Tickets/TicketsTable";
import { TicketsTableViewDisplay } from "components/Tickets/TicketsTable/table.helper";
import AddTicketModal from "components/Tickets/AddTicketModal";
import { usePermissions } from "stores/authentication/helpers";
import { Box } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

const RepairsTab: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const canCreateTask = usePermissions().tasks.create;
  const { uuid: maintenaceUUID } = maintenance;

  const [viewCompleted, setViewCompleted] = useState(false);

  const toggleCompletedFilters = () => setViewCompleted((state) => !state);

  return (
    <Box pt={3}>
      <SuiTypographyTs variant="h4" fontWeight="medium">
        Tareas {viewCompleted ? "Finalizadas" : "Activas"}
        {canCreateTask && (
          <AddTicketModal
            community={maintenance.community.slug}
            maintenanceUuid={maintenance.uuid}
            maintenance={maintenance}
          />
        )}
        <SuiTypographyTs
          style={{
            cursor: "pointer",
            padding: 15,
            float: "right",
            paddingBottom: 0,
          }}
          variant="button"
          onClick={toggleCompletedFilters}
        >
          {!viewCompleted ? "Ver finalizadas" : "Ver activas"}
        </SuiTypographyTs>
      </SuiTypographyTs>

      <Divider />

      <TicketsTable
        hideFilters
        columnsView={TicketsTableViewDisplay.minTicket}
        params={{
          maintenance_uuid: maintenaceUUID,
          is_finished: viewCompleted,
        }}
      />
    </Box>
  );
};
export default RepairsTab;
