import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

const useDeactivateUser = (userUUID: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/users/${userUUID}/deactivate`;
      return await axios.patch(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario eliminado",
        message: "Usuario eliminado con exito",
      });
    },
  });
};

export default useDeactivateUser;
