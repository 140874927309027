export const desktopStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  maxHeight: '70vh',
  //   zIndex: 2000,
  p: 0,
  width: '40%',
};

export const mobileStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  maxHeight: '80vh',
  zIndex: 2000,
  p: 0,
  width: '100%',
};
