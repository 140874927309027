import { types } from '../actions/types';

export const analyticsReducer = (
  state = {
    getDashboard: {},
    getSupervisorDashboard: {},
    getItemsByPlanification: {},
    getPlanificactionItems: {},
    createPlanificationItem: {},
    editPlanificationItem: {},
    deletePlanificationItem: {},
    getPlanificationCategory: {},
    createPlanificationCategory: {},
    editPlanificationCategory: {},
    deletePlanificationCategory: {},
    getPlanifications: {},
    getHelpCenter: {},
    togglePlanificationCategory: {},
    createPlanification: {},
    postExportAnualPlanification: {},
  },
  action
) => {
  switch (action.type) {
    case types.setGetDashboard:
      return {
        ...state,
        getDashboard: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    case types.setGetSupervisorDashboard:
      return {
        ...state,
        getSupervisorDashboard: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    case types.setGetItemsByPlanifications:
      return {
        ...state,
        getItemsByPlanification: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setGetPlanificactionItems:
      return {
        ...state,
        getPlanificactionItems: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setCreatePlanificationItem:
      return {
        ...state,
        createPlanificationItem: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setEditPlanificationItem:
      return {
        ...state,
        editPlanificationItem: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setDeletePlanificationItem:
      return {
        ...state,
        deletePlanificationItem: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setGetPlanificationCategory:
      return {
        ...state,
        getPlanificationCategory: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setCreatePlanificationCategory:
      return {
        ...state,
        createPlanificationCategory: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setEditPlanificationCategory:
      return {
        ...state,
        editPlanificationCategory: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setDeletePlanificationCategory:
      return {
        ...state,
        deletePlanificationCategory: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setGetHelpCenter:
      return {
        ...state,
        getHelpCenter: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setGetPLanifications:
      return {
        ...state,
        getPlanifications: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setTogglePlanificationCategory:
      return {
        ...state,
        togglePlanificationCategory: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setCreatePlanification:
      return {
        ...state,
        createPlanification: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setPostExportAnualPlanification:
      return {
        ...state,
        postExportAnualPlanification: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    default:
      return state;
  }
};
