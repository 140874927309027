import React, { useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Filters from "./Filters";
import { VisitsDataFilters } from "./types";
import SwappiDataTable from "components/SwappiTable";
import { ColumnTable } from "components/SwappiTable/types";
import { VisitReportModel } from "api/services/visits/types";
import { GetVisitsReports } from "api/services/visits/queries";
import { getColumns } from "./helpers";
import { useExportCenterFiltersStore, useExportCenterStore } from "../store";

const SelectVisitsTable = () => {
  const {
    visits: selectedVisitsReports,
    addVisits,
    removeVisits,
  } = useExportCenterStore();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [dataFilters, setDataFilters] = useState<VisitsDataFilters>({
    author: "",
    community: useExportCenterFiltersStore.getState().community,
    title: "",
  });

  const { data, isLoading } = GetVisitsReports({
    page: currentPage,
    page_size: pageSize,
    o: "none",
    author: dataFilters.author,
    community: dataFilters.community,
    title: dataFilters.title,
  });
  const columns = useMemo<ColumnTable<VisitReportModel>[]>(() => {
    return getColumns({
      data,
      selectedVisitsReports,
      addVisits,
      removeVisits,
    });
  }, [selectedVisitsReports, data]);
  return (
    <Box>
      <Card sx={{ p: 2, overflow: "visible", mb: 2 }}>
        <Filters setFilters={setDataFilters} />
      </Card>
      <Card sx={{ px: 2, overflow: "visible" }}>
        <SwappiDataTable
          columns={columns}
          isLoading={isLoading}
          defaultPageSize={pageSize}
          onChangePageSize={setPageSize}
          data={data?.results || []}
          pagination={{
            count: data?.count,
            previous: data?.previous,
            next: data?.next,
            currentPage,
            onChangePage: setCurrentPage,
          }}
        />
      </Card>
    </Box>
  );
};

export default SelectVisitsTable;
