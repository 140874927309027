import { Box, Stack } from "@mui/material";
import {
  BudgetModel,
  MaintenanceModel,
} from "api/services/maintenances/models";
import useUpdateMaintenanceBudget from "api/services/maintenances/mutations/updateMaintenanceBudget";
import InputControlled from "components/forms/controlled/InputControlled";
import SelectControlled from "components/forms/controlled/SelectControlled";
import { SuiButtonTs } from "components/SuiButton";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import { useForm } from "react-hook-form";

const EditBudgetForm: React.FC<{
  budget: BudgetModel;
  maintenance: MaintenanceModel;
}> = ({ budget, maintenance }) => {
  const { mutate: updateBudget } = useUpdateMaintenanceBudget({
    maintenanceUUID: maintenance.uuid,
    budgetUUID: budget.uuid,
  });
  const budgetUpdated = useEventEmitter("budgetUpdated");
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      budget_uuid: budget.uuid,
      year: budget.year,
      amount: budget.amount,
      currency: budget.currency,
    },
  });

  const onSubmit = handleSubmit((data) => {
    updateBudget(data, {
      onSuccess: (data) => {
        budgetUpdated.emit(data);
      },
    });
  });
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <InputControlled
          control={control}
          label="Año"
          name="year"
          type="number"
          rules={{ min: 1900, max: 2099 }}
        />
        <Box display={"flex"} gap={2}>
          <SelectControlled
            control={control}
            label="Moneda"
            name="currency"
            options={[
              { value: "CLP", label: "CLP" },
              { value: "UF", label: "UF" },
            ]}
          />
          <InputControlled
            control={control}
            label="Monto"
            name="amount"
            type="number"
          />
        </Box>
      </Stack>
      <Box mt={4}>
        <SuiButtonTs
          variant="gradient"
          color="success"
          size="small"
          type="submit"
          fullWidth
          disabled={!isDirty}
        >
          Guardar cambios
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default EditBudgetForm;
