import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SuiBox from "components/SuiBox";
import MailIcon from "@mui/icons-material/Mail";
import { withStyles } from "@material-ui/core/styles";
import Form from "./Form";
import SuiButton from "components/SuiButton";
import { useSelector } from "react-redux";
import "./style.css";
import { mobileMaxWidth } from "utils";

export default function UploadFileDrawer({
  maintenance,
  title,
  color,
  slugType,
  ticket,
  areaUuid,
  checklistItemUuid,
  alignButton,
  variant,
  community,
  visitReport,
  fromReport,
  user,
  moduleType,
}) {
  const uploadDocumentResponse = useSelector(
    (state) => state.documents.addDocument
  );
  const createCertificationResponse = useSelector(
    (state) => state.maintenance.createCertification
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    if (uploadDocumentResponse?.data) {
      setState({ ...state, right: false });
    }
  }, [uploadDocumentResponse]);

  React.useEffect(() => {
    if (createCertificationResponse?.data) {
      setState({ ...state, right: false });
    }
  }, [createCertificationResponse]);

  const list = (anchor) => (
    <Box role="presentation">
      <Form
        user={user}
        maintenance={maintenance}
        slugType={slugType}
        ticket={ticket}
        areaUuid={areaUuid}
        community={community}
        checklistItemUuid={checklistItemUuid}
        toggleDrawer={toggleDrawer}
        visitReport={visitReport}
        fromReport={fromReport}
        document={document}
        moduleType={moduleType}
      />
    </Box>
  );

  return (
    <div
      style={{
        textAlign: alignButton ? alignButton : "right",
      }}
    >
      <SuiButton
        color={color}
        variant={variant ? variant : "gradient"}
        size="small"
        onClick={toggleDrawer("right", true)}
      >
        {title}
      </SuiButton>{" "}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{ zIndex: 99999 }}
      >
        {list("right")}
      </Drawer>
    </div>
  );
}
