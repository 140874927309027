import CommunityManagement from "layouts/pages/communities/community";
import React from "react";
import { useParams } from "react-router-dom";

const CommunityPage = () => {
  const { slug } = useParams();
  return (
    <div>
      <CommunityManagement communitySlug={slug} />
    </div>
  );
};

export default CommunityPage;
