import { CircularProgress } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import useTicketProgressFinish from 'api/services/tickets/mutations/TicketProgressFinish';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
interface Props {
  ticket: TicketModel;
}
const FinishButton: FC<Props> = ({ ticket }) => {
  const { mutate: finishTicket, isPending: isSubmitingFinish } = useTicketProgressFinish({
    uuid: ticket.uuid,
  });
  const submitFinish = () => {
    finishTicket();
  };
  return (
    <SuiButtonTs
      style={{ marginTop: 10 }}
      fullWidth
      variant={'gradient'}
      color="info"
      size="small"
      disabled={isSubmitingFinish}
      onClick={submitFinish}
    >
      {isSubmitingFinish ? <CircularProgress size={15} /> : 'Finalizar'}
    </SuiButtonTs>
  );
};

export default FinishButton;
