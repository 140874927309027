import {
  useGetDocumentsPage,
  DocumentsPageFiltersProps,
  DocumentsPageParams,
} from "api/services/documents/queries/getDocumentsPage";
import SwappiDataTable from "components/SwappiTable";
import React, { useEffect, useState } from "react";
import { createColumnsHelper } from "./helpers";
import { Box } from "@mui/material";

const DocumentsTable: React.FC<{
  filters?: DocumentsPageFiltersProps;
}> = ({ filters }) => {
  const columns = createColumnsHelper();
  const [tableParams, setTableParams] = useState<DocumentsPageParams>({
    page: 1,
    page_size: 20,
  });

  const { data: tableData, isLoading } = useGetDocumentsPage({
    ...tableParams,
    ...filters,
  });

  const onChangePage = (newPage: number) => {
    setTableParams((prevState) => {
      return { ...prevState, page: newPage };
    });
  };
  const onChangePageSize = (newSize: number) =>
    setTableParams((prevState) => {
      return { ...prevState, page_size: newSize, page: 1 };
    });

  useEffect(() => {
    setTableParams((state) => ({ ...state, page: 1 }));
  }, [filters]);

  return (
    <Box>
      <SwappiDataTable
        columns={columns}
        data={tableData.results}
        isLoading={isLoading}
        defaultPageSize={tableParams.page_size}
        onChangePageSize={onChangePageSize}
        pagination={{
          count: tableData?.count,
          previous: tableData?.previous,
          next: tableData?.next,
          currentPage: tableParams.page,
          onChangePage: onChangePage,
        }}
      />
    </Box>
  );
};

export default DocumentsTable;
