import React, { useState } from "react";
// Importa todo lo necesario
import { BiArchiveIn } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { deleteReminder } from "redux/actions/users";
import Swal from "sweetalert2";
import CreateReminder from "../Create";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import { Grid, Tooltip } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SuiBadge from "components/SuiBadge";

export default function Reminder({ reminder }) {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        {/* Mueve SuiBox con botones al inicio de Card para que estén dentro y en la esquina superior derecha */}
        <SuiBox
          style={{
            position: "absolute",
            right: 0,
            zIndex: 1,
            margin: 8,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <CreateReminder reminder={reminder} update={true} />

          <div>
            <Tooltip title="Eliminar" placement="bottom">
              <SuiBadge
                style={{ cursor: "pointer" }}
                variant="gradient"
                color="error"
                badgeContent={<BiArchiveIn />}
                onClick={() => {
                  Swal.fire({
                    title: "¿Deseas eliminar esta nota?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      dispatch(deleteReminder(reminder.uuid));
                    }
                  });
                }}
              />
            </Tooltip>
          </div>
        </SuiBox>
        <SuiBox sx={{ p: 3, maxHeight: "30vh", overflow: "auto" }}>
          <SuiTypography variant="h5" fontWeight="medium">
            {reminder.title}
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="medium">
            Comunidad: {reminder.community && reminder.community.name}
          </SuiTypography>
          <br />
          <SuiTypography variant="button" fontWeight="medium">
            Fecha de Expiración: {reminder.due_date}
          </SuiTypography>
          <SuiTypography variant="body2">{ReactHtmlParser(reminder.body)}</SuiTypography>
        </SuiBox>
      </Card>
    </Grid>
  );
}
