import { Box, Button, Dialog } from '@mui/material';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, JSX, useState } from 'react';
import useMediaScreen from 'utils/Hooks/useMedia';

interface Props {
  children: JSX.Element | any;
}
const MobileDialogContainer: FC<Props> = ({ children }) => {
  const { isMobile } = useMediaScreen();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnOpen = () => {
    setIsOpen(true);
  };
  const handleOnClose = () => {
    setIsOpen(false);
  };
  if (isMobile)
    return (
      <>
        <Box p={1} display={'flex'} justifyContent={'center'}>
          <SuiButtonTs onClick={handleOnOpen} variant="text" color={'dark'}>
            Ver acciones
          </SuiButtonTs>
        </Box>
        <Dialog open={isOpen} onClose={handleOnClose}>
          <Box>{children}</Box>
        </Dialog>
      </>
    );
  return <>{children}</>;
};

export default MobileDialogContainer;
