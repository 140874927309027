import React, { useMemo, useState } from "react";
import { Box, Card, Grid } from "@mui/material";
import Filters from "./Filters";
import SwappiDataTable from "components/SwappiTable";
import { useGetDocumentsPage } from "api/services/documents/queries/getDocumentsPage";
import { FiltersState } from "./types";
import { ColumnTable } from "components/SwappiTable/types";
import { DocumentModel } from "api/services/documents/models";
import { getColumns } from "./helpers";
import { useExportCenterFiltersStore, useExportCenterStore } from "../store";

const SelectDocumentsTable: React.FC = () => {
  const {
    documents: selectedDocuments,
    addDocuments,
    removeDocuments,
  } = useExportCenterStore();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [filters, setFilters] = useState<FiltersState>({
    name: "",
    communities: useExportCenterFiltersStore.getState().community,
    type: "",
  });
  const { data, isLoading } = useGetDocumentsPage({
    page: currentPage,
    page_size: pageSize,
    o: "",
    name: filters.name,
    communities: filters.communities,
    type: filters.type,
  });

  const columns = useMemo<ColumnTable<DocumentModel>[]>(() => {
    return getColumns({
      data,
      selectedDocuments,
      addDocuments,
      removeDocuments,
    });
  }, [selectedDocuments, data]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Card sx={{ p: 2, overflow: "visible" }}>
            <Filters setFilters={setFilters} />
          </Card>
        </Grid>
        <Grid item md={9}>
          <Card sx={{ p: 2 }}>
            <SwappiDataTable
              columns={columns}
              data={data?.results}
              isLoading={isLoading}
              defaultPageSize={pageSize}
              onChangePageSize={setPageSize}
              pagination={{
                count: data?.count,
                previous: data?.previous,
                next: data?.next,
                currentPage,
                onChangePage: setCurrentPage,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectDocumentsTable;
