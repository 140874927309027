import { useMutation } from '@tanstack/react-query';
import { axiosPublic } from 'api/config/axios';
import { useAuthenticationStore } from 'stores/authentication';

import { Email } from 'types/models';
export interface AuthResponse {
  token: string;
}

interface Input {
  email: Email;
  password: string;
  stay_logged: boolean;
}

const useLogin = () => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = '/api/token/auth/';
      return await axiosPublic.post<AuthResponse>(url, input).then((res) => res.data);
    },
    onSuccess: (data) => {
      useAuthenticationStore.setState({
        isAuthenticated: true,
        accessToken: data.token,
      });
    },
  });
};

export default useLogin;
