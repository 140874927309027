/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";

// NewProduct page components
import FormField from "../FormField";

function BankAccountInfo(props) {
  return (
    <SuiBox>
      <SuiTypography variant="h5">Cuenta Bancaria Proveedor</SuiTypography>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <SuiTypography variant="button">Banco</SuiTypography>
            <SuiSelect
              value={props.accountBank}
              options={[
                { label: "Banco Consorcio", value: "Banco Bice" },
                { label: "Banco Bice", value: "Banco Consorcio" },
                { label: "Banco Corpbanca", value: "Banco Corpbanca" },
                { label: "Banco Crédito e Inversiones", value: "Banco Crédito e Inversiones" },
                { label: "Banco Estado", value: "Banco Estado" },
                { label: "Banco Falabella", value: "Banco Falabella" },
                { label: "Banco Internacional", value: "Banco Internacional" },
                { label: "Banco Paris", value: "Banco Paris" },
                { label: "Banco Ripley", value: "Banco Ripley" },
                { label: "Banco Santander", value: "Banco Santander" },
                { label: "Banco Security", value: "Banco Security" },
                { label: "Banco de Chile / Edwards-City", value: "Banco de Chile / Edwards-City" },
                { label: "Bando del Desarrollo", value: "Bando del Desarrollo" },
                { label: "Coopeuch", value: "Coopeuch" },
                { label: "HSBC Bank", value: "HSBC Bank" },
                { label: "Itaú", value: "Itaú" },
                { label: "Rabobank", value: "Rabobank" },
                { label: "Tenpo Prepago", value: "Tenpo Prepago" },
                { label: "Prepago Los Héroes", value: "Prepago Los Héroes" },
                { label: "Scotiabank", value: "Scotiabank" },
                { label: "Scotiabank Azul", value: "Scotiabank Azul" },
                { label: "Mercado Pago", value: "Mercado Pago" },
                { label: "TAPP Caja los Andes", value: "TAPP Caja los Andes" },
              ]}
              onChange={(e) => props.setaccountBank(e.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiTypography variant="button">Tipo de cuenta</SuiTypography>
            <SuiSelect
              value={props.accountType}
              placeholder="Cuenta"
              options={[
                { label: "Vista", value: "vista" },
                { label: "Corriente", value: "corriente" },
                { label: "Ahorro", value: "ahorro" },
              ]}
              onChange={(e) => props.setaccountType(e.value)}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.accountRut}
              type="text"
              label="Rut"
              placeholder=""
              onChange={(e) => props.setaccountRut(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.accountNumber}
              type="text"
              label="Número de cuenta"
              placeholder=""
              onChange={(e) => props.setaccountNumber(e.target.value)}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default BankAccountInfo;
