import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ChecklistItemModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  itemUUID: UUID;
  objectUUID: UUID;
  checklistUUID: UUID;
}
interface Input {
  name?: string;
  done?: boolean;
}
const useUpdateChecklistItem = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/checklist-item/rename/${params.itemUUID}`;
      return await axios
        .put<ChecklistItemModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Item Actualizado",
        message: "Item actualizado correctamente",
      });
    },
  });
};

export default useUpdateChecklistItem;
