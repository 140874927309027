import React, { useState, useEffect } from "react";

const VotingBox = ({ initialVotes, isMostVoted, totalVotes }) => {
  // Función para simular un voto (puedes conectar esto con tu lógica de votación real)

  // Estilos para la caja de votación
  const boxStyle = {
    width: "100%",
    marginBottom: "10px",
    border: `1px solid ${isMostVoted ? "rgba(130,214,22, 0.7)" : "rgba(234,6,6, 0.7)"}`,
    borderRadius: "5px",
  };

  // Estilos para la barra de votación
  const fillStyle = {
    width: initialVotes === 0 ? "100%" : `${(initialVotes / totalVotes) * 100}%`,
    backgroundColor:
      initialVotes === 0 ? "white" : isMostVoted ? "rgba(130,214,22, 0.7)" : "rgba(234,6,6, 0.7)", // Opacidad agregada
    color:
      initialVotes === 0 ? (isMostVoted ? "rgba(130,214,22, 0.7)" : "rgba(234,6,6, 0.7)") : "white",
    textAlign: "center",
    transition: "width 0.5s ease",
  };

  return (
    <div style={boxStyle} onClick={() => {}}>
      <div style={fillStyle}>
        {initialVotes}/{totalVotes} Votos
      </div>
    </div>
  );
};

export default VotingBox;
