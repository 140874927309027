import { UserModel } from "api/services/user/models";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import ManageUserPermissionsForm from "./forms/ManageUserPermissionsForm";
import { Box } from "@mui/material";
import { SuiTypographyTs } from "components/SuiTypography";

interface Props {
  user: UserModel;
}

const ManageUserPermissionsModal = forwardRef<ModalRefHandle, Props>(
  ({ user }, ref) => {
    const modalRef = useRef<ModalRefHandle>();

    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
    }));

    useEventListener("userUpdated", () => {
      modalRef.current?.close();
    });

    return (
      <BaseModal ref={modalRef}>
        <Box p={3} width={{ md: 700, xs: "100vw" }}>
          <Box>
            <SuiTypographyTs variant="h4" fontWeight="medium">
              Permisos de usuario
            </SuiTypographyTs>
            {user && <ManageUserPermissionsForm user={user} />}
          </Box>
        </Box>
      </BaseModal>
    );
  }
);

ManageUserPermissionsModal.displayName = "ManageUserPermissionsModal";
export default ManageUserPermissionsModal;
