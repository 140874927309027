import { Box } from "@mui/material";
import TeamManagement from "layouts/pages/admin-team/TeamManagement";
import React from "react";

const TeamPage: React.FC = () => {
  return (
    <Box>
      <TeamManagement />
    </Box>
  );
};

export default TeamPage;
