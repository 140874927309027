/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiDropzone from 'components/SuiDropzone';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';
import SuiButton from 'components/SuiButton';
import { useParams, useNavigate } from 'react-router-dom';
import { createArea, importAreas } from 'redux/actions/communities';
import { useSelector, useDispatch } from 'react-redux';
import SuiInput from 'components/SuiInput';

function Form({ close, buildings }) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [buildingSelected, setbuildingSelected] = useState(null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const handleCreate = () => {
    let form = new FormData();
    form.append('file', file);
    form.append('slug', slug);
    dispatch(importAreas(form));
    close();
  };
  return (
    <SuiBox>
      <SuiBox p={3} style={{ overflow: 'show' }}>
        <SuiTypography variant="h5">Importar Áreas Comunes</SuiTypography>
      </SuiBox>
      <SuiBox
        component="form"
        pb={3}
        px={3}
        onSubmit={(e) => {
          e.preventDefault();
          handleCreate();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <a
              style={{ fontSize: '12px' }}
              href="https://swappi-backend-bucket.s3.us-east-2.amazonaws.com/areas.xlsx"
              target="_blank"
            >
              Descargar archivo de ejemplo
            </a>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography variant="h6">Archivo</SuiTypography>
            <SuiInput type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography variant="caption">
              **Se debe respetar el nombre de la columna y de la hoja para su correcto
              funcionamiento
            </SuiTypography>
          </Grid>

          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" onClick={handleCreate}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Form;
