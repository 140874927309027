import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";

import { UUID } from "crypto";
import { IMessage } from "api/utils/queries";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  uuid: UUID;
}
const useDeleteDocument = () => {
  return useMutation({
    mutationFn: async (input: Params) => {
      const url = `${API_URL}/documents/delete/${input.uuid}/`;
      return axios.delete<IMessage>(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Documento eliminado",
        message: "El documento ha sido eliminado de forma correcta",
      });
    },
  });
};

export default useDeleteDocument;
