import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { SuiTypographyTs } from "components/SuiTypography";

interface LabelValuePairProps {
  label: string;
  value: string | number | React.ReactNode | any;
  labelWidth?: number;
  valueWidth?: number;
}

const LabelValuePair: React.FC<LabelValuePairProps> = ({
  label,
  value,
  labelWidth = 4,
  valueWidth = 8,
}) => {
  return (
    <Box mb={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={labelWidth}>
          <Typography variant="button" fontWeight="bold" textTransform="none">
            {label}:
          </Typography>
        </Grid>
        <Grid item xs={valueWidth}>
          <SuiTypographyTs
            variant="button"
            fontWeight="regular"
            textTransform="none"
          >
            {value}
          </SuiTypographyTs>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LabelValuePair;
