import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { getPermission } from 'utils';
import { mobileMaxWidth } from 'utils';
import { Icon } from '@mui/material';
import SuiBadge from 'components/SuiBadge';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  maxHeight: '90vh',
  borderRadius: '10px',
  boxShadow: 24,
  width: '30%',
};

const styleMobile = {
  position: 'absolute',
  width: '100%',
  height: '80vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
};

export default function EditUser(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {getPermission(['superadmin', 'supervisor', 'administrador']) && (
        <SuiBadge
          variant="gradient"
          color="warning"
          badgeContent={<Icon size="12px">edit</Icon>}
          onClick={handleOpen}
        />
      )}
      <Modal
        style={{ overflow: 'scroll' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={''}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <Form memberProp={props.user} />
        </Box>
      </Modal>
    </div>
  );
}
