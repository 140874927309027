import React, { useState, MouseEvent, FC, JSX, Fragment } from "react";
import { MoreVert } from "@mui/icons-material";
import {
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SxProps,
} from "@mui/material";

export interface DropdownMenuItem {
  label?: string | JSX.Element;
  icon?: boolean | JSX.Element;
  selected?: boolean;
  onClick?: () => unknown;
  color?: string;
}

interface Props {
  buttonIcon?: JSX.Element;
  closeOnClick?: boolean;
  items: DropdownMenuItem[] | DropdownMenuItem[][];
  buttonSx?: SxProps;
  renderButton?: (props: {
    onClick: (event: MouseEvent<HTMLElement>) => void;
  }) => JSX.Element;
}

const DropdownMenu: FC<Props> = ({
  buttonIcon,
  items,
  closeOnClick = true,
  buttonSx,
  renderButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const closeMenu = () => setAnchorEl(null);
  const openMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const renderMenuItems = () => {
    const elements: JSX.Element[] = [];

    items.forEach((anyItem, idx) => {
      if (Array.isArray(anyItem)) {
        const arrItems: DropdownMenuItem[] = anyItem;
        arrItems.forEach((subItem, subIdx) => {
          elements.push(
            <MenuItem
              key={`${idx}-${subIdx}`}
              selected={subItem.selected}
              sx={{ color: subItem.color }}
              onClick={() => {
                closeOnClick && closeMenu();
                subItem.onClick?.();
              }}
            >
              {subItem.icon && (
                <ListItemIcon sx={{ color: subItem.color }}>
                  {subItem.icon}
                </ListItemIcon>
              )}
              {subItem.label}
            </MenuItem>
          );
        });
        if (idx < items.length - 1) {
          elements.push(<Divider key={`divider-${idx}`} />);
        }
      } else {
        const item: DropdownMenuItem = anyItem;

        elements.push(
          <MenuItem
            key={idx}
            selected={item.selected}
            sx={{ color: item.color }}
            onClick={() => {
              closeOnClick && closeMenu();
              item.onClick?.();
            }}
          >
            {item.icon && (
              <ListItemIcon sx={{ color: item.color }}>
                {item.icon}
              </ListItemIcon>
            )}
            {item.label}
          </MenuItem>
        );
      }
    });

    return elements;
  };

  return (
    <>
      {renderButton ? (
        renderButton({ onClick: openMenu })
      ) : (
        <IconButton onClick={openMenu} sx={{ ...buttonSx }}>
          {buttonIcon || <MoreVert />}
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default DropdownMenu;
