import { Box } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import React from "react";
import AddDocumentButton from "./AddDocumentButton";
import DocumentsTable from "./VisitDocumentsTable";

const VisitDocuments: React.FC<{ visit: VisitModel }> = ({ visit }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <AddDocumentButton visit={visit} />
      </Box>
      <Box mt={2}>
        <DocumentsTable visit={visit} />
      </Box>
    </Box>
  );
};

export default VisitDocuments;
