import React, { FC, useMemo } from 'react';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import { useGetUF } from 'api/services/others/queries/GetUf';
import { TicketModel } from 'api/services/tickets/models';
import DocumentPricingCard from './DocumentPricingCard';
import CreatePollButton from './CreatePollButton';
import { documentValueInClp } from 'utils/helpers/documents/currency';
import { usePermissions } from 'stores/authentication/helpers';
import SuiButton, { SuiButtonTs } from 'components/SuiButton';
import { useGetComparativeTable } from 'api/services/files/queries/GetTicketPDF';

interface Props {
  ticket: TicketModel;
}
const DocumentsPricing: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();

  const getComparativeTable = useGetComparativeTable({ ticketUUID: ticket.uuid });

  const { data: UF } = useGetUF();

  const { documentsSorted, lowestPricing } = useMemo(() => {
    if (UF === undefined)
      return {
        documentsSorted: [],
        lowestPricing: 0,
      };
    const documentsSorted = ticket.documents
      .filter((file) => file.type.slug === 'cotizacion')
      .sort((docA, docB) => {
        const aPrice = documentValueInClp(docA, UF.valor);
        const bPrice = documentValueInClp(docB, UF.valor);
        return aPrice - bPrice;
      });
    return { documentsSorted, lowestPricing: documentValueInClp(documentsSorted[0], UF.valor) };
  }, [ticket, UF]);

  const canCreatePoll = canEditTask && ticket.polls.length === 0;
  if (UF === undefined)
    return (
      <Card sx={{ p: 3 }}>
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress color="info" />
        </Box>
      </Card>
    );
  return (
    <Card sx={{ p: 3 }}>
      <Box>
        <Box display={'flex'}>
          <Box flex={1}>
            <Typography variant="h4" fontWeight="bold" textTransform="capitalize">
              Cotizaciones
            </Typography>
          </Box>
          <Box>
            {canCreatePoll && <CreatePollButton ticket={ticket} />}
            {
              <SuiButtonTs
                variant="gradient"
                color="primary"
                size="small"
                onClick={() => !getComparativeTable.isPending && getComparativeTable.mutate()}
              >
                {getComparativeTable.isPending ? <CircularProgress color="inherit" size={12} /> : 'Descargar'}
              </SuiButtonTs>
            }
            <Typography variant="h6" pb={2} fontWeight="bold" textTransform="capitalize">
              UF hoy: {UF.valor.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={1}>
          {documentsSorted.map((document) => {
            return (
              <Grid key={document.uuid} item sm={4} xs={12}>
                <DocumentPricingCard
                  document={document}
                  ticket={ticket}
                  referenceValueCLP={lowestPricing}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Card>
  );
};

export default DocumentsPricing;
