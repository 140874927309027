/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { AiOutlineArrowUp } from "react-icons/ai";
import { createPublicVote } from "redux/actions/polls";
import VotingBox from "../../Polls/VotesBar";
import { GetUF } from "api/services/tickets/queries";
import { Box } from "@mui/material";
import RecommendedBadge from "../../Files/View/BudgetCard/Recommended";
import { useGetDocumentUrl } from "api/services/documents/queries/getDocumentUrl";

function isMostVotedChoice(poll, choiceUuid) {
  if (!poll) {
    return "No hay encuestas disponibles.";
  }

  const voteCounts = poll.poll_choices.map((choice) => choice.votes.length);
  const maxVotes = Math.max(...voteCounts);

  const choiceIndex = poll.poll_choices.findIndex(
    (choice) => choice.uuid === choiceUuid
  );
  if (choiceIndex === -1) {
    return "Opción no encontrada.";
  }

  return voteCounts[choiceIndex] === maxVotes;
}
function BudgetCardVotation({
  ticket,
  file: document,
  publicMode,
  index,
  choice,
  poll,
}) {
  const getDocumentUrl = useGetDocumentUrl(document.uuid, true);
  const dispatch = useDispatch();
  const { uuid, token } = useParams();
  const [ufPrice, setUfPrice] = useState(35000);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const getUFPriceResponse = useSelector((state) => state.tickets.getUFPrice);
  const [minPrice, setMinPrice] = useState(document.globalMinPrice);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (getUFPriceResponse.data) {
      setUfPrice(getUFPriceResponse.data.uf);
    }
  }, [getUFPriceResponse]);

  return (
    <Card
      sx={{
        height: "100%",
        border: `2px solid ${document.is_recommended ? "#22bb33" : "#eaeaf0"}`,
        p: 2,
        overflow: "visible",
      }}
    >
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <Box>
          {document.is_recommended && (
            <RecommendedBadge
              style={{
                top: -15,
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, 0%)",
              }}
            />
          )}
        </Box>
        <SuiTypography
          variant="h5"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {document.name} {document.is_recommended && "recomendado"}
        </SuiTypography>

        {document.approved ? (
          <SuiTypography
            variant="h5"
            fontWeight="medium"
            color="success"
            textAlign="center"
            textTransform="capitalize"
          >
            {"Aprobado"}
          </SuiTypography>
        ) : (
          ""
        )}
        <SuiButton
          color="success"
          onClick={() => {
            dispatch(createPublicVote(token, { choice_uuid: choice.uuid }));
          }}
          variant="gradient"
          size="small"
        >
          Votar
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox>
          <SuiBox>
            <VotingBox
              initialVotes={choice.votes.length}
              isMostVoted={isMostVotedChoice(poll, choice.uuid)}
              totalVotes={poll.max_votes}
            />
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Precio"}: &nbsp;
            </SuiTypography>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color={index == 0 ? "success" : "error"}
            >
              &nbsp; {document.currency == "UF" ? "" : "$"}{" "}
              {document.price.toLocaleString("es-CL")} {document.currency}{" "}
              {document.tax && document.tax}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Precio Total"}: &nbsp;
            </SuiTypography>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color={index == 0 ? "success" : "error"}
            >
              &nbsp; $
              {document.gross_value &&
                (document.currency == "UF"
                  ? document.gross_value * ufPrice
                  : document.gross_value
                ).toLocaleString("es-CL", {
                  maximumFractionDigits: 0,
                })}{" "}
              CLP
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Diferencia"}: &nbsp;
            </SuiTypography>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color={index == 0 ? "success" : "error"}
            >
              &nbsp;$
              {index != 0
                ? (
                    (document.currency == "UF"
                      ? document.price * ufPrice
                      : document.price) - minPrice
                  ).toLocaleString("es-CL")
                : "-"}
              &nbsp;
              {index != 0 && <AiOutlineArrowUp />} &nbsp;
              {index != 0 && minPrice != 0
                ? (
                    ((document.currency == "UF"
                      ? document.price * ufPrice
                      : document.price) *
                      100) /
                      minPrice -
                    100
                  )
                    .toFixed(0)
                    .toLocaleString("es-CL")
                : "-"}
              %
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Nombre Proveedor"}: &nbsp;
            </SuiTypography>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color="info"
              style={{ cursor: "pointer" }}
              onClick={() =>
                document.service_provider &&
                !publicMode &&
                navigate(`/providers/${document.service_provider.uuid}`, {
                  state: { provider: document.service_provider },
                })
              }
            >
              &nbsp;
              {document.service_provider
                ? document.service_provider.executive_name ||
                  document.service_provider.razon_social
                : ""}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              <a href={`https://api.swappi.cl/documents/${document.uuid}/url/redirect/`} target="_blank">
                Ver archivo adjunto
              </a>
            </SuiTypography>
          </SuiBox>
          <div>
            <SuiTypography
              onClick={toggleDropdown}
              variant="button"
              fontWeight="bold"
              style={{ cursor: "pointer" }}
              textTransform="capitalize"
            >
              {isOpen ? "Ocultar" : "Ver"} Observación
            </SuiTypography>
            {isOpen && (
              <div>
                <SuiTypography variant={"caption"}>
                  {document.observations}
                </SuiTypography>
              </div>
            )}
          </div>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard

export default BudgetCardVotation;
