import { Box, Typography } from "@mui/material";
import  SuiInputTs  from "components/SuiInput/SuiInputTs";
import React from "react";
import { Control, useController, UseControllerProps } from "react-hook-form";
import { fileTypeMap } from "./input.constants";

interface InputProps extends UseControllerProps {
  control: Control<any>;
  label?: string;
  placeholder?: string;
  size?: "small" | "medium" | "large";
  type?: "password" | "email" | "number" | "file" | "year" | "radio";
  multiline?: number;
  requiredDot?: boolean;
  fileType?: "image";
  validate?: (value: string) => boolean;
  format?: (value: string) => string;
}

const RequiredDot = () => <span style={{ color: "red" }}>*</span>;

const InputControlled: React.FC<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  size = "small",
  type,
  multiline,
  requiredDot,
  validate,
  format,
  fileType,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  const { value: fieldValue, ...restField } = field;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "file" && e.target.files) {
      field.onChange(e.target.files[0]);
    } else {
      if (validate && validate(e.target.value) === false) {
        return;
      }
      const value = format ? format(e.target.value) : e.target.value;
      field.onChange(value);
    }
  };

  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label} {requiredDot && <RequiredDot />}
        </Typography>
      )}
      <SuiInputTs
        {...restField}
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        type={type}
        // Removed {...rules}
        {...(type === "file" ? {} : { value: fieldValue })}
        {...(multiline && { multiline: true, rows: multiline })}
        inputProps={{
          ...(type === "file" && { accept: fileTypeMap[fileType] }),
        }}
      />
      <Typography variant="caption" fontWeight="light" color={"error.main"}>
        {error?.message}
      </Typography>
    </Box>
  );
};

export default InputControlled;
