import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { SelectOptionForm } from "types/forms";
import { userToken } from "utils/user/user";
import { getPermission } from "utils";
import { UserModel } from "api/services/user/models";
import useEventListener from "lib/events/useEventListener";
import { queryClient } from "api/config/queryClient";

const KEY_GET_USER_STAFF = "GetUserStaff";
export const useGetManagementStaff = (input = { enabled: true }) => {
  const query = useQuery({
    enabled:
      input.enabled &&
      getPermission(["superadmin", "administrador", "supervisor"]),
    queryKey: [KEY_GET_USER_STAFF],
    queryFn: async () => {
      const { slug } = userToken().management;
      const url = `${API_URL}/users/manager/${slug}/staff`;
      return axios.get<UserModel[]>(url).then((res) => res.data);
    },
  });

  const data = query.data || [];
  const dataAsOptions: SelectOptionForm[] = data.map((member) => ({
    value: member.email,
    label: member.first_name,
  }));

  useEventListener(["userDeactivated", "userCreated"], () => {
    query.refetch();
  });
  useEventListener<UserModel>("userUpdated", (data) => {
    queryClient.setQueryData([KEY_GET_USER_STAFF], (state: UserModel[]) => {
      console.log(state, data);
      return state?.map((user) => {
        if (user.uuid === data.uuid) return data;
        return user;
      });
    });
  });

  return { ...query, dataAsOptions, data };
};
