import React, { FC, memo, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { useGetManagementStaff } from "api/services/staff/queries/getManagementStaff";
import CheckboxControlled from "components/forms/controlled/CheckboxControlled";
import InputControlled from "components/forms/controlled/InputControlled";
import SelectBadgeControlled from "components/forms/controlled/SelectBadgeControlled";
import { SelectedBadgeValue } from "components/forms/controlled/SelectBadgeControlled/types";
import SelectControlled from "components/forms/controlled/SelectControlled";
import SelectOrderControlled from "components/forms/controlled/SelectOrderControlled";
import { useForm } from "react-hook-form";
import {
  ORDER_BY_OPTIONS,
  PRIORITY_OPTIONS,
  STATUS_OPTIONS,
} from "./constants";
import { Email } from "types/models";
import DateRangePicker from "components/DateRangePicker";

export interface IFormValues {
  title: string;
  community: string;
  member: Email;
  orderBy: string;
  priorty: SelectedBadgeValue;
  status: SelectedBadgeValue;
  inactive: boolean;
  responsible: Email;
  created_after: string;
  created_before: string;
}

interface TicketFiltersProps {
  onChange: (data: IFormValues) => unknown;
  hideFilter?: {
    title?: boolean;
    community?: boolean;
    member?: boolean;
    orderBy?: boolean;
    priorty?: boolean;
    status?: boolean;
    inactive?: boolean;
    responsible?: boolean;
  };
}

const TableFilters: FC<TicketFiltersProps> = ({
  onChange,
  hideFilter = {},
}) => {
  const { dataAsOptions: communitiesOptions } = useGetCommunities();
  const { dataAsOptions: membersOptions } = useGetManagementStaff();
  const [rangeCreateDates, setRangeCreateDates] = useState<{startDate: string, endDate: string}>({startDate: "", endDate: ""});
  const { control, watch, setValue } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      title: "",
      community: "",
      member: "",
      orderBy: "none",
      priorty: {},
      status: {},
      inactive: false,
      responsible: "",
      created_after: "",
      created_before: "",
    },
  });

  useEffect(() => {
    const subscription = watch((value) => {
      onChange?.(value as IFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValue("created_after", rangeCreateDates.startDate);
    setValue("created_before", rangeCreateDates.endDate);
  }, [rangeCreateDates, setValue]);

  return (
    <Box>
      <Typography variant="h5" fontWeight="medium" style={{ marginBottom: 3 }}>
        Filtros
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        {!hideFilter.title && (
          <Grid item xs={12} md={3}>
            <InputControlled
              control={control}
              label="Título"
              name="title"
              placeholder="Título"
            />
          </Grid>
        )}
        {!hideFilter.community && (
          <Grid item xs={12} md={3}>
            <SelectControlled
              control={control}
              label="Comunidad"
              name="community"
              placeholder="Comunidad"
              options={[{ label: "Todas", value: "" }, ...communitiesOptions]}
            />
          </Grid>
        )}
        {!hideFilter.responsible && (
          <Grid item xs={12} md={3}>
            <SelectControlled
              control={control}
              label="Responsable"
              placeholder="Responsable"
              name="responsible"
              options={[{ label: "Todos", value: "" }, ...membersOptions]}
            />
          </Grid>
        )}
        {!hideFilter.member && (
          <Grid item xs={12} md={3}>
            <SelectControlled
              control={control}
              label="Colaborador"
              name="member"
              placeholder="Colaborador"
              options={[{ label: "Todos", value: "" }, ...membersOptions]}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {!hideFilter.priorty && (
          <Grid item xs={12} md={3}>
            <SelectBadgeControlled
              control={control}
              label="Prioridad"
              name="priorty"
              isMulti
              options={[...PRIORITY_OPTIONS]}
            />
          </Grid>
        )}
        {!hideFilter.status && (
          <Grid item xs={12} md={6}>
            <SelectBadgeControlled
              control={control}
              label="Estado"
              name="status"
              isMulti
              options={[...STATUS_OPTIONS]}
            />
          </Grid>
        )}
        {!hideFilter.inactive && (
          <Grid item xs={12} md={3}>
          
            <CheckboxControlled
              control={control}
              label="Tareas Inactivas"
              name="inactive"
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="label" fontWeight="medium" sx={{pb:1}}>
              Rangos de fechas de creación
            </Typography>
          <DateRangePicker setDates={setRangeCreateDates} />
        </Grid>
        {!hideFilter.orderBy && (
          <Grid item xs={12} md={3}>
            <SelectOrderControlled
              control={control}
              label="Ordenar"
              name="orderBy"
              placeholder="Ordenar"
              options={[...ORDER_BY_OPTIONS]}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TableFilters;
