import { UUID } from 'crypto';
import { useMutation } from '@tanstack/react-query';
import { API_URL } from 'redux/actions/types';
import axios from 'axios';
import { TicketQueryHelper } from '../queries/GetTicket';

interface Params {
  ticketUUID?: UUID;
  commentaryUUID: UUID;
}

const useDeleteTicketCommentary = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/comment/delete/${params.commentaryUUID}`;
      return await axios
        .delete(url)
        .then((res) => res.data)
        .then(() => {
          if (params.ticketUUID)
            TicketQueryHelper.deleteCommentary(params.commentaryUUID, params.ticketUUID);
        });
    },
  });
};

export default useDeleteTicketCommentary;
