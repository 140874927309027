/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

function DataTableBodyCell({
  noBorder,
  align,
  component = null,
  position = undefined,
  children,
  py = 1.5,
  px = 3,
}) {
  const { light } = colors;
  const { size } = typography;
  const { borderWidth } = borders;

  return (
    <SuiBox
      component="td"
      textAlign={align}
      position={position}
      left={position == 'sticky' ? 0 : 'auto'}
      zIndex={position === 'sticky' ? 99 : 'auto'}
      style={{ background: position === 'sticky' ? 'white' : 'transparent' }}
      fontSize={size.sm}
      borderBottom={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      border={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      py={py}
      px={px}
    >
      <SuiBox display="inline-block" width="100%" color="text" sx={{ verticalAlign: 'middle' }}>
        {component ? component : children}
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default DataTableBodyCell;
