import React, { FC } from 'react';
import { TicketModel } from 'api/services/tickets/models';
import NewCommentary from './NewCommentary';
import { Card, Typography } from '@mui/material';
import CommentariesList from './CommentariesList';

interface Props {
  ticket: TicketModel;
}

const TicketCommentaries: FC<Props> = ({ ticket }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h4" fontWeight="bold" textTransform="capitalize" sx={{ mb: 2 }}>
        Comentarios
      </Typography>

      <NewCommentary ticket={ticket} />
      <CommentariesList ticket={ticket} />
    </Card>
  );
};

export default TicketCommentaries;
