import { useMutation } from "@tanstack/react-query";
import { IMessage } from "api/utils/queries";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { TicketModel } from "../models";
import { TicketQueryHelper } from "../queries/GetTicket";
import { toastNotification } from "components/ui/ToastNotification";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";
interface Params {
  ticketUUID: UUID;
}

type Response = IMessage<TicketModel>;

const useUpdateTicketTimeline = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${params.ticketUUID}/change-timeline/`;
      return await axios.put<Response>(url).then((res) => res.data);
    },
    onSuccess: (data) => {
      TicketQueryHelper.updateTicket(data.data);
      toastNotification.success({
        message: "El ticket ha sido asignado",
        title: "Ticket asignado",
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TICKETS_TABLE],
      });
    },
  });
};

export default useUpdateTicketTimeline;
