import React from 'react';
import { Box } from '@mui/material';
import TicketsManagement from 'layouts/pages/tickets/AdminTickets';

const TicketsPage = () => {
  return (
    <Box>
      <TicketsManagement />
    </Box>
  );
};

export default TicketsPage;
