import LoadingView from 'components/Loading';
import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthenticate } from 'stores/authentication/helpers';

interface Props {
  redirect: string;
}

const PublicGuard: FC<Props> = ({ redirect }) => {
  const { isAuthenticated } = useAuthenticate();

  if (isAuthenticated === undefined) return <LoadingView />;
  if (isAuthenticated === false) return <Outlet />;
  return <Navigate to={redirect} />;
};

export default PublicGuard;
