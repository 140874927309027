/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { FC, useEffect } from 'react';
// @mui material components
import Grid from '@mui/material/Grid';

import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

// Soft UI Dashboard PRO React example components
// Teams page components
import { Box } from '@mui/material';
import Loading from 'components/Loading';

import { useGetTicket } from 'api/services/tickets/queries/GetTicket';
import TicketDetail from './TicketDetail';

import { UUID } from 'crypto';

import Actions from './Actions';
import useMediaScreen from 'utils/Hooks/useMedia';

interface Props {
  ticketUUID: UUID;
  onArchiveTicket?: () => unknown;
}
const TicketView: FC<Props> = ({ ticketUUID: ticketUUID, onArchiveTicket }) => {
  const { isMobile } = useMediaScreen();
  const { data: ticket, isLoading } = useGetTicket({ uuid: ticketUUID });

  if (!ticketUUID || isLoading || ticket === null) return <Loading />;

  if (isMobile)
    return (
      <Box py={1}>
        <Grid container>
          <Grid item xs={12}>
            <Actions ticket={ticket} onArchiveTicket={onArchiveTicket} />
          </Grid>
          <Grid item sx={{ overflowY: 'auto', maxHeight: '80vh', p: 2 }} xs={12}>
            <TicketDetail ticket={ticket} />
          </Grid>
        </Grid>
      </Box>
    );
  return (
    <Box>
      <Grid container>
        <Grid item sm={10} sx={{ overflowY: 'auto', maxHeight: '90vh', p: 2 }}>
          <TicketDetail ticket={ticket} />
        </Grid>
        <Grid item sm={2}>
          <Actions ticket={ticket} onArchiveTicket={onArchiveTicket} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketView;
