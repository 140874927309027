/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import { useState } from "react";
import { useDispatch } from "react-redux";
// Images
import img from "assets/images/principal-login.svg";
import { resetPassword } from "redux/actions/auth";

function Cover() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  return (
    <CoverLayout
      title="Recuperar contraseña"
      description="Recibirás un correo con un enlace para recuperar tu contraseña."
      image={img}
      top={30}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="correo"
            placeholder="Ingresa tu correo"
            onChange={(e) => setEmail(e.target.value)}
          />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetPassword({ email: email.split(" ").join("").toLowerCase() }));
            }}
          >
            Recuperar
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default Cover;
