import { ThumbUp } from '@mui/icons-material';
import { Box, Paper, SxProps } from '@mui/material';
import React, { FC } from 'react';
interface Props {
  style: SxProps;
}
const RecommendedBadge: FC<Props> = ({ style }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        ...style,
        px: 2,
        bgcolor: '#22bb33',
        color: 'whitesmoke',
      }}
    >
      <Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{ fontSize: 16 }}>
          <span style={{ marginRight: '10px' }}>Recomendado</span>
          <ThumbUp fontSize="inherit" />
        </Box>
      </Box>
    </Paper>
  );
};

export default RecommendedBadge;
