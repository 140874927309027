import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SuiButtonTs } from "components/SuiButton";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import useMediaScreen from "utils/Hooks/useMedia";
import {
  mobileStyle,
  contentContainer,
  styleDesktop,
} from "../TicketDetailModal/styles";
import { useTicketModalStore } from "./store";
import TicketView from "../TicketView";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import useEventListener from "lib/events/useEventListener";
import { EVENTS } from "lib/events/events";
import { UUID } from "crypto";

const navButtonStyle = {
  position: "absolute",
  top: "50%",
  zIndex: 1,
};

const buttonProps = {
  variant: "gradient",
  color: "info",
  size: "small",
};

const TicketModal = () => {
  const { isMobile } = useMediaScreen();
  const {
    isOpen,
    closeModal,
    ticketUUID,
    handlePrev,
    handleNext,
    setSelectedTicketUUID,
  } = useTicketModalStore();

  useEventListener(EVENTS.NEW_TICKET, (ticketUUID: UUID) => {
    setSelectedTicketUUID(ticketUUID);
  });

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={isMobile ? mobileStyle : styleDesktop}>
        {handlePrev && (
          <SuiButtonTs
            {...buttonProps}
            onClick={handlePrev}
            sx={{ ...navButtonStyle, left: -30 }}
            startIcon={<AiOutlineLeft size="20px" />}
          />
        )}
        <Box sx={contentContainer}>
          <Box position={"relative"}>
            <Box position={"absolute"} width={"100%"}>
              <Box display={"flex"} justifyContent={"end"}>
                <IconButton onClick={closeModal}>
                  <Close style={{ alignSelf: "right" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <TicketView ticketUUID={ticketUUID} onArchiveTicket={closeModal} />
        </Box>
        {handleNext && (
          <SuiButtonTs
            {...buttonProps}
            onClick={handleNext}
            sx={{ ...navButtonStyle, right: -30, color: "black" }}
            endIcon={<AiOutlineRight size="20px" />}
          />
        )}
      </Box>
    </Modal>
  );
};
export default TicketModal;
