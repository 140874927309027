import React from "react";
import { EmergencyModel } from "api/services/emergencies/models";
import { Box, Tooltip } from "@mui/material";
import { SuiBadgeTs } from "components/SuiBadge";
import { Delete, Edit } from "@mui/icons-material";
import useEventEmitter from "lib/events/useEventEmitter";
import { usePermissions } from "stores/authentication/helpers";
import useDeleteEmergency from "api/services/emergencies/mutations/deleteEmergency";
import Swal from "sweetalert2";

const EmergencyActions: React.FC<{ emergency: EmergencyModel }> = ({
  emergency,
}) => {
  const editEmergency = useEventEmitter("editEmergency");
  const emergencyDeleted = useEventEmitter("emergencyDeleted");

  const canEditMaintenance = usePermissions().maintenances.edit;
  const canDeleteMaintenance = usePermissions().maintenances.delete;

  const { mutate: deleteEmergency, isPending } = useDeleteEmergency(
    emergency.uuid
  );

  const handleDeleteEmergency = () => {
    Swal.fire({
      title: "¿Quieres eliminar esta emergencia?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmergency(null, {
          onSuccess: (data) => {
            emergencyDeleted.emit(data);
          },
        });
      }
    });

    // ¿Quieres eliminar esta emergencia?
  };
  const handleEditEmergency = () => {
    editEmergency.emit(emergency);
  };

  return (
    <Box display={"flex"}>
      {canEditMaintenance && (
        <Tooltip title={"Editar emergencia"} placement="right">
          <Box>
            <SuiBadgeTs
              style={{ marginLeft: 2, cursor: "pointer" }}
              onClick={handleEditEmergency}
              badgeContent={<Edit />}
              color="warning"
              variant="gradient"
              container
            />
          </Box>
        </Tooltip>
      )}

      {canDeleteMaintenance && (
        <Tooltip title={"Borrar emergencia"} placement="right">
          <Box>
            <SuiBadgeTs
              style={{ marginLeft: 2, cursor: "pointer" }}
              onClick={handleDeleteEmergency}
              badgeContent={<Delete />}
              color="error"
              variant="gradient"
              container
              disabled
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default EmergencyActions;
