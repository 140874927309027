import { DocumentModel } from "api/services/documents/models";
import { useGetDocumentsPage } from "api/services/documents/queries/getDocumentsPage";
import { VisitModel } from "api/services/visits/models";
import LoadingView from "components/Loading";
import SwappiDataTable from "components/SwappiTable";
import { ColumnTable } from "components/SwappiTable/types";
import React from "react";

const columns: ColumnTable<DocumentModel>[] = [
  {
    header: "Nombre",
    accessorKey: "name",
  },
  {
    header: "Comunidad",
    accessorKey: "community",
    cell: ({ row }) => row.original.community.name,
  },
  {
    header: "Documento",
    accessorKey: "file",
    cell: ({ row }) => (
        <a target="_blank" href={`https://api.swappi.cl/documents/${row.original.uuid}/url/redirect`}>
            Ver
        </a>
    ),
},

];

const DocumentsTable: React.FC<{ visit: VisitModel }> = ({ visit }) => {
  const { data, isLoading } = useGetDocumentsPage({
    page: 1,
    page_size: 20,
    visit: visit.uuid,
  });
  return (
    <SwappiDataTable
      columns={columns}
      data={data.results}
      isLoading={isLoading}
    />
  );
};

export default DocumentsTable;
