/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';
import { getProvidersByManagement } from 'redux/actions/providers';
import { getAreas } from 'redux/actions/communities';
// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiDatePicker from 'components/SuiDatePicker';
// Data
import SuiButton from 'components/SuiButton';
import { createUnit } from 'redux/actions/communities';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createMaintenance } from 'redux/actions/maintenance';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { createPlanificationCategory } from 'redux/actions/analytics';
import SuiInput from 'components/SuiInput';
import { createPlanificationItem, editPlanificationItem } from 'redux/actions/analytics';
import { getAuthUserProfile } from 'stores/authentication/helpers';

function Form(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [name, setName] = useState(props.edit ? props.item.title : '');
  const [price, setPrice] = useState(props.edit ? props.item.budget : '');
  const providersResponse = useSelector((state) => state.providers.getProvidersByManagement);
  const [provider, setProvider] = useState(
    props.edit ? props.item.service_provider && props.item.service_provider.uuid : null
  );
  const [currency, setCurrency] = useState(props.edit ? props.item.currency : 'CLP');

  useEffect(() => {
    let management = getAuthUserProfile().management.slug;
    dispatch(getProvidersByManagement(management));
  }, []);
  const handleSubmit = (e) => {
    let form = {
      title: name,
      body: '',
      community: slug,
      budget: price,
      currency: currency,
      category: props.category,
    };
    if (provider) form.service_provider = provider;
    dispatch(createPlanificationItem(props.planification.uuid, form));
    props.close();
  };

  const handleSubmitEdit = (e) => {
    let form = {
      title: name,
      body: '',
      community: slug,
      currency: currency,
      budget: price,
      category: props.category,
    };
    if (provider) form.service_provider = provider;

    dispatch(editPlanificationItem(props.item.uuid, form));
    props.close();
  };

  return (
    <SuiBox>
      <Card id="basic-info" style={{ overflow: 'visible' }}>
        <SuiBox p={3}>
          <SuiTypography variant="h5">{props.edit ? 'Editar' : 'Crear'} Item</SuiTypography>
        </SuiBox>
        <SuiBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Nombre
                </SuiTypography>
              </SuiBox>

              <SuiInput
                placeholder="Nombre"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Proveedor
              </SuiTypography>
              <SuiSelect
                value={provider}
                size="small"
                onChange={(e) => setProvider(e.value)}
                options={
                  providersResponse.data
                    ? providersResponse.data.map((provider) => ({
                        value: provider.uuid,
                        label: provider.executive_name || provider.razon_social,
                      }))
                    : []
                }
              />
            </Grid>
            <Grid item xs={8}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Precio estimado
                </SuiTypography>
              </SuiBox>

              <SuiInput
                placeholder="Precio estimado"
                size="small"
                fullWidth
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Moneda
                </SuiTypography>
              </SuiBox>

              <SuiSelect
                placeholder="CLP"
                size="small"
                fullWidth
                value={currency}
                options={[
                  { value: 'CLP', label: 'CLP' },
                  { value: 'UF', label: 'UF' },
                ]}
                onChange={(e) => setCurrency(e.value)}
              />
            </Grid>
          </Grid>
          <SuiBox mt={2}>
            {props.edit ? (
              <SuiButton
                variant={'gradient'}
                color="warning"
                size="small"
                onClick={handleSubmitEdit}
              >
                Editar
              </SuiButton>
            ) : (
              <SuiButton variant={'gradient'} color="success" size="small" onClick={handleSubmit}>
                Crear
              </SuiButton>
            )}
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Form;
