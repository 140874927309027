/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import BuildingCard from "examples/Cards/BuildingCard";
import { useSelector, useDispatch } from "react-redux";

import CreateUnit from "./Create";
import DataTable from "examples/Tables/DataTable";

// General page component
import { useParams, useNavigate } from "react-router-dom";
import { getUnits } from "redux/actions/communities";

const unitsTableData = (buildingUnits) => {
  let columns = [
    { Header: "Torre", accessor: "building", width: "6%" },
    { Header: "Numero", accessor: "number", width: "6%" },
    { Header: "Email dueño", accessor: "ownerEmail", width: "12%" },
    { Header: "Nombre dueño", accessor: "ownerName", width: "12%" },
    { Header: "Rut dueño", accessor: "ownerRut", width: "12%" },
    { Header: "Email Arrendatario", accessor: "tenantEmail", width: "12%" },
    { Header: "Nombre Arrendatario", accessor: "tenantName", width: "12%" },
    { Header: "Rut Arrendatario", accessor: "tenantRut", width: "12%" },
    { Header: "Poder simple", accessor: "poderSimple" },
    { Header: "Acciones", accessor: "actions" },
  ];
  if (buildingUnits.data === undefined) {
    return { columns, rows: [] };
  }
  let rows = buildingUnits.data.map((unit, i) => {
    return {
      building: unit.building,
      number: unit.number,
      ownerEmail: unit.owner_email,
      tenantEmail: unit.tenant_email,
      poderSimple: unit.poder_simple == null ? "No" : "Si",
      ownerName: unit.owner_name,
      tenantName: unit.tenant_name,
      ownerRut: unit.owner_rut,
      tenantRut: unit.tenant_rut,
      actions: null,
    };
  });
  return { columns, rows };
};

function Units(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const units = useSelector((state) => state.communities.units);
  const [dataTable, setdataTable] = useState(unitsTableData(units));
  const createUnits = useSelector((state) => state.communities.createUnit);
  useEffect(() => {
    if (props.buildings) {
      dispatch(getUnits(slug));
    }
  }, []);

  useEffect(() => {
    if (props.buildings != undefined) {
      dispatch(getUnits(slug));
    }
  }, [props.buildings]);

  useEffect(() => {
    setdataTable(unitsTableData(units));
  }, [units]);

  useEffect(() => {
    dispatch(getUnits(slug));
  }, [createUnits]);

  return (
    <Card>
      <SuiBox p={5}>
        <Divider />
        <SuiTypography variant="h4" fontWeight="medium">
          Unidades
          <CreateUnit />
        </SuiTypography>

        <Divider />
        <DataTable table={dataTable} />
      </SuiBox>
    </Card>
  );
}

export default Units;
