import { Box, Typography } from '@mui/material';
import { ChecklistModel } from 'api/services/checklist/models';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
import useChecklistTemplateModalStore from '../../ChecklistTemplateModal/store';
import useAddObjectChecklistDrawerStore from '../../store';
import useAddObjectChecklistFromTemplate from 'api/services/checklist/mutations/AddTicketChecklistFromTemplate';
import { UUID } from 'crypto';
interface Props {
  objectUUID: UUID;
  checklist: ChecklistModel;
}
const TemplateItem: FC<Props> = ({ checklist, objectUUID }) => {
  const onAddSuccess = useAddObjectChecklistDrawerStore((state) => state.onAddSuccess);
  const openChecklistTemplateModal = useChecklistTemplateModalStore((state) => state.openModal);
  const closeDrawer = useAddObjectChecklistDrawerStore((state) => state.closeDrawer);

  const addChecklistToTicket = useAddObjectChecklistFromTemplate({ objectUUID });

  const handleAddItem = () => {
    addChecklistToTicket.mutate(
      {
        template: checklist.uuid,
      },
      {
        onSuccess: (checklist) => {
          onAddSuccess?.(checklist);
          closeDrawer();
        },
      }
    );
  };
  const handleOnViewChecklist = () => {
    closeDrawer();
    openChecklistTemplateModal(checklist.uuid);
  };
  return (
    <Box>
      <Box mb={1} ml={0.5} lineHeight={0} display="flex" style={{ justifyContent: 'space-around' }}>
        <Box style={{ width: '70%' }}>
          <Typography variant="body2">
            {
              /* break word if len higher than 15 */
              checklist.name.length > 20 ? checklist.name.substring(0, 20) + '...' : checklist.name
            }
          </Typography>
        </Box>

        <SuiButtonTs variant="text" color="info" size="small" onClick={handleOnViewChecklist}>
          Ver
        </SuiButtonTs>
        {/* <ChecklistTemplate checklist={item} /> */}
        <SuiButtonTs variant="text" color="success" size="small" onClick={handleAddItem}>
          Agregar
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default TemplateItem;
