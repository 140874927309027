import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

interface Params {
  objectUUID: UUID;
  checklistUUID: UUID;
}
const useDeleteChecklist = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/delete-checklist/${params.checklistUUID}`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.info({
        title: "Checklist eliminado",
        message: "El checklist ha sido eliminado de la tarea",
      });
    },
  });
};

export default useDeleteChecklist;
