import { Box } from '@mui/material';
import { SuiBadgeTs } from 'components/SuiBadge';
import React, { FC } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { useVisitReportModalStore } from '../VisitsReportModal/store';
import { VisitsReportsActionsProps } from './types';

const VisitsReportsActions: FC<VisitsReportsActionsProps> = ({ report }) => {
  const handleOpenModal = () => {
    useVisitReportModalStore.setState({
      isModalOpen: true,
      visitReport: report,
    });
  };
  return (
    <Box>
      <SuiBadgeTs
        container
        badgeContent={<AiOutlineEye />}
        size="xs"
        style={{ cursor: 'pointer' }}
        onClick={handleOpenModal}
      />
    </Box>
  );
};

export default VisitsReportsActions;
