import React, { useEffect } from 'react';
import { SuiButtonTs } from 'components/SuiButton';

import { TicketModel } from 'api/services/tickets/models';
import useMediaScreen from 'utils/Hooks/useMedia';
import useAddObjectChecklistDrawerStore from 'components/ChecklistsComponents/AddChecklistDrawer/store';
import { UUID } from 'crypto';
import { ChecklistModel } from 'api/services/checklist/models';

interface Props {
  objectUUID: UUID;
  onAdd: (checklist: ChecklistModel) => void;
  variant?: string;
  color?: string;
  alignButton?: string;
}
const AddChecklistButton: React.FC<Props> = ({ objectUUID, onAdd, color, variant, alignButton }) => {
  const { isMobile } = useMediaScreen();

  const openChecklistDrawer = useAddObjectChecklistDrawerStore((state) => state.openDrawner);
  const handleOnOpenDrawer = () => {
    openChecklistDrawer(objectUUID, onAdd);
  };
  return (
    <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
      <SuiButtonTs variant={variant ? variant : 'gradient'} color={color ? color : 'success'} alignButton={alignButton ? alignButton : "left"} size="small" onClick={handleOnOpenDrawer}>
        Agregar Checklist
      </SuiButtonTs>{' '}
    </div>
  );
};

export default AddChecklistButton;
