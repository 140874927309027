/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';

import DefaultDoughnutChart from 'examples/Charts/DoughnutCharts/DefaultDoughnutChart';
import PieChart from 'examples/Charts/PieChart';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
// Data
import { getDashboard } from 'redux/actions/analytics';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { BiLockOpen } from 'react-icons/bi';
import { FaTasks } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import {
  getWorkloadFormattedData,
  getPieFormattedData,
  getLineChartDataByMonth,
} from 'layouts/pages/charts/utils';
import { getPermission } from 'utils';
import { getSupervisorDashboard } from 'redux/actions/analytics';
import { getCommunitiesByUser } from 'redux/actions/communities';
import SuiSelect from 'components/SuiSelect';
import SuiDatePicker from 'components/SuiDatePicker';
import { useLocation } from 'react-router-dom';
import LoadingView from 'components/Loading';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
function Charts() {
  const dispatch = useDispatch();
  const dashboardResponse = useSelector((state) => state.analytics.getDashboard);
  const communitiesResponse = useGetCommunities();
  const { pathname } = useLocation();

  const [selectedCommunity, setselectedCommunity] = useState(null);
  const [startDate, setStartDate] = useState();
  const [first, setFirst] = useState(true);
  const [endDate, setEndDate] = useState();
  const supervisorDashboardResponse = useSelector(
    (state) => state.analytics.getSupervisorDashboard
  );
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    setLoading(true);
    if (getPermission('administrador') || getPermission('superadmin')) {
      dispatch(
        getDashboard({
          communities: selectedCommunity,
          created_before: endDate,
          created_after: startDate,
        })
      );
    } else if (getPermission('supervisor')) {
      dispatch(
        getSupervisorDashboard({
          communities: selectedCommunity,
          created_before: endDate,
          created_after: startDate,
        })
      );
    }
  }, [startDate, endDate, selectedCommunity]);

  useEffect(() => {
    setLoading(true);
  }, [pathname]);

  useEffect(() => {
    if (communitiesResponse.data) {
    }
  }, [communitiesResponse]);

  useEffect(() => {
    if (new Date() - dashboardResponse.time < 2000)
      if (dashboardResponse.data) {
        setDashboard(dashboardResponse.data);
        setLoading(false);
      }
  }, [dashboardResponse]);

  useEffect(() => {
    if (new Date() - supervisorDashboardResponse.time < 2000)
      if (supervisorDashboardResponse.data) {
        setDashboard(supervisorDashboardResponse.data);
        setLoading(false);
      }
  }, [supervisorDashboardResponse]);

  if (loading) return <LoadingView />;

  return (
    <SuiBox my={3}>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Comunidad
          </SuiTypography>
          <SuiSelect
            size="small"
            value={selectedCommunity}
            onChange={(e) => setselectedCommunity(e.value)}
            options={
              communitiesResponse.data
                ? communitiesResponse.data.map((e) => {
                    return { value: e.slug, label: e.name };
                  })
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Fecha inicio
          </SuiTypography>
          <SuiDatePicker value onChange={(e) => setStartDate(e[0].toISOString())} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Fecha Término
          </SuiTypography>
          <SuiDatePicker onChange={(e) => setEndDate(e[0].toISOString())} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: 'Duración promedio de tareas', fontWeight: 'bold' }}
            count={dashboard.avg_duration.toFixed(1) + ' días'}
            icon={<BiTimeFive />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: 'Tareas finalizadas', fontWeight: 'bold' }}
            count={dashboard.closed_tickets}
            icon={<HiOutlineLockClosed />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: 'Tareas abiertas', fontWeight: 'bold' }}
            count={dashboard.open_tickets}
            icon={<BiLockOpen />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: 'Total de tareas', fontWeight: 'bold' }}
            count={dashboard.ticket_count}
            icon={<FaTasks />}
          />
        </Grid>
      </Grid>

      <SuiBox mb={3} mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DefaultLineChart
              title="Cantidad de tareas cerradas por mes"
              chart={getLineChartDataByMonth(dashboard.tickets_closed_by_month)}
            />
          </Grid>
          {getPermission(['superadmin', 'administrador']) && (
            <Grid item xs={12} md={6}>
              <HorizontalBarChart
                title="Carga de trabajo por usuario"
                chart={getWorkloadFormattedData(
                  dashboard.staff_workloads,
                  'Tareas por usuario',
                  'primary'
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <HorizontalBarChart
              title="Carga de trabajo por comunidad"
              chart={getWorkloadFormattedData(
                dashboard.community_workloads,
                'Tareas por comunidad',
                'info'
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChart
              title="Distribución de tareas por estado"
              chart={getPieFormattedData(dashboard.ticket_state_distribution)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultDoughnutChart
              title="Distribución de tareas por prioridad"
              chart={getPieFormattedData(dashboard.ticket_priority_distribution)}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Charts;
