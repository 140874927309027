import React, { useMemo } from "react";
import { Box, Card, Typography, Tooltip, IconButton, CircularProgress } from "@mui/material";
import { ColumnTable } from "components/SwappiTable/types";
import SwappiDataTable from "components/SwappiTable";
import { useExportHistory, useExportHistoryUuid } from "api/services/analytics/queries";
import { ExportCenterPdfParams } from "api/services/analytics/types";
import { SuiBoxTs } from "components/SuiBox";
import DownloadIcon from "@mui/icons-material/Download";
import { useExportCenterStore } from '../store';
import { toastNotification } from "components/ui/ToastNotification";

const ExportHistoryTable = () => {
  const { data, isLoading } = useExportHistory();
  const loadReport = useExportCenterStore((state) => state.loadReport);
  
  const [selectedUuid, setSelectedUuid] = React.useState<string>();
  const [loadingUuid, setLoadingUuid] = React.useState<string | null>(null);
  
  const { data: reportData, refetch } = useExportHistoryUuid(selectedUuid);

  React.useEffect(() => {
    if (reportData) {
      loadReport({
        tickets: reportData.tickets,
        maintenances: reportData.maintenances,
        observationsMaintenances: reportData.export_history?.data?.maintenance_observations,
        visits: reportData.reports,
        documents: reportData.documents,
        observationsGeneral: reportData.export_history?.data.general_observations,
      });
      toastNotification.success({
        title: "Reporte cargado",
        message: "Se ha cargado correctamente el reporte exportado.",
      });
      setLoadingUuid(null);
    }
  }, [reportData, loadReport]);

  const columns = useMemo<ColumnTable<ExportCenterPdfParams>[]>(() => [
    {
      header: "Fecha Exportación",
      accessorKey: "created",
      cell: (info) => new Date(info.getValue() as string).toLocaleString('es-ES', {
        dateStyle: 'medium',
        timeStyle: 'short'
      }),
    },
    {
      header: "Tareas",
      accessorKey: "tickets",
      cell: (info) => (info.getValue() as string[]).length,
    },
    {
      header: "Documentos",
      accessorKey: "documents",
      cell: (info) => (info.getValue() as string[]).length,
    },
    {
      header: "Mantenimientos",
      accessorKey: "maintenances",
      cell: (info) => (info.getValue() as string[]).length,
    },
    {
      header: "Visitas en Terreno",
      accessorKey: "reports",
      cell: (info) => (info.getValue() as string[]).length,
    },
    {
      header: "Acciones",
      accessor: "actions",
      cell: ({ row }) => (
        <Tooltip title="Cargar reporte">
          <IconButton 
            onClick={() => handleLoadReport(row.original)}
            size="small"
            disabled={loadingUuid === row.original.uuid}
          >
            {loadingUuid === row.original.uuid ? (
              <CircularProgress size={24} />
            ) : (
              <DownloadIcon />
            )}
          </IconButton>
        </Tooltip>
      ),
    },
  ], [loadingUuid]);

  const handleLoadReport = async (rowData) => {
    setSelectedUuid(rowData.uuid);
    setLoadingUuid(rowData.uuid);
    await refetch();
  };

  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <SuiBoxTs>
          <SuiBoxTs mb={2} sx={{ textAlign: 'center' }}>
            <Typography variant="h4">Historial Centro de Exportación</Typography>
          </SuiBoxTs>
          <SwappiDataTable
            columns={columns}
            data={data || []}
            isLoading={isLoading}
            defaultPageSize={10}
            localSearch
          />
        </SuiBoxTs>
      </Card>
    </Box>
  );
};

export default ExportHistoryTable;
