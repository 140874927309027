import { types } from "../actions/types";

export const helperReducer = (state = { activityModalClosed: {} }, action) =>
{
    switch (action.type)
    {

        case types.setActivityModalClosed:
            return { activityModalClosed: action.payload };

        default:
            return state;
    }
};
