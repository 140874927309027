/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "examples/Tables/DataTablePagination";
import SuiBadge from "components/SuiBadge";
import Header from "layouts/pages/profile/components/Header";
import Loading from "components/Loading";
// General page component
import MemberList from "components/Tickets/MemberList";
import { useParams, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { getPermission } from "utils";
import SuiAvatar, { SuiAvatarTs } from "components/SuiAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import SuiSelect from "components/SuiSelect";
import { getCommunitiesByUser } from "redux/actions/communities";
import { getVisitReports } from "redux/actions/reports";
import { getUsersByManagement } from "redux/actions/users";
import Swal from "sweetalert2";
import { BiArchiveIn } from "react-icons/bi";
import { deleteVisitReport } from "redux/actions/reports";
import Create from "./Create";
import { mobileMaxWidth } from "utils";
import { isPermissionEnabled } from "utils";
import SuiInput from "components/SuiInput";
import ExportReports from "./Export";
import { useAuthenticationStore } from "stores/authentication";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";

const parseDate = (unix_timestamp) => {
  var date = new Date(unix_timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formattedTime =
    day +
    "/" +
    month +
    "/" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);

  return formattedTime;
};

const renderMembers = (members) => (
  <SuiBox display="flex">
    {members.map(
      (member, i) =>
        member && (
          <Tooltip key={i} title={member.first_name} placement="bottom">
            <SuiAvatarTs
              uuid={member.uuid}
              src={member.profile_picture}
              alt={member.first_name}
              size="xs"
              sx={({ borders: { borderWidth }, palette: { white } }) => ({
                ml: -1.25,
                border: `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",
                "&:hover, &:focus": {
                  zIndex: "10",
                },
              })}
              style={{ backgroundColor: "black" }}
            />
          </Tooltip>
        )
    )}
  </SuiBox>
);

function AdmiVisit(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { slug, providerUuid } = useParams();
  const getAdminVisitsResponse = useSelector(
    (state) => state.reports.getVisitReports
  );
  const createMaintenanceResponse = useSelector(
    (state) => state.maintenance.createMaintenance
  );
  const [member, setMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [dataTable, setdataTable] = useState(null);
  const [viewCompleted, setViewCompleted] = useState(false);
  const [loading, setloading] = useState(true);
  const [communitiesFilters, setCommunitiesFilters] = useState([]);
  const deleteVisitResponse = useSelector(
    (state) => state.reports.deleteVisitReport
  );
  const [selectedCommunity, setSelectedCommunity] = useState(false);
  const getCommunitiesByUserResponse = useGetCommunities();
  const [title, setTitle] = useState("");
  const createVisitReportResponse = useSelector(
    (state) => state.communities.createVisitReport
  );
  const usersManagementResponse = useSelector(
    (state) => state.users.getUsersByManagement
  );
  const [selectedProvider, setSelectedProvider] = useState("");
  const [canNext, setCanNext] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortType, setSortType] = useState("none");
  const [sortWay, setSortWay] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const doRequest = () => {
    let params = {
      title: title,
      page: page,
      page_size: pageSize,
      community: slug ? slug : selectedCommunity,
      o: sortWay + sortType,
    };

    if (member) {
      params.author = member;
    }
    dispatch(getVisitReports(params));
  };

  useEffect(() => {
    if (createVisitReportResponse.data) {
      if (Date.now() - createVisitReportResponse.message < 3000)
        navigate("/visits/report/" + createVisitReportResponse.data.uuid);
    }
  }, [createVisitReportResponse]);

  const unitsTableData = (reports) => {
    let columns = [
      { Header: "Titulo", accessor: "title", width: "30%" },
      { Header: "Comunidad", accessor: "community", width: "20%" },
      { Header: "Usuario", accessor: "usuario", width: "10%" },
      { Header: "Creado", accessor: "created", width: "30%" },
      { Header: "Acciones", accessor: "actions" },
    ];
    let rows = reports.map((report, i) => {
      let date = parseDate(report.created);
      /* get contract in maintenance.contracts filter by is_active */
      return {
        community: report.community.name,
        title: (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (isMobile) navigate("/visits/report/" + report.uuid);
              else window.open("/visits/report/" + report.uuid, "_blank");
            }}
          >
            {report.title}
          </p>
        ),
        usuario: <MemberList members={[report.author]} />,
        created: date,
        actions: (
          <div>
            <Tooltip title={"Ver Reporte"} placement="top">
              <SuiBadge
                container
                badgeContent={<AiOutlineEye size="16px" />}
                size="xs"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (true) navigate("/visits/report/" + report.uuid);
                  else window.open("/visits/report/" + report.uuid, "_blank");
                }}
                bgcolor={"info"}
              ></SuiBadge>
            </Tooltip>
            <br />
            {isPermissionEnabled("fieldVisits-deleteVisit") && (
              <Tooltip title={"Eliminar Reporte"} placement="top">
                <SuiBadge
                  container
                  badgeContent={<BiArchiveIn size="16px" />}
                  size="xs"
                  style={{ cursor: "pointer", marginTop: 2 }}
                  onClick={() => {
                    Swal.fire({
                      title: "Quieres eliminar este reporte?",
                      showDenyButton: true,
                      confirmButtonText: "Si",
                      denyButtonText: `No`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        dispatch(deleteVisitReport(report.uuid));
                        Swal.fire("Archivado!", "", "success");
                      } else if (result.isDenied) {
                        Swal.fire("Reporte no archivado", "", "error");
                      }
                    });
                  }}
                  color={"error"}
                ></SuiBadge>
              </Tooltip>
            )}
          </div>
        ),
      };
    });
    return { columns, rows };
  };

  useEffect(() => {
    if (deleteVisitResponse.data) {
      doRequest();
    }
  }, [deleteVisitResponse]);

  useEffect(() => {
    let profile = useAuthenticationStore.getState().userProfile;
    doRequest();
    if (getPermission(["superadmin", "administrador"])) {
      if (!props.member) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getUsersByManagement(profile.management.slug));
      }
    }
  }, []);

  useEffect(() => {
    if (createMaintenanceResponse.data) {
      doRequest();
    }
  }, [createMaintenanceResponse]);

  useEffect(() => {
    if (usersManagementResponse.data) {
      setMembers(usersManagementResponse.data);
    }
  }, [usersManagementResponse]);

  useEffect(() => {
    if (getAdminVisitsResponse.data) {
      setCanNext(getAdminVisitsResponse.data.next);
      setTotalEntries(getAdminVisitsResponse.data.count);
      setCanPrev(getAdminVisitsResponse.data.previous);
      setdataTable(unitsTableData(getAdminVisitsResponse.data.results));
      setloading(false);
    }
  }, [getAdminVisitsResponse]);

  useEffect(() => {
    doRequest();
  }, [selectedCommunity, selectedProvider, pageSize, page, member, title]);

  return (
    <SuiBox p={0} pt={4}>
      <SuiTypography variant="h4" textAlign="center" fontWeight="medium">
        Visitas en Terreno
        <SuiTypography
          style={{
            cursor: "pointer",
            padding: 15,
            float: "right",
            paddingBottom: 0,
          }}
          variant="button"
          onClick={(e) => setViewCompleted(!viewCompleted)}
        >
          {" "}
        </SuiTypography>
      </SuiTypography>

      <Divider />
      {loading ? (
        <Loading />
      ) : (
        <SuiBox>
          <Card sx={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}>
            <SuiBox>
              <SuiBox
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <SuiTypography variant="h5" fontWeight="medium" mb={1}>
                  Filtros
                </SuiTypography>
                <SuiBox style={{ display: "flex", justifyContent: "flex-end" }}>
                  {getPermission([
                    "superadmin",
                    "administrador",
                    "supervisor",
                  ]) && <ExportReports />}
                  {isPermissionEnabled("fieldVisits-createVisit") && <Create />}
                </SuiBox>
              </SuiBox>
              <SuiBox mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <SuiBox>
                      <SuiTypography variant="h6" fontWeight="medium">
                        Título
                      </SuiTypography>
                      <SuiInput
                        placeholder="Título"
                        size="small"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </SuiBox>
                  </Grid>
                  {!slug && (
                    <Grid item xs={12} md={6} lg={6}>
                      <SuiBox>
                        <SuiTypography variant="h6" fontWeight="medium">
                          Comunidades
                        </SuiTypography>
                        <SuiSelect
                          size="small"
                          value={selectedCommunity}
                          onChange={(e) => setSelectedCommunity(e.value)}
                          options={[
                            { label: "Todas", value: false },
                            ...getCommunitiesByUserResponse?.data?.map((community) => {
                              return { label: community.name, value: community.slug };
                            }),
                          ]}
                        />
                      </SuiBox>
                    </Grid>
                  )}
                  {getPermission(["superadmin", "administrador"]) && (
                    <Grid item xs={12} md={6}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        Colaborador
                      </SuiTypography>
                      <SuiSelect
                        size="small"
                        value={member}
                        placeholder="Colaborador"
                        style={{ zIndex: 1000, width: "100%" }}
                        options={[
                          { value: "", label: "Todos" },
                          ...members.map((member) => {
                            return {
                              value: member.email,
                              label: member.first_name,
                            };
                          }),
                        ]}
                        onChange={(e) => setMember(e.value)}
                      />
                    </Grid>
                  )}
                </Grid>
              </SuiBox>

              <Divider />
            </SuiBox>
            {dataTable && (
              <DataTable
                totalEntries={totalEntries}
                canSearch={false}
                table={dataTable}
                changePage={setPage}
                canNext={canNext}
                canPrev={canPrev}
                page={page}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            )}
          </Card>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default AdmiVisit;
