import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { UUID } from 'crypto';
import { DocumentModel } from '../models';
import { TicketQueryHelper } from 'api/services/tickets/queries/GetTicket';

interface Params {
  ticketUUID?: UUID;
  documentUUID: UUID;
}

interface Input {
  is_recommended?: boolean;
}
const useUpdateDocument = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/documents/update/${params.documentUUID}`;
      return axios.put<DocumentModel>(url, input).then((res) => res.data);
    },
    onSuccess: (data) => {
      if (params.ticketUUID) TicketQueryHelper.updateDocument(data, params.ticketUUID);
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export default useUpdateDocument;
