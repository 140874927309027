import { types } from "../actions/types";

export const documentsReducer = (
    state = {
        getDocumentsTags: {}, approveDocument: {}, getDocumentsByArea: {},
        getDocumentsByCommunity: {}, getDocumentsByManagement: {}, updateDocument: {},
        deleteDocument: {}, addDocument: {}, getDocumentsTagsByManagement: {},
    },
    action
) =>
{
    switch (action.type)
    {
        case types.setGetDocumentsTags:
            return {
                ...state,
                getDocumentsTags: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        case types.setApproveDocument:
            return {
                ...state,
                approveDocument: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        case types.setGetDocumentsByArea:
            return {
                ...state,
                getDocumentsByArea: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        case types.setGetDocumentsByCommunity:
            return {
                ...state,
                getDocumentsByCommunity: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        case types.setGetDocumentsByManagement:
            return {
                ...state,
                getDocumentsByManagement: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        case types.setUpdateDocument:
            return {
                ...state,
                updateDocument: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                    time: new Date(),
                },
            };
        case types.setAddDocument:
            return {
                ...state,
                addDocument: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                    time: new Date(),
                },
            };
        case types.setDeleteDocument:
            return {
                ...state,
                deleteDocument: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                    time: new Date(),
                },
            };
        case types.setGetDocumentsTagsByManagement:
            return {
                ...state,
                getDocumentsTagsByManagement: {
                    status: action.payload.status,
                    data: action.payload.data,
                    title: action.payload.title,
                },
            };
        default:
            return state;
    }
};
