import React, { FC, JSX } from 'react';
import { StepConnector, stepConnectorClasses, styled } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#2164ff',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#2164ff',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#A3A3A8',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

0;

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#A3A3A8',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#2164ff',
  }),
  ...(ownerState.completed && {
    color: '#2164ff',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#2164ff',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

export interface ExportIconsProps extends StepIconProps {
  labelIcon: JSX.Element;
}
export const ExportIcons: FC<ExportIconsProps> = (props) => {
  const { active, completed, className, labelIcon } = props;
  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {labelIcon}
    </QontoStepIconRoot>
  );
};
