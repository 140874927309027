import Axios from 'axios';
import {
  clearSessionStorage,
  getAccessToken,
  storeAccessToken,
} from 'stores/authentication/helpers';
import { isTokenExpired } from './axios.helper';
import { refreshAccessToken } from 'api/services/auth/query/refreshAccessToken';

Axios.interceptors.request.use(async (config) => {
  const token = getAccessToken();
  if (!token) return config;
  if (isTokenExpired(token)) {
    try {
      const refreshResponse = await refreshAccessToken();
      storeAccessToken(refreshResponse.token);
      config.headers.common['Authorization'] = `Bearer ${refreshResponse.token}`;
      return config;
    } catch (err) {
      clearSessionStorage();
      const controller = new AbortController();
      controller.abort();
    }
  }
  config.headers.common['Authorization'] = `Bearer ${token}`;
  return config;
});

Axios.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  // if error is 401
  if (error) {
    // console.log(error);
    if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
      if (err.response.data.detail === 'No active account found with the given credentials') {
        return Promise.reject(err);
      } else if (err.response.data.code === 'token_not_valid') {
        // localStorage.removeItem('access-token');
        // localStorage.removeItem('refresh-token');
        // localStorage.removeItem('profile');
        // window.location.reload();
      } else {
        // localStorage.removeItem('access-token');
        // localStorage.removeItem('refresh-token');
        // localStorage.removeItem('profile');
        // window.location.reload();
      }
    }
    return Promise.reject(error);
  }
});
