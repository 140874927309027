import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import CompleteVisitButton from "./CompleteVisitButton";
import VisitDetails from "./VisitDetails";
import VisitDocuments from "./VisitDocuments";
import EditVisitButton from "./EditVist";
import { usePermissions } from "stores/authentication/helpers";
import DeleteVisitButton from "./VisistsTable/DeleteVisitButton";

interface Props {
  visit: VisitModel;
  modalRef: React.RefObject<any>;
}

const VisitView: React.FC<Props> = ({ visit, modalRef }) => {
  const canEditVisit = usePermissions().visits.edit;
  const canDeleteVisit = usePermissions().visits.delete;
  return (
    <Box
      sx={{
        minHeight: '50vh',
        maxHeight: '70vh',
        width: '40vw',
        overflow: 'auto',
      }}
    >
      <Box display={"flex"} gap={2}>
        <Box flex={1}>
          <Typography fontWeight="medium">{visit.title}</Typography>
        </Box>
        {canEditVisit && (
          <Box>
            <EditVisitButton visit={visit} />
          </Box>
        )}
        {canDeleteVisit && (
          <Box>
            <DeleteVisitButton
              visit={visit}
              onCloseModal={() => modalRef.current?.close()}
            />
          </Box>
        )}
      </Box>
      <Divider />
      <Stack spacing={2}>
        <VisitDetails visit={visit} />
        {!visit.completed && <CompleteVisitButton visit={visit} />}
        {visit.completed && <VisitDocuments visit={visit} />}
      </Stack>
    </Box>
  );
};

export default VisitView;
