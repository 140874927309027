import { Box, Icon } from "@mui/material";
import { DocumentModel } from "api/services/documents/models";
import useDeleteDocument from "api/services/documents/mutations/DeleteDocument";
import DocumentUrl from "components/Documents/DocumentUrl";
import EditFileDrawer from "components/EditFileDrawer";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import { usePermissions } from "stores/authentication/helpers";
import Swal from "sweetalert2";

const TableActions: React.FC<{ document: DocumentModel }> = ({ document }) => {
  const canEditDocument = usePermissions().documents.edit;
  const canDeleteDocument = usePermissions().documents.delete;
  const { mutate: deleteDocument } = useDeleteDocument();
  const documentDeleted = useEventEmitter("documentDeleted");
  return (
    <Box display={"flex"} gap={1}>
      <DocumentUrl uuid={document.uuid} />
      {canEditDocument && (
        <EditFileDrawer
          file={document}
          community={document.community && document.community.slug}
          slugType={document.type.slug}
        />
      )}
      {canDeleteDocument && (
        <Icon
          sx={{ cursor: "pointer" }}
          fontSize="small"
          onClick={() =>
            Swal.fire({
              title: "¿Quieres eliminar este documento?",
              showDenyButton: true,
              confirmButtonText: "Si",
              denyButtonText: `No`,
            }).then((result) => {
              if (result.isConfirmed) {
                deleteDocument(
                  { uuid: document.uuid },
                  { onSuccess: (data) => documentDeleted.emit(data) }
                );
              }
            })
          }
        >
          delete
        </Icon>
      )}
    </Box>
  );
};

export default TableActions;
