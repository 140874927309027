/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiEditor from "components/SuiEditor";
import SuiSelect from "components/SuiSelect";
import { validadorEmail } from "utils";
import { validate, clean, format, getCheckDigit } from "rut.js";
// NewProduct page components
import FormField from "../FormField";

function GeneralInfo(props) {
  const [editorValue, setEditorValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br>"
  );
  const [rutValido, setrutValido] = useState(false);

  return (
    <SuiBox>
      <SuiTypography variant="h5">Información Proveedor</SuiTypography>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              value={props.name}
              label="Nombre"
              onChange={(e) => props.setname(e.target.value)}
              placeholder="Pedro Pérez"
            />
            <SuiTypography variant="caption">* este campo es obligatorio</SuiTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Giro"
              value={props.giro}
              onChange={(e) => props.setgiro(e.target.value)}
              placeholder="Sin giro"
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Email"
              value={props.email}
              error={props.email != "" && !validadorEmail(props.email)}
              onChange={(e) => props.setemail(e.target.value)}
              placeholder="pedro@swappi.cl"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Teléfono"
              value={props.phone}
              onChange={(e) => props.setphone(e.target.value)}
              placeholder="+56991652878"
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Rut"
              value={props.rut}
              onChange={(e) => {
                let formatted = format(e.target.value);
                props.setrut(formatted);
                setrutValido(validate(formatted));
              }}
              error={!rutValido && props.rut != ""}
              placeholder="11111111-1"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              onChange={(e) => props.setrazonSocial(e.target.value)}
              type="text"
              value={props.razonSocial}
              label="Razón Social"
              placeholder=""
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default GeneralInfo;
