import { DocumentModel } from "api/services/documents/models";
import { TicketModel } from "api/services/tickets/models";
import { create } from "zustand";

export interface EditFileDrawerState {
  isOpen: boolean;
  document: DocumentModel;
  ticket: TicketModel;
  // setDocument: (document: SwappiDocument) => void
  // openDrawner: () => void
  closeDrawner: () => void;
}

export const useDocumentEditorDrawer = create<EditFileDrawerState>((set) => ({
  isOpen: false,
  document: null,
  ticket: null,
  closeDrawner: () => set({ isOpen: false }),
}));

export const DocumentEditorDrawner = {
  setDocument: (document: DocumentModel, ticket: TicketModel) => {
    useDocumentEditorDrawer.setState({
      isOpen: true,
      ticket,
      document,
    });
  },
};
