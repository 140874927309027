import { useEffect, useMemo } from "react";
import { useAuthenticationStore } from ".";
import useRefreshAccessToken from "api/services/auth/query/refreshAccessToken";
import useGetAuthUserProfile from "api/services/user/queries/getUserSessionProfile";
import { GroupRole } from "routes/types";

export const setAuthenticationStore = useAuthenticationStore.setState;

export const getAuthUserProfile = () =>
  useAuthenticationStore.getState().userProfile;

export const getAccessToken = () =>
  useAuthenticationStore.getState().accessToken;

export const clearSessionStorage = () =>
  useAuthenticationStore.setState({
    isAuthenticated: false,
    accessToken: undefined,
    userProfile: undefined,
  });
export const storeAccessToken = (input: string) =>
  useAuthenticationStore.setState({ accessToken: input });

export const usePermissions = () => {
  const userProfile = useAuthenticationStore((state) => state.userProfile);
  return useMemo(
    () => ({
      canCreateDocument:
        userProfile?.permissions.documents?.createDocument?.enabled,
      canEditDocument:
        userProfile?.permissions.documents?.editDocument?.enabled,
      canEditTask: userProfile?.permissions.tasks?.editTask?.enabled,
      canDeleteTask: userProfile?.permissions.tasks?.deleteTask?.enabled,
      tasks: {
        create: userProfile?.permissions.tasks?.createTask?.enabled,
      },
      documents: {
        create: userProfile?.permissions.documents?.createDocument?.enabled,
        edit: userProfile?.permissions.documents?.editDocument?.enabled,
        delete: userProfile?.permissions.documents?.deleteDocument?.enabled,
      },
      visits: {
        create: userProfile?.permissions.visits?.createVisit?.enabled,
        edit: userProfile?.permissions.visits?.editVisit?.enabled,
        delete: userProfile?.permissions.visits?.deleteVisit?.enabled,
      },
      maintenances: {
        edit: userProfile?.permissions.maintenances?.editMaintenance?.enabled,
        delete:
          userProfile?.permissions.maintenances?.deleteMaintenance?.enabled,
      },
    }),
    [userProfile]
  );
};

export const useAuthenticate = () => {
  const isAuthenticated = useAuthenticationStore(
    (state) => state.isAuthenticated
  );
  const userProfile = useAuthenticationStore((state) => state.userProfile);
  const refreshAccessToken = useRefreshAccessToken();
  const getAuthUserProfile = useGetAuthUserProfile();

  const fetchAccessToken = async () => {
    const response = await refreshAccessToken.refetch();
    if (response.status === "success") {
      setAuthenticationStore({
        isAuthenticated: true,
        accessToken: response.data.token,
      });
    }
    if (response.status === "error") {
      setAuthenticationStore({
        isAuthenticated: false,
      });
    }
  };

  const fetchAuthProfile = async () => {
    const response = await getAuthUserProfile.refetch();
    if (response.status === "success") {
      setAuthenticationStore({
        userProfile: response.data,
      });
    }
    if (response.status === "error") {
      setAuthenticationStore({
        isAuthenticated: false,
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated === undefined) {
      fetchAccessToken();
    }
    if (isAuthenticated === true && userProfile === undefined) {
      fetchAuthProfile();
    }
  }, [isAuthenticated]);

  const closeSession = () => {
    setAuthenticationStore({
      isAuthenticated: false,
      accessToken: undefined,
      userProfile: undefined,
    });
  };
  return { isAuthenticated, userProfile, closeSession };
};
