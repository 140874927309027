/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { createProviderByManagement } from "redux/actions/providers";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { useDispatch, useSelector } from "react-redux";
// NewProduct page components
import GeneralInfo from "./components/GeneralInfo";
import BankAccountInfo from "./components/BankAccountInfo";

import { validadorEmail } from "utils";
import { getAuthUserProfile } from "stores/authentication/helpers";

const steps = ["1. Información General", "2. Datos Bancarios"];

function Form(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [razonSocial, setrazonSocial] = useState("");
  const [giro, setgiro] = useState("");
  const [rut, setrut] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [accountRut, setaccountRut] = useState("");
  const [accountBank, setaccountBank] = useState("");
  const [accountType, setaccountType] = useState("");
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCreateProvider = () => {
    if (email != "" && !validadorEmail(email)) {
      return;
    }
    let form = {
      razon_social: razonSocial,
      giro: giro,
      rut: rut,
      executive_name: name,
      email: email,
      phone: phone,
      account_number: accountNumber,
      account_rut: accountRut,
      account_bank: accountBank,
      account_type: accountType,
      contacts: {},
    };
    let profile = getAuthUserProfile();
    dispatch(createProviderByManagement(profile.management.slug, form));
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <GeneralInfo
            setrazonSocial={setrazonSocial}
            razonSocial={razonSocial}
            setgiro={setgiro}
            giro={giro}
            setrut={setrut}
            setname={setname}
            name={name}
            setemail={setemail}
            email={email}
            setphone={setphone}
            phone={phone}
            rut={rut}
          />
        );
      case 1:
        return (
          <BankAccountInfo
            setaccountNumber={setaccountNumber}
            setaccountRut={setaccountRut}
            setaccountBank={setaccountBank}
            accountRut={accountRut}
            setaccountType={setaccountType}
            accountBank={accountBank}
            accountType={accountType}
          />
        );
      default:
        return null;
    }
  }

  return (
    <SuiBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ overflow: "visible" }}>
            <SuiBox p={2}>
              <SuiBox>
                {getStepContent(activeStep)}
                <SuiBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  {activeStep === 0 ? (
                    <SuiBox />
                  ) : (
                    <SuiButton
                      variant="gradient"
                      color="secondary"
                      onClick={handleBack}
                    >
                      volver
                    </SuiButton>
                  )}
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      if (!isLastStep) {
                        handleNext();
                      } else {
                        handleCreateProvider();
                        props.handleClose();
                      }
                    }}
                  >
                    {isLastStep ? "Crear" : "Siguiente"}
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Form;
