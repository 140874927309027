import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { toastNotification } from "components/ui/ToastNotification";

const useDeleteMaintenance = (uuid: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/maintenance/${uuid}/delete`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Mantención eliminada",
        message: "La mantención ha sido eliminada con éxito",
      });
    },
  });
};

export default useDeleteMaintenance;
