/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import BuildingCard from 'examples/Cards/BuildingCard';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';
import SuiProgress from 'components/SuiProgress';
import Header from 'layouts/pages/profile/components/Header';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Loading from 'components/Loading';
import Checkbox from '@mui/material/Checkbox';
// General page component
import { useParams, useNavigate } from 'react-router-dom';

import { getPermission } from 'utils';
import SuiAvatar from 'components/SuiAvatar';
import Tooltip from '@material-ui/core/Tooltip';
import MaintenanceModal from 'layouts/pages/maintenance/View/MaintenanceCard';
import { mobileMaxWidth } from 'utils';
import CreateMaintenanceModal from 'layouts/pages/maintenance/Create';

const parseDate = (unix_timestamp) => {
  var date = new Date(unix_timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = '0' + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = '0' + date.getSeconds();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formattedTime =
    day +
    '/' +
    month +
    '/' +
    year +
    ' ' +
    hours +
    ':' +
    minutes.substr(-2) +
    ':' +
    seconds.substr(-2);

  return formattedTime;
};

const renderMembers = (members) => (
  <SuiBox display="flex">
    {members.map((member, i) => (
      <Tooltip key={i} title={member.first_name} placement="bottom">
        <SuiAvatar
          src={member.profile_picture}
          alt={member.first_name}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            ml: -1.25,
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: 'pointer',
            position: 'relative',
            '&:hover, &:focus': {
              zIndex: '10',
            },
          })}
          style={{ backgroundColor: 'black' }}
        />
      </Tooltip>
    ))}
  </SuiBox>
);

const unitsTableData = (maintenances) => {
  let columns = [
    { Header: 'Comunidad', accessor: 'community', width: '20%' },
    { Header: 'Area común', accessor: 'area', width: '20%' },
    { Header: 'Proveedor', accessor: 'provider', width: '20%' },
    { Header: 'Fecha expiración', accessor: 'end_date', width: '20%' },
    { Header: 'Contrato', accessor: 'contract', width: '20%' },
    { Header: 'Certificación', accessor: 'certification', width: '20%' },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  let rows = maintenances.map((maintenance, i) => {
    let date = Date.parse(maintenance.created);
    /* get contract in maintenance.contracts filter by is_active */
    let contract = maintenance?.contract?.filter((contract) => contract.is_active);
    let certification = maintenance?.documents?.filter(
      (document) => document.type.slug == 'certificacion' && document.is_active
    );
    contract = contract && contract[contract.length - 1];
    certification = certification && certification[certification.length - 1];
    return {
      community: maintenance.community.name,
      area: maintenance.area.name,
      provider: maintenance.service_provider
        ? maintenance.service_provider.executive_name || maintenance.service_provider.razon_social
        : 'Sin proveedor',
      end_date: parseDate(maintenance.end_date),
      contract:
        contract && contract.file ? (
          <a target="_blank" href={contract && contract.file}>
            <SuiBadge
              style={{ float: 'left', cursor: 'pointer' }}
              badgeContent={'Contrato'}
              size="xs"
              container
              color={new Date(contract.expires_on) < new Date() ? 'warning' : 'success'}
            />
          </a>
        ) : (
          <SuiBadge
            style={{ float: 'left' }}
            badgeContent={'Sin Contrato'}
            size="xs"
            container
            color="error"
          />
        ),
      certification:
        certification && certification.file ? (
          <a target="_blank" href={certification && certification.file}>
            <SuiBadge
              style={{ float: 'left', cursor: 'pointer' }}
              badgeContent={'Certificación'}
              size="xs"
              container
              color={new Date(certification.expires_on) < new Date() ? 'warning' : 'success'}
            />
          </a>
        ) : (
          <SuiBadge
            style={{ float: 'left' }}
            badgeContent={'Sin Certificación'}
            size="xs"
            container
            color="error"
          />
        ),
      actions: (
        <div>
          <MaintenanceModal modal={true} maintenance={maintenance} />
        </div>
      ),
    };
  });
  return { columns, rows };
};

function ActionPlanMaintenancesTable(props) {
  const dispatch = useDispatch();
  const { slug, providerUuid } = useParams();

  const [dataTable, setdataTable] = useState(unitsTableData(props.maintenances));

  useEffect(() => {
    if (props.maintenances) {
      setdataTable(unitsTableData(props.maintenances));
    }
  }, [props.maintenances]);

  const renderContent = () => {
    return (
      <SuiBox p={window.innerWidth <= mobileMaxWidth ? 0 : 4} pt={3}>
        <SuiBox display="flex" justifyContent="space-between" mb={2}>
          {' '}
          <SuiTypography variant="h4" fontWeight="medium">
            Mantenciones
          </SuiTypography>
          <CreateMaintenanceModal />
        </SuiBox>
        <p style={{ fontSize: 15, fontWeight: 100 }}>
          * sólo se mostrarán mantenciones que tengan fecha de expiración próxima.
        </p>
        <DataTable pageSize={5} canSearch={false} table={dataTable} />
      </SuiBox>
    );
  };

  return <Card>{renderContent()}</Card>;
}

export default ActionPlanMaintenancesTable;
