/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import { useSelector, useDispatch } from 'react-redux';

// General page component
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../profile/components/Header';
import Loading from 'components/Loading';
import { getCommunitiesByUser } from 'redux/actions/communities';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import { Card, Checkbox, Divider, Grid } from '@mui/material';
import { getPermission } from 'utils';
import { getUsersByManagement } from 'redux/actions/users';
import { getActionPlan } from 'redux/actions/communities';
import ActionPlanTicketsTable from './TicketsTable';
import ActionPlanVisitTable from './VisitsTable';
import ActionPlanDocumentsTable from './DocumentsTable';
import ActionPlanMaintenancesTable from './MaintenancesTable';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
import { FaHouseUser, FaTasks } from 'react-icons/fa';
import { GrHostMaintenance, GrVmMaintenance } from 'react-icons/gr';
import { HiDocument } from 'react-icons/hi';
import { AiFillTool } from 'react-icons/ai';
import PieChart from 'examples/Charts/PieChart';
import Reminders from './Reminders';
import LoadingView from 'components/Loading';
import { useAuthenticationStore } from 'stores/authentication';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';

const lastMonth = 'lastMonth';
const lastWeek = 'lastWeek';
const nextWeek = 'nextWeek';
const nextMonth = 'nextMonth';

const getPlan = (type, plans) => {
  let plan = plans.data;
  if (type === lastMonth) {
    return plan.last_month;
  } else if (type === lastWeek) {
    return plan.last_week;
  } else if (type === nextWeek) {
    return plan.next_week;
  } else if (type === nextMonth) {
    return plan.next_month;
  }
};

function ActionPlan() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const communities = useGetCommunities();
  const usersManagementResponse = useSelector((state) => state.users.getUsersByManagement);
  const planResponse = useSelector((state) => state.communities.getActionPlan);

  const createTicketResponse = useSelector((state) => state.tickets.createTicket);
  const createTicketRecurrentResponse = useSelector((state) => state.tickets.createTicketRecurrent);
  const createReminderResponse = useSelector((state) => state.users.createReminder);
  const updateReminderResponse = useSelector((state) => state.users.updateReminder);

  const editMaintenanceResponse = useSelector((state) => state.maintenance.editMaintenance);
  const editVisitResponse = useSelector((state) => state.communities.editVisit);
  const updateDocumentResponse = useSelector((state) => state.documents.updateDocument);
  const updateTicketResponse = useSelector((state) => state.tickets.updateTickets);

  const deleteMaintenanceResponse = useSelector((state) => state.maintenance.deleteMaintenance);
  const deleteVisitResponse = useSelector((state) => state.communities.deleteVisit);
  const deleteDocumentResponse = useSelector((state) => state.documents.deleteDocument);
  const deleteTicketResponse = useSelector((state) => state.tickets.archiveTicket);
  const deleteReminderResponse = useSelector((state) => state.users.deleteReminder);

  const [reminders, setReminders] = useState([]);

  const [communitiesFilters, setCommunitiesFilters] = useState([]);
  const [member, setMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [nextType, setNextType] = useState(nextWeek);
  const [chart, setChart] = useState({});
  const [plan, setPlan] = useState(null);

  const [showTickets, setShowTickets] = useState(true);
  const [showVisits, setShowVisits] = useState(true);
  const [showDocuments, setShowDocuments] = useState(true);
  const [showMaintenances, setShowMaintenances] = useState(true);
  const [showReminders, setShowReminders] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (getPermission(['superadmin', 'administrador'])) {
      let profile = useAuthenticationStore.getState().userProfile;
      dispatch(getUsersByManagement(profile.management.slug));
    }
  }, []);

  useEffect(() => {
    if (usersManagementResponse.data) {
      setMembers(usersManagementResponse.data);
    }
  }, [usersManagementResponse]);

  useEffect(() => {
    let management = useAuthenticationStore.getState().userProfile.management.slug;
    dispatch(
      getActionPlan({
        communities: communitiesFilters.map((c) => c.value),
        management,
        users: member ? [member] : [],
      })
    );
    setLoading(true);
  }, [communitiesFilters, member]);

  useEffect(() => {
    if (planResponse.data) {
      setReminders(planResponse.data.reminders);
      let selectedPlan = getPlan(nextType, planResponse);
      setPlan(selectedPlan);
      setChart(mapChart(selectedPlan.metrics.tickets_by_priority));
      setLoading(false);
    }
  }, [planResponse]);

  useEffect(() => {
    if (!planResponse.data) return;
    setReminders(planResponse.data.reminders);
    let selectedPlan = getPlan(nextType, planResponse);
    setPlan(selectedPlan);
    setChart(mapChart(selectedPlan.metrics.tickets_by_priority));
  }, [nextType]);

  useEffect(() => {
    // List of response objects to check
    const responses = [
      createTicketResponse,
      createTicketRecurrentResponse,
      createReminderResponse,
      updateDocumentResponse,
      updateTicketResponse,
      updateReminderResponse,
      editMaintenanceResponse,
      editVisitResponse,
      deleteMaintenanceResponse,
      deleteVisitResponse,
      deleteDocumentResponse,
      deleteTicketResponse,
      deleteReminderResponse,
    ];

    // Current time
    const now = new Date();
    // Check each response to see if its time is within 1000ms of now
    let ms = 1000;
    const shouldDispatch = responses.some((response) => response.time - now < ms);

    if (shouldDispatch) {
      let management = useAuthenticationStore.getState().userProfile.management.slug;
      dispatch(
        getActionPlan({
          communities: communitiesFilters.map((c) => c.value),
          management,
          users: member ? [member] : [],
        })
      );
    }
  }, [
    createTicketResponse,
    createTicketRecurrentResponse,
    createReminderResponse,
    updateReminderResponse,
    updateDocumentResponse,
    updateTicketResponse,
    editMaintenanceResponse,
    editVisitResponse,
    deleteMaintenanceResponse,
    deleteVisitResponse,
    deleteDocumentResponse,
    deleteTicketResponse,
    deleteReminderResponse,
  ]);

  const mapChart = (inputData) => {
    // Colores de fondo predeterminados para utilizar de forma cíclica en caso de que tengamos más categorías de las esperadas.
    const defaultBackgroundColors = ['info', 'primary', 'dark', 'secondary'];

    // Preparar la estructura de los datos de salida.
    let outputData = {
      labels: [],
      datasets: {
        label: 'Projects',
        backgroundColors: [],
        data: [],
      },
    };

    // Rellenar las estructuras de salida basándose en la data de entrada.
    inputData.forEach((item, index) => {
      // Añadir la etiqueta basada en la prioridad.
      outputData.labels.push(item.priority.charAt(0).toUpperCase() + item.priority.slice(1));

      // Añadir el dato de conteo.
      outputData.datasets.data.push(item.count);

      // Añadir el color de fondo. Se utiliza el módulo para ciclar a través de los colores predeterminados si hay más categorías que colores.
      outputData.datasets.backgroundColors.push(
        defaultBackgroundColors[index % defaultBackgroundColors.length]
      );
    });

    return outputData;
  };

  return (
    <>
      <SuiTypography textAlign="center" variant="h4" fontWeight="medium" mt={1}>
        Plan de Acción
      </SuiTypography>
      <Divider />
      <Card sx={{ padding: 3, overflow: 'visible', marginTop: 2 }}>
        <SuiTypography variant="h5" p={2} pl={0}>
          Filtros
        </SuiTypography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SuiTypography variant="h6" pl={0}>
              Elige tu vista
            </SuiTypography>
            <SuiSelect
              size="small"
              value={nextType}
              onChange={(e) => setNextType(e.value)}
              options={[
                { value: lastMonth, label: 'Mensual (Últimos 30 días)' },
                { value: lastWeek, label: 'Semanal (Últimos 7 días)' },
                { value: nextWeek, label: 'Semanal (Próximos 7 días)' },
                { value: nextMonth, label: 'Mensual (Próximos 30 días)' },
              ]}
              placeholder="Semanal/Mensual"
            ></SuiSelect>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiTypography variant="h6" pl={0}>
              Comunidades
            </SuiTypography>
            <SuiSelect
              size="small"
              value={communitiesFilters}
              onChange={(e) => {
                setCommunitiesFilters(e);
              }}
              isMulti
              options={
                communities &&
                communities.data && [
                  ...(communities.data &&
                    communities.data.map((community) => {
                      return { value: community.slug, label: community.name };
                    })),
                ]
              }
              placeholder="Todas"
            ></SuiSelect>
          </Grid>
          {getPermission(['superadmin', 'administrador']) && (
            <Grid item xs={12} sm={4}>
              <SuiTypography variant="h6" fontWeight="medium">
                Colaborador
              </SuiTypography>
              <SuiSelect
                size="small"
                value={member}
                placeholder="Colaborador"
                style={{ zIndex: 1000, width: '100%' }}
                options={[
                  { value: null, label: 'Todos' },
                  ...members.map((member) => {
                    return { value: member.email, label: member.first_name };
                  }),
                ]}
                onChange={(e) => setMember(e.value)}
              />
            </Grid>
          )}

          <Grid item xs={6} sm={3} md={2}>
            <SuiTypography variant="body2" pl={0}>
              Tareas{' '}
              <Checkbox checked={showTickets} onChange={(e) => setShowTickets(e.target.checked)} />{' '}
            </SuiTypography>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <SuiTypography variant="body2" pl={0}>
              Mantenciones{' '}
              <Checkbox
                checked={showMaintenances}
                onChange={(e) => setShowMaintenances(e.target.checked)}
              />{' '}
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <SuiTypography variant="body2" pl={0}>
              Visitas
              <Checkbox
                checked={showVisits}
                onChange={(e) => setShowVisits(e.target.checked)}
              />{' '}
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <SuiTypography variant="body2" pl={0}>
              Documentos{' '}
              <Checkbox
                checked={showDocuments}
                onChange={(e) => setShowDocuments(e.target.checked)}
              />{' '}
            </SuiTypography>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <SuiTypography variant="body2" pl={0}>
              Notas{' '}
              <Checkbox
                checked={showReminders}
                onChange={(e) => setShowReminders(e.target.checked)}
              />{' '}
            </SuiTypography>
          </Grid>
        </Grid>
      </Card>

      {loading && <LoadingView />}

      {!loading && plan && (
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={3}>
            <MiniStatisticsCard
              title={{ text: 'Tareas por vencer', fontWeight: 'bold' }}
              count={plan.metrics.ticket_count}
              icon={<FaTasks />}
            />
          </Grid>
          {/*<Grid item xs={12} sm={6}>
          <PieChart flex={true} chart={chart} title="Prioridad tareas" height="15vh" />
    </Grid>*/}
          <Grid item xs={12} sm={3}>
            <MiniStatisticsCard
              title={{ text: 'Mantenciones por expirar', fontWeight: 'bold' }}
              count={plan.metrics.maintenance_count}
              icon={<AiFillTool color="white" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MiniStatisticsCard
              title={{ text: 'Visitas a realizar', fontWeight: 'bold' }}
              count={plan.metrics.visit_count}
              icon={<FaHouseUser />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MiniStatisticsCard
              title={{ text: 'Documentos por vencer', fontWeight: 'bold' }}
              count={plan.metrics.document_count}
              icon={<HiDocument />}
            />
          </Grid>
          {showReminders && (
            <Grid item xs={12}>
              <Reminders reminders={reminders} />
            </Grid>
          )}
          {showTickets && (
            <Grid item xs={12}>
              <ActionPlanTicketsTable tickets={plan.tickets} viewType={nextType} />
            </Grid>
          )}

          {showMaintenances && (
            <Grid item xs={12}>
              <ActionPlanMaintenancesTable maintenances={plan.maintenances} />
            </Grid>
          )}
          {showVisits && (
            <Grid item xs={12}>
              <ActionPlanVisitTable visits={plan.visits} />
            </Grid>
          )}
          {showDocuments && (
            <Grid item xs={12}>
              <ActionPlanDocumentsTable documents={plan.documents} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default ActionPlan;
