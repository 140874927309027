import { Box } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useTicketProgressNextStep from 'api/services/tickets/mutations/TicketProgressNextStep';
interface Props {
  ticket: TicketModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}

interface FormValues {
  comment: string;
}

const NextStepForm: FC<Props> = ({ ticket, onCancel, onSuccess }) => {
  const { mutate: setNextStepTicket, isPending: isSubmitingNext } = useTicketProgressNextStep({
    uuid: ticket.uuid,
  });

  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues: { comment: '' } });
  useEffect(() => {
    reset();
  }, [ticket]);

  const submitNextStep = handleSubmit((data) => {
    setNextStepTicket(
      { comment: data.comment },
      {
        onSuccess,
      }
    );
  });

  const cancelForm = () => {
    onCancel?.();
  };
  return (
    <Box>
      <InputControlled
        label="Comentario"
        control={control}
        name="comment"
        placeholder="Comentario"
        size="medium"
      />
      <Box display={'flex'} justifyContent={'space-between'} mt={1}>
        <SuiButtonTs
          variant={'gradient'}
          color="success"
          size="small"
          disabled={isSubmitingNext}
          onClick={submitNextStep}
        >
          Avanzar
        </SuiButtonTs>
        <SuiButtonTs
          variant={'gradient'}
          color="error"
          size="small"
          onClick={cancelForm}
          disabled={isSubmitingNext}
        >
          Cancelar
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default NextStepForm;
