/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import BuildingCard from 'examples/Cards/BuildingCard';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';
import BudgetCardVotation from './BudgetCard';
// General page component
import { useParams, useNavigate } from 'react-router-dom';
import { getTicketsByCommunity } from 'redux/actions/tickets';
import { getPollByUuid } from 'redux/actions/polls';
import LoadingView from 'components/Loading';
import logo from 'assets/images/logo-blue.png';
import { getUFPrice } from 'redux/actions/tickets';

function PublicFilePoll() {
  const navigate = useNavigate();
  const { uuid, token } = useParams();
  const dispatch = useDispatch();
  const [ufPrice, setUfPrice] = useState(35000);
  const [poll, setPoll] = useState(null);
  const getUFPriceResponse = useSelector((state) => state.tickets.getUFPrice);
  const createPublicVoteResponse = useSelector((state) => state.polls.createPublicVote);
  const createVotePollResponse = useSelector((state) => state.polls.createVotePoll);
  const getPollByUuidResponse = useSelector((state) => state.polls.getPollByUuid);

  const [choices, setChoices] = useState(null);
  const sortedChoices = (poll) => {
    let pollChoices = poll.poll_choices;
    let cotizaciones = pollChoices.filter((file) => file.document.type.slug === 'cotizacion');

    if (cotizaciones.length > 0) {
      const sortedCotizaciones = cotizaciones.sort((a, b) => {
        let aPrice =
          a.document.currency === 'UF' ? a.document.gross_value * ufPrice : a.document.gross_value;
        let bPrice =
          b.document.currency === 'UF' ? b.document.gross_value * ufPrice : b.document.gross_value;
        return aPrice - bPrice;
      });

      // Asignar el precio mínimo global a los documentos relevantes
      let minPrice =
        sortedCotizaciones[0].document.currency === 'UF'
          ? sortedCotizaciones[0].document.gross_value * ufPrice
          : sortedCotizaciones[0].document.gross_value;

      sortedCotizaciones.forEach((file) => {
        file.document.globalMinPrice = minPrice;
      });

      // Retornar las cotizaciones ordenadas para ser utilizadas en el renderizado
      setChoices(sortedCotizaciones);
    }
  };
  useEffect(() => {
    if (getPollByUuidResponse.data) {
      setPoll(getPollByUuidResponse.data);
      sortedChoices(getPollByUuidResponse.data.poll);
    }
  }, [getPollByUuidResponse]);

  useEffect(() => {
    if (getUFPriceResponse.data) {
      setUfPrice(getUFPriceResponse.data.uf);
    }
  }, [getUFPriceResponse]);

  useEffect(() => {
    if (createPublicVoteResponse.data) {
      dispatch(getPollByUuid(uuid));
    }
  }, [createPublicVoteResponse]);

  useEffect(() => {
    dispatch(getPollByUuid(uuid));
    //    setMinPrice();
    dispatch(getUFPrice());
  }, []);

  return !poll ? (
    <LoadingView />
  ) : (
    <SuiBox>
      <SuiBox style={{ display: 'flex', paddingTop: 20 }}>
        <a style={{ margin: '0 auto' }} target="_blank" href="https://app.swappi.cl">
          <img src={logo} style={{ width: '10rem', margin: '0 auto' }} />
        </a>
        {poll.management && poll.management.logo && (
          <a
            style={{ margin: '0 auto' }}
            target="_blank"
            href={poll.management && poll.management.web_site}
          >
            <img src={poll.management.logo} style={{ width: '10rem', margin: '0 auto' }} />
          </a>
        )}
      </SuiBox>
      <SuiTypography
        variant="h4"
        pb={2}
        fontWeight="bold"
        textTransform="capitalize"
        textAlign="center"
      >
        Votación Cotizaciones
      </SuiTypography>
      <SuiBox mb={2}>
        <Card>
          <SuiBox p={3}>
            <SuiBox style={{ justifyContent: 'space-between', display: 'flex' }}>
              <SuiTypography variant="h5" pb={2} fontWeight="bold" textTransform="capitalize">
                {poll.poll.question}
              </SuiTypography>
              <SuiTypography variant="h6" pb={2} fontWeight="bold" textTransform="capitalize">
                UF hoy: {ufPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              {choices &&
                choices.map((option, i) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
                    <BudgetCardVotation
                      index={i}
                      file={option.document}
                      choice={option}
                      poll={poll.poll}
                    />
                  </Grid>
                ))}
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
    </SuiBox>
  );
}

export default PublicFilePoll;
