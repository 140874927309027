import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { useDispatch } from "react-redux";
import { setNotification } from "redux/actions/tickets";
import { API_URL } from "redux/actions/types";
import { ChecklistModel } from "../models";
import { TicketQueryHelper } from "api/services/tickets/queries/GetTicket";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  objectUUID: UUID;
  checklistUUID: UUID;
}
interface Input {
  name: string;
}
const useUpdateChecklist = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/edit-checklist/${params.checklistUUID}`;
      return await axios
        .put<ChecklistModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Checklist actualizado",
        message: "El nombre del checklist ha sido actualizado.",
      });
    },
  });
};

export default useUpdateChecklist;
