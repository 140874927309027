import { ISODateString } from "types/models";
import * as yup from "yup";
interface ConfirmVisitFormFields {
  completed_on: ISODateString[];
  comment: string;
}

export const scheme: yup.SchemaOf<ConfirmVisitFormFields> = yup.object().shape({
  comment: yup.string(),
  completed_on: yup.array().of(yup.string().required()).required(),
});
