import { Box, Paper } from '@mui/material';
import React, { FC, useMemo } from 'react';
import Title from './Title';
import NewItem from './NewItem';
import Item from './Item';
import { UUID } from 'crypto';
import useGetChecklistTemplates from 'api/services/checklist/queries/GetChecklistTemplates';

interface Props {
  checklistUUID: UUID;
  onDelete: () => void;
}
const ChecklistTemplateEditor: FC<Props> = ({ checklistUUID, onDelete }) => {
  const { data: templates } = useGetChecklistTemplates(!!checklistUUID);
  const checklist = useMemo(() => {
    if (!templates || !checklistUUID) return undefined;
    return templates.find((item) => item.uuid === checklistUUID);
  }, [checklistUUID, templates]);

  return (
    <div>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Title checklist={checklist} onDelete={onDelete} />
        {checklist.items.map((item) => {
          return (
            <Box key={item.uuid}>
              <Item checklist={checklist} item={item} />
            </Box>
          );
        })}
        <NewItem checklist={checklist} />
      </Paper>
    </div>
  );
};

export default ChecklistTemplateEditor;
