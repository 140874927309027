// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_ENV_API_URL;

export const types = {
  changeLanguage: '[Lang] Change',
  signIn: '[Auth] Sign In',
  signOut: '[Auth] Sign Out',
  setNotification: 'Notification',

  /* USERS */
  setGetUsers: '[Users] Get',
  setCreateUser: '[Users] Create',
  setUpdateUser: '[Users] Update',
  setDeleteUser: '[Users] Delete',
  setGetUserProfile: '[Users] Get Profile',
  setGetUsersByManagement: '[Users] Get Users By Management',
  setAddCommunityToSupervisor: '[Users] Add Community To Supervisor',
  setUpdateUserProfile: '[Users] Update User Profile',
  setConfigNotificactions: '[Users] Config Notifications',
  setChangePassword: '[Users] Change Password',
  setDeactiveUser: '[Users] Deactivate User',
  setUpdateUserWithAdmin: '[Users] Update User With Admin',
  setUpdateAdminLogo: '[Users] Update Admin Logo',
  setGetComiteByCommunitySlug: '[Users] Get Comite By Community Slug',
  setResetPasswordConfirm: '[Users] Reset Password Confirm',
  setResetPassword: '[Users] Reset Password',
  setEditPermissions: '[Users] Edit Permission',
  setGetUsersCommunities: '[Users] Get Users Communities',
  setWeeklyReportConfig: '[Users] Weekly Report Config',

  /* Communities */
  setGetCommunitiesByUser: '[Communities] Get Communities By User',
  setCreateBuilding: '[Communities] Create Building',
  setGetCommunity: '[Communities] Get Community',
  setGetUnits: '[Communities] Get Building Units',
  setGetAreas: '[Communities] Get Areas',
  setCreateArea: '[Communities] Create Area',
  setCreateUnit: '[Communities] Create Unit',
  setGetVisitsByCommunity: '[Communities] Get Visits By Community',
  setGetVisitsByManagement: '[Communities] Get Visits By Management',
  setMarkAsVisited: '[Communities] Mark As Visited',
  setGetBuildingsByCommunity: '[Communities] Get Buildings By Community',
  setEditVisit: '[Communities] Edit Visit',
  setEditArea: '[Communities] Edit Area',
  setDeleteArea: '[Communities] Delete Area',
  setGetCommunityStaff: '[Communities] Get Community Staff',
  setUpdateCommunityImage: '[Communities] Update Community Image',
  setCreateVisitReport: '[Communities] Create Visit Report',
  setUpdateVisitReport: '[Communities] Update Visit Report',
  setGetVisitReport: '[Communities] Get Visit Report',
  setGetCommunityGantt: '[Communities] Get Community Gantt',
  setDeleteBuilding: '[Communities] Delete Building',
  setEditBuilding: '[Communities] Edit Building',

  /* Tickets */
  setGetTicketsByCommunity: '[Tickets] Get Tickets By Community',
  setCreateTicket: '[Tickets] Create Ticket',
  setGetTicketByUuId: '[Tickets] Get Ticket By UuId',
  setAddCommentToTicket: '[Tickets] Add Comment To Ticket',
  setAddChecklistToTicket: '[Tickets] Add Checklist To Ticket',
  setAddItemToCheckList: '[Tickets] Add Item To CheckList',
  setMarkChecklistItem: '[Tickets] Mark Checklist Item',
  setUpdateItemName: '[Tickets] Update Item Name',
  setDeleteChecklistItem: '[Tickets] Delete Checklist Item',
  setDeleteChecklist: '[Tickets] Delete Checklist',
  setTicketGoForward: '[Tickets] Update Ticket State',
  setTicketGoBack: '[Tickets] Ticket Go Back',
  setUpdateTicketArea: '[Tickets] Update Ticket Area',
  setAddDocumentToTicket: '[Tickets] Add Document To Ticket',
  setAssignTicketToUser: '[Tickets] Assign Ticket To User',
  setGetTicketsByManagement: '[Tickets] Get Tickets By Management',
  setUpdateTickets: '[Tickets] Update Tickets',
  setEditChecklistName: '[Tickets] Edit Checklist Name',
  setArchiveTicket: '[Tickets] Archive Ticket',
  setUnarchiveTicket: '[Tickets] Unarchive Ticket',
  setGetPublicTicket: '[Tickets] Get Public Tickets',
  setGetTicketsExcelManagement: '[Tickets] Get Tickets Excel Management',
  setGetTicketsExcelCommunity: '[Tickets] Get Tickets Excel Community',
  setGetTicketsPDFCommunity: '[Tickets] Get Tickets PDF Community',
  setGetTicketsPDFManagement: '[Tickets] Get Tickets PDF Management',
  setGetTicketPDF: '[Tickets] Get Ticket PDF',
  setDeleteVisit: '[Tickets] Delete Visit',
  setEditComment: '[Tickets] Edit Comment',
  setGetTicketCrewUsers: '[Tickets] Get Ticket Crew Users',
  setDeleteCommentFromTicket: '[Tickets] Delete Comment From Ticket',
  setGetUFPrice: '[Tickets] Get UF Price',
  setGetTicketUsers: '[Tickets] Get Ticket Users',
  setUpdateTicketTimeline: '[Tickets] Update Ticket Timeline',
  setShowTicketToComitee: '[Tickets] Show Ticket To Comitee',
  setFinishTicketTimeline: '[Tickets] Finish Ticket Timeline',
  setGetMembersForTicketAssignment: '[Tickets] Get Members For Ticket Assignment',
  setCreateTicketRecurrent: '[Tickets] Create Ticket Recurrent',
  setToggleNotifyTimeline: '[Tickets] Toggle Notify Timeline',
  /* Checklist Template */
  setCreateChecklistTemplate: '[Tickets] Create Checklist Template',
  setGetChecklistsTemplate: '[Tickets] Get Checklists Template',
  setCreateChecklistTemplateItem: '[Tickets] Create Checklist Template Item',
  setEditChecklistTemplateItem: '[Tickets] Edit Checklist Template Item',
  setEditChecklistTemplate: '[Tickets] Edit Checklist Template',
  setDeleteChecklistTemplate: '[Tickets] Delete Checklist Template',
  setDeleteChecklistTemplateItem: '[Tickets] Delete Checklist Template Item',
  setSaveChecklistAsTemplate: '[Tickets] Save Checklist As Template',
  setAddChecklistToTicketFromTemplate: '[Tickets] Add Checklist To Ticket From Template',
  setGetChecklistTemplate: '[Tickets] Get Checklist Template',
  /* Providers */
  setGetProvidersByManagement: '[Providers] Get Providers By Management',
  setCreateProviderByManagement: '[Providers] Create Provider By Management',
  setUpdateProvider: '[Providers] Update Provider',
  setDeleteProvider: '[Providers] Delete Provider',
  setGetTicketsByProvider: '[Providers] Get Tickets By Provider',
  setGetMaintenanceByProvider: '[Providers] Get Maintenance By Provider',
  setGetDocumentsByProvider: '[Providers] Get Documents By Provider',
  setAddCommunityToProvider: '[Providers] Add Community To Provider',
  setCreateContact: '[Providers] Create Contact',
  setGetProvider: '[Providers] Get Provider',
  setImportProviders: '[Providers] Import Providers',
  /* Documents */
  setGetDocumentsTags: '[Documents] Get Documents Tags',
  setApproveDocument: '[Documents] Approve Document',
  setGetDocumentsByArea: '[Documents] Get Documents By Area',
  setGetDocumentsByCommunity: '[Documents] Get Documents By Community',
  setGetDocumentsByManagement: '[Documents] Get Documents By Management',
  setUpdateDocument: '[Documents] Update Document',
  setAddDocument: '[Documents] Add Document',
  setDeleteDocument: '[Documents] Delete Document',
  setGetDocumentsTagsByManagement: '[Documents] Get Documents Tags By Management',

  /* Maintenance */
  setCreateMaintenance: '[Maintenance] Create Maintenance',
  setCreateMaintenanceVisit: '[Maintenance] Create Maintenance Visit',
  setGetMaintenanceByCommunity: '[Maintenance] Get Maintenance By Community',
  setEditMaintenance: '[Maintenance] Edit Maintenance',
  setDeleteMaintenance: '[Maintenance] Delete Maintenance',
  setGetMaintenance: '[Maintenance] Get Maintenance',
  setCreateCertification: '[Maintenance] Create Certification',
  setCreateEmergency: '[Maintenance] Create Emergency',
  setUpdateEmergency: '[Maintenance] Update Emergency',
  setDeleteEmergency: '[Maintenance] Delete Emergency',
  setDeleteCertification: '[Maintenance] Delete Certification',
  setGetMaintenancesByManagement: '[Maintenance] Get Maintenance By Management',
  setGetExportMaintenance: '[Maintenance] Get Export Maintenance',
  setEditEmergency: '[Maintenance] Edit Emergency',
  setRegenerateMaintenanceVisits: '[Maintenance] Regenerate Maintenance Visits',
  setCreateMaintenanceBudget: '[Maintenance] Create Maintenance Budget',
  setEditMaintenanceBudget: '[Maintenance] Edit Maintenance Budget',
  setDeleteMaintenanceBudget: '[Maintenance] Delete Maintenance Budget',

  /* Analytics */
  setGetDashboard: '[Analytics] Get Dashboard',
  setGetSupervisorDashboard: '[Analytics] Get Dashboard By Supervisor',
  setEditVisitReport: '[Analytics] Edit Visit Report',
  setGetVisitReports: '[Analytics] Get Visit Reports By Community',
  setGetVisitReportPDF: '[Analytics] Get Visit Report PDF',
  setDeleteVisitReport: '[Analytics] Delete Visit Report',
  setPostExportVisitReports: '[Analytics] Post Export Visit Reports',
  setCreatePlanification: '[Analytics] Create Planification',

  setGetItemsByPlanifications: '[Planification] Get Planification By Community',
  setGetPlanificactionItems: '[Planification] Get Planification Items',
  setCreatePlanificationItem: '[Planification] Create Planification Item',
  setEditPlanificationItem: '[Planification] Edit Planification Item',
  setDeletePlanificationItem: '[Planification] Delete Planification Item',
  setGetPlanificationCategory: '[Planification] Get Planification Category',
  setCreatePlanificationCategory: '[Planification] Create Planification Category',
  setEditPlanificationCategory: '[Planification] Edit Planification Category',
  setDeletePlanificationCategory: '[Planification] Delete Planification Category',
  setGetHelpCenter: '[HelpCenter] Get Help Center',
  setGetPLanifications: '[Planification] Get Planifications',
  setTogglePlanificationCategory: '[Planification] Toggle Planification Category',
  setPostExportAnualPlanification: '[Planification] Post Export Anual Planification',

  setGetActivity: '[Activities] Get Activities',
  setMarkAllAsRead: '[Activities] Mark All As Read',
  setMarkAsRead: '[Activities] Mark As Read',
  setGetReadActivity: '[Activities] Get Read Activities',
  setGetActivityUnreadCount: '[Activities] Get Activity Unread Count',
  setAssignAllComitees: '[Tickets] Assign All Comitees',

  setCreatePoll: '[Polls] Create Poll',
  setCreateVote: '[Polls] Create Vote',
  setGetPollByUuid: '[Polls] Get Poll By Uuid',
  setCreatePublicVote: '[Polls] Create Public Vote',
  setDeletePoll: '[Polls] Delete Poll',

  setGetRegionsByCountry: '[Regions] Get Regions By Country',
  setGetCommunesByRegions: '[Communes] Get Communes By Regions',

  setGetFAQ: '[FAQ] Get FAQ',
  setActivityModalClosed: '[Helper] Set Activity Modal Closed',
  setGetDTEs: '[DTEs] Get DTEs',
  setGetActionPlan: '[Action Plan] Get Action Plan',

  setGetReminders: '[Reminders] Get Reminders',
  setUpdateReminder: '[Reminders] Update Reminder',
  setDeleteReminder: '[Reminders] Delete Reminder',
  setCreateReminder: '[Reminders] Create Reminder',

  setImportAreas: '[Communities] Import Areas',
};
