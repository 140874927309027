import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

import { useGetDocumentUrls } from "api/services/documents/queries/getDocumentUrl";
import SuiTypography from 'components/SuiTypography';
import { Card, Box } from '@mui/material';
import UploadFileDrawer from 'components/UploadFileDrawer';
import EditFileDrawer from 'components/EditFileDrawer';

const VideoCarousel = ({ videoUrls }) => {
  // Obtener las URLs firmadas como una lista de queries
  const videosUrlSigned = useGetDocumentUrls(videoUrls.map((video) => video.uuid), true);

  // Verificar si alguna consulta está cargando
  const isLoading = videosUrlSigned.some((query) => query.isLoading);

  // Manejar el caso de errores en las consultas
  const hasError = videosUrlSigned.some((query) => query.isError);

  // Combinar datos originales con URLs firmadas
  const enrichedVideos = videoUrls.map((video, index) => ({
    ...video,
    signedUrl: videosUrlSigned[index]?.data?.url || null,
  }));

  // Filtrar videos con URLs válidas
  const validVideos = enrichedVideos.filter((video) => video.signedUrl);

  // Mostrar un spinner o un mensaje mientras se cargan las URLs
  if (isLoading) {
    return <div>Cargando videos...</div>;
  }

  // Manejar el caso de errores
  if (hasError) {
    return <div>Error al cargar algunos videos. Por favor, inténtelo de nuevo.</div>;
  }

  return (
    <Card
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuiTypography variant="h4" gutterBottom>
        Videos
      </SuiTypography>
      <Box
        sx={{
          flex: 1,
          height: 'auto', // Permitir que el contenido ajuste su altura
          overflow: 'hidden', // Evitar desbordamientos
          '@media (max-width: 600px)': {
            height: '70vh', // Altura adecuada para móviles
          },
        }}
      >
        <Swiper
          pagination={{ clickable: true }}
          navigation
          modules={[Pagination, Navigation]}
          spaceBetween={10}
          slidesPerView={1}
          style={{
            height: '100%', // Swiper ocupará todo el espacio del contenedor
          }}
        >
          {validVideos.map((video, index) => (
            <SwiperSlide key={index}>
              <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px',
                  }}
                ><EditFileDrawer file={video} /></div>
                {console.log(video)}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {/* Pasar el objeto completo del video */}
                
                <video
                  controls
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                  }}
                  onError={(e) => console.error('Error al cargar el video:', e)}
                >
                  <source src={video.signedUrl} type="video/mp4" />
                  Tu navegador no soporta la reproducción de videos.
                </video>
              </Box>
              <SuiTypography sx={{fontSize: 15, padding: 3.5}}>
                {video.observations}
              </SuiTypography>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Card>
  );
};

export default VideoCarousel;
