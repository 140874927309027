import React, { useState } from "react";
import { PDFDocument } from "pdf-lib";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function MergePDFComponent() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mergePDF, setMergePDF] = useState(false);
  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const mergePDFs = async (files) => {
    const mergedPdf = await PDFDocument.create();

    for (const file of files) {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const mergedPdfFile = await mergedPdf.save();
    downloadMergedPDF(mergedPdfFile);
  };

  const downloadMergedPDF = (mergedPdfFile) => {
    const blob = new Blob([mergedPdfFile], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "archivo-unificado.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFiles.length > 0) {
      await mergePDFs(selectedFiles);
    }
  };

  return (
    <div>
      <SuiTypography variant="h6" onClick={() => setMergePDF(!mergePDF)}>
        ¿Quieres unir varios archivos <bold>PDF </bold>
        en uno solo?
      </SuiTypography>
      <SuiTypography
        style={{ cursor: "pointer" }}
        onClick={() => setMergePDF(!mergePDF)}
        textAlign="center"
        variant="body2"
      >
        Click Aquí
      </SuiTypography>
      {mergePDF && (
        <form onSubmit={handleSubmit}>
          <input type="file" multiple onChange={handleFileChange} accept="application/pdf" />
          <SuiButton variant="gradient" color="success" type="submit" size="small">
            Unir
          </SuiButton>
        </form>
      )}
    </div>
  );
}

export default MergePDFComponent;
