import { useQuery } from '@tanstack/react-query';
import { axiosPublic } from 'api/config/axios';

import { AuthResponse } from '../mutation/login';

const GET_SESSION_KEY = 'get_session_query';
export const refreshAccessToken = async () => {
  const url = '/api/token/refresh/';
  return await axiosPublic.get<AuthResponse>(url).then((res) => res.data);
};

const useRefreshAccessToken = () => {
  return useQuery({
    enabled: false,
    staleTime: 1000 * 60 * 5,
    queryKey: [GET_SESSION_KEY],
    queryFn: refreshAccessToken,
    retry: false,
  });
};

export default useRefreshAccessToken;
