import React, { FC } from 'react';
import { TicketActionsProps } from './types';
import { Tooltip } from '@mui/material';

import { SuiBadgeTs } from 'components/SuiBadge';
import { isPermissionEnabled } from 'utils';
import Swal from 'sweetalert2';
import { AiOutlineEye, AiOutlinePaperClip } from 'react-icons/ai';
import { BiArchiveIn } from 'react-icons/bi';
import { archiveTicket } from 'redux/actions/tickets';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Box } from '@mui/system';
import { TicketModalHelper } from '../TicketModal/store';

const TicketTableActions: FC<TicketActionsProps> = ({ ticket, refetch }) => {
  const dispatch = useDispatch(); // cambiar a reactquery
  const handleArchiveTickets = () => {
    dispatch(archiveTicket(ticket.uuid) as unknown as AnyAction);
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Tooltip title={'Ver tarea'} placement="top">
        <SuiBadgeTs
          container
          badgeContent={<AiOutlineEye size="16px" />}
          size="xs"
          style={{ cursor: 'pointer' }}
          onClick={() => TicketModalHelper.openTicket(ticket.uuid)}
          bgcolor={'info'}
        />
      </Tooltip>
      <Tooltip title="Copiar enlace público" placement="top">
        <SuiBadgeTs
          container
          badgeContent={<AiOutlinePaperClip size="16px" />}
          size="xs"
          style={{ cursor: 'pointer', marginTop: 2 }}
          onClick={() => {
            navigator.clipboard.writeText('https://app.swappi.cl/ticket/public/' + ticket.uuid);
          }}
          color={'success'}
        />
      </Tooltip>
      {isPermissionEnabled('tasks-deleteTask') && (
        <Tooltip title="Eliminar tarea" placement="top">
          <SuiBadgeTs
            container
            badgeContent={<BiArchiveIn size="16px" />}
            size="xs"
            style={{ cursor: 'pointer', marginTop: 2 }}
            onClick={() => {
              Swal.fire({
                title: '¿Quieres eliminar esta tarea?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: `No`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  handleArchiveTickets();
                  Swal.fire('Archivado!', '', 'success');
                } else if (result.isDenied) {
                  Swal.fire('Tarea no archivada', '', 'error');
                }
              });
            }}
            color={'error'}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default TicketTableActions;
