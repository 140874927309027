import { yupResolver } from "@hookform/resolvers/yup";
import { VisitModel } from "api/services/visits/models";
import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import InputControlled from "components/forms/controlled/InputControlled";
import React from "react";
import { useForm } from "react-hook-form";
import { scheme } from "./form.helpers";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { SuiButtonTs } from "components/SuiButton";
import useUpdateVisit from "api/services/visits/mutations/updateVisit";
import { format } from "date-fns";
import useEventEmitter from "lib/events/useEventEmitter";

interface Props {
  visit: VisitModel;
}

const EditVisitForm: React.FC<Props> = ({ visit }) => {
  const { mutate: updateVisit } = useUpdateVisit({ uuid: visit.uuid });
  const visitUpdated = useEventEmitter("visitUpdated");

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: {
      title: visit.title,
      comment: visit.comment,
      start: [visit.start],
      end: [visit.end],
    },
  });
  const onSubmit = handleSubmit((data) => {
    updateVisit(
      {
        title: data.title,
        comment: data.comment,
        start: data.start[0] && format(data.start[0], "yyyy-MM-dd"),
        end: data.start[0] ? format(data.start[0], "yyyy-MM-dd") : null,
      },
      {
        onSuccess: (data) => {
          visitUpdated.emit(data);
        },
      }
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <Typography>Actualizar visita</Typography>
        <InputControlled
          label="Título"
          control={control}
          name="title"
          placeholder="Titulo de la visita"
        />
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item md={6} xs>
              <DatePickerControlled
                label="Inicio"
                control={control}
                name="start"
                options={{
                  maxDate: watch("end")?.[0] && new Date(watch("end")[0]),
                }}
              />
            </Grid>
            <Grid item md={6} xs>
              <DatePickerControlled
                label="Fin"
                control={control}
                name="end"
                options={{
                  minDate: watch("start")?.[0] && new Date(watch("start")[0]),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {visit.completed && (
          <InputControlled
            label="Comentario"
            placeholder="Comentar"
            control={control}
            name="comment"
            multiline={5}
          />
        )}
        <Box display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            disabled={!isDirty || !isValid}
            size="small"
            color="success"
            variant="gradient"
            type="submit"
          >
            Guardar
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default EditVisitForm;
