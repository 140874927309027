import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { Box } from "@mui/material";
import UpdateUserForm from "./forms/UpdateUserForm";
import { UserModel } from "api/services/user/models";

const UpdateUserModal = forwardRef<ModalRefHandle, { user: UserModel }>(
  ({ user }, ref) => {
    const modalRef = useRef<ModalRefHandle>(null);

    useEventListener("userUpdated", () => {
      modalRef.current?.close();
    });

    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
    }));

    return (
      <BaseModal ref={modalRef}>
        <Box p={3} width={{ md: 650, xs: "100vw" }}>
          <h4>Actualizar usuario</h4>
          {user && <UpdateUserForm user={user} />}
        </Box>
      </BaseModal>
    );
  }
);
UpdateUserModal.displayName = "UpdateUserModal";
export default UpdateUserModal;
