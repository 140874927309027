/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import { useSelector, useDispatch } from 'react-redux';
import { getDTEs } from 'redux/actions/users';
import DataTable from 'examples/Tables/DataTable';
import Header from '../profile/components/Header';
import SuiTypography from 'components/SuiTypography';

function Billing(props) {
  const dispatch = useDispatch();
  const dtesResponse = useSelector((state) => state.users.getDTEs);
  const [dtes, setDtes] = useState([]);

  useEffect(() => {
    if (dtesResponse) if (dtesResponse.data) setDtes(dtesResponse.data.dtes);
  }, [dtesResponse]);

  useEffect(() => {
    dispatch(getDTEs());
  }, []);

  return (
    <Card sx={{ marginTop: 5, padding: 3 }}>
      <SuiTypography variant="h5">Facturas</SuiTypography>
      <DataTable
        table={{
          columns: [
            { Header: 'Folio', accessor: 'folio' },
            { Header: 'Monto', accessor: 'monto' },
            { Header: 'Estado', accessor: 'status' },
            { Header: 'Fecha de emisión', accessor: 'fecha-emision' },
            { Header: 'Fecha de pago', accessor: 'fecha-pago' },
            { Header: 'Fecha de vencimiento', accessor: 'fecha-vencimiento' },
          ],
          rows: dtes.map((dte) => {
            return {
              folio: (
                <a href={dte.url} target="_blank">
                  #{dte.folio}
                </a>
              ),
              'fecha-emision': dte.fecha_emision,
              'fecha-pago': dte.fecha_pago,
              'fecha-vencimiento': dte.fecha_vencimiento,
              monto: dte.monto_total.toLocaleString('es-CL', {
                style: 'currency',
                currency: 'CLP',
              }),
              status: dte.status,
            };
          }),
        }}
      />
    </Card>
  );
}

export default Billing;
