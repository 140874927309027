import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ChecklistItemModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";

interface Input {
  name: string;
}
interface Params {
  checklistUUID: UUID;
  objectUUID: UUID;
}
const useAddChecklistItem = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/checklist-item/add/checklist/${params.checklistUUID}`;
      return axios.post<ChecklistItemModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Actividad agregada",
        message: "Actividad agregada a checklist correctamente!",
      });
    },
  });
};

export default useAddChecklistItem;
