import { Box } from "@mui/material";
import useGetMaintenance from "api/services/maintenances/queries/getMaintenance";
import LoadingView from "components/Loading";
import { UUID } from "crypto";
import MaintenanceView from "layouts/pages/maintenance/View";
import React from "react";
import { useParams } from "react-router-dom";

const PublicMaintenance = () => {
  const { uuid } = useParams<{ uuid: UUID }>();
  const { data: maintenance, isLoading } = useGetMaintenance(uuid as UUID);
  console.log({ maintenance });
  if (isLoading) return <LoadingView />;

  return (
    <Box>
      <MaintenanceView maintenance={maintenance} />
    </Box>
  );
};

export default PublicMaintenance;
