import { Visibility } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { SuiBadgeTs } from "components/SuiBadge";
import React from "react";

const ShowDetailButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Tooltip title={"Editar visita"} placement="top">
      <SuiBadgeTs
        onClick={onClick}
        badgeContent={<Visibility />}
        sx={{ cursor: "pointer" }}
        color="info"
        variant="gradient"
        container
      />
    </Tooltip>
  );
};

export default ShowDetailButton;
