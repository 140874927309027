import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Control, useController, UseControllerProps } from "react-hook-form";
import RichTextEditor from "components/forms/Custom/RichTextEditor";

interface RichInputProps extends UseControllerProps {
  label?: string;
  placeholder?: string;
  control: Control<any>;
  areaHeight?: string | number;
}

const RichTextEditorControlled: FC<RichInputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  areaHeight = 100,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });

  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <RichTextEditor
        {...field}
        placeholder={placeholder}
        areaHeight={areaHeight}
      />
    </Box>
  );
};

export default RichTextEditorControlled;
