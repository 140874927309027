import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ChecklistItemModel, ChecklistModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { checklistTemplatesHelper } from "../queries/GetChecklistTemplates";

interface Params {
  checklistUUID: UUID;
  itemUUID: UUID;
}
interface Input {
  name?: string;
}
const useUpdateChecklistTemplateItem = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/templates/checklist-items/${params.itemUUID}/edit/`;
      return await axios
        .put<ChecklistItemModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: (item) => {
      checklistTemplatesHelper.updateChecklistItem(item, params.checklistUUID);
      toastNotification.success({
        title: "Actividad actualizada",
        message: "Actividad actualizada correctamente",
      });
    },
  });
};

export default useUpdateChecklistTemplateItem;
