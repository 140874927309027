/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "redux/actions/communities";
import { changePassword } from "redux/actions/auth";
function ChangePassword() {
  const dispatch = useDispatch();
  const [actualPassword, setActualPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const passwordRequirements = [
    "Su contraseña no puede asemejarse tanto a su otra información personal.",
    "Su contraseña debe contener al menos 8 caracteres.",
    "Su contraseña no puede ser una clave utilizada comúnmente.",
    "Su contraseña no puede ser completamente numérica.",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SuiBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SuiTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SuiTypography>
      </SuiBox>
    );
  });
  const handleChangePassword = () => {
    if (password !== passwordConfirm) {
      let notification = {
        status: "error",
        message: "Las contraseñas no coinciden",
        title: "Cambio contraseña",
      };
      dispatch(setNotification(notification));
    } else {
      dispatch(
        changePassword({
          old_password: actualPassword,
          new_password: password,
          new_password_confirmation: passwordConfirm,
        })
      );
    }
  };

  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">Cambiar Contraseña</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Contraseña actual"
              onChange={(e) => setActualPassword(e.target.value)}
              placeholder="Contraseña actual"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Nueva contraseña"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Nueva contraseña"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Confirmar contraseña"
              onChange={(e) => setPasswordConfirm(e.target.value)}
              placeholder="Confirmar contraseña"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <SuiBox mt={6} mb={1}>
          <SuiTypography variant="h5">Requerimientos de contraseña</SuiTypography>
        </SuiBox>
        <SuiBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <SuiBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </SuiBox>
          <SuiBox ml="auto">
            <SuiButton variant="gradient" color="dark" size="small" onClick={handleChangePassword}>
              actualizar contraseña
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default ChangePassword;
