import React from "react";
import SuiBadge, { SuiBadgeTs } from "components/SuiBadge";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import useDeactivateUser from "api/services/user/mutations/deactivateUser";
import useEventEmitter from "lib/events/useEventEmitter";
import { UUID } from "crypto";
import { FaDeleteLeft } from "react-icons/fa6";

interface User {
  uuid: string;
  // Puedes agregar más propiedades del usuario si es necesario
}

interface DeleteUserButtonProps {
  user: User;
}

const DeleteUserButton: React.FC<DeleteUserButtonProps> = ({ user }) => {
  const { mutate: deactivateUser } = useDeactivateUser(user?.uuid as UUID);
  const userDeactivated = useEventEmitter("userDeactivated");

  const handleDelete = () => {
    console.log(user);
    Swal.fire({
      title: "¿Quieres eliminar este usuario?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deactivateUser(null, {
          onSuccess: (data) => {
            userDeactivated.emit("userDeactivated");
          },
        });
        Swal.fire("Eliminado", "", "success");
      }
    });
  };

  return (
    <Tooltip title="Eliminar" placement="top">
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        variant="gradient"
        color="error"
        badgeContent={<FaDeleteLeft size={12} />}
        sx={{ marginRight: 1 }}
        onClick={handleDelete}
      />
    </Tooltip>
  );
};

export default DeleteUserButton;