/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';
import { getProvidersByManagement } from 'redux/actions/providers';
import { getAreas } from 'redux/actions/communities';
// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiDatePicker from 'components/SuiDatePicker';
// Data
import SuiButton from 'components/SuiButton';
import { createUnit } from 'redux/actions/communities';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createMaintenance } from 'redux/actions/maintenance';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { createPlanificationCategory } from 'redux/actions/analytics';
import SuiInput from 'components/SuiInput';
import { getPlanificationCategory } from 'redux/actions/analytics';
import { Checkbox, Divider, Icon } from '@mui/material';
import { togglePlanificationCategory } from 'redux/actions/analytics';
import SuiBadge from 'components/SuiBadge';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { deletePlanificationCategory } from 'redux/actions/analytics';
import Swal from 'sweetalert2';
import ManageCategory from './ManageCategory';
import { isPermissionEnabled } from 'utils';
import { getAuthUserProfile } from 'stores/authentication/helpers';

function Form(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [name, setName] = useState('');
  const planificationCategoryResponse = useSelector(
    (state) => state.analytics.getPlanificationCategory
  );

  const deletePlanificationCategoryResponse = useSelector(
    (state) => state.analytics.deletePlanificationCategory
  );

  const editPlanificationCategoryResponse = useSelector(
    (state) => state.analytics.editPlanificationCategory
  );

  useEffect(() => {
    dispatch(getPlanificationCategory());
    let management = getAuthUserProfile().management.slug;
    dispatch(getProvidersByManagement(management));
  }, []);

  useEffect(() => {
    if (deletePlanificationCategoryResponse && deletePlanificationCategoryResponse.data)
      dispatch(getPlanificationCategory());
  }, [deletePlanificationCategoryResponse]);

  useEffect(() => {
    if (editPlanificationCategoryResponse && editPlanificationCategoryResponse.data)
      dispatch(getPlanificationCategory());
  }, [editPlanificationCategoryResponse]);

  const handleSubmit = (e) => {
    let form = {
      name: name,
    };

    dispatch(createPlanificationCategory(form));
    props.close();
  };

  return (
    <SuiBox>
      <Card id="basic-info" style={{ overflow: 'visible' }}>
        {isPermissionEnabled('planification-createCategory') && (
          <SuiBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiBox>
                  <SuiTypography variant="h5">Crear Categoría</SuiTypography>
                </SuiBox>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nombre
                  </SuiTypography>
                </SuiBox>

                <SuiInput
                  placeholder="Nombre"
                  size="small"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>
            <SuiBox mt={2}>
              <SuiButton variant={'gradient'} color="success" size="small" onClick={handleSubmit}>
                Crear
              </SuiButton>
            </SuiBox>
          </SuiBox>
        )}
        <Divider />
        <SuiBox pb={3} px={3}>
          <SuiBox pt={3} pb={3}>
            <SuiTypography variant="h5">Asignar categorías</SuiTypography>
          </SuiBox>
          <SuiBox mb={1} ml={0.5} lineHeight={0}>
            {planificationCategoryResponse.data &&
              planificationCategoryResponse.data.map((item) => (
                <ManageCategory
                  item={item}
                  categories={props.categories}
                  planification={props.planification}
                />
              ))}
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Form;
