import { useQuery } from "@tanstack/react-query";
import { axiosPublic } from "api/config/axios";
import { UUID } from "crypto";

export const useOpenDocument = (documentId: UUID) => {
  const query = useQuery({
    enabled: false,
    staleTime: Infinity,
    queryKey: ["GetDocumentUrl", documentId],
    queryFn: async () => {
      const url = `/documents/${documentId}/url/`;
      return await axiosPublic
        .get<{ url: string }>(url)
        .then((res) => res.data);
    },
  });

  const handleAction = async () => {
    if (!query.data) {
      const { data } = await query.refetch();
      window.open(data.url, "_blank");
    } else {
      window.open(query.data.url, "_blank");
    }
  };

  return { ...query, handleAction };
};
