/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

import { useSelector, useDispatch } from 'react-redux';
// Soft UI Dashboard PRO React example components
import AreaCard from 'examples/Cards/CommunitiesCards/AreaCard';

// General page components
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import { getCommunity } from 'redux/actions/communities';
import { useParams } from 'react-router-dom';
import { GiGreenhouse } from 'react-icons/gi';
import { getAreas } from 'redux/actions/communities';
import Create from './Create';
import Area from './Area';
import MaintenanceModal from '../../../maintenance/View/MaintenanceCard';
import { getMaintenanceByCommunity } from 'redux/actions/maintenance';
import { getDocumentsByArea } from 'redux/actions/documents';
import CreateMaintenanceModal from '../../../maintenance/Create';
import Documents from 'layouts/pages/documents';
import Header from 'layouts/pages/profile/components/Header';
import SuiInput from 'components/SuiInput';
import Loading from 'components/Loading';
import { getBuildingByAreas } from 'utils';
import SuiSelect from 'components/SuiSelect';
import Update from './Update';
import Swal from 'sweetalert2';
import SuiButton from 'components/SuiButton';
import { deleteArea } from 'redux/actions/communities';
import { getPermission } from 'utils';
import { getBuildingsByCommunity } from 'redux/actions/communities';
import CreateBuilding from '../buildings/Create';
import { Divider } from '@mui/material';
import { isPermissionEnabled } from 'utils';
import { updateCommunityImage } from 'redux/actions/communities';
import { getRegionsByCountry } from 'redux/actions/communities';
import { getCommunesByRegions } from 'redux/actions/communities';
import ImportAreas from './Import';
import Maintenances from 'layouts/pages/maintenance/AdminMaintenances';

function Areas(props) {
  const dispatch = useDispatch();
  const areas = useSelector((state) => state.communities.areas);
  const createArea = useSelector((state) => state.communities.createArea);
  const [areaSelected, setareaSelected] = useState(0);
  const [areaSelectedId, setareaSelectedId] = useState();
  const [areaSelectedForEdit, setareaSelectedForEdit] = useState(null);
  const [selectedIndex, setselectedIndex] = useState(0);
  const maintenanceResponse = useSelector((state) => state.maintenance.getMaintenanceByCommunity);
  const createMaintenanceResponse = useSelector((state) => state.maintenance.createMaintenance);
  const updateAreaResponse = useSelector((state) => state.communities.editArea);
  const deleteAreaResponse = useSelector((state) => state.communities.deleteArea);
  const importAreasResponse = useSelector((state) => state.communities.importAreas);
  const getBuildingsByCommunityResponse = useSelector(
    (state) => state.communities.getBuildingsByCommunity
  );
  const [loading, setloading] = useState(true);
  const getDocumentsByAreaResponse = useSelector((state) => state.documents.getDocumentsByArea);
  const getRegionsByCountryResponse = useSelector((state) => state.communities.getRegionsByCountry);
  const [buildings, setbuildings] = useState([]);
  const [buildingSelected, setbuildingSelected] = useState(null);
  const [search, setsearch] = useState('');
  const createMaintenanceVisitResponse = useSelector(
    (state) => state.maintenance.createMaintenanceVisit
  );
  const [maintenances, setmaintenances] = useState({ maintenances: [], visits: [] });
  let { slug } = useParams();
  const [editCommunity, setEditCommunity] = useState(false);

  const [name, setName] = useState(props.community && props.community.data.community.name);
  const [rut, setRut] = useState(props.community && props.community.data.community.rut);
  const [street, setStreet] = useState(props.community && props.community.data.community.street);
  const [number, setNumber] = useState(props.community && props.community.data.community.number);
  const [commune, setCommune] = useState(
    props.community &&
      props.community.data.community.commune &&
      props.community.data.community.commune.uuid
  );
  const [region, setRegion] = useState(
    props.community &&
      props.community.data.community.commune &&
      props.community.data.community.commune.region &&
      props.community.data.community.commune.region.uuid
  );

  const updateCommunityImageResponse = useSelector(
    (state) => state.communities.updateCommunityImage
  );
  const communesResponse = useSelector((state) => state.communities.getCommunesByRegions);

  const [communes, setCommunes] = useState([]);
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    setloading(true);
    dispatch(getAreas(slug));
    dispatch(getBuildingsByCommunity(slug));
    dispatch(getRegionsByCountry('chile'));
  }, []);

  useEffect(() => {
    setloading(true);
    dispatch(getAreas(slug));
  }, [slug]);

  useEffect(() => {
    if (updateAreaResponse.data) {
      dispatch(getAreas(slug));
    }
  }, [updateAreaResponse]);

  useEffect(() => {
    if (importAreasResponse.data && new Date() - importAreasResponse.time < 2000) {
      dispatch(getAreas(slug));
    }
  }, [importAreasResponse]);

  useEffect(() => {
    if (getRegionsByCountryResponse.data) {
      setRegions(getRegionsByCountryResponse.data);
    }
  }, [getRegionsByCountryResponse]);

  useEffect(() => {
    if (communesResponse.data) {
      setCommunes(communesResponse.data);
    }
  }, [communesResponse]);

  useEffect(() => {
    if (region) {
      dispatch(getCommunesByRegions(region));
    }
  }, [region]);

  useEffect(() => {
    if (getBuildingsByCommunityResponse.data) {
      setbuildings([
        { value: null, label: 'todas' },
        ...getBuildingsByCommunityResponse.data.map((building) => ({
          value: building.uuid,
          label: building.name,
        })),
      ]);
    }
  }, [getBuildingsByCommunityResponse]);

  useEffect(() => {
    if (deleteAreaResponse.data) {
      dispatch(getAreas(slug));
    }
  }, [deleteAreaResponse]);

  useEffect(() => {
    if (createArea.data) dispatch(getAreas(slug));
  }, [createArea]);

  useEffect(() => {
    if (areas.data) {
      setloading(false);
      if (areas.data.length > 0) {
        if (selectedIndex > areas.data.length - 1) {
          setselectedIndex(selectedIndex - 1);
        } else {
          setareaSelected(areas.data[selectedIndex].name);
          setareaSelectedId(areas.data[selectedIndex].uuid);
          setareaSelectedForEdit(areas.data[selectedIndex]);
        }
      }
    }
  }, [areas]);

  useEffect(() => {
    dispatch(getMaintenanceByCommunity(slug));
  }, []);

  useEffect(() => {
    if (maintenanceResponse.data !== undefined) {
      setmaintenances(maintenanceResponse.data);
    }
  }, [maintenanceResponse]);

  useEffect(() => {
    if (createMaintenanceResponse.data !== undefined) {
      dispatch(getMaintenanceByCommunity(slug));
    }
  }, [createMaintenanceResponse]);

  useEffect(() => {
    if (createMaintenanceVisitResponse.data !== undefined) {
      dispatch(getMaintenanceByCommunity(slug));
    }
  }, [createMaintenanceVisitResponse]);

  useEffect(() => {
    setloading(true);
  }, [props.selected, slug]);

  const editCommunityRequest = (d) => {
    dispatch(updateCommunityImage(slug, d));
  };

  const handleDeleteArea = () => {
    let form = { area_uuid: areaSelectedForEdit.uuid };
    dispatch(deleteArea(areaSelectedForEdit.uuid, form));
  };

  return (
    <SuiBox style={{ padding: 10 }}>
      <SuiBox style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SuiTypography variant="h5" fontWeight="medium" pb={1}>
          Información general de la Comunidad
        </SuiTypography>
        <SuiButton
          variant="gradient"
          color="warning"
          onClick={() => {
            if (!editCommunity) setEditCommunity(true);
            if (editCommunity) {
              // remove all nulls
              let d = {
                name: name,
                rut: rut,
                street: street,
                number: number,
                region: region,
                commune: commune,
              };
              for (var propName in d) {
                if (d[propName] === null) {
                  delete d[propName];
                }
              }
              editCommunityRequest(d);
              setEditCommunity(false);
            }
          }}
          size="small"
        >
          {editCommunity ? 'Guardar' : 'Editar'}
        </SuiButton>
      </SuiBox>
      <Card sx={{ p: 3, mb: 2, overflow: 'visible' }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Nombre: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiInput
                size="small"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            ) : (
              <SuiTypography variant="body2">{name}</SuiTypography>
            )}
          </Grid>

          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Rut: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiInput
                size="small"
                value={rut}
                onChange={(e) => {
                  setRut(e.target.value);
                }}
              />
            ) : (
              <SuiTypography variant="body2">{rut}</SuiTypography>
            )}
          </Grid>
          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Región: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiSelect
                value={region}
                onChange={(e) => setRegion(e.value)}
                size="small"
                options={regions.map((region) => ({
                  label: region.name,
                  value: region.uuid,
                }))}
              />
            ) : (
              <SuiTypography variant="body2">
                {regions.find((objeto) => objeto.uuid == region) &&
                  regions.find((objeto) => objeto.uuid == region).name}
              </SuiTypography>
            )}
          </Grid>
          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Comuna: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiSelect
                value={commune}
                onChange={(e) => setCommune(e.value)}
                size="small"
                options={communes.map((commune) => ({
                  label: commune.name,
                  value: commune.uuid,
                }))}
              />
            ) : (
              <SuiTypography variant="body2">
                {communes.find((objeto) => objeto.uuid == commune) &&
                  communes.find((objeto) => objeto.uuid == commune).name}
              </SuiTypography>
            )}
          </Grid>

          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Calle: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiInput
                size="small"
                value={street}
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
              />
            ) : (
              <SuiTypography variant="body2">{street}</SuiTypography>
            )}
          </Grid>
          <Grid item xs={2}>
            <SuiTypography variant="h6" fontWeight="medium">
              Número: &nbsp;
            </SuiTypography>
          </Grid>
          <Grid item xs={10}>
            {editCommunity ? (
              <SuiInput
                size="small"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            ) : (
              <SuiTypography variant="body2">{number}</SuiTypography>
            )}
          </Grid>
        </Grid>
      </Card>

      <SuiTypography variant="h5" fontWeight="medium">
        Áreas Comunes / Sistemas / Equipos
        {getPermission(['superadmin', 'administrador', 'supervisor']) &&
          isPermissionEnabled('areasSystems-createAreaSystem') && (
            <SuiBox mt={1} style={{ float: 'right' }}>
              <CreateBuilding />
              <Create buildings={buildings} />
              <ImportAreas />
            </SuiBox>
          )}
      </SuiTypography>

      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={3}>
          {' '}
          <Grid item xs={12} sm={3}>
            <Card sx={{ padding: 3, marginTop: 3, marginRight: 2, overflow: 'visible' }}>
              <SuiBox pt={2}>
                <SuiTypography variant="button" fontWeight="medium">
                  Seleccionar torre
                </SuiTypography>
                <SuiSelect
                  placeholder="Seleccione torre"
                  onChange={(e) => {
                    setbuildingSelected(e.value);
                  }}
                  options={buildings}
                />
              </SuiBox>
              <SuiBox pt={2} mb={1} width="100%" ml="auto">
                <SuiInput
                  placeholder="Buscar areas..."
                  onChange={(e) => setsearch(e.target.value)}
                />
              </SuiBox>

              {areas.data
                ? areas.data.map((area, i) => {
                    return (
                      (!area.building ||
                        buildingSelected === null ||
                        (area.building && area.building.uuid == buildingSelected)) &&
                      area.name.toLowerCase().includes(search.toLowerCase()) && (
                        <SuiBox
                          key={i}
                          onClick={() => {
                            setareaSelectedId(area.uuid);
                            setareaSelectedForEdit(area);
                            setselectedIndex(i);
                            setareaSelected(area.name);
                          }}
                        >
                          <SuiTypography
                            variant="button"
                            style={{
                              color: areaSelectedId == area.uuid ? 'blue' : '',
                              cursor: 'pointer',
                            }}
                          >
                            {area.building ? area.name + ' ' + area.building.name : area.name}
                          </SuiTypography>
                        </SuiBox>
                      )
                    );
                  })
                : ''}
            </Card>
          </Grid>
          <Grid item xs={12} sm={9}>
            <SuiBox mt={3}>
              <Area
                areaSelected={areaSelected}
                selectedUuid={areaSelectedId}
                areaSelectedForEdit={areaSelectedForEdit}
                buildings={buildings}
              />
            </SuiBox>
            <SuiBox mt={3}>
              <Card style={{ padding: 20 }}>
                <Grid container spacing={3}>
                  <Maintenances area_uuid={areaSelectedId} />
                </Grid>
              </Card>
            </SuiBox>
            <SuiBox mt={3}>
              <Card style={{ padding: 20 }}>
                <Documents area={areaSelected} areaUuid={areaSelectedId} />
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      )}
    </SuiBox>
  );
}

export default Areas;
