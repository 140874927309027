import { Box, CircularProgress } from "@mui/material";
import useAddTicketCommentary, {
  AddTicketCommentInput,
} from "api/services/tickets/mutations/AddTicketCommentary";
import { TicketModel } from "api/services/tickets/models";
import RichTextEditorControlled from "components/forms/controlled/RichTextEditorControlled";
import { SuiButtonTs } from "components/SuiButton";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  ticket: TicketModel;
}
const NewCommentary: FC<Props> = ({ ticket }) => {
  const { mutate: addComment, isPending } = useAddTicketCommentary({
    ticketUUID: ticket.uuid,
  });
  const {
    handleSubmit,
    control,
    formState,
    reset: resetForm,
  } = useForm<AddTicketCommentInput>({
    mode: "onSubmit",
    defaultValues: {
      text: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    addComment(data, {
      onSuccess() {
        resetForm();
      },
    });
  });

  return (
    <Box>
      <RichTextEditorControlled control={control} name="text" />

      <Box display={"flex"} justifyContent={"end"} mt={2}>
        {/* <CircularProgress size={32} color={ColorsTheme.Secondary} /> */}
        <SuiButtonTs
          variant="gradient"
          color="success"
          size="small"
          onClick={onSubmit}
          disabled={!formState.isDirty || isPending}
        >
          Comentar
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default NewCommentary;
