import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

interface Props {
  children: React.ReactNode;
}

export interface DialogContainerRef {
  handleOpen: () => void;
  handleClose: () => void;
}

const DialogContainer = forwardRef<DialogContainerRef, Props>(({ children }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ overflowY: 'visible', width: 500 }}>{children}</DialogContent>
    </Dialog>
  );
});

DialogContainer.displayName = 'DialogContainer';
export default DialogContainer;
