// import Axios from "axios";

import axios, { AxiosError } from 'axios';
import { API_URL } from 'redux/actions/types';
import { useAuthenticationStore } from 'stores/authentication';

export const axiosPublic = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosPublic.interceptors.response.use(undefined, (error) => {
  console.log('interceptors:', { error });
  // if error is 401

  console.log(error);
  return Promise.reject(error);
});

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Ensure credentials are sent with every request
});

apiClient.interceptors.request.use((request) => {
  const accessToken = useAuthenticationStore.getState().accessToken;
  request.headers.common['Authorization'] = `Bearer ${accessToken}`;
  return request;
});

apiClient.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error) {
    console.log(error);

    return Promise.reject(error);
  }
});

export default apiClient;
