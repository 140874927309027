import { useMutation } from "@tanstack/react-query";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { BudgetModel } from "../models";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
interface Input {
  budget_uuid: UUID;
  amount: number;
  currency: string;
  year: number;
}

interface Params {
  maintenanceUUID: UUID;
  budgetUUID: UUID;
}

const useUpdateMaintenanceBudget = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/maintenance/${params.maintenanceUUID}/budgets/${params.budgetUUID}`;
      return await axios.put<BudgetModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Presupuesto actualizada",
        message:
          "Los datos del presupuesto han sido actualizados con correctamente.",
      });
    },
  });
};

export default useUpdateMaintenanceBudget;
