import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { TicketModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { ErrorMessage } from "api/utils/errors";
import { TicketQueryHelper } from "../queries/GetTicket";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";

interface Params {
  uuid: UUID;
}

const useTicketProgressFinish = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${params.uuid}/finish-timeline/`;
      return await axios.post<TicketModel>(url).then((res) => res.data);
    },
    onSuccess(updatedTicket) {
      TicketQueryHelper.updateTicket(updatedTicket);
      toastNotification.success({
        title: "Ticket finalizado",
        message: "El ticket ha sido finalizado",
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TICKETS_TABLE],
      });
    },
    onError(error: ErrorMessage) {
      toastNotification.error({
        title: "Error al cambiar el estado de la tarea",
        message: error.data.message,
      });
    },
  });
};

export default useTicketProgressFinish;
