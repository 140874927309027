export const typeColorMapping = {
  ['Cotización']: 'info',
  Factura: 'warning',
  Cheque: 'success',
  Otro: 'dark',
  Contrato: 'primary',
  'Comprobante de pago': 'dark',
  default: 'error',
};

export const checksFilters = [
  { name: 'Imagen', slug: 'imagen' },
  { name: 'Visita', slug: 'visita' },
  { name: 'Contrato', slug: 'contrato' },
  { name: 'Comprobante de pago', slug: 'comprobante-de-pago' },
  { name: 'Cheque', slug: 'cheque' },
  { name: 'Factura', slug: 'factura' },
  { name: 'RRHH', slug: 'rrhh' },
  { name: 'Contabilidad', slug: 'contabilidad' },
  { name: 'Cotización', slug: 'cotizacion' },
  { name: 'Certificación', slug: 'certificacion' },
  { name: 'Informe', slug: 'informe' },
  { name: 'Legal', slug: 'legal' },
  { name: 'Seguros', slug: 'seguros' },
  { name: 'Otro', slug: 'otro' },
];
