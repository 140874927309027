import axios from "axios";
import { API_URL } from "redux/actions/types";
import { CommunityArea } from "../models";
import { useQuery } from "@tanstack/react-query";
import { SelectOptionForm } from "types/forms";
import QUERY_KEYS from "api/utils/keys";
export interface Params {
  slug: string;
}

const useGetCommunityArea = (params: Params, enabled = false) => {
  const query = useQuery<CommunityArea[]>({
    enabled: enabled,
    staleTime: 1000 * 2,
    queryKey: [QUERY_KEYS.GET_COMMUNITIES_AREAS, params.slug],
    queryFn: async () => {
      const url = `${API_URL}/users/community/${params.slug}/areas`;
      return await axios.get<CommunityArea[]>(url).then((res) => res.data);
    },
  });

  const data = query.data || [];
  const dataAsOptions: SelectOptionForm[] = data.map((communityArea) => ({
    value: communityArea.uuid,
    label: communityArea.name,
  }));

  return { ...query, dataAsOptions, data };
};

export default useGetCommunityArea;
