import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ChecklistItemModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { checklistTemplatesHelper } from "../queries/GetChecklistTemplates";

interface Params {
  checklistUUID: UUID;
  itemUUID: UUID;
}

const useDeleteChecklistTemplateItem = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/templates/checklist-items/${params.itemUUID}/delete/`;

      return await axios
        .delete<ChecklistItemModel>(url)
        .then((res) => res.data);
    },
    onSuccess: () => {
      checklistTemplatesHelper.deleteChecklistItem(
        params.itemUUID,
        params.checklistUUID
      );
      toastNotification.info({
        title: "Actividad eliminada",
        message: "Actividad eliminada correctamente",
      });
    },
  });
};

export default useDeleteChecklistTemplateItem;
