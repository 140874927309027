import { Box, CircularProgress } from '@mui/material';
import { ChecklistModel } from 'api/services/checklist/models';
import useAddChecklistTemplateItem from 'api/services/checklist/mutations/AddChecklistTemplateItem';
import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  checklist: ChecklistModel;
}
interface AddItemForm {
  name: string;
}
const NewItem: FC<Props> = ({ checklist }) => {
  const addItemToTemplate = useAddChecklistTemplateItem({ checklistTemplateUUID: checklist.uuid });
  const { control, formState, handleSubmit, reset } = useForm<AddItemForm>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    addItemToTemplate.mutate(
      { name: data.name },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  });

  return (
    <Box display={'flex'} gap={2}>
      <Box sx={{ flex: 1 }}>
        <InputControlled control={control} name="name" placeholder="Nueva actividad" />
      </Box>
      <Box>
        <SuiButtonTs
          variant="gradient"
          color="success"
          size="small"
          disabled={!formState.isDirty}
          onClick={onSubmit}
        >
          <Box width={80}>
            {addItemToTemplate.isPending ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              'Agregar'
            )}
          </Box>
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default NewItem;
