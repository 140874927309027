import { TicketPriority, TicketStatus } from 'api/services/tickets/models';

export const PRIORITY_ARR: TicketPriority[] = [
  TicketPriority.critical,
  TicketPriority.high,
  TicketPriority.medium,
  TicketPriority.low,
];
export const STATUS_ARR: TicketStatus[] = [
  TicketStatus.Cotizando,
  TicketStatus.Creada,
  TicketStatus.EnProgreso,
  TicketStatus.EnVotacion,
  TicketStatus.FinDeServicio,
  TicketStatus.InicioDeServicio,
  TicketStatus.RecepcionDeServicio,
];

export const ORDER_BY_OPTIONS = [
  { value: 'none', label: 'Ninguno' },
  { value: 'modified', label: 'Modificación' },
  { value: 'title', label: 'Título' },
  { value: 'date', label: 'Fecha Término' },
  { value: 'created', label: 'Fecha Creación' },
  { value: 'status', label: 'Estado' },
  { value: 'priority', label: 'Prioridad' },
];
