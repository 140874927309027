// creat  react  component with hooks

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getActivity } from 'redux/actions/activity';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Header from 'layouts/pages/profile/components/Header';
import { useSelector } from 'react-redux';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { Grid, Tooltip } from '@mui/material';
import TicketModal from 'components/Tickets/TicketDetailModal';

import SuiBadge from 'components/SuiBadge';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { AiOutlineEye, AiOutlinePaperClip } from 'react-icons/ai';
import AnualPlanModal from './AnualPlanModal';
import MaintenanceModal from 'layouts/pages/maintenance/View/MaintenanceCard';
import { setActivityModalClosed } from 'redux/actions/helper';

const parseDate = (isoDateString) => {
  const isoDate = new Date(isoDateString);

  const day = isoDate.getDate().toString().padStart(2, '0');
  const month = (isoDate.getMonth() + 1).toString().padStart(2, '0');
  const year = isoDate.getFullYear();

  const hours = isoDate.getHours().toString().padStart(2, '0');
  const minutes = isoDate.getMinutes().toString().padStart(2, '0');
  const seconds = isoDate.getSeconds().toString().padStart(2, '0');

  const formattedDate = `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  return formattedDate;
};

export const ActivityRow = ({
  activity,
  pushOrPopNotification,
  selectedNotifications,
  isUnread,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const handleNotificationClick = () => {
    if (!activity.is_read) {
      pushOrPopNotification(activity.uuid);
    }
  };

  const handleCommunityClick = () => {
    navigate(`/communities/${activity.community.slug}`);
  };

  const renderProfilePicture = () => {
    if (activity.action_user.profile_picture) {
      return (
        <img
          src={activity.action_user.profile_picture}
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
        />
      );
    }
    return null;
  };

  const renderJsonContent = () => {
    if (activity.json_content.field) {
      return (
        <SuiBox display="flex">
          <SuiBox>
            <SuiBadge
              variant="gradient"
              badgeContent={activity.json_content.previous}
              color="warning"
              size="sm"
              container
            />
          </SuiBox>
          <SuiBox p={1}>
            <FaRegArrowAltCircleRight />
          </SuiBox>
          <SuiBox>
            <SuiBadge
              variant="gradient"
              badgeContent={activity.json_content.new}
              color="success"
              size="sm"
              container
            />
          </SuiBox>
        </SuiBox>
      );
    }
    return null;
  };

  return (
    <SuiBox
      onClick={handleNotificationClick}
      style={{
        padding: '5px 20px',
        margin: '2px',
        border: '1px solid #000',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
        backgroundColor: selectedNotifications.includes(activity.uuid) ? '#d9d9d9' : '#fff',
        borderRadius: '10px',
      }}
    >
      <SuiBox display="flex" justifyContent="space-between" pb={1}>
        <SuiBox>
          {activity.community && (
            <SuiBadge
              variant="gradient"
              badgeContent={activity.community.name}
              color="dark"
              size="sm"
              container
              sx={{ cursor: 'pointer' }}
              onClick={handleCommunityClick}
            />
          )}
          {activity.service_provider && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Proveedores'}
              color="warning"
              size="sm"
              container
            />
          )}
          {activity.ticket && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Tareas'}
              color="secondary"
              size="sm"
              container
            />
          )}
          {activity.document && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Documentos'}
              color="light"
              size="sm"
              container
            />
          )}
          {activity.planification && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Planificación Anual'}
              color="info"
              size="sm"
              container
            />
          )}
          {activity.report && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Visita en terreno'}
              color="primary"
              size="sm"
              container
            />
          )}
          {activity.maintenance && (
            <SuiBadge
              style={{ marginLeft: 10 }}
              variant="gradient"
              badgeContent={'Mantención'}
              color="success"
              size="sm"
              container
            />
          )}
        </SuiBox>
        <SuiTypography variant="body2" fontWeight="medium">
          <span style={{ fontWeight: 'bold' }}>Fecha:</span> {parseDate(activity.created)}
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" justifyContent="space-between">
        <SuiBox display="flex" style={{ width: '70%' }}>
          {renderProfilePicture()}
          <SuiTypography variant="body2" fontWeight="medium" p={1}>
            {activity.action_user.first_name}
          </SuiTypography>
          <SuiTypography variant="body2" fontWeight="medium" p={1}>
            {activity.text}
          </SuiTypography>
          {renderJsonContent()}
        </SuiBox>
        <SuiBox display="flex" flexDirection="row">
          {activity.ticket && (
            <TicketModal uuid={activity.ticket} community={activity.community.slug} />
          )}
          {activity.planification && (
            <>
              <AnualPlanModal planification={activity.planification} />
            </>
          )}
          {activity.report && (
            <SuiBox ml={1}>
              <Tooltip title={'Ver Reporte'} placement="top">
                <SuiBadge
                  container
                  badgeContent={<AiOutlineEye size="16px" />}
                  size="xs"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    if (isMobile) navigate('/visits/report/' + activity.report);
                    else window.open('/visits/report/' + activity.report, '_blank');
                  }}
                  color={'info'}
                ></SuiBadge>
              </Tooltip>
            </SuiBox>
          )}
          {activity.service_provider && (
            <SuiBox ml={1}>
              <Tooltip title={'Ver proveedor'} placement="top">
                <SuiBadge
                  container
                  badgeContent={<AiOutlineEye size="16px" />}
                  size="xs"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    if (isMobile) navigate('/providers/' + activity.service_provider);
                    else window.open('/providers/' + activity.service_provider, '_blank');
                  }}
                  color={'info'}
                ></SuiBadge>
              </Tooltip>
            </SuiBox>
          )}
          {activity.document && (
            <SuiBox ml={1}>
              <Tooltip title="Ver documento" placement="top">
                <a
                  href={activity.document.file}
                  target="_blank"
                  onClick={(e) => dispatch(setActivityModalClosed({ time: new Date() }))}
                >
                  <SuiBadge badgeContent={<AiOutlinePaperClip size="16px" />} size="xs" container />
                </a>
              </Tooltip>
            </SuiBox>
          )}
          {activity.maintenance && (
            <SuiBox ml={1}>
              <Tooltip title="Ver mantención" placement="top">
                <MaintenanceModal modal={true} activityRow={true} uuid={activity.maintenance} />
              </Tooltip>
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
};
