import { types } from "../actions/types";

export const activityReducer = (state = { getActivity: {}, markAsRead: {}, markAllAsRead: {}, getReadActivity: {}, getActivityUnreadCount: {} }, action) =>
{
  switch (action.type)
  {
    case types.setGetActivity:
      return {
        ...state,
        getActivity: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date()
        },
      }
    case types.setMarkAsRead:
      return {
        ...state,
        markAsRead: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date()
        }
      }
    case types.setMarkAllAsRead:
      return {
        ...state,
        markAllAsRead: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date()
        }
      }

    case types.setGetReadActivity:
      return {
        ...state,
        getReadActivity: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date()
        }
      }

    case types.setGetActivityUnreadCount:
      return {
        ...state,
        getActivityUnreadCount: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        }
      }



    default:
      return state;
  }
};
