import { useParams } from 'react-router-dom';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'examples/Footer';
import LoadingView from 'components/Loading';
import CommunityGantt from 'components/CommunityGantt';
import { useGetCommunityPublicGantt } from 'api/services/communities/queries/GetPublicGantt';

function PublicCommunityGantt() {
  const { slug: communitySlug } = useParams();
  const { data: communityGantt, isLoading } = useGetCommunityPublicGantt({ slug: communitySlug as string });

  if (isLoading) return <LoadingView />;
  return (
    <PageLayout>
        <h2 style={{textAlign: 'center', paddingTop: 10}}>Carta Gantt</h2>
      <CommunityGantt data={communityGantt} />
      <Footer />
    </PageLayout>
  );
}

export default PublicCommunityGantt;
