import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { TicketModel, TicketsPage } from './models';
import { userToken } from 'utils/user/user';
import { useQuery } from '@tanstack/react-query';
import {
  GetTicketDetailInput,
  GetTicketMembersOptionsInput,
  GetTicketsByManamentParams,
} from './types/inputs';
import { PossibleAssigments } from './types/responses';
import { setGetPublicTicket } from 'redux/actions/tickets';
import { useDispatch } from 'react-redux';
import QUERY_KEYS from 'api/utils/keys';

export const GetTicketsPageByManagement = (params: GetTicketsByManamentParams) => {
  return useQuery<TicketsPage>({
    staleTime: 1000 * 60 * 5,
    queryKey: ['GetTicketsByManagement', params],
    queryFn: async () => {

      const url = `${API_URL}/tickets/list/`;
      return await axios
        .get<TicketsPage>(url, { params })
        .then((res) => res.data)
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
  });
};

export const GetTicketByUUID = (input: GetTicketDetailInput) => {
  const dispatch = useDispatch();
  return useQuery<TicketModel>({
    queryKey: [QUERY_KEYS.TICKET, input.uuid],
    queryFn: async () => {
      const currentUrl = window.location.href;
      if (currentUrl.includes('/ticket/public/')) {
        const url = `${API_URL}/tickets/public/${input.uuid}`;
        return await fetch(url)
          .then((response) => response.json())
          .then((data) => {
            dispatch(
              setGetPublicTicket({
                status: 200,
                message: 'OK',
                data: data,
              })
            );
            return data;
          });
        // dispatch(setGetPublicTicket(error));
      } else {
        const url = `${API_URL}/tickets/by-uuid/${input.uuid}`;
        return await axios
          .get<{ ticket: TicketModel }>(url)
          .then((res) => res.data.ticket)
          .catch((error) => {
            console.log(error);
            return null;
          });
      }
    },
    enabled: !!input.uuid,
    // staleTime: 1000 * 60 * 5,
  });
};

export const GetUF = () => {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['GetUFValue'],
    queryFn: async () => {
      return await fetch('https://mindicador.cl/api')
        .then((res) => res.json())
        .then((dailyIndicators) => dailyIndicators.uf.valor);
    },
  });
};

export const GetTicketAssignmentMemebers = (input?: GetTicketMembersOptionsInput) => {
  const query = useQuery<PossibleAssigments>({
    queryKey: ['GetTicketAssignmentMemebers', input?.communitySlug],
    queryFn: async () => {
      const { communitySlug } = input;
      const url = `${API_URL}/tickets/${communitySlug}/possible-assignees/`;
      return await axios.get<PossibleAssigments>(url).then((res) => res.data);
    },
    staleTime: 1000 * 60 * 5,
    enabled: input?.communitySlug !== null,
    placeholderData: {
      all_possible_assignees: [],
      auto_possible_assignees: [],
    },
  });

  const selectOptions =
    query.data.all_possible_assignees?.map((user) => ({
      label: user.first_name,
      value: user.email,
    })) || [];
  const defaultSelected =
    query.data.auto_possible_assignees?.map((user) => ({
      label: user.first_name,
      value: user.email,
    })) || [];
  return { ...query, selectOptions, defaultSelected };
};
