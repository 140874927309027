import { Stack } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { ColumnTable } from "components/SwappiTable/types";
import { useMemo } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { parseDate } from "utils";

export const useCreateColumns = () => {
  const navigate = useNavigate();
  return useMemo<ColumnTable<MaintenanceModel>[]>(() => {
    return [
      {
        header: "Comunidad",
        accessorKey: "community",
        cell: ({ row }) => row.original.community.name,
      },
      {
        header: "Area común",
        accessorKey: "area",
        cell: ({ row }) => row.original.area.name,
      },
      {
        header: "Proveedor",
        accessorKey: "provider",
        cell: ({ row }) =>
          row.original.service_provider?.executive_name ||
          row.original.service_provider?.razon_social ||
          "Sin proveedor",
      },
      {
        header: "Fecha expiración",
        accessorKey: "end_date",
        cell: ({ row }) => parseDate(row.original.end_date),
      },
      {
        header: "Contrato",
        accessorKey: "contract",
        cell: ({ row }) => {
          const [contract] = row.original.contract.filter(
            (contract) => contract.is_active
          );
          if (contract)
            return (
              <a href={`https://api.swappi.cl/documents/${contract.uuid}/url/redirect/`} target="_blank">
                <SuiBadgeTs
                  badgeContent={"Contrato"}
                  size="xs"
                  container
                  color={
                    new Date(contract.expires_on) < new Date()
                      ? "warning"
                      : "success"
                  }
                />
              </a>
            );
          return (
            <SuiBadgeTs
              style={{ float: "left" }}
              badgeContent={"Sin Contrato"}
              size="xs"
              container
              color="error"
            />
          );
        },
      },
      {
        header: "Certificación",
        accessorKey: "certification",
        cell: ({ row }) => {
          const certification =
            row.original.certifications[0]?.documents.filter(
              (item) => item.is_active
            )[0];
          if (certification)
            return (
              <a href={`https://api.swappi.cl/documents/${certification.uuid}/url/redirect/`} target="_blank">
                  <SuiBadgeTs
                    badgeContent={"Certificación"}
                    size="xs"
                    container
                    color={
                      new Date(certification.expires_on) < new Date()
                        ? "warning"
                        : "success"
                    }
                  />
                </a>
            );
          return (
            <SuiBadgeTs
              style={{ float: "left" }}
              badgeContent={"Sin Certificación"}
              size="xs"
              container
              color="error"
            />
          );
        },
      },
      {
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => {
          return (
            <Stack direction={"row"}>
              <SuiBadgeTs
                style={{ cursor: "pointer" }}
                badgeContent={<AiOutlineEye size="16px" />}
                onClick={() =>{
                  navigate(`/maintenances/${row.original.uuid}`)
                }}
                size="xs"
                container
              />
            </Stack>
          );
        },
      },
    ];
  }, []);
};
