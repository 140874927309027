/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import colors from '../base/colors';

const link = {
  // defaultProps: {
  //   // underline: "none",
  //   // color: "inherit",
  // },
  defaultProps: {
    underline: 'always',
    color: colors.info.main,
  },
};

export default link;
