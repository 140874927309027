import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Modal,
  Box,
  Button,
  Typography,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import ExportHistoryTable from "../index";
import { Close } from "@mui/icons-material";
import SuiButton, { SuiButtonTs } from "components/SuiButton";

const ExportHistoryModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Botón con tooltip para abrir el modal */}
      <Tooltip title="Historial centro de exportación">
        <SuiButtonTs
          onClick={handleOpen}
          variant="text"
          color="dark"
          startIcon={<HistoryIcon />}
          sx={{ textTransform: "none", borderRadius: "8px" }}
        >
          Historial
        </SuiButtonTs>
      </Tooltip>

      {/* Modal que contiene la tabla */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="export-history-modal-title"
        aria-describedby="export-history-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
            borderRadius: "16px", // Esquinas redondeadas
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="text" color="primary">
              <IconButton>
                <Close />
              </IconButton>
            </Button>
          </Box>
          <ExportHistoryTable />
        </Box>
      </Modal>
    </>
  );
};

export default ExportHistoryModal;
