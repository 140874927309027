/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Settings page components
import BaseLayout from 'layouts/pages/account/components/BaseLayout';
import Sidenav from 'layouts/pages/account/settings/components/Sidenav';
import Header from 'layouts/pages/profile/components/Header';
import BasicInfo from 'layouts/pages/account/settings/components/BasicInfo';
import ChangePassword from 'layouts/pages/account/settings/components/ChangePassword';
import Authentication from 'layouts/pages/account/settings/components/Authentication';
import Accounts from 'layouts/pages/account/settings/components/Accounts';
import Notifications from 'layouts/pages/account/settings/components/Notifications';
import Sessions from 'layouts/pages/account/settings/components/Sessions';
import DeleteAccount from 'layouts/pages/account/settings/components/DeleteAccount';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from 'redux/actions/users';
import Loading from 'components/Loading';
import SuiButton from 'components/SuiButton';
import { setNotification } from 'redux/actions/communities';

function Settings() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.users.getUserProfile);
  const updateProfileResponse = useSelector((state) => state.users.updateUserProfile);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.data) {
        setLoading(false);
        localStorage.setItem('profile', JSON.stringify(userProfile.data));
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (updateProfileResponse) {
      if (updateProfileResponse.data) {
        dispatch(getUserProfile());
      }
    }
  }, [updateProfileResponse]);
  if (loading) return <Loading />;
  return (
    <SuiBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicInfo profile={userProfile.data} />
              </Grid>
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
              <Grid item xs={12}>
                <Notifications />
              </Grid>
              <Grid item xs={12}>
                <SuiButton variant="gradient" color="error" fullWidth onClick={() => {
                  let notification = { status: "success", title: "Solicitud enviada", message: "Se ha enviado una solicitud para eliminar su cuenta." };
                  dispatch(setNotification(notification))
                }}>
                  Solicitar Eliminar Cuenta
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Settings;
