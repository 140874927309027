import { UUID } from 'crypto';
import { create } from 'zustand';

export interface TicketModalState {
  isOpen: boolean;
  ticketUUID: UUID;
  closeModal: () => void;
  setSelectedTicketUUID: (uuid: UUID) => void;
  handleNext: () => unknown;
  handlePrev: () => unknown;
}

export const useTicketModalStore = create<TicketModalState>((set) => ({
  isOpen: false,
  ticketUUID: null,
  closeModal: () => set({ isOpen: false, ticketUUID: null }),
  setSelectedTicketUUID: (uuid: UUID) => set({ ticketUUID: uuid, isOpen: true }),
  handleNext: undefined,
  handlePrev: undefined,
}));
export const TicketModalHelper = {
  openTicket: (ticketUUID: UUID) => {
    useTicketModalStore.setState({
      ticketUUID,
      isOpen: true,
    });
  },
};
