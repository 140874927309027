import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { SuiButtonTs } from "components/SuiButton";
import BaseDialog, { DialogHandle } from "components/ui/BaseDialog";
import useEventListener from "lib/events/useEventListener";
import { VisitModel } from "api/services/visits/models";
import FormUploadFile, {
  MAINTENANCE_VISIT_FILE_UPLOADED,
} from "./AddDocumentForm";

const AddDocumentButton: React.FC<{ visit: VisitModel }> = ({ visit }) => {
  const fileDialogRef = useRef<DialogHandle>(null);
  const openDialog = () => {
    fileDialogRef.current.open();
  };

  useEventListener(MAINTENANCE_VISIT_FILE_UPLOADED, () => {
    fileDialogRef.current.close();
  });
  return (
    <Box>
      <SuiButtonTs
        size="small"
        color="secondary"
        variant="gradient"
        onClick={openDialog}
      >
        Subir documento
      </SuiButtonTs>
      <BaseDialog ref={fileDialogRef}>
        <Box minWidth={500}>
          <Typography variant="h4" mb={2}>
            Confirmar visita
          </Typography>
          <FormUploadFile visit={visit} />
        </Box>
      </BaseDialog>
    </Box>
  );
};

export default AddDocumentButton;
