import { useMutation } from "@tanstack/react-query";
import { ErrorMessage } from "api/utils/errors";
import { TicketQueryHelper } from "api/services/tickets/queries/GetTicket";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { PollModel } from "../models";

interface Params {
  ticketUUID: UUID;
  communitySlug: string;
}
export interface CreatePollInput {
  question: string;
  start_date: ISODateString;
  end_date: ISODateString;
}
const useCreatePoll = (params: Params) => {
  return useMutation({
    mutationFn: async (input: CreatePollInput) => {
      const url = `${API_URL}/polls/create/`;
      return await axios
        .post<PollModel>(url, {
          ...input,
          ticket: params.ticketUUID,
          community: params.communitySlug,
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      if (params.ticketUUID)
        TicketQueryHelper.updatePoll(data, params.ticketUUID);
      toastNotification.success({
        title: "Votación creada con éxito",
        message: "La votación ha sido creada con éxito",
      });
    },
    onError: (error: ErrorMessage) => {
      toastNotification.error({
        title: "Error al crear votación",
        message: error.data.message,
      });
    },
  });
};

export default useCreatePoll;
