import React, { useMemo, useEffect } from "react";

import Grid from "@mui/material/Grid";

import { SuiButtonTs } from "components/SuiButton";
import {
  validate as validateRut,
  clean as cleanRut,
  format as formatRut,
} from "rut.js";
import { useForm } from "react-hook-form";
import InputControlled from "components/forms/controlled/InputControlled";
import { Box } from "@mui/material";
import useEventEmitter from "lib/events/useEventEmitter";
import { UserModel } from "api/services/user/models";
import useUpdateUser from "api/services/user/mutations/updateUser";
import SwitchControlled from "components/forms/controlled/SwitchControlled";
import { SuiInputTs } from "components/SuiInput";

const UpdateUserForm: React.FC<{ user: UserModel }> = ({ user }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: useMemo(() => {
      return {
        first_name: user.first_name,
        phone: user.phone,
        email: user.email,
        rut: formatRut(user.rut),
        weekly_report_notification: user.weekly_report_notification,
        profile_picture: user.profile_picture,
        is_concierge: user.management ? user.groups.some(group => group.name === "conserje") : false,
        is_butler: user.management ? user.groups.some(group => group.name === "mayordomo") : false,
        is_admin: !user.management ? user.groups.some(group => group.name === "administrador") : false,
        is_supervisor: !user.management ? user.groups.some(group => group.name === "supervisor") : false,
      };
    }, [user]),
  });
  const { mutate: updateUser } = useUpdateUser(user.uuid);
  const userUpdated = useEventEmitter("userUpdated");
  const onSubmit = handleSubmit((data) => {
    const groups = user.management
      ? [
          data.is_concierge && { name: "conserje" },
          data.is_butler && { name: "mayordomo" }
        ].filter(Boolean)
      : [
          data.is_admin && { name: "administrador" },
          data.is_supervisor && { name: "supervisor" }
        ].filter(Boolean);

    updateUser(
      {
        first_name: data.first_name,
        phone: data.phone,
        rut: cleanRut(data.rut),
        weekly_report_notification: data.weekly_report_notification,
        profile_picture: data.profile_picture,
        groups,
      },
      {
        onSuccess: (data) => userUpdated.emit(data),
      }
    );
  });

  const watchConcierge = watch("is_concierge");
  const watchButler = watch("is_butler");
  const watchAdmin = watch("is_admin");
  const watchSupervisor = watch("is_supervisor");

  useEffect(() => {
    if (watchConcierge) {
      setValue("is_butler", false, { shouldDirty: true });
    }
  }, [watchConcierge, setValue]);

  useEffect(() => {
    if (watchButler) {
      setValue("is_concierge", false, { shouldDirty: true });
    }
  }, [watchButler, setValue]);

  useEffect(() => {
    if (watchAdmin) {
      setValue("is_supervisor", false, { 
        shouldDirty: true,
        shouldTouch: true 
      });
    }
  }, [watchAdmin, setValue]);

  useEffect(() => {
    if (watchSupervisor) {
      setValue("is_admin", false, { 
        shouldDirty: true,
        shouldTouch: true 
      });
    }
  }, [watchSupervisor, setValue]);

  return (
    <form onSubmit={onSubmit}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Nombre"
            name="first_name"
            control={control}
            placeholder="Ingrese su nombre"
            rules={{ required: "*Este campo es requerido" }}
            requiredDot
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Teléfono"
            name="phone"
            control={control}
            placeholder="+56991652881"
            rules={{ required: "*Este campo es requerido" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Rut"
            placeholder="11111111-1"
            name="rut"
            control={control}
            format={(value) => formatRut(value)}
            rules={{
              required: "*Este campo es requerido",
              validate: (value) =>
                validateRut(value) || "El rut ingresado no es válido",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SwitchControlled
            label="Reporte semanal"
            name="weekly_report_notification"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputControlled
            control={control}
            label="Foto de perfil"
            name="profile_picture"
            type="file"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box display="flex" gap={2}>
            {user.management ? (
              <>
                <SwitchControlled
                  label="Conserje"
                  name="is_concierge"
                  control={control}
                />
                <SwitchControlled
                  label="Mayordomo"
                  name="is_butler"
                  control={control}
                />
              </>
            ) : (
              <>
                <SwitchControlled
                  label="Administrador"
                  name="is_admin"
                  control={control}
                />
                <SwitchControlled
                  label="Supervisor"
                  name="is_supervisor"
                  control={control}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <SuiButtonTs
          color="success"
          variant="gradient"
          size="small"
          type="submit"
          disabled={!isValid || !isDirty}
        >
          Guardar cambios
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default UpdateUserForm;
