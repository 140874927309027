import { useQuery } from '@tanstack/react-query';
import { axiosPublic } from 'api/config/axios';
import QUERY_KEYS from 'api/utils/keys';
import axios from 'axios';
import { ISODateString } from 'types/models';

interface Indicador {
  // codigo: string;
  // nombre: string;
  // unidad_medida: string;
  // fecha: ISODateString; // Puedes usar Date si prefieres convertir la cadena de fecha a un objeto Date
  valor: number;
}

interface MindicadorData {
  version: string;
  autor: string;
  fecha: string; // También puedes usar Date aquí si prefieres
  uf: Indicador;
  ivp: Indicador;
  dolar: Indicador;
  dolar_intercambio: Indicador;
  euro: Indicador;
  ipc: Indicador;
  utm: Indicador;
  imacec: Indicador;
  tpm: Indicador;
  libra_cobre: Indicador;
  tasa_desempleo: Indicador;
  bitcoin: Indicador;
}

const instance = axios.create();

export const useGetUF = () => {
  return useQuery<Indicador>({
    queryKey: [QUERY_KEYS.GET_UF],
    queryFn: async () => {
      return await instance
        .get<MindicadorData>('https://mindicador.cl/api')
        .then((res) => res.data.uf);
    },
  });
};
