import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { queryClient } from "api/config/queryClient";
import { GET_MAINTENANCE_KEY } from "api/services/maintenances/queries/getMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";

import { VisitModel } from "api/services/visits/models";
import useDeleteVisit from "api/services/visits/mutations/deleteVisit";
import { SuiBadgeTs } from "components/SuiBadge";
import React from "react";
import Swal from "sweetalert2";
import { GET_VISITS_KEY } from "layouts/pages/calendar/CreateVisit/CreateForm";

const DeleteVisitButton: React.FC<{
  visit: VisitModel;
  maintenance?: MaintenanceModel;
  onCloseModal: () => void; // Añadir prop para cerrar el modal
}> = ({ visit, maintenance, onCloseModal }) => {
  const { mutate: deleteVisit } = useDeleteVisit(visit.uuid);

  const handleDelete = () => {
    Swal.fire({
      title: "¿Quieres eliminar esta visita?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVisit(null, {
          onSuccess: () => {
            if (maintenance?.uuid) {
              queryClient.invalidateQueries({
                queryKey: [GET_MAINTENANCE_KEY, maintenance.uuid],
              });
            }
            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey[0] === GET_VISITS_KEY,
            });
            onCloseModal(); // Cierra el modal al completar con éxito
          },
        });
      }
    });
  };

  return (
    <Tooltip title="Borrar visita" placement="top">
      <SuiBadgeTs
        onClick={handleDelete}
        badgeContent={<Delete />}
        sx={{ cursor: "pointer" }}
        color="error"
        variant="gradient"
        container
      />
    </Tooltip>
  );
};

export default DeleteVisitButton;
