import useExportMaintenancePDF from "api/services/maintenances/actions/exportMaintenancePdf";
import { MaintenanceModel } from "api/services/maintenances/models";
import { SuiButtonTs } from "components/SuiButton";
import React from "react";
import { GrDocumentPdf } from "react-icons/gr";

const ExportMaintenanceButton: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const { action: exportPDF, isPending } = useExportMaintenancePDF(
    maintenance.uuid
  );
  const handleClick = () => {
    exportPDF();
  };
  return (
    <SuiButtonTs sx={{ gap: 1 }} onClick={handleClick} disabled={isPending}>
      Exportar <GrDocumentPdf size={15} />
    </SuiButtonTs>
  );
};

export default ExportMaintenanceButton;
