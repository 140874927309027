import { Box, Checkbox, Tooltip } from "@mui/material";
import { MaintenancesPage } from "api/services/maintenances/models";
import { MaintenanceModel } from "api/services/maintenances/models";

import { SuiBadgeTs } from "components/SuiBadge";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ColumnTable } from "components/SwappiTable/types";
import MaintenanceModal from "layouts/pages/maintenance/View/MaintenanceCard";
import { parseDate } from "utils";
import { SelectedMaintenancesState } from "../types";
interface ColumnsRequired {
  data: MaintenancesPage;
  selectedMaintenances: SelectedMaintenancesState;
  addMaintenances: (item: MaintenanceModel[]) => void;
  removeMaintenances: (item: MaintenanceModel[]) => void;
}
export const getColumns = ({
  data,
  selectedMaintenances,
  addMaintenances,
  removeMaintenances,
}: ColumnsRequired): ColumnTable<MaintenanceModel>[] => {
  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll) addMaintenances(data.results);
    else removeMaintenances(data.results);
  };
  const handleCheckbox = (checked: boolean, item: MaintenanceModel) => {
    if (checked) addMaintenances([item]);
    else removeMaintenances([item]);
  };

  const validateCheckAll = () =>
    data?.results.every((ticket) => {
      return selectedMaintenances[ticket.uuid] !== undefined;
    });
  const allChecked = validateCheckAll();

  return [
    {
      header: () => (
        <Box onClick={() => handleCheckAll(!allChecked)} display={"flex"}>
          <Checkbox checked={allChecked} />
          <Tooltip
            title="Esta opción selecciona solo la hoja actual"
            placement="top"
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      ),
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Checkbox
          checked={selectedMaintenances[row.original.uuid] !== undefined}
          onChange={(value) =>
            handleCheckbox(value.target.checked, row.original)
          }
        />
      ),
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community.name,
    },
    {
      header: "Area común",
      accessorKey: "area",
      cell: ({ row }) => row.original.area.name,
    },
    {
      header: "Proveedor",
      accessorKey: "provider",

      cell: ({ row }) =>
        row.original.service_provider?.executive_name ||
        row.original.service_provider?.razon_social ||
        "Sin proveedor",
    },
    {
      header: "Fecha expiración",
      accessorKey: "end_date",
      cell: ({ row }) => parseDate(row.original.end_date),
    },
    {
      header: "Contrato",
      accessorKey: "contract",

      cell: ({ row }) => {
        const [contract] = row.original.contract
          .filter((item) => item.is_active)
          .slice(-1);
        if (contract?.file)
          return (
            <a target="_blank" href={contract?.file}>
              <SuiBadgeTs
                style={{ float: "left", cursor: "pointer" }}
                badgeContent={"Contrato"}
                size="xs"
                container
                color={
                  new Date(contract.expires_on) < new Date()
                    ? "warning"
                    : "success"
                }
              />
            </a>
          );

        return (
          <SuiBadgeTs
            style={{ float: "left" }}
            badgeContent={"Sin Contrato"}
            size="xs"
            container
            color="error"
          />
        );
      },
    },
    {
      header: "Certificación",
      accessorKey: "certification",

      cell: ({ row }) => {
        const certification = row.original.certifications[0]?.documents.filter(
          (item) => item.is_active
        )[0];

        if (certification?.file)
          return (
            <a target="_blank" href={certification && certification.file}>
              <SuiBadgeTs
                style={{ float: "left", cursor: "pointer" }}
                badgeContent={"Certificación"}
                size="xs"
                container
                color={
                  new Date(certification.expires_on) < new Date()
                    ? "warning"
                    : "success"
                }
              />
            </a>
          );
        return (
          <SuiBadgeTs
            style={{ float: "left" }}
            badgeContent={"Sin Certificación"}
            size="xs"
            container
            color="error"
          />
        );
      },
    },
    {
      header: "Acciones",
      accessorKey: "actions",
      cell: ({ row }) => <MaintenanceModal modal uuid={row.original.uuid} />,
    },
  ];
};
