import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { checklistTemplatesHelper } from "../queries/GetChecklistTemplates";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  uuid: UUID;
}
const useDeleteChecklistTemplate = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/templates/checklists/${params.uuid}/delete/`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      checklistTemplatesHelper.deleteChecklist(params.uuid);
      toastNotification.info({
        title: "Plantilla eliminada",
        message: "Se ha eliminado la plantilla de forma correcta",
      });
    },
  });
};

export default useDeleteChecklistTemplate;
