import React from "react";
import useChecklistTemplateModalStore from "./store";
import { Box, Modal } from "@mui/material";
import useMediaScreen from "utils/Hooks/useMedia";
import { desktopStyle, mobileStyle } from "./styles";
import ChecklistTemplateEditor from "components/ChecklistsComponents/ChecklistTemplateEditor";
import useEventEmitter from "lib/events/useEventEmitter";

export const CLOSE_CHECKLIST_TEMPLATE_MODAL = "CLOSE_CHECKLIST_TEMPLATE_MODAL";

const ChecklistTemplateModal = () => {
  const { isMobile } = useMediaScreen();
  const { isOpen, checklistUUID, closeModal } =
    useChecklistTemplateModalStore();

  const onCloseModalEvent = useEventEmitter(CLOSE_CHECKLIST_TEMPLATE_MODAL);

  const handleOnClose = () => {
    onCloseModalEvent.emit();
    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={handleOnClose}>
      <Box sx={isMobile ? mobileStyle : desktopStyle}>
        <Box p={3}>
          <ChecklistTemplateEditor
            checklistUUID={checklistUUID}
            onDelete={handleOnClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ChecklistTemplateModal;
