/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiButton from 'components/SuiButton';
// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';
import { updateUserProfile } from 'redux/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'utils';
import { updateAdminLogo } from 'redux/actions/users';
import { getUserProfile } from 'redux/actions/users';
import { getAuthUserProfile } from 'stores/authentication/helpers';

function BasicInfo(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [profileChange, setProfileChange] = useState(false);
  const [file, setFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const updateAdminLogoResponse = useSelector((state) => state.users.updateAdminLogo);

  const dispatch = useDispatch();
  useEffect(() => {
    setName(props.profile.first_name);
    setPhone(props.profile.phone);
    setRut(props.profile.rut);
    setEmail(props.profile.email);
  }, [props.profile]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('uuid', props.profile.uuid);
    formData.append('first_name', name);
    formData.append('phone', phone);
    formData.append('rut', rut);
    formData.append('email', email);
    if (isFilePicked) {
      formData.append('profile_picture', file);
    }
    dispatch(updateUserProfile(formData));
    setProfileChange(false);
  };

  useEffect(() => {
    if (updateAdminLogoResponse.data) {
      dispatch(getUserProfile());
    }
  }, [updateAdminLogoResponse]);

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Información Principal</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Foto"
              type="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setIsFilePicked(true);
                setProfileChange(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Nombre"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setProfileChange(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="email" value={email} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Teléfono"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setProfileChange(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Rut"
              value={rut}
              onChange={(e) => {
                setRut(e.target.value);
                setProfileChange(true);
              }}
            />
          </Grid>
          {getPermission(['superadmin', 'administrador']) && (
            <Grid item xs={12} sm={6}>
              <FormField
                label="Logo"
                type="file"
                onChange={(e) => {
                  let formData = new FormData();
                  formData.append('logo', e.target.files[0]);
                  let slugManagement = getAuthUserProfile().management.slug;
                  dispatch(updateAdminLogo(slugManagement, formData));
                }}
              />
            </Grid>
          )}
          {getPermission(['superadmin', 'administrador']) && (
            <Grid item xs={12} sm={6}>
              <FormField
                label="Banner"
                type="file"
                onChange={(e) => {
                  let formData = new FormData();
                  formData.append('banner', e.target.files[0]);
                  let slugManagement = getAuthUserProfile().management.slug;
                  dispatch(updateAdminLogo(slugManagement, formData));
                }}
              />
            </Grid>
          )}

          {profileChange && (
            <Grid item xs={12} sm={6} mt={3}>
              <SuiBox ml="auto">
                {' '}
                <SuiButton color="success" size="small" onClick={handleUpdate}>
                  Guardar
                </SuiButton>
              </SuiBox>
            </Grid>
          )}
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
