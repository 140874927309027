import { useMutation } from '@tanstack/react-query';
import { axiosPublic } from 'api/config/axios';
import { IMessage } from 'api/utils/queries';
import { useAuthenticationStore } from 'stores/authentication';

const useLogout = () => {
  return useMutation({
    mutationFn: async () => {
      const url = '/api/token/end/';
      return await axiosPublic.post<IMessage>(url).then((rest) => rest.data);
    },
    onSuccess: () => {
      useAuthenticationStore.setState({
        isAuthenticated: false,
        userProfile: undefined,
        accessToken: undefined,
      });
    },
  });
};

export default useLogout;
