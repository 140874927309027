import React, { FC, useRef, useState, useMemo } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { DocumentModel } from "api/services/documents/models";
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./example.css";
import { SuiBadgeTs } from "components/SuiBadge";
import ShowMoreLess from "components/ShowMore";
import { isPermissionEnabled } from "utils";
import EditFileDrawer, {
  EditFileDrawerHandles,
} from "components/EditFileDrawer";
import { deleteDocument } from "redux/actions/documents";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { AnyAction } from "redux";
import useMediaScreen from "utils/Hooks/useMedia";
import DropdownMenu from "components/DropdownMenu";
import { Delete, Edit } from "@mui/icons-material";
import UploadFileDrawer from "components/UploadFileDrawer";
import { ReportModel } from "api/services/analytics/models";
import { useGetDocumentUrls } from "api/services/documents/queries/getDocumentUrl";

interface Props {
  documents: DocumentModel[];
  report: ReportModel;
}

const DocumentsImagesCard: FC<Props> = ({ documents, report }) => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaScreen();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const drawerRef = useRef<EditFileDrawerHandles>(null);

  const uuids = documents.map((doc) => doc.uuid);
  const documentQueries = useGetDocumentUrls(uuids, true);

  const galleryItems: ReactImageGalleryItem[] = useMemo(() => {
    return documentQueries
      .map((query) => {
        const url = query.data?.url;
        return {
          original: url,
          thumbnail: url,
          thumbnailHeight: 100,
          thumbnailWidth: 100,
          originalHeight: 400,
        };
      })
      .filter((item) => item.original && item.thumbnail);
  }, [documentQueries]);

  const selectedDocument = documents[selectedIndex];

  const handleDeleteDocument = () =>
    Swal.fire({
      title: "¿Quieres eliminar este documento?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteDocument(
            selectedDocument.uuid,
            "report"
          ) as unknown as AnyAction
        );
        Swal.fire("Eliminado", "", "success");
      }
    });

  const handleEditDocument = () => drawerRef.current?.toggleDrawer();

  return (
    <Card sx={{ p: 2 }}>
      <Box display={"flex"}>
        <Typography variant="h5" flex={1}>
          Imágenes
        </Typography>
      </Box>
      {documents.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} data-section="images-gallery">
            <Box className="image-gallery-wrapper">
              <ReactImageGallery
                items={galleryItems}
                onSlide={setSelectedIndex}
                infinite={false}
                showPlayButton={false}
                showIndex={false}
                showBullets={isMobile}
                showNav={isMobile}
                showThumbnails={!isMobile}
              />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} data-section="documents-info">
            <Box>
              <Box display={"flex"} mb={1}>
                <Box flex={1}>
                  <Typography variant="h4" fontWeight="bold">
                    {selectedDocument && selectedDocument.name}
                  </Typography>
                </Box>
                <Box>
                  <DropdownMenu
                    items={[
                      [
                        {
                          label: "Editar información",
                          icon: <Edit />,
                          onClick: handleEditDocument,
                        },
                      ],
                      [
                        {
                          label: "Eliminar documento",
                          icon: <Delete />,
                          onClick: handleDeleteDocument,
                        },
                      ],
                    ]}
                  />
                  {isPermissionEnabled("fieldVisits-editVisit") && (
                    <section>
                      <EditFileDrawer
                        ref={drawerRef}
                        fromReport={true}
                        file={selectedDocument}
                        slugType={"imagen"}
                        community={
                          selectedDocument && selectedDocument.community
                        }
                        onlyDrawer
                      />
                    </section>
                  )}
                </Box>
              </Box>
              <Stack direction={"row"} spacing={1} mb={2}>
                {selectedDocument &&
                  selectedDocument.tags.map((tag) => (
                    <SuiBadgeTs
                      key={tag.uuid}
                      variant="gradient"
                      color={"info"}
                      size={"small"}
                      container
                      badgeContent={tag.name}
                    />
                  ))}
              </Stack>
              <Typography mb={1} variant="h5" fontWeight="bold">
                Descripción
              </Typography>
              <ShowMoreLess height={360}>
                <Typography
                  variant="button"
                  fontWeight={"regular"}
                  textTransform={"none"}
                >
                  {selectedDocument && selectedDocument.observations}
                </Typography>
              </ShowMoreLess>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography textAlign="center">No hay imágenes</Typography>
      )}
    </Card>
  );
};

export default DocumentsImagesCard;
