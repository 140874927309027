import React from 'react';
import { Navigate } from 'react-router-dom';
import { getPermission } from 'utils';

const RoleLanding = () => {
  if (getPermission(['comite', 'conserje', 'mayordomo'])) return <Navigate to={'/tickets'} />;

  return <Navigate to={'/communities'} />;
};

export default RoleLanding;
