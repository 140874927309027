import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiButton from "components/SuiButton";
import { getPermission } from "utils";
import { mobileMaxWidth } from "utils";
import { FaEdit } from "react-icons/fa";
import SuiBadge from "components/SuiBadge";
import { Tooltip } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  borderRadius: "10px",
  boxShadow: 24,
};

const styleMobile = {
  position: "absolute",
  width: "100%",
  height: "80vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

export default function CreateReminder(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const scrollContainerRef = useRef(null);

  const handleScrollButtonClick = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;

      // Calculamos la diferencia entre la altura total del contenido y la altura visible del contenedor
      const scrollDistance = scrollContainer.scrollHeight - scrollContainer.clientHeight;

      // Hacemos el scroll hasta el final del contenido
      scrollContainer.scrollTo({
        top: scrollDistance,
        behavior: "smooth", // Esto crea un desplazamiento suave animado
      });
    }
  };
  return (
    <div>
      {!props.update &&
        getPermission(["superadmin", "supervisor", "administrador", "mayordomo", "conserje"]) && (
          <SuiButton variant="gradient" color="success" size="small" onClick={handleOpen}>
            Crear Notas
          </SuiButton>
        )}
      {props.update &&
        getPermission(["superadmin", "supervisor", "administrador", "mayordomo", "conserje"]) && (
          <Tooltip title="Editar" placement="top">
            <SuiBadge
              style={{ cursor: "pointer" }}
              badgeContent={<FaEdit />}
              onClick={handleOpen}
              color="warning"
            />
          </Tooltip>
        )}
      <Modal
        style={{ overflow: "visible" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box
          ref={scrollContainerRef}
          sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}
        >
          <Form
            scroll={handleScrollButtonClick}
            handleClose={handleClose}
            update={props.update}
            reminder={props.reminder}
          />
        </Box>
      </Modal>
    </div>
  );
}
