import { Button } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import useCreatePollDrawerStore from 'components/Polls/CreatePollFormDrawer/store';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
interface Props {
  ticket: TicketModel;
}
const CreatePollButton: FC<Props> = ({ ticket }) => {
  const openCratePollForm = useCreatePollDrawerStore((state) => state.open);
  const handleOpenForm = () => {
    openCratePollForm(ticket);
  };
  return (
    <SuiButtonTs variant="gradient" color="success" size="small" onClick={handleOpenForm}>
      Crear Votación
    </SuiButtonTs>
  );
};

export default CreatePollButton;
