import { Box } from '@mui/material';
import { CommentaryModel, TicketModel } from 'api/services/tickets/models';
import useEditTicketCommentary from 'api/services/tickets/mutations/EditTicketCommentary';
import RichTextEditorControlled from 'components/forms/controlled/RichTextEditorControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface CommentaryEditorProps {
  onCancel: () => unknown;
  onSuccess: () => unknown;
  commentary: CommentaryModel;
  ticket?: TicketModel;
}
interface FormState {
  text: string;
}

const CommentaryEditor: FC<CommentaryEditorProps> = ({
  commentary,
  ticket,
  onCancel,
  onSuccess,
}) => {
  const { mutate: editCommentary, isPending } = useEditTicketCommentary({
    commentaryUUID: commentary.uuid,
    ticketUUID: ticket.uuid,
  });

  const { control, reset, formState, handleSubmit } = useForm<FormState>();
  useEffect(() => {
    reset({ text: commentary.text });
  }, []);

  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const onSubmit = handleSubmit((data) => {
    editCommentary(data, { onSuccess: () => onSuccess() });
  });
  return (
    <div>
      <RichTextEditorControlled control={control} name="text" />
      <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={1}>
        <SuiButtonTs variant="gradient" color="secondary" size="small" onClick={handleOnCancel}>
          Cancelar
        </SuiButtonTs>
        <SuiButtonTs
          variant="gradient"
          color="success"
          size="small"
          onClick={onSubmit}
          disabled={!formState.isDirty || isPending}
        >
          Guardar
        </SuiButtonTs>
      </Box>
    </div>
  );
};

export default CommentaryEditor;
