import { API_URL } from "redux/actions/types";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { queryClient } from "api/config/queryClient";
import useEventListener from "lib/events/useEventListener";
import { UserModel } from "../models";

const queryKey = "GetComiteByCommunitySlug";

export const useGetComiteByCommunitySlug = (communitySlug: string) => {
  const query = useQuery({
    enabled: !!communitySlug,
    queryKey: [queryKey, communitySlug],
    queryFn: async () => {
      const url = `${API_URL}/users/community/${communitySlug}/board/`;
      return axios.get(url).then((res) => res.data);
    },
  });
  useEventListener<UserModel>(
    ["userUpdated", "userCreated", "userDeactivated"],
    () => {
      queryClient.invalidateQueries({
        queryKey: [queryKey, communitySlug],
      });
    }
  );
  

  return { ...query };
};