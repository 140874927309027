import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { SuiAvatarTs } from "components/SuiAvatar";
import { SuiButtonTs } from "components/SuiButton";
import React, {
  MouseEvent,
  useState,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticate } from "stores/authentication/helpers";
import useMediaScreen from "utils/Hooks/useMedia";

// Definimos la interfaz para las funciones expuestas por DeviceWrapper
interface DeviceWrapperHandles {
  openMenu: (event: MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
}

const DeviceWrapper = forwardRef<DeviceWrapperHandles, { children: ReactNode }>(
  ({ children }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { isMobile } = useMediaScreen();

    const isOpen = Boolean(anchorEl);
    const closeMenu = () => setAnchorEl(null);
    const openMenu = (event: MouseEvent<HTMLElement>) =>
      setAnchorEl(event.currentTarget);

    useImperativeHandle(ref, () => ({
      openMenu,
      closeMenu,
    }));

    return isMobile ? (
      <Dialog open={isOpen} onClose={closeMenu}>
        <Box sx={{ minWidth: 300, p: 2 }}>{children}</Box>
      </Dialog>
    ) : (
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        TransitionComponent={Grow}
        PaperProps={{ elevation: 5, sx: { minWidth: 300, p: 2 } }}
        
      >
        {children}
      </Menu>
    );
  }
);

// Asignamos un nombre de display al componente para evitar el error
DeviceWrapper.displayName = "DeviceWrapper";

const AccountAvatarMenu = () => {
  const navigate = useNavigate();
  const { userProfile, closeSession } = useAuthenticate();
  const wrapperRef = useRef<DeviceWrapperHandles>(null);

  const handleManageAccount = () => {
    navigate("/profile");
    wrapperRef.current?.closeMenu();
  };

  return (
    <Box>
      <Tooltip title="Cuenta" placement="bottom">
        <IconButton onClick={(event) => wrapperRef.current?.openMenu(event)}>
          <SuiAvatarTs
            uuid={userProfile.uuid}
            src={userProfile.profile_picture}
            alt={userProfile.first_name}
          />
        </IconButton>
      </Tooltip>
      <DeviceWrapper ref={wrapperRef}>
        <section>
          <Box display="flex" gap={1} alignItems="center" mb={2}>
            <SuiAvatarTs
              uuid={userProfile.uuid}
              src={userProfile.profile_picture}
              alt={userProfile.first_name}
            />
            <Typography
              variant="button"
              textTransform="none"
              fontWeight="regular"
            >
              {userProfile.first_name} - {userProfile.email}
            </Typography>
          </Box>
          <SuiButtonTs
            onClick={handleManageAccount}
            variant="gradient"
            color="info"
            size="small"
            fullWidth
          >
            Administrar cuenta
          </SuiButtonTs>
          <Divider />
          <MenuItem onClick={closeSession}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </section>
      </DeviceWrapper>
    </Box>
  );
};

export default AccountAvatarMenu;
