import { Box, Stack } from "@mui/material";
import { UserModel } from "api/services/user/models";
import { SuiAvatarTs } from "components/SuiAvatar";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiTypographyTs } from "components/SuiTypography";
import CircleContainer from "components/ui/CircleContainer";
import React from "react";
import { SuiColorVariant } from "utils/sui/colors.types";

type ColorMap = { [key: string]: SuiColorVariant };
const groupColorMap: ColorMap = {
  supervisor: "primary",
  conserje: "warning",
  superadmin: "success",
};

interface Props {
  user: UserModel;
}

const UserTeamCard: React.FC<Props> = ({ user }) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      width={120}
      height={"100%"}
      p={1}
    >
      <CircleContainer>
        <SuiAvatarTs uuid={user.uuid} alt={user.first_name} />
      </CircleContainer>
      {user.groups[0].name && (
        <Box>
          <SuiBadgeTs
            container
            size="xs"
            variant="gradient"
            color={groupColorMap[user.groups[0].name]}
            badgeContent={user.groups[0].name}
          />
        </Box>
      )}
      <SuiTypographyTs
        mt={1}
        textAlign={"center"}
        variant="button"
        color="text"
        fontWeight="regular"
      >
        {user.first_name}
      </SuiTypographyTs>
    </Stack>
  );
};

export default UserTeamCard;
