/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import Footer from 'examples/Footer';
import ComplexProjectCard from 'examples/Cards/ProjectCards/ComplexProjectCard';
import PlaceholderCard from 'examples/Cards/PlaceholderCard';
import SimpleBlogCard from 'examples/Cards/BlogCards/SimpleBlogCard';
// Project page components
import Header from 'layouts/pages/profile/components/Header';
import Documents from 'layouts/pages/documents';
import { useDispatch, useSelector } from 'react-redux';

function DocumentsByManagement() {
  useState(() => {}, []);

  return <Documents management={true} />;
}

export default DocumentsByManagement;
