import { MaintenanceModel } from "api/services/maintenances/models";
import React, { useMemo, useState } from "react";
import { useCreateDocumentsTableColumns } from "./colums.helper";
import SwappiDataTable from "components/SwappiTable";
import useEventListener from "lib/events/useEventListener";

const DocumentsTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const columns = useCreateDocumentsTableColumns();
  const [filters, setFilters] = useState();

  useEventListener("setDocumentsFilters", (data) => {
    setFilters(data);
  });

  const filteredData = useMemo(() => {
    return maintenance.documents;
  }, [filters, maintenance.documents]);

  return (
    <div>
      <SwappiDataTable columns={columns} data={filteredData} localSearch />
    </div>
  );
};

export default DocumentsTable;
