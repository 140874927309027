import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'api/config/queryClient';
import { VisitModel } from 'api/services/visits/models';
import axios from 'axios';
import useEventListener from 'lib/events/useEventListener';
import { API_URL } from 'redux/actions/types';
const queryKey = "GetVisits";

const fetchVisits = async (start, end, communities) => {
  const isEmpty = value => 
    value == null || // null o undefined
    (typeof value === 'string' && value.trim() === '') || // cadena vacía
    (Array.isArray(value) && value.length === 0) || // array vacío
    (typeof value === 'object' && Object.keys(value).length === 0); // objeto vacío
  
  const params = {
    ...(start && !isEmpty(start) && { start }),
    ...(end && !isEmpty(end) && { end }),
    ...(communities && !isEmpty(communities) && { communities }),
  };
  
  const { data } = await axios.get(`${API_URL}/visits/`, {
    params
  });
  return data;
};

export const useVisits = (start, end, communities) => {
  const queryResult = useQuery({
    enabled: true,
    queryKey: [queryKey, start, end],
    queryFn: () => fetchVisits(start, end, communities),
  });

  // Escucha el evento "visitUpdated" para invalidar las queries relacionadas
  useEventListener<VisitModel>(["visitUpdated"], () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === queryKey,
    });
  });

  return queryResult;
};
