import React, { FC } from "react";
import { Box, Typography, Checkbox, SxProps } from "@mui/material";
import { Control, useController, UseControllerProps } from "react-hook-form";
import { SelectOptionForm } from "types/forms";

interface SelectProps extends UseControllerProps {
  label?: string;
  control: Control<any>;
  options?: SelectOptionForm[];
  variant?: "start-item";
}
const startItem: SxProps = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  alignItems: "flex-start",
};
const CheckboxControlled: FC<SelectProps> = ({
  name,
  control,
  rules,
  label,
  disabled,
  variant,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <Box
      component={"label"}
      sx={{ ...(variant === "start-item" && startItem) }}
    >
      {label && (
        <Typography variant="h6" fontWeight="medium">
          {label}
        </Typography>
      )}
      <Checkbox {...field} checked={field.value} disabled={disabled} />
    </Box>
  );
};

export default CheckboxControlled;
