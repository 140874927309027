import { SelectOptionForm } from 'types/forms';
import { PRIORITY_ARR, STATUS_ARR } from 'layouts/pages/export-center/SelectTicketTable/constants';
import { PriorityFilter, StatusFilter } from 'layouts/pages/export-center/SelectTicketTable/types';

export const parsePriority = (priorityFilter: PriorityFilter) => {
  const arr = [];
  PRIORITY_ARR.forEach((item) => {
    if (priorityFilter[item] !== undefined) arr.push(item);
  });
  return arr.join();
};

export const parseStatus = (status: StatusFilter) => {
  const arr = [];
  STATUS_ARR.forEach((item) => {
    if (status[item] !== undefined) arr.push(item);
  });
  return arr.join();
};

export const parseCommunities = (communities: SelectOptionForm[]) => {
  return communities.map(({ value }) => value).join();
};

export const parseOrderBy = (orderBy: string, sortBy: string) => {
  return `${sortBy}${orderBy}`;
};
