import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import { mobileMaxWidth, parseDate, isPermissionEnabled } from "utils";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import "assets/css/recordButton.css";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import SuiTypography from "components/SuiTypography";
// Calendar application components
import Header from "layouts/pages/profile/components/Header";
import { useParams } from "react-router-dom";
import {
  getVisitReport,
  editVisitReport,
  getVisitReportPDF,
} from "redux/actions/reports";
import LoadingView from "components/Loading";
import {
  Box,
  Card,
  Divider,
  Icon,
  Typography,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SuiButton from "components/SuiButton";
import SuiEditor from "components/SuiEditor";
import SuiInput from "components/SuiInput";
import UploadFileDrawer from "components/UploadFileDrawer";
import AddTicketModal from "components/Tickets/AddTicketModal";
import SuiBadge from "components/SuiBadge";
import { ticketStatusColor, ticketPriorityColor } from "utils";
import SuiProgress from "components/SuiProgress";
import TicketModal from "components/Tickets/TicketDetailModal";
import { BiArchiveIn } from "react-icons/bi";
import DataTable from "examples/Tables/DataTable";
import SuiAvatar from "components/SuiAvatar";
import { archiveTicket } from "redux/actions/tickets";
import Swal from "sweetalert2";
import { getPublicVisitReport } from "redux/actions/communities";
import { BsPaperclip } from "react-icons/bs";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "assets/images/logo-blue.png";
import Checklist from "components/ChecklistsComponents/Checklist";
import AddChecklistButton from "components/pages/VisitsReports/Actions/AddChecklistButton";
import useEventListener from "lib/events/useEventListener";
import { EVENTS } from "lib/events/events";
import DocumentsImagesCard from "components/VisitsReports/DocumentsImagesCard";
import ShowMoreLess from "components/ShowMore";
import { FaEye } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import VideoCarousel from "components/VideoCarrousel";
const renderMembers = (members) => (
  <SuiBox display="flex">
    {members.map((member, i) => (
      <Tooltip key={i} title={member.first_name} placement="bottom">
        <SuiAvatar
          src={member.profile_picture}
          alt={member.first_name}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            ml: -1.25,
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",
            "&:hover, &:focus": {
              zIndex: "10",
            },
          })}
          style={{ backgroundColor: "black" }}
        />
      </Tooltip>
    ))}
  </SuiBox>
);

function VisitReport(props) {
  const dispatch = useDispatch();
  const { slug, uuid: paramUuid } = useParams();
  const uuid = paramUuid || props.uuid;

  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});
  const [editDescription, setEditDescription] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [editedDescription, setEditedDescription] = useState("");
  const [editedTitle, setEditedTitle] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [exportingTicket, setExportingTicket] = useState(false);

  const getReportResponse = useSelector(
    (state) => state.communities.getVisitReport
  );
  const deleteDocumentResponse = useSelector(
    (state) => state.documents.deleteDocument
  );
  const ticketResponse = useSelector((state) => state.tickets.createTicket);
  const addDocument = useSelector((state) => state.documents.addDocument);
  const editFileResponse = useSelector(
    (state) => state.documents.updateDocument
  );
  const editReportResponse = useSelector(
    (state) => state.reports.editVisitReport
  );
  const getReportPdfResponse = useSelector(
    (state) => state.reports.getVisitReportPDF
  );
  const deleteTicketResponse = useSelector(
    (state) => state.tickets.archiveTicket
  );
  const checklistResponse = useSelector(
    (state) => state.tickets.addChecklistToTicket
  );
  const itemChecklistResponse = useSelector(
    (state) => state.tickets.addItemToChecklist
  );
  const deleteChecklistResponse = useSelector(
    (state) => state.tickets.deleteChecklist
  );
  const deleteChecklistItemResponse = useSelector(
    (state) => state.tickets.deleteChecklistItem
  );
  const markChecklistItemResponse = useSelector(
    (state) => state.tickets.markChecklistItem
  );
  const addChecklistToTicketFromTemplateResponse = useSelector(
    (state) => state.tickets.addChecklistToTicketFromTemplate
  );

  const hasVideos = documents.some(({ type }) => type.slug === "video");
  const doRequest = useCallback(() => {
    dispatch(getVisitReport(uuid));
  }, [dispatch, uuid]);

  const handleArchiveTickets = (ticketUuid) => {
    dispatch(archiveTicket(ticketUuid));
  };

  useEventListener(EVENTS.NEW_TICKET, () => {
    doRequest();
  });

  useEffect(() => {
    if (props.public) {
      dispatch(getPublicVisitReport(uuid));
    } else {
      dispatch(getVisitReport(uuid));
    }
  }, [props.public, dispatch, uuid]);

  useEffect(() => {
    if (getReportPdfResponse?.data) {
      setExportingTicket(false);
    }
  }, [getReportPdfResponse]);

  useEffect(() => {
    const responses = [
      ticketResponse,
      addDocument,
      editReportResponse,
      checklistResponse,
      addChecklistToTicketFromTemplateResponse,
      markChecklistItemResponse,
      itemChecklistResponse,
      deleteChecklistResponse,
      deleteChecklistItemResponse,
      deleteTicketResponse,
      deleteDocumentResponse,
      editFileResponse,
    ];
    const shouldUpdate = responses.some((res) => res && res.data);
    if (shouldUpdate) {
      doRequest();
    }
  }, [
    ticketResponse,
    addDocument,
    editReportResponse,
    checklistResponse,
    addChecklistToTicketFromTemplateResponse,
    markChecklistItemResponse,
    itemChecklistResponse,
    deleteChecklistResponse,
    deleteChecklistItemResponse,
    deleteTicketResponse,
    deleteDocumentResponse,
    editFileResponse,
    doRequest,
  ]);

  useEffect(() => {
    if (getReportResponse?.data) {
      const {
        report: reportData,
        checklists: reportChecklists,
        tickets: reportTickets,
        documents: reportDocs,
      } = getReportResponse.data;
      setReport(reportData);
      setChecklists(reportChecklists);
      setEditedDescription(reportData.body);
      setEditedTitle(reportData.title);
      setTickets(unitsTableData(reportTickets, handleArchiveTickets));
      setDocuments(reportDocs);
      setLoading(false);
    }
  }, [getReportResponse]);

  const unitsTableData = (tickets, archive) => {
    let columns = [
      { Header: "Título", accessor: "title" },
      { Header: "Comunidad", accessor: "community" },
    ];

    if (window.innerWidth >= mobileMaxWidth) {
      const addColumns = [
        { Header: "Responsables", accessor: "assignees" },
        { Header: "Prioridad", accessor: "priority" },
        { Header: "Estado", accessor: "status" },
        { Header: "Progreso", accessor: "progress" },
        { Header: "Checklist", accessor: "checklists_progress" },
        { Header: "Fecha inicio", accessor: "created" },
        { Header: "Fecha término", accessor: "end_date" },
      ];
      columns = columns.concat(addColumns);
    }

    if (slug && window.innerWidth >= mobileMaxWidth) {
      columns = [
        { Header: "Título", accessor: "title" },
        { Header: "Responsables", accessor: "assignees" },
        { Header: "Prioridad", accessor: "priority" },
        { Header: "Estado", accessor: "status" },
        { Header: "Progreso", accessor: "progress" },
        { Header: "Checklist", accessor: "checklists_progress" },
        { Header: "Fecha inicio", accessor: "created" },
        { Header: "Fecha término", accessor: "end_date" },
        { Header: "Acciones", accessor: "actions" },
      ];
    }

    if (!columns.find((c) => c.accessor === "actions")) {
      columns.push({ Header: "Acciones", accessor: "actions" });
    }

    if (!tickets) {
      return { columns, rows: [] };
    }

    const rows = tickets.map((ticket) => {
      const date = Date.parse(ticket.due_date);
      return {
        title: (
          <TicketModal
            uuid={ticket.uuid}
            title={ticket.title}
            community={ticket.community}
            update={doRequest}
          />
        ),
        code: ticket.code,
        community: ticket.community.name,
        assignees: renderMembers(ticket.assignees),
        status: (
          <SuiBadge
            style={{ float: "left" }}
            badgeContent={ticket.status}
            color={ticketStatusColor[ticket.status]}
            size="xs"
            container
          />
        ),
        progress: (
          <SuiProgress
            style={{ width: "100%" }}
            value={(parseFloat(ticket.progress) * 100).toFixed()}
            variant="gradient"
            label
          />
        ),
        checklists_progress: (
          <SuiProgress
            style={{ width: "100%" }}
            value={(parseFloat(ticket.checklists_progress) * 100).toFixed()}
            variant="gradient"
            label
          />
        ),
        documents:
          ticket.document_number && ticket.document_number > 0 ? "Si" : "No",
        priority: (
          <SuiBadge
            style={{ float: "left" }}
            badgeContent={ticket.priority}
            color={ticketPriorityColor[ticket.priority]}
            size="xs"
            container
          />
        ),
        created: ticket.created && parseDate(ticket.created),
        end_date: ticket.due_date && parseDate(date),
        area: ticket.area ? ticket.area.name : "",
        actions: (
          <div>
            {props.public ? (
              <a
                href={`https://app.swappi.cl/ticket/public/${ticket.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                <SuiBadge
                  badgeContent={<FaEye size="16px" />}
                  color="info"
                  size="xs"
                  container
                />
              </a>
            ) : (
              <TicketModal
                uuid={ticket.uuid}
                community={ticket.community}
                update={doRequest}
              />
            )}
            {isPermissionEnabled("tasks-deleteTask") && (
              <SuiBadge
                container
                badgeContent={<BiArchiveIn size="16px" />}
                size="xs"
                style={{ cursor: "pointer", marginTop: 2 }}
                onClick={() => {
                  Swal.fire({
                    title: "Quieres eliminar esta tarea?",
                    showDenyButton: true,
                    confirmButtonText: "Si",
                    denyButtonText: `No`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      archive(ticket.uuid);
                      Swal.fire("Archivado!", "", "success");
                    } else if (result.isDenied) {
                      Swal.fire("Tarea no archivada", "", "error");
                    }
                  });
                }}
                color={"error"}
              />
            )}
          </div>
        ),
      };
    });

    return { columns, rows };
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [actionsOpen, setActionsOpen] = useState(false);

  const actionButtons = (
    <SuiBox
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        pb: 2,
        pt: 2,
      }}
    >
      <SuiTypography fontWeight="medium" component="h6" textAlign="center">
        Acciones
      </SuiTypography>
      <Divider />
      {exportingTicket ? (
        <SuiBox style={{ textAlign: "center", marginBottom: "10px" }}>
          <CircularProgress size={20} />
        </SuiBox>
      ) : (
        <SuiBox display="flex" flexDirection="column" gap={1}>
          <SuiButton
            size="small"
            variant="text"
            color="dark"
            onClick={() => {
              navigator.clipboard.writeText(
                "https://app.swappi.cl/public/visits/report/" + report.uuid
              );
            }}
          >
            Copiar Enlace <BsPaperclip />
          </SuiButton>
          {!props.public && (
            <SuiButton
              color="dark"
              variant="text"
              size="small"
              onClick={() => {
                if (exportingTicket) return;
                setExportingTicket(true);
                dispatch(getVisitReportPDF(report.uuid));
              }}
            >
              Exportar Pdf{" "}
              <Icon>
                <GrDocumentPdf />
              </Icon>
            </SuiButton>
          )}
          {!props.public && isPermissionEnabled("fieldVisits-editVisit") && (
            <Tooltip placement="top" title="Editar" sx={{ cursor: "pointer" }}>
              <SuiButton
                color="dark"
                variant="text"
                onClick={() => {
                  if (!props.public) {
                    setEditDescription(!editDescription);
                    setEditedDescription(report.body);
                  }
                }}
              >
                Editar <Icon>edit</Icon>
              </SuiButton>
            </Tooltip>
          )}
          {!props.public && (
            <SuiBox display="flex" justifyContent="center">
              <AddChecklistButton
                objectUUID={uuid}
                onAdd={doRequest}
                variant="text"
                color="dark"
                alignButton={"center"}
              />
            </SuiBox>
          )}
          {isPermissionEnabled("fieldVisits-editVisit") && (
            <UploadFileDrawer
              user={undefined}
              fromReport={true}
              visitReport={report.uuid}
              community={report.community?.slug}
              title={"Subir Imagen o Video"}
              color="dark"
              moduleType="visitReport"
              variant={"text"}
              alignButton={"center"}
            />
          )}
          {isPermissionEnabled("fieldVisits-editVisit") && (
            <SuiBox display="flex" justifyContent="center">
              <AddTicketModal
                alignButton={"left"}
                color="dark"
                variant="text"
                visitReport={report.uuid}
                community={report.community?.slug}
              />
            </SuiBox>
          )}
        </SuiBox>
      )}
    </SuiBox>
  );

  const renderContent = () => (
    <SuiBox pt={3}>
      {/* Si es mobile, mostramos un botón arriba para mostrar/ocultar el panel de acciones */}
      {isMobile && (
        <Box mb={2}>
          <SuiBox display="flex" justifyContent="center">
            <SuiButton
              variant="text"
              color="dark"
              onClick={() => setActionsOpen(!actionsOpen)}
            >
              {actionsOpen ? "Ocultar Acciones" : "Acciones"}
            </SuiButton>
          </SuiBox>
          <Collapse in={actionsOpen} timeout="auto" unmountOnExit>
            <SuiBox
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 9999,
                background: "#fff",
                boxShadow: "0px 2px 8px rgba(0,0,0,0.2)",
                p: 2,
              }}
            >
              <SuiButton
                variant="text"
                color="dark"
                onClick={() => setActionsOpen(false)}
                sx={{ float: "right" }}
              >
                Cerrar
              </SuiButton>
              <Box mt={4}>{actionButtons}</Box>
            </SuiBox>
          </Collapse>
        </Box>
      )}

      <TicketModal create={true} update={doRequest} />
      <SuiBox style={{ display: "flex", justifyContent: "center" }}>
        {props.public && (
          <a
            style={{ margin: "0 auto" }}
            target="_blank"
            rel="noreferrer"
            href="https://app.swappi.cl"
          >
            <img
              src={logo}
              style={{ width: "10rem", margin: "0 auto" }}
              alt="Logo"
            />
          </a>
        )}
      </SuiBox>
      <SuiTypography
        variant="h4"
        fontWeight="medium"
        textAlign="center"
        mt={1}
        mb={1}
      >
        Reporte Visita
      </SuiTypography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Card sx={{ p: 2 }}>
            {!props.public && isPermissionEnabled("fieldVisits-editVisit") && (
              <SuiBox display="flex" justifyContent="flex-end">
                <Tooltip
                  placement="top"
                  title="Editar"
                  sx={{ cursor: "pointer" }}
                >
                  <SuiButton
                    color="dark"
                    variant="text"
                    onClick={() => {
                      if (!props.public) {
                        setEditDescription(!editDescription);
                        setEditedDescription(report.body);
                      }
                    }}
                  >
                    Editar&nbsp;&nbsp;<Icon>edit</Icon>
                  </SuiButton>
                </Tooltip>
              </SuiBox>
            )}
            <Box display={"flex"} gap={2}>
              <Box flex={1}>
                {!editDescription ? (
                  <Typography
                    variant="h4"
                    fontWeight="medium"
                    onClick={() => {
                      if (!props.public) {
                        setEditDescription(true);
                        setEditedDescription(report.body);
                      }
                    }}
                  >
                    {report.title}
                  </Typography>
                ) : (
                  <SuiInput
                    size="small"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                  />
                )}
              </Box>
              <Box>
                <SuiTypography variant="h6" fontWeight="medium">
                  {report.community?.name}
                </SuiTypography>
              </Box>
            </Box>
            <Divider />
            <Box mt={2}>
              <Box>
                <SuiTypography
                  fontWeight="medium"
                  component="h5"
                  onClick={() => {
                    if (!props.public) {
                      setEditDescription(true);
                      setEditedDescription(report.body);
                    }
                  }}
                >
                  Descripción
                </SuiTypography>
              </Box>
              <Box>
                {!editDescription ? (
                  <ShowMoreLess height={210}>
                    <Box>
                      {!editedDescription && (
                        <div
                          onClick={() => {
                            if (!props.public) {
                              setEditDescription(true);
                            }
                          }}
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          <p>No hay descripción</p>
                        </div>
                      )}
                      <SuiBox p={0}>
                        <SuiTypography
                          variant="body2"
                          style={{ cursor: "pointer" }}
                          component={"span"}
                          onClick={() => {
                            if (!props.public) {
                              setEditDescription(true);
                            }
                          }}
                          color="text"
                          className="ql-editor"
                        >
                          {ReactHtmlParser(editedDescription)}
                        </SuiTypography>
                      </SuiBox>
                    </Box>
                  </ShowMoreLess>
                ) : (
                  <SuiBox p={1}>
                    <SuiEditor
                      mb={2}
                      size="small"
                      onChange={(e) => setEditedDescription(e)}
                      multiline
                      rows={10}
                      value={editedDescription}
                    />
                    <SuiBox style={{ float: "right", marginTop: 5 }}>
                      <SuiButton
                        size="small"
                        color="success"
                        variant="gradient"
                        onClick={() => {
                          dispatch(
                            editVisitReport({
                              uuid: report.uuid,
                              body: editedDescription,
                              title: editedTitle,
                            })
                          );
                          setEditDescription(false);
                        }}
                      >
                        Guardar
                      </SuiButton>
                      <SuiButton
                        size="small"
                        color="error"
                        variant="gradient"
                        onClick={() => setEditDescription(false)}
                      >
                        Cancelar
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                )}
              </Box>
            </Box>
          </Card>
          <Divider />
          <Card sx={{ padding: 3, marginTop: 2 }}>
            <Box>
              {checklists && (
                <Box>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <SuiTypography fontWeight="medium" component="h5">
                      Checklists
                    </SuiTypography>
                    {!props.public && (
                      <SuiBox display="flex" justifyContent="flex-end">
                        <AddChecklistButton
                          objectUUID={uuid}
                          onAdd={doRequest}
                          variant="gradient"
                          color="success"
                          alignButton={"center"}
                        />
                      </SuiBox>
                    )}
                  </Box>
                  {checklists.length === 0 && (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <p>No hay checklists disponibles</p>
                    </div>
                  )}
                  {checklists.map((checklist, i) => (
                    <Checklist
                      key={i}
                      checklist={checklist}
                      objectUUID={uuid}
                      editEnabled={!props.public}
                      onEdit={doRequest}
                      onDelete={doRequest}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Card>
          <Divider />
          <Box mt={2}>
            {isPermissionEnabled("fieldVisits-editVisit") && (
              <UploadFileDrawer
                user={undefined}
                fromReport={true}
                visitReport={report.uuid}
                community={report.community?.slug}
                title={"Subir Imagen o Video"}
                color="success"
                moduleType="visitReport"
                variant={"gradient"}
                alignButton={"right"}
              />
            )}
            <DocumentsImagesCard
              documents={documents.filter(
                (document) => document.type.slug == "imagen"
              )}
              report={report}
            />
          </Box>

          {hasVideos && (
            <SuiBox>
              <Divider />
              <VideoCarousel
                videoUrls={documents.map(
                  (document) => document.type.slug === "video" && document
                )}
              />
            </SuiBox>
          )}
          <Divider />
          <Card sx={{ padding: 3, mt: 1 }}>
            <SuiBox
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <SuiTypography fontWeight="medium" component="h5">
                Tareas
              </SuiTypography>
            </SuiBox>
            {isPermissionEnabled("fieldVisits-editVisit") && (
              <SuiBox display="flex" justifyContent="flex-end">
                <AddTicketModal
                  alignButton={"left"}
                  color="success"
                  variant="gradient"
                  visitReport={report.uuid}
                  community={report.community?.slug}
                />
              </SuiBox>
            )}
            {tickets.rows?.length === 0 && (
              <div style={{ textAlign: "center", width: "100%" }}>
                <p>No hay tareas disponibles</p>
              </div>
            )}
            {tickets.rows?.length > 0 && <DataTable table={tickets} />}
          </Card>
        </Grid>

        {/* Botones de acción en Desktop */}
        {!isMobile && (
          <Grid item xs={12} md={2}>
            {actionButtons}
          </Grid>
        )}
      </Grid>
    </SuiBox>
  );

  if (props.public) {
    return loading ? (
      <LoadingView />
    ) : (
      <PageLayout overflow={"scroll"}>
        <SuiBox p={3}>{renderContent()}</SuiBox>
      </PageLayout>
    );
  }

  return loading ? <LoadingView /> : renderContent();
}

export default VisitReport;
