import { types } from '../actions/types';

export const providersReducer = (
  state = {
    getProvidersByManagement: {},
    createProviderByManagement: {},
    updateProvider: {},
    deleteProvider: {},
    getProviderProfile: {},
    getTicketsByProvider: {},
    getMaintenanceByProvider: {},
    getDocumentsByProvider: {},
    addCommunityToProvider: {},
    createContact: {},
    getProvider: {},
    importProviders: {},
  },
  action
) => {
  switch (action.type) {
    case types.setGetProvidersByManagement:
      return {
        ...state,
        getProvidersByManagement: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setCreateProviderByManagement:
      return {
        ...state,
        createProviderByManagement: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setUpdateProvider:
      return {
        ...state,
        updateProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setDeleteProvider:
      return {
        ...state,
        deleteProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetTicketsByProvider:
      return {
        ...state,
        getTicketsByProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetMaintenanceByProvider:
      return {
        ...state,
        getMaintenanceByProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetDocumentsByProvider:
      return {
        ...state,
        getDocumentsByProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setAddCommunityToProvider:
      return {
        ...state,
        addCommunityToProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setCreateContact:
      return {
        ...state,
        createContact: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    case types.setGetProvider:
      return {
        ...state,
        getProvider: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    case types.setImportProviders:
      return {
        ...state,
        importProviders: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    default:
      return state;
  }
};
