export interface QueryOptions {
  enabled?: boolean;
  onSuccessCb: (data: any) => void;
}

export interface PageParams {
  page: number;
  page_size: number;
}

export interface PageResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface IMessage<T = undefined> {
  message: string;
  data: T;
}

export const PLACEHOLDER_PAGE: PageResponse<any> = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};
