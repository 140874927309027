import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { SlugString } from "types/models";
import { UserGroupsEnum } from "../models.enum";
import { toastNotification } from "components/ui/ToastNotification";
import { UserModel } from "../models";

interface Input {
  first_name: string;
  email: string;
  phone: string;
  rut: string;
  management: string;
  password: string;
  groups: UserGroupsEnum[];
  community_slug?: SlugString;
}

const useCreateUser = () => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/users/`;
      return axios.post<UserModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario creado con exito",
        message: "El usuario se ha creado con exito",
      });
    },
  });
};

export default useCreateUser;
