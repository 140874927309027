import { UUID } from "crypto";
import { CommunityModel } from "../communities/models";
import { DocumentModel } from "../documents/models";
import { ISODateString, Email } from "types/models";
import { ChecklistModel } from "api/services/checklist/models";
import { PollModel } from "../polls/models";

export enum TicketPriority {
  critical = "crítica",
  high = "alta",
  medium = "media",
  low = "baja",
}

export enum TicketStatus {
  Cotizando = "cotizando",
  Creada = "creada",
  EnProgreso = "en progreso",
  EnVotacion = "en votación",
  FinDeServicio = "fin de servicio",
  InicioDeServicio = "inicio de servicio",
  RecepcionDeServicio = "recepción de servicio",
}
export interface Pagination {
  count: number;
  next: string | null;
  previous: string | null;
}
export interface TicketsPage extends Pagination {
  results: TicketItemModel[];
}

export interface TicketItemModel {
  uuid: UUID;
  code: string;
  title: string;
  area?: Area;
  status: TicketStatus;
  created: string;
  modified: string;
  progress: string;
  priority: TicketPriority;
  community: CommunityModel;
  responsible: UserMin | null;
  assignees: UserMin[];
  due_date: string | null;
  document_number: number;
  finished_on: string | null;
  is_after_sale: boolean;
  is_finished: boolean;
  show_comitee: boolean;
  checklists_progress: number;
  service_provider: ServiceProvider; // Define el tipo adecuado para service_provider
  is_inactive: boolean;
  is_recurrent: boolean;
  started_on: string | null;
}

export interface Area {
  uuid: UUID;
  name: string;
}

interface ServiceProvider {
  uuid: string;
  razon_social: string;
  giro: string;
  rut: string;
  executive_name: string;
  email: string;
  phone: string;
  account_number: string;
  account_rut: string;
  account_bank: string;
  account_type: string;
}

interface Step {
  uuid: string;
  name: string;
  status: string;
  comment: string | null;
  modified: string;
}

export interface UserMin {
  uuid: UUID;
  first_name: string;
  email: Email;
  phone: string;
  profile_picture: string;
}

export interface CommentaryModel {
  uuid: UUID;
  text: string;
  created: ISODateString;
  modified: ISODateString;
  user: UserMin;
}

export interface TicketModel {
  uuid: UUID;
  code: string;
  title: string;
  comment: string;
  area: Area;
  status: TicketStatus;
  created: string;
  modified: string;
  management: any;
  comments: CommentaryModel[];
  checklists: ChecklistModel[];
  user: UserMin;
  steps: Step[];
  documents: DocumentModel[];
  priority: string;
  assignees: UserMin[];
  community: CommunityModel;
  progress: string;
  due_date: ISODateString;
  finished_on: string | null;
  is_after_sale: boolean;
  report: any; // Puedes ajustar el tipo de acuerdo a la estructura del reporte
  is_inactive: boolean;
  show_comitee: boolean;
  is_recurrent: boolean;
  started_on: string | null;
  notify_step_change: boolean;
  polls: PollModel[];
  responsible?: UserMin;
  commentaries_visible: boolean;
}
