import { useQuery } from '@tanstack/react-query';
import { ExportCenterPdfParams } from './types';
import { API_URL } from 'redux/actions/types';
import axios from 'axios';

export const useExportHistory = () => {
  return useQuery<ExportCenterPdfParams[]>({
    queryKey: ['exportHistory'],
    queryFn: async () => {
      const { data } = await axios.get(`${API_URL}/activity/export-history/`);
      const parsedData = data.map((item: any) => ({ uuid: item.uuid,...item.data, created: item.created })).reverse()

      console.log(parsedData);
      return parsedData;
    }
  });
};



export const useExportHistoryUuid = (uuid: string | undefined) => {
    return useQuery<ExportCenterPdfParams>({
      queryKey: ['exportHistory', uuid],
      queryFn: async () => {
        const { data } = await axios.get(`${API_URL}/activity/export-history/${uuid}/`);
        return data;
      },
      enabled: !!uuid
    });
  };
  
  
  