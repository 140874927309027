/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';

// Custom styles for SuiProgress
import SuiProgressRoot from 'components/SuiProgress/SuiProgressRoot';

const SuiProgress = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
  <div>
    {label && (
      <SuiTypography variant="button" fontWeight="medium" color="text">
        {value}%
      </SuiTypography>
    )}
    <SuiProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={parseFloat(value)}
      ownerState={{ color, value, variant }}
    />
  </div>
));

// Setting default values for the props of SuiProgress
SuiProgress.defaultProps = {
  variant: 'contained',
  color: 'info',
  value: 0,
  label: false,
};

// Typechecking props for the SuiProgress
SuiProgress.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  label: PropTypes.bool,
};
export const SuiProgressTs = (props) => <SuiProgress {...props} />;

export default SuiProgress;
