import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Control, useController, UseControllerProps } from "react-hook-form";
import { SelectOptionForm } from "types/forms";

interface SelectProps extends UseControllerProps {
  label?: string;
  control: Control<any>;
  options?: SelectOptionForm[];
  direction?: "column" | "row";
}

const RadioSelectControlled: React.FC<SelectProps> = ({
  name,
  control,
  rules,
  label,
  options = [],
  direction,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  console.log("value", field.value);
  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row={direction === "row"}
            {...field}
            sx={{ gap: direction === "row" && 2 }}
          >
            {options.map((item) => {
              return (
                <FormControlLabel
                  sx={{ m: 0, alignItems: "center", display: "flex" }}
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default RadioSelectControlled;
