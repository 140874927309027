import { types } from "../actions/types";

export const ticketsReducer = (state = {
    getTicketsByCommunity: {},
    createTicket: {},
    getTicketByUuId: {},
    addCommentToTicket: {},
    addChecklistToTicket: {},
    addItemToChecklist: {},
    markChecklistItem: {},
    updateItemName: {},
    selectChecklistItem: {},
    deleteChecklist: {},
    deleteChecklistItem: {},
    ticketGoForward: {},
    ticketGoBack: {},
    updateTicketArea: {},
    addDocumentToTicket: {},
    assignTicketToUser: {},
    getTicketsByManagement: {},
    updateTickets: {},
    editChecklistName: {},
    archiveTicket: {},
    getPublicTicket: {},
    getTicketsExcelManagement: {},
    getTicketsExcelCommunity: {},
    getTicketsPDFManagement: {},
    getTicketsPDFCommunity: {},
    editComment: {},
    getTicketPDF: {},
    createChecklistTemplate: {},
    getChecklistTemplates: {},
    createChecklistTemplateItem: {},
    editChecklistTemplateItem: {},
    editChecklistTemplate: {},
    deleteChecklistTemplate: {},
    deleteChecklistTemplateItem: {},
    saveChecklistAsTemplate: {},
    addChecklistToTicketFromTemplate: {},
    getChecklistTemplate: {},
    deleteCommentFromTicket: {},
    getUFPrice: {},
    updateTicketTimeline: {},
    showTicketToComitee: {},
    finishTicketTimeline: {},
    getMembersForTicketAssignment: {},
    createTicketRecurrent: {},
    toggleNotifyTimeline: {},
    assignAllComitees: {},
}, action) =>
{
    switch (action.type)
    {
        case types.setGetTicketsByCommunity:
            return {
                ...state,
                getTicketsByCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setCreateTicket:
            return {
                ...state,
                createTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketByUuId:
            return {
                ...state,
                getTicketByUuId: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAddCommentToTicket:
            return {
                ...state,
                addCommentToTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAddChecklistToTicket:
            return {
                ...state,
                addChecklistToTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAddItemToCheckList:
            return {
                ...state,
                addItemToChecklist: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setDeleteCommentFromTicket:
            return {
                ...state,
                deleteCommentFromTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setMarkChecklistItem:
            return {
                ...state,
                markChecklistItem: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setUpdateItemName:
            return {
                ...state,
                updateItemName: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setDeleteChecklistItem:
            return {
                ...state,
                deleteChecklistItem: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setDeleteChecklist:
            return {
                ...state,
                deleteChecklist: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setTicketGoForward:
            return {
                ...state,
                ticketGoForward: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setTicketGoBack:
            return {
                ...state,
                ticketGoBack: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setUpdateTicketArea:
            return {
                ...state,
                updateTicketArea: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAddDocumentToTicket:
            return {
                ...state,
                addDocumentToTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAssignTicketToUser:
            return {
                ...state,
                assignTicketToUser: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketsByManagement:
            return {
                ...state,
                getTicketsByManagement: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setUpdateTickets:
            return {
                ...state,
                updateTickets: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setEditChecklistName:
            return {
                ...state,
                editChecklistName: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setArchiveTicket:
            return {
                ...state,
                archiveTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetPublicTicket:
            return {
                ...state,
                getPublicTicket: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };

        case types.setGetTicketsExcelManagement:
            return {
                ...state,
                getTicketsExcelManagement: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketsExcelCommunity:
            return {
                ...state,
                getTicketsExcelCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketsPDFCommunity:
            return {
                ...state,
                getTicketsPDFCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketsPDFManagement:
            return {
                ...state,
                getTicketsPDFManagement: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setEditComment:
            return {
                ...state,
                editComment: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetTicketPDF:
            return {
                ...state,
                getTicketPDF: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setCreateChecklistTemplate:
            return {
                ...state,
                createChecklistTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetChecklistsTemplate:
            return {
                ...state,
                getChecklistTemplates: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setCreateChecklistTemplateItem:
            return {
                ...state,
                createChecklistTemplateItem: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setEditChecklistTemplateItem:
            return {
                ...state,
                editChecklistTemplateItem: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setEditChecklistTemplate:
            return {
                ...state,
                editChecklistTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setDeleteChecklistTemplate:
            return {
                ...state,
                deleteChecklistTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setDeleteChecklistTemplateItem:
            return {
                ...state,
                deleteChecklistTemplateItem: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setSaveChecklistAsTemplate:
            return {
                ...state,
                saveChecklistAsTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setAddChecklistToTicketFromTemplate:
            return {
                ...state,
                addChecklistToTicketFromTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetChecklistTemplate:
            return {
                ...state,
                getChecklistTemplate: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetUFPrice:
            return {
                ...state,
                getUFPrice: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setUpdateTicketTimeline:
            return {
                ...state,
                updateTicketTimeline: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setShowTicketToComitee:
            return {
                ...state,
                showTicketToComitee: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };

        case types.setFinishTicketTimeline:
            return {
                ...state,
                finishTicketTimeline: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setGetMembersForTicketAssignment:
            return {
                ...state,
                getMembersForTicketAssignment: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };

        case types.setCreateTicketRecurrent:
            return {
                ...state,
                createTicketRecurrent: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            };
        case types.setToggleNotifyTimeline:
            return {
                ...state,
                toggleNotifyTimeline: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                }
            }
        case types.setAssignAllComitees:
            return {
                ...state,
                assignAllComitees: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                }
            }
        default:
            return state;
    }
}