/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';

// General page component
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getPermission } from 'utils';

import { mobileMaxWidth } from 'utils';
import VisitsModal from 'layouts/pages/calendar/VisitView';
import { deleteDocument } from 'redux/actions/documents';
import { isPermissionEnabled } from 'utils';
import EditFileDrawer from 'components/EditFileDrawer';
import { MdOpenInNew } from 'react-icons/md';
import FilePreviewModal from 'layouts/pages/documents/View';
import { Icon } from '@mui/material';
import UploadFileDrawer from 'components/UploadFileDrawer';
import DocumentUrl from 'components/Documents/DocumentUrl';

const parseDate = (unix_timestamp) => {
  var date = new Date(unix_timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = '0' + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = '0' + date.getSeconds();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formattedTime =
    day +
    '/' +
    month +
    '/' +
    year +
    ' ' +
    hours +
    ':' +
    minutes.substr(-2) +
    ':' +
    seconds.substr(-2);

  return formattedTime;
};
const handleDeleteDocument = (uuid, dispatch) => {
  dispatch(deleteDocument(uuid));
};

const unitsTableData = (documents, dispatch) => {
  let columns = [
    { Header: 'Nombre', accessor: 'name', width: '35%' },
    { Header: 'Comunidad', accessor: 'community', width: '20%' },

    { Header: 'Tipo', accessor: 'type', width: '20%' },
    { Header: 'Etiquetas', accessor: 'tags', width: '25%' },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  if (documents === undefined) {
    return { columns, rows: [] };
  }
  let rows = documents.map((document, i) => {
    return {
      name: document.name,
      community: document.community && document.community.name,
      type: (
        <SuiBadge
          color={
            document.type.name == 'Cotización'
              ? 'info'
              : document.type.name == 'Factura'
              ? 'warning'
              : document.type.name == 'Cheque'
              ? 'success'
              : document.type.name == 'Otro'
              ? 'dark'
              : document.type.name == 'Contrato'
              ? 'primary'
              : document.type.name == 'Comprobante de pago'
              ? 'dark'
              : 'error'
          }
          size="xs"
          badgeContent={document.type.name}
        />
      ),
      tags: (
        <div>
          {document.tags.map((tag, i) => {
            return <SuiBadge key={i} color="light" size="xs" badgeContent={tag.name} />;
          })}
        </div>
      ),
      actions: (
        <SuiBox display='flex'>
          <DocumentUrl uuid={document.uuid} />
          {getPermission(['superadmin', 'administrador', 'supervisor']) &&
            isPermissionEnabled('documents-editDocument') && (
              <EditFileDrawer
                file={document}
                community={document.community && document.community.slug}
                slugType={document.type.slug}
              />
            )}
          
          {getPermission(['superadmin', 'administrador', 'supervisor']) &&
            isPermissionEnabled('documents-deleteDocument') && (
              <Icon
                sx={{ cursor: 'pointer' }}
                fontSize="small"
                onClick={() =>
                  Swal.fire({
                    title: '¿Quieres eliminar este documento?',
                    showDenyButton: true,
                    confirmButtonText: 'Si',
                    denyButtonText: `No`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      handleDeleteDocument(document.uuid, dispatch);
                      Swal.fire('Eliminado', '', 'Success');
                    } else if (result.isDenied) {
                    }
                  })
                }
              >
                delete
              </Icon>
            )}
        </SuiBox>
      ),
    };
  });
  return { columns, rows };
};

function ActionPlanDocumentsTable(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [visit, setVisit] = useState(null);
  const [dataTableFull, setdataTableFull] = useState(unitsTableData(props.documents, dispatch));
  useEffect(() => {
    if (props.documents) {
      setdataTableFull(unitsTableData(props.documents, dispatch));
    }
  }, [props.documents]);

  return (
    <Card>
      <SuiBox p={window.innerWidth <= mobileMaxWidth ? 0 : 4} pt={3}>
        <VisitsModal visit={visit} />
        <SuiBox display="flex" justifyContent="space-between" mb={2}>
          <SuiTypography variant="h4" fontWeight="medium">
            Documentos
          </SuiTypography>
          <UploadFileDrawer title={'Subir documento'} color="success" />
        </SuiBox>
        <p style={{ fontSize: 15, fontWeight: 100 }}>
          * sólo se mostrarán documentos que tengan fecha de expiración.
        </p>
        <DataTable pageSize={5} canSearch={false} table={dataTableFull} />
      </SuiBox>
    </Card>
  );
}

export default ActionPlanDocumentsTable;
