import { Box, Stack } from "@mui/material";
import useUpdateEmeregency from "api/services/emergencies/mutations/updateEmergency";
import { EmergencyModel } from "api/services/emergencies/models";
import InputControlled from "components/forms/controlled/InputControlled";
import RichTextEditorControlled from "components/forms/controlled/RichTextEditorControlled";
import { SuiButtonTs } from "components/SuiButton";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import { useForm } from "react-hook-form";

const EditEmergencyForm: React.FC<{ emergency: EmergencyModel }> = ({
  emergency,
}) => {
  const { mutate: updateEmergency } = useUpdateEmeregency(emergency.uuid);
  const emergencyUpdated = useEventEmitter("emergencyUpdated");
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: {
      title: emergency.title,
      description: emergency.description,
    },
  });
  const onSubmit = handleSubmit((data) => {
    updateEmergency(data, {
      onSuccess: (data) => {
        emergencyUpdated.emit(data);
      },
    });
  });
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <InputControlled
          control={control}
          name="title"
          label="Título"
          rules={{ required: true }}
        />
        <RichTextEditorControlled name="description" control={control} />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            color={"success"}
            variant={"gradient"}
            size="small"
            type="submit"
            disabled={!isValid || !isDirty}
          >
            Guardar cambios
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default EditEmergencyForm;
