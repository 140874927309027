import { create } from 'zustand';
import { UserModel } from 'api/services/user/models';

export interface AuthStoreState {
  isAuthenticated: boolean;
  userProfile: UserModel;
  accessToken: string;
}

export const useAuthenticationStore = create<AuthStoreState>(() => ({
  isAuthenticated: undefined,
  userProfile: undefined,
  accessToken: undefined,
}));
