import React, { FC } from 'react';
import { MemberListProps } from './types';
import Tooltip from '@material-ui/core/Tooltip';
import { SuiAvatarTs } from 'components/SuiAvatar';
import { Box } from '@mui/material';

const MemberList: FC<MemberListProps> = ({ members }) => {
  return (
    <Box display="flex">
      {members.map((member) => (
        <Tooltip
          key={member.profile_picture + member.first_name}
          title={member.first_name}
          placement="bottom"
        >
          <Box>
            <SuiAvatarTs
              uuid={member.uuid}
              src={member.profile_picture}
              alt={member.first_name}
              size="xs"
              sx={({ borders: { borderWidth }, palette: { white } }) => ({
                ml: -1.25,
                border: `${borderWidth[2]} solid ${white.main}`,
                cursor: 'pointer',
                position: 'relative',
                '&:hover, &:focus': {
                  zIndex: '10',
                },
              })}
              style={{ backgroundColor: 'black' }}
            />
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export default MemberList;
