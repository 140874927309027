import { PollChoiceModel, PollModel } from 'api/services/polls/models';

export function getPollChoiceWithMostVotes(poll: PollModel): PollChoiceModel | null {
  if (poll.poll_choices.length === 0) {
    return null; // No hay elecciones en la encuesta
  }

  let pollChoiceWithMostVotes = poll.poll_choices[0];
  let allSameVotes = true;

  for (let i = 1; i < poll.poll_choices.length; i++) {
    if (poll.poll_choices[i].votes.length !== poll.poll_choices[0].votes.length) {
      allSameVotes = false;
    }
    if (poll.poll_choices[i].votes.length > pollChoiceWithMostVotes.votes.length) {
      pollChoiceWithMostVotes = poll.poll_choices[i];
    }
  }

  return allSameVotes ? null : pollChoiceWithMostVotes;
}
