import { ISODateString } from 'types/models';
import * as yup from 'yup';
interface FormValues {
  question: string;
  start_date: ISODateString[];
  end_date: ISODateString[];
}
export const scheme: yup.SchemaOf<FormValues> = yup.object().shape({
  question: yup.string().required('La pregunta es requerida'),
  start_date: yup.array().of(yup.string().required()).required(),
  end_date: yup
    .array()
    .of(yup.string().required())
    .required()
    .test(
      'is-greater',
      'La fecha de cierre no puede ser menor que la fecha de inicio',
      function (value) {
        const { start_date } = this.parent;
        return !value || !start_date || new Date(value[0]) >= new Date(start_date[0]);
      }
    ),
});
