/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import BuildingCard from "examples/Cards/BuildingCard";
import { useSelector, useDispatch } from "react-redux";
import Create from "../../../../../../components/Tickets/AddTicketModal";
import DataTable from "examples/Tables/DataTable";
import SuiBadge from "components/SuiBadge";
import { getPermission, parseDate } from "utils";
// General page component
import { useParams, useNavigate } from "react-router-dom";
import { getTicketsByCommunity } from "redux/actions/tickets";
import Update from "../Update";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import { deleteArea } from "redux/actions/communities";
import { isPermissionEnabled } from "utils";
import TicketsTable from "components/Tickets/TicketsTable";

function Area(props) {
  const dispatch = useDispatch();
  const { slug: communitySlug } = useParams();

  const handleDeleteArea = () => {
    let form = { area_uuid: props.areaSelectedForEdit.uuid };
    dispatch(deleteArea(props.areaSelectedForEdit.uuid, form));
  };

  return (
    <Card>
      <SuiBox p={5}>
        <SuiTypography variant="h3">
          {props.areaSelected}
          {getPermission(["superadmin", "administrador", "supervisor"]) && (
            <SuiBox style={{ float: "right" }}>
              {isPermissionEnabled("areasSystems-editAreaSystem") && (
                <Update
                  areaSelected={props.areaSelectedForEdit}
                  buildings={props.buildings}
                  selectedUuid={props.areaSelectedId}
                />
              )}
              {isPermissionEnabled("areasSystems-deleteAreaSystem") && (
                <SuiButton
                  style={{ marginLeft: 4 }}
                  size="small"
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    Swal.fire({
                      title:
                        "Estas seguro que quieres eliminar esta area común?",
                      showDenyButton: true,
                      confirmButtonText: "Si",
                      denyButtonText: `No`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        handleDeleteArea();
                        Swal.fire("Eliminada!", "", "success");
                      } else if (result.isDenied) {
                        Swal.fire("Area no eliminada", "", "error");
                      }
                    });
                  }}
                >
                  Eliminar Área Común
                </SuiButton>
              )}
            </SuiBox>
          )}
        </SuiTypography>
        <Divider />
        <SuiBox mb={3}>
          <SuiTypography variant="h4">Especificaciones</SuiTypography>
          <SuiBox p={3}>
            {ReactHtmlParser(
              props.areaSelectedForEdit &&
                props.areaSelectedForEdit.specifications
            )}
          </SuiBox>
        </SuiBox>
        <Divider />
        <SuiTypography variant="h4" fontWeight="medium">
          Tareas
          {isPermissionEnabled("tasks-createTask") && (
            <Create areaUuid={props.selectedUuid} />
          )}
        </SuiTypography>

        <Divider />
        <TicketsTable
          columnsView={"communities"}
          params={{ communities: communitySlug, area_uuid: props.selectedUuid }}
          hideFilters
        />
      </SuiBox>
    </Card>
  );
}

export default Area;
