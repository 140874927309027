import { Box, CircularProgress, ClickAwayListener, Divider, Tooltip } from '@mui/material';
import {useGetTicketPDF} from 'api/services/files/queries/GetTicketPDF';
import { TicketModel } from 'api/services/tickets/models';
import useArchiveTicket from 'api/services/tickets/mutations/ArchiveTicket';
import { useAssignToCommittee } from 'api/services/tickets/mutations/AssignToCommittee';
import useToggleShowTicketToCommittee from 'api/services/tickets/mutations/ToggleShowTicketToCommittee';
import { useToggleTimelineNotifications } from 'api/services/tickets/mutations/ToggleTimelineNotifications';
import { useUpdateTicket } from 'api/services/tickets/mutations/UpdateTicket';
import useUpdateTicketTimeline from 'api/services/tickets/mutations/UpdateTicketTimeline';
import { SuiButtonTs } from 'components/SuiButton';
import { SuiTypographyTs } from 'components/SuiTypography';
import { FC, useState } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import { GrDocumentPdf } from 'react-icons/gr';
import useMediaScreen from 'utils/Hooks/useMedia';

import AddChecklistButton from './AddTicketChecklist';
import Files from 'components/UploadFileDrawer';
import Swal from 'sweetalert2';
import { usePermissions } from 'stores/authentication/helpers';
import MobileDialogContainer from './MobileDialogContainer';

interface Props {
  ticket: TicketModel;
  onArchiveTicket?: () => unknown;
}
const Actions: FC<Props> = ({ ticket, onArchiveTicket }) => {
  const { isMobile } = useMediaScreen();
  const { uuid: ticketUUID } = ticket;
  const { canEditTask, canDeleteTask, canCreateDocument } = usePermissions();
  const [open, setOpen] = useState(false);

  const updateTicket = useUpdateTicket({ uuid: ticketUUID });
  const getTicketPDF = useGetTicketPDF({ ticketUUID, downloadName: ticket?.code });
  const assignToCommittee = useAssignToCommittee({ ticketUUID });
  const updateTicketTimeline = useUpdateTicketTimeline({ ticketUUID });
  const toggleTimelineNotifications = useToggleTimelineNotifications({ ticketUUID });
  const toggleShowTicketToCommittee = useToggleShowTicketToCommittee({ ticketUUID });
  const archiveTicket = useArchiveTicket({ ticketUUID });

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <MobileDialogContainer>
      <Box>
        <div
          style={
            !isMobile
              ? {
                  textAlign: 'left',
                  padding: 2,
                  marginTop: '5vh',
                }
              : { textAlign: 'center', padding: 2 }
          }
        >
          <SuiTypographyTs
            variant="h6"
            textAlign="center"
            style={isMobile ? { cursor: 'pointer', marginTop: 10 } : {}}
          >
            Acciones
          </SuiTypographyTs>
          <Divider />
          <div>
            <Box pl={2}>
              {getTicketPDF.isPending ? (
                <Box style={{ textAlign: 'center' }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <SuiButtonTs
                  color="dark"
                  variant={'text'}
                  size="small"
                  disabled={getTicketPDF.isPending}
                  onClick={() => getTicketPDF.mutate()}
                >
                  Exportar Pdf
                  <GrDocumentPdf style={{ marginLeft: 10, cursor: 'pointer' }} />
                </SuiButtonTs>
              )}
            </Box>
            <Box pl={2} pt={2}>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Enlace copiado!"
                >
                  <SuiButtonTs
                    size="small"
                    variant="text"
                    color={'dark'}
                    onClick={() => {
                      handleTooltipOpen();
                      navigator.clipboard.writeText(
                        'https://app.swappi.cl/ticket/public/' + ticket?.uuid
                      );
                    }}
                  >
                    Copiar Enlace
                    <BsPaperclip />
                  </SuiButtonTs>
                </Tooltip>
              </ClickAwayListener>
            </Box>
            {canEditTask && (
              <Box pl={2} pt={2}>
                <SuiButtonTs
                  size="small"
                  variant="text"
                  color={'dark'}
                  disabled={assignToCommittee.isPending}
                  onClick={() => assignToCommittee.mutate()}
                >
                  Asignar al comité
                </SuiButtonTs>
              </Box>
            )}
            {canCreateDocument && (
              <Box pl={2} pt={2}>
                <Files
                  user={undefined}
                  alignButton={'none'}
                  ticket={ticket}
                  title={'Subir Documentos'}
                  color="dark"
                  variant="text"
                  maintenance={undefined}
                  slugType={undefined}
                  areaUuid={undefined}
                  checklistItemUuid={undefined}
                  community={undefined}
                  visitReport={undefined}
                  moduleType={undefined}
                  fromReport={undefined}
                />
              </Box>
            )}
            {canEditTask && (
              <Box pl={2} pt={2}>
                <AddChecklistButton ticket={ticket} />
              </Box>
            )}
            {canEditTask && (
              <Box pl={2} pt={2}>
                <SuiButtonTs
                  size="small"
                  variant="text"
                  color={'dark'}
                  disabled={updateTicketTimeline.isPending}
                  onClick={() => {
                    Swal.fire({
                      title: '¿Quieres cambiar la linea de tiempo de esta tarea?',
                      showDenyButton: true,
                      confirmButtonText: 'Si',
                      denyButtonText: `No`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        updateTicketTimeline.mutate(null, {
                          onSuccess: () => {
                            Swal.fire('Cambiado', '', 'success');
                          },
                        });
                      }
                    });
                  }}
                >
                  Cambiar Timeline
                </SuiButtonTs>
              </Box>
            )}
            {canEditTask && (
              <Box pl={2} pt={2}>
                <SuiButtonTs
                  size="small"
                  variant="text"
                  color={'dark'}
                  disabled={updateTicket.isPending}
                  onClick={() =>
                    updateTicket.mutate({
                      is_after_sale: !ticket.is_after_sale,
                    })
                  }
                >
                  Cambiar a {ticket?.is_after_sale ? 'no Postventa' : 'Postventa'}
                </SuiButtonTs>
              </Box>
            )}
            {canEditTask && (
              <Box pl={2} pt={2}>
                <Tooltip
                  title={
                    (ticket?.notify_step_change ? 'Desactivar' : 'Activar') +
                    ' notificación al avanzar o retroceder el timeline'
                  }
                  placement="top"
                >
                  <SuiButtonTs
                    style={{ textAlign: 'left' }}
                    size="small"
                    variant="text"
                    color={'dark'}
                    disabled={toggleTimelineNotifications.isPending}
                    onClick={() => toggleTimelineNotifications.mutate()}
                  >
                    {(ticket?.notify_step_change ? 'Desactivar' : 'Activar') +
                      ' notificación Timeline'}
                  </SuiButtonTs>
                </Tooltip>
              </Box>
            )}
            {canEditTask && (
              <Box pl={2} pt={2}>
                <Tooltip
                  title={
                    (ticket?.commentaries_visible ? 'Oculta' : 'Revela') + ' comentarios al Comité'
                  }
                  placement="top"
                >
                  <SuiButtonTs
                    size="small"
                    style={{ textAlign: 'left' }}
                    variant="text"
                    color={'dark'}
                    disabled={updateTicket.isPending}
                    onClick={() =>
                      updateTicket.mutate({
                        commentaries_visible: !ticket.commentaries_visible,
                      })
                    }
                  >
                    {ticket?.commentaries_visible ? 'Ocultar comentarios' : 'Revelar comentarios'} a
                    Comité
                  </SuiButtonTs>
                </Tooltip>
              </Box>
            )}
            {canDeleteTask && (
              <Box pl={2} pt={2}>
                <SuiButtonTs
                  size="small"
                  variant="text"
                  color={'dark'}
                  disabled={toggleShowTicketToCommittee.isPending}
                  onClick={() => toggleShowTicketToCommittee.mutate()}
                >
                  {ticket?.show_comitee ? 'Ocultar a Comité' : 'Mostrar a Comité'}
                </SuiButtonTs>
              </Box>
            )}
            {canDeleteTask && (
              <Box pl={2} pt={2}>
                <SuiButtonTs
                  size="small"
                  variant="text"
                  color={'dark'}
                  onClick={() => {
                    Swal.fire({
                      title: '¿Quieres eliminar esta tarea?',
                      showDenyButton: true,
                      confirmButtonText: 'Si',
                      denyButtonText: `No`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        archiveTicket.mutate(null, {
                          onSuccess: () => {
                            Swal.fire('Tarea eliminada', '', 'success');
                            onArchiveTicket?.();
                          },
                        });
                      }
                    });
                  }}
                >
                  Eliminar Tarea
                </SuiButtonTs>
              </Box>
            )}
          </div>
          {/* {expandActions && (
     
        )} */}
        </div>
      </Box>
    </MobileDialogContainer>
  );
};

export default Actions;
