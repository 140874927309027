import { QueryClient } from "@tanstack/react-query";
import { toastNotification } from "components/ui/ToastNotification";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      onError: (err) => {
        const error = err as any;
        toastNotification.error({
          title: "Ha ocurrido un error",
          message: error?.data?.message,
        });
      },
    },
  },
});
