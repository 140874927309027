/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Soft UI Dashboard PRO React components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import { addDocumentToTicket } from "redux/actions/tickets";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersByManagement } from "redux/actions/providers";
import { useParams } from "react-router-dom";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import SuiDatePicker from "components/SuiDatePicker";
import { createMaintenanceVisit } from "redux/actions/maintenance";
import { getUsersByManagement } from "redux/actions/users";
import { getCommunitiesByUser, markAsVisited } from "redux/actions/communities";
import { Divider } from "@mui/material";
import DocumentsForm from "layouts/pages/documents/Upload/Form";
import { dateToLocale } from "utils";
import { editVisit, deleteVisit } from "redux/actions/communities";
import { getPermission } from "utils";
import Swal from "sweetalert2";
import Documents from "layouts/pages/documents";
import SuiEditor from "components/SuiEditor";
import { isPermissionEnabled } from "utils";
function Form({ visit }) {
  const dispatch = useDispatch();
  const [uploadDocument, setuploadDocument] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [file, setFile] = useState(null);
  const [edit, setEdit] = useState(false);
  const [startDate, setStartDate] = useState(visit.start);
  const [endDate, setEndDate] = useState(visit.end);
  const [comment, setComment] = useState(visit.comment);
  const [title, setTitle] = useState(visit.title);
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    setStartDate(visit.start);
    setEndDate(visit.end);
    setComment(visit.comment);
    setTitle(visit.title);
  }, [visit]);

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  const markAsVisitedAction = () => {
    const formData = new FormData();

    if (!visit.completed) {
      formData.append("completed_on", selectedStartDate);
    }
    formData.append("file", file);
    dispatch(markAsVisited(visit.uuid, formData));
    if (!visit.completed) {
      props.close();
    }
  };

  const handleEditVisit = () => {
    let start = startDate;
    let end = endDate;
    if (startDate instanceof Date) {
    } else {
      start = new Date(startDate);
    }
    if (endDate instanceof Date) {
    } else {
      end = new Date(endDate);
    }

    let form = {
      title: title,
      comment: comment,
      start: start.toISOString(),
      end: end.toISOString(),
    };
    dispatch(editVisit(visit.uuid, form));
    setEdit(!edit);
  };

  return (
    <SuiBox style={{ maxHeight: "60vh", overflow: "scroll" }}>
      <Card style={{ marginBottom: 20, width: "100%" }}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          {edit ? (
            <SuiInput
              width="80%"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <SuiTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {" "}
              {title}
            </SuiTypography>
          )}

          {edit ? (
            <Tooltip title={"Guardar"} placement="top">
              <Icon
                onClick={(e) => {
                  setEdit(true);
                  handleEditVisit();
                }}
              >
                save
              </Icon>
            </Tooltip>
          ) : (
            getPermission([
              "superadmin",
              "administrador",
              "supervisor",
              "conserje",
              "mayordomo",
            ]) &&
            isPermissionEnabled("visits-editVisit") && (
              <SuiBox>
                <Tooltip title={"Editar visita"} placement="top">
                  <Icon onClick={(e) => setEdit(true)}>edit</Icon>
                </Tooltip>
                {getPermission(["superadmin", "administrador", "supervisor"]) &&
                  isPermissionEnabled("visits-deleteVisit") && (
                    <Tooltip title={"Eliminar visita"} placement="top">
                      <Icon
                        onClick={(e) => {
                          Swal.fire({
                            title: "¿Quieres eliminar esta visita?",
                            showDenyButton: true,
                            confirmButtonText: "Si",
                            denyButtonText: `No`,
                          }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                              dispatch(deleteVisit(visit.uuid));
                              Swal.fire("Eliminado", "", "Success");
                            } else if (result.isDenied) {
                            }
                          });
                        }}
                      >
                        delete
                      </Icon>
                    </Tooltip>
                  )}
              </SuiBox>
            )
          )}
        </SuiBox>
        <SuiBox p={2}>
          <Divider />
          <SuiBox>
            {false && visit.community ? (
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Comunidad: &nbsp;
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  {visit.community.replace("-", " ")}
                </SuiTypography>
              </SuiBox>
            ) : (
              ""
            )}
            <Grid container spacing={3} pt={2}>
              <Grid item xs={12} md={6}>
                <SuiBox display="flex" py={1} pr={2}>
                  <SuiTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Inicio: &nbsp;
                  </SuiTypography>
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {edit ? (
                      <SuiDatePicker
                        data-enable-time
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e[0]);
                        }}
                      />
                    ) : startDate instanceof Date ? (
                      startDate.toLocaleDateString("es-ES")
                    ) : (
                      new Date(startDate).toLocaleDateString("es-ES")
                    )}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <SuiBox display="flex" py={1} pr={2}>
                  <SuiTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Fin: &nbsp;
                  </SuiTypography>
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {edit ? (
                      <SuiDatePicker
                        data-enable-time
                        value={endDate}
                        onChange={(e) => setEndDate(e[0])}
                      />
                    ) : endDate instanceof Date ? (
                      endDate.toLocaleDateString("es-ES")
                    ) : (
                      new Date(endDate).toLocaleDateString("es-ES")
                    )}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            </Grid>
            {visit.ticket ? (
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Tarea: &nbsp;
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {visit.ticket.code}
                </SuiTypography>
              </SuiBox>
            ) : (
              ""
            )}
            {visit.service_provider ? (
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Proveedor: &nbsp;
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {visit.service_provider.executive_name ||
                    visit.service_provider.razon_social}
                </SuiTypography>
              </SuiBox>
            ) : (
              ""
            )}
            <SuiBox py={1} pr={2}>
              <SuiTypography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Comentario: &nbsp;
              </SuiTypography>
              <br />
              {edit ? (
                <SuiInput
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    console.log(e.target.value);
                  }}
                  multiline
                  rows={5}
                />
              ) : (
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {comment}{" "}
                </SuiTypography>
              )}{" "}
            </SuiBox>
            <SuiBox display="flex" py={1} pr={2}>
              <SuiTypography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Completada: &nbsp;
              </SuiTypography>
              <SuiTypography variant="button" fontWeight="regular" color="text">
                {visit.completed ? "Si" : "No"}
              </SuiTypography>
            </SuiBox>
            {visit.completed && (
              <SuiBox display="flex" py={1} pr={2}>
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Completada en: &nbsp;
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {dateToLocale(visit.completed_on)}
                </SuiTypography>
              </SuiBox>
            )}{" "}
            {(uploadDocument || visit.completed) &&
              isPermissionEnabled("documents-createDocument") && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <SuiBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Documento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      type="file"
                      size="small"
                      onChange={(e) => changeHandler(e)}
                    />
                  </Grid>
                  {!visit.completed && (
                    <Grid item xs={12} md={4}>
                      <SuiBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Fecha de visita
                        </SuiTypography>
                      </SuiBox>
                      <SuiDatePicker
                        onChange={(e) =>
                          setSelectedStartDate(e[0].toISOString().split("T")[0])
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <SuiButton
                      style={{ marginTop: 30 }}
                      size="small"
                      color="success"
                      variant="gradient"
                      onClick={() => markAsVisitedAction()}
                    >
                      {visit.completed
                        ? "Subir documento"
                        : "Marcar como visitado"}
                    </SuiButton>
                  </Grid>
                </Grid>
              )}{" "}
            {getPermission([
              "superadmin",
              "administrador",
              "supervisor",
              "conserje",
              "mayordomo",
            ]) &&
              isPermissionEnabled("visits-editVisit") &&
              !uploadDocument &&
              !visit.completed && (
                <SuiBox display="flex" py={1} pr={2}>
                  <SuiButton
                    size="small"
                    color="success"
                    variant="gradient"
                    onClick={() => setuploadDocument(!uploadDocument)}
                  >
                    Visitado
                  </SuiButton>
                </SuiBox>
              )}
            <Documents visit={visit} />
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Form;