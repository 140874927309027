import { types } from "../actions/types";

export const pollsReducer = (state = {
    createPoll: {},
    createVote: {},
    getPollByUuid: {},
    createPublicVote: {},
    deletePoll: {},
}, action) =>
{
    switch (action.type)
    {
        case types.setCreatePoll:
            return {
                ...state,
                createPoll: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            }
        case types.setCreateVote:
            return {
                ...state,
                createVote: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            }
        case types.setGetPollByUuid:
            return {
                ...state,
                getPollByUuid: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            }

        case types.setCreatePublicVote:
            return {
                ...state,
                createPublicVote: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            }

        case types.setDeletePoll:
            return {
                ...state,
                deletePoll: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            }
        default:
            return state;
    }
};
