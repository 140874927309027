let colors = ["info", "success", "warning", "error", "primary", "secondary", "dark", "light"];

export const getWorkloadFormattedData = (data, label, color) => {
  const labels = Object.keys(data);
  const values = Object.values(data);
  const datasets = [
    {
      label: label,
      color: color,
      data: values,
    },
  ];
  return { labels, datasets };
};

export const getPieFormattedData = (data, label, color) => {
  const labels = Object.keys(data);
  const values = Object.values(data);
  /* map array  of valaues  length */
  const backgroundColors = values.map((value, index) => {
    let i = index;
    if (index >= colors.length - 1) {
      i = index - colors.length;
    }
    return colors[i];
  });

  const datasets = {
    label: label,
    backgroundColors,
    data: values,
  };
  return { labels, datasets };
};

export const getLineChartDataByMonth = (data, label) => {
  const month = ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"];
  const labels = month;
  /* Array with data object value if exists else 0 */
  const values = Array.from({ length: 12 }, (_, i) => i + 1).map((i) => {
    return data[i - 1] ? data[i - 1] : 0;
  });
  const datasets = [{ label: label, color: "info", data: values }];
  return { labels, datasets };
};
