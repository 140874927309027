import React, { useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { VisitModel } from "api/services/visits/models";
import { MaintenanceModel } from "api/services/maintenances/models";

import { dateToLocale } from "utils";
import { usePermissions } from "stores/authentication/helpers";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import SwappiDataTable from "components/SwappiTable";
import { ColumnTable } from "components/SwappiTable/types";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiBoxTs } from "components/SuiBox";
import VisitView from "..";
import DeleteVisitButton from "./DeleteVisitButton";
import ShowDetailButton from "./ShowDetailButton";

const VisitsTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const canEditVisit = usePermissions().visits.edit;
  const canDeleteVisit = usePermissions().visits.delete;
  const modalDetailRef = useRef<ModalRefHandle>(null);
  const [itemSelected, setItemSelected] = useState<VisitModel>(null);

  const data = maintenance.visits;

  const handleOpenVisit = (item: VisitModel) => {
    setItemSelected(item);
    modalDetailRef.current.open();
  };

  const visitSelected: VisitModel =
    itemSelected &&
    maintenance.visits.find((visit) => visit.uuid === itemSelected.uuid);

  const modalActions = useMemo(() => {
    if (!itemSelected) return {};
    const itemIndex = maintenance.visits.findIndex(
      (item) => item.uuid === itemSelected.uuid
    );
    const prevItem = maintenance.visits[itemIndex - 1];
    const nexItem = maintenance.visits[itemIndex + 1];
    return {
      onPrev: prevItem ? () => setItemSelected(prevItem) : undefined,
      onNext: nexItem ? () => setItemSelected(nexItem) : undefined,
    };
  }, [itemSelected]);

  const columns: ColumnTable<VisitModel>[] = [
    { header: "Título", accessorKey: "title", width: "30%" },
    {
      header: "Rango de fechas",
      accessorKey: "dates",
      width: "25%",
      cell: ({ row }) =>
        `${dateToLocale(row.original.start)}-${dateToLocale(row.original.end)}`,
    },
    {
      header: "Completado",
      accessorKey: "completed",
      width: 10,
      cell: ({ row }) => {
        return (
          <SuiBoxTs
            width="100%"
            alignContent={"center"}
            justifyContent={"center"}
          >
            <SuiBadgeTs
              badgeContent={
                row.original.completed ? <AiOutlineCheckCircle /> : <FaTimes />
              }
              color={row.original.completed ? "success" : "error"}
              variant="gradient"
              container
            />
          </SuiBoxTs>
        );
      },
    },
    {
      header: "Fecha visitada",
      accessorKey: "completedOn",

      cell: ({ row }) =>
        row.original.completed_on
          ? dateToLocale(row.original.completed_on)
          : "",
    },
    {
      header: "Acciones",
      accessorKey: "actions",
      cell: ({ row }) => {
        return (
          <Stack direction={"row"} spacing={1}>
            {canEditVisit && (
              <ShowDetailButton onClick={() => handleOpenVisit(row.original)} />
            )}
            {canDeleteVisit && (
              <DeleteVisitButton
                onCloseModal={() => modalDetailRef.current?.close()}
                visit={row.original}
                maintenance={maintenance}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <Box>
      <SwappiDataTable columns={columns} data={data} />
      <BaseModal ref={modalDetailRef} {...modalActions}>
        <Box p={2}>
          <VisitView modalRef={modalDetailRef} visit={visitSelected} />
        </Box>
      </BaseModal>
    </Box>
  );
};
export default VisitsTable;
