import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { MaintenanceModel } from "../models";

export const GET_MAINTENANCE_KEY = "getMaintenance";

const useGetMaintenance = (maintenanceUUID: UUID) => {
  return useQuery({
    enabled: !!maintenanceUUID,
    queryKey: [GET_MAINTENANCE_KEY, maintenanceUUID],
    queryFn: async () => {
      const url = `${API_URL}/maintenance/${maintenanceUUID}`;
      return await axios.get<MaintenanceModel>(url).then((res) => res.data);
    },
  });
};

export default useGetMaintenance;
