/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import { SuiTypographyTs } from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
// Project page components

import LoadingView from "components/Loading";
import { SuiSelectTs } from "components/SuiSelect";
// import { useAuthenticationStore } from "stores/authentication";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { Box } from "@mui/material";

const AdminCommunities = () => {
  const {
    data: communities,
    dataAsOptions: communitiesOptions,
    isLoading,
  } = useGetCommunities();
  const storedValue = useMemo(() => localStorage.getItem("buscador"), []);
  const [search, setsearch] = useState(JSON.parse(storedValue) || []);

  // const updateCommunity = useSelector(
  //   (state) => state.communities.updateCommunityImage
  // );

  useEffect(() => {
    localStorage.setItem("buscador", JSON.stringify(search));
  }, [search]);

  // Dropdown menu template for the ComplexProjectCard

  if (isLoading) return <LoadingView />;

  return (
    <Box mt={{ xs: 1, lg: 3 }} mb={1}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Box mb={1}>
            <SuiTypographyTs variant="h5">Comunidades</SuiTypographyTs>
          </Box>
          <Box mb={2}>
            <SuiTypographyTs variant="body2" color="text">
              En esta vista puedes ver todas las comunidades en las que
              participas.
            </SuiTypographyTs>
          </Box>
          <SuiTypographyTs variant="body2" color="text">
            Cantidad de comunidades de la administración: {communities.length}
          </SuiTypographyTs>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ pr: 2 }}>
          <SuiSelectTs
            placeholder="Buscar comunidad"
            options={communitiesOptions}
            isMulti
            onChange={(e) => setsearch(e)}
            value={search}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {communities.map((community, i) => {
          if (search.length === 0) {
            return (
              <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                <SimpleBlogCard
                  image={community.picture}
                  title={community.name}
                  community={community}
                  description=""
                  action={{
                    type: "internal",
                    route: "/communities/" + community.slug,
                    color: "info",
                    label: "Ver",
                  }}
                />
              </Grid>
            );
          } else if (search.some((obj) => obj.label === community.name)) {
            return (
              <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                <SimpleBlogCard
                  image={community.picture}
                  title={community.name}
                  community={community}
                  description=""
                  action={{
                    type: "internal",
                    route: "/communities/" + community.slug,
                    color: "info",
                    label: "Ver",
                  }}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

export default AdminCommunities;
