/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";
import { configNotificactions } from "redux/actions/users";
import { getUserProfile } from "redux/actions/users";

function Notifications() {
  const { pathname } = window.location;
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.users.getUserProfile);
  const configNotificactionsResponse = useSelector((state) => state.users.configNotifications);
  const [maintenance, setMaintenance] = useState(false);
  const [visit, setVisit] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [newTickets, setNewTickets] = useState(false);
  const [assignedTickets, setAssignedTickets] = useState(false);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.data) {
        setMaintenance(userProfile.data.maintenance_notifications);
        setVisit(userProfile.data.visit_notifications);
        setTicket(userProfile.data.ticket_notifications);
        setNewTickets(userProfile.data.new_ticket_notifications);
        setAssignedTickets(userProfile.data.assigned_ticket_notifications);
      }
    }
  }, [pathname]);
  const handleChange = (maintenance, visit, ticket, newTickets, assignedTickets) => {
    let form = {
      maintenance_notifications: maintenance,
      ticket_notifications: ticket,
      visit_notifications: visit,
      new_ticket_notifications: newTickets,
      assigned_ticket_notifications: assignedTickets,
    };
    dispatch(configNotificactions(form));
  };

  useEffect(() => {
    if (configNotificactionsResponse) {
      if (configNotificactionsResponse.data) {
        dispatch(getUserProfile());
      }
    }
  }, [configNotificactionsResponse]);

  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Notificaciones</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Puedes elegir como quieres recibir las notificaciones, a continuación se muestra la
          configuración actual:
        </SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto" sx={{ overflow: "scroll" }}>
          <Table sx={{ minWidth: "36rem" }}>
            <SuiBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Actividad
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Email
                </TableCell>
              </TableRow>
            </SuiBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Vencimiento contrato mantención
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text" fontWeight="regular">
                      Notificación cuando un contrato de mantención está por vencer (30 días antes).
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={maintenance}
                    onChange={() => {
                      setMaintenance(!maintenance);
                      handleChange(!maintenance, visit, ticket, newTickets, assignedTickets);
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Visitas
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text" fontWeight="regular">
                      Notificar cuando haya una visita prontamente en una comunidad (3 días antes).
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={visit}
                    onChange={() => {
                      setVisit(!visit);
                      handleChange(maintenance, !visit, ticket, newTickets, assignedTickets);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Vencimiento de una tarea
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text" fontWeight="regular">
                      Notificar cuando una tarea está por vencer (1 día antes).
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={ticket}
                    onChange={() => {
                      setTicket(!ticket);
                      handleChange(maintenance, visit, !ticket, newTickets, assignedTickets);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Nueva tarea
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text" fontWeight="regular">
                      Notificar cuando se crea una nueva tarea en una comunidad asociada.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={newTickets}
                    onChange={() => {
                      setNewTickets(!newTickets);
                      handleChange(maintenance, visit, ticket, !newTickets, assignedTickets);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SuiBox lineHeight={1.4}>
                    <SuiTypography display="block" variant="button" fontWeight="regular">
                      Asignación tarea
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text" fontWeight="regular">
                      Notificar cuando se le asigne una tarea.
                    </SuiTypography>
                  </SuiBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={assignedTickets}
                    onChange={() => {
                      setAssignedTickets(!assignedTickets);
                      handleChange(maintenance, !visit, ticket, newTickets, !assignedTickets);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Notifications;
