import { Box } from "@mui/material";
import { DocumentModel, TagModel } from "api/services/documents/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { ColumnTable } from "components/SwappiTable/types";
import TableActions from "layouts/pages/documents/DocumentsTable/TableActions";
import React, { useMemo } from "react";
const colorsMap = {
  Cotización: "info",
  Factura: "warning",
  Cheque: "success",
  Otro: "dark",
  Contrato: "primary",
  "Comprobante de pago": "dark",
};

const TagsList: React.FC<{ tags: TagModel[] }> = ({ tags }) => {
  return (
    <Box display={"flex"} gap={2}>
      {tags.map((tag) => {
        return (
          <SuiBadgeTs
            color="light"
            size="xs"
            key={tag.name}
            badgeContent={tag.name}
          />
        );
      })}
    </Box>
  );
};

export const useCreateDocumentsTableColumns = () => {
  return useMemo(() => {
    const columns: ColumnTable<DocumentModel>[] = [
      {
        header: "Nombre",
        accessorKey: "name",
      },
      {
        header: "Comunidad",
        accessorFn: (item) => item.community?.name,
        width: "20%",
        cell: ({ row }) => row.original.community?.name,
      },
      {
        header: "Tipo",
        accessorKey: "type.name",
        cell: ({ row }) => (
          <SuiBadgeTs
            size="xs"
            color={colorsMap[row.original.type.name] || "error"}
            badgeContent={row.original.type.name}
          />
        ),
      },
      {
        header: "Etiquetas",
        cell: ({ row }) => <TagsList tags={row.original.tags} />,
      },
      {
        header: "Acciones",
        cell: ({ row }) => <TableActions document={row.original} />,
      },
    ];
    return columns;
  }, []);
};
