import { Delete, Edit } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { CommentaryModel, TicketModel } from 'api/services/tickets/models';
import DropdownMenu from 'components/DropdownMenu';
import ShowMoreLess from 'components/ShowMore';
import { SuiTypographyTs } from 'components/SuiTypography';
import HTMLReactParser from 'html-react-parser';
import React, { FC, useState } from 'react';
import { parseDate } from 'utils';
import { timeSince } from 'utils/dates';
import CommentaryEditor from './CommentaryEditor';
import useDeleteTicketCommentary from 'api/services/tickets/mutations/DeleteTicketCommentary';
import { useAuthenticationStore } from 'stores/authentication';

const hover = { ':hover': { bgcolor: 'whitesmoke' } };
interface Props {
  commentary: CommentaryModel;
  ticket: TicketModel;
}

const Commentary: FC<Props> = ({ commentary, ticket }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const user = useAuthenticationStore((store) => store.userProfile);

  const canModifyCommentary = user?.email === commentary.user.email;
  const wasModified = commentary.created !== commentary.modified;
  const showDate = wasModified ? commentary.created : commentary.modified;
  const tooltipText = `${wasModified ? 'Editado' : 'Creado'} el ${parseDate(showDate)}`;

  const { mutate: deleteCommentary } = useDeleteTicketCommentary({
    commentaryUUID: commentary.uuid,
    ticketUUID: ticket.uuid,
  });

  const onEditCancel = () => {
    setIsEditing(false);
  };
  const onEditSuccess = () => {
    setIsEditing(false);
  };
  return (
    <Box sx={{ ...(!isEditing && hover) }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} gap={1} alignItems={'baseline'}>
          <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
            {commentary.user.first_name}
          </Typography>
          <Tooltip title={tooltipText} placement="bottom">
            <Box>
              <SuiTypographyTs variant="caption" fontWeight="light">
                {wasModified && 'Editado'} {timeSince(showDate)}
              </SuiTypographyTs>
            </Box>
          </Tooltip>
        </Box>
        <Box>
          {canModifyCommentary && isEditing === false && (
            <DropdownMenu
              items={[
                {
                  label: 'Editar',
                  icon: <Edit />,
                  onClick: () => setIsEditing(true),
                },
                {
                  label: 'Eliminar',
                  icon: <Delete />,
                  onClick: () => deleteCommentary(),
                },
              ]}
            />
          )}
        </Box>
      </Box>

      <Box>
        {isEditing === false ? (
          <ShowMoreLess height={100}>{HTMLReactParser(commentary.text)}</ShowMoreLess>
        ) : (
          <CommentaryEditor
            commentary={commentary}
            ticket={ticket}
            onCancel={onEditCancel}
            onSuccess={onEditSuccess}
          />
        )}
      </Box>
    </Box>
  );
};

export default Commentary;
