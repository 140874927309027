import LoadingView from 'components/Loading';
import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import HalfScreenErrorBanner from 'components/HalfScreenErrorBanner';
import ErrorBanner from 'components/ErrorBanner';
import { useAuthenticate } from 'stores/authentication/helpers';
interface Props {
  redirect: string;
}

const AuthGuard: FC<Props> = ({ redirect }) => {
  const { isAuthenticated, userProfile } = useAuthenticate();

  if (isAuthenticated === false) return <Navigate to={redirect} />;
  if (isAuthenticated === undefined || userProfile === undefined) return <LoadingView />;
  if (userProfile?.management?.is_blocked && userProfile.groups[0].name != 'comite' && userProfile.groups[0].name != 'conserje' && userProfile.groups[0].name != 'mayordomo')
    return (
      <section>
        <HalfScreenErrorBanner number={userProfile.management.unpaid_documents_count} />
        <Outlet />
      </section>
    );
  if (userProfile?.management?.has_pending_payment && userProfile.groups[0].name != 'comite' && userProfile.groups[0].name != 'conserje' && userProfile.groups[0].name != 'mayordomo')
    return (
      <section>
        <ErrorBanner number={userProfile.management.unpaid_documents_count} />
        <Outlet />
      </section>
    );
  
  return <Outlet />;
};
export default AuthGuard;
