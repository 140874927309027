import React, { useMemo } from "react";
import { Typography, Box, Stack, Icon, Tooltip } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { queryClient } from "api/config/queryClient";
import { DocumentModel } from "api/services/documents/models";
import useDeleteDocument from "api/services/documents/mutations/DeleteDocument";
import { MaintenanceModel } from "api/services/maintenances/models";
import { GET_MAINTENANCE_KEY } from "api/services/maintenances/queries/getMaintenance";
import DocumentUrl from "components/Documents/DocumentUrl";
import EditFileDrawer from "components/EditFileDrawer";
import ShowMoreDialog from "components/ShowMoreDialog";
import { SuiBadgeTs } from "components/SuiBadge";
import { FcApproval, FcCancel } from "react-icons/fc";
import { usePermissions } from "stores/authentication/helpers";
import Swal from "sweetalert2";
import { dateToLocale } from "utils";

export const useCreateColumns = (maintenance: MaintenanceModel) => {
  const { mutate: deleteDocument } = useDeleteDocument();
  const canDeleteDocuments = usePermissions().documents.delete;
  const canEditDocuments = usePermissions().documents.edit;

  return useMemo<ColumnDef<DocumentModel>[]>(() => {
    return [
      { header: "Título", accessorKey: "name", width: "40%" },
      {
        header: "Observación",
        accessorKey: "document",
        width: "10%",
        cell: ({ row }) => {
          const document = row.original;
          return (
            <ShowMoreDialog
              content={document.observations}
              renderButton={(open) => {
                return (
                  <Typography variant="caption" onClick={open}>
                    <Box
                      maxWidth={200}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {document.observations}
                    </Box>
                  </Typography>
                );
              }}
            />
          );
        },
      },
      {
        header: "Activo",
        accessorKey: "is_active",
        width: "10%",
        cell: ({ row }) => {
          const document = row.original;
          return (
            <Box width="100%" alignContent={"center"} justifyContent={"center"}>
              {document.is_active ? (
                <FcApproval size="20px" />
              ) : (
                <FcCancel size="20px" />
              )}
            </Box>
          );
        },
      },
      {
        header: "Fecha expiración",
        accessorKey: "expires_on",
        width: "20%",
        cell: ({ row }) => dateToLocale(row.original.expires_on),
      },
      {
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => {
          const document = row.original;
          return (
            <Stack direction={"row"}>
              <Box>
                <Tooltip title={"Abrir"} placement="top">
                  <DocumentUrl uuid={document.uuid} />
                </Tooltip>
              </Box>
              {canEditDocuments && (
                <Box>
                  <Tooltip title={"Editar contrato"} placement="top">
                    <SuiBadgeTs
                      color="warning"
                      variant="gradient"
                      style={{ marginLeft: 2 }}
                      container
                      badgeContent={
                        <EditFileDrawer
                          file={document}
                          community={maintenance.community}
                          slugType={document.type.slug}
                        />
                      }
                    />
                  </Tooltip>
                </Box>
              )}
              {canDeleteDocuments && (
                <Box>
                  <Tooltip title={"Eliminar contrato"} placement="top">
                    <SuiBadgeTs
                      style={{ marginLeft: 2 }}
                      onClick={() => {
                        Swal.fire({
                          title: "¿Quieres eliminar este contrato?",
                          showDenyButton: true,
                          confirmButtonText: "Si",
                          denyButtonText: `No`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteDocument(
                              { uuid: document.uuid },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries({
                                    queryKey: [
                                      GET_MAINTENANCE_KEY,
                                      maintenance.uuid,
                                    ],
                                  });
                                },
                              }
                            );
                          }
                        });
                      }}
                      badgeContent={<Icon>delete</Icon>}
                      color="error"
                      variant="gradient"
                      container
                    />
                  </Tooltip>
                </Box>
              )}
            </Stack>
          );
        },
      },
    ];
  }, []);
};
