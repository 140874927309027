import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Outlet } from "react-router-dom";
import Header from "layouts/pages/profile/components/Header";
import AppSidebar from "../Sidebar";
import Footer from "examples/Footer";
import { Container } from "@mui/material";

export const LayoutWrapper = () => {
  return (
    <DashboardLayout>
      <AppSidebar />
      <Header />
      <Outlet />
      <Footer />
    </DashboardLayout>
  );
};
