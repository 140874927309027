import React, { forwardRef } from 'react';
import { Props as SelectProps, SelectInstance } from 'react-select';
import SuiSelect from 'components/SuiSelect'; // Ajusta la ruta de importación según sea necesario

interface SuiSelectProps extends SelectProps {
  size?: 'small' | 'medium' | 'large';
  error?: boolean;
  success?: boolean;
}

const SuiSelectAny = SuiSelect as any;

const SuiSelectTs = forwardRef<SelectInstance<any>, SuiSelectProps>((props, ref) => {
  return <SuiSelectAny ref={ref} {...props} />;
});

SuiSelectTs.displayName = 'SuiSelectTs';

export default SuiSelectTs;
