import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { userToken } from "utils/user/user";
import { DocumentModel } from "../models";
import { PageParams, PageResponse, PLACEHOLDER_PAGE } from "api/utils/queries";
import { UUID } from "crypto";
import { Email } from "types/models";
import useEventListener from "lib/events/useEventListener";
import { queryClient } from "api/config/queryClient";

export const GET_DOCUMENTS_TABKE_KEY = "GetDocumentsByManagement";

type DocumentsPage = PageResponse<DocumentModel>;

export interface DocumentsPageFiltersProps {
  o: "" | "-";
  name: string;
  type: string;
  communities: string;
  service_provider: UUID;
  area: UUID;
  visit: UUID;
  maintenance: UUID;
  user: Email;
}

export type DocumentsPageParams = Partial<DocumentsPageFiltersProps> &
  PageParams;

export const useGetDocumentsPage = (params: DocumentsPageParams) => {
  const query = useQuery<DocumentsPage>({
    queryKey: [GET_DOCUMENTS_TABKE_KEY, params],
    queryFn: async () => {
      const { slug } = userToken().management;
      const url = `${API_URL}/documents/by-management/${slug}`;
      return await axios
        .get<DocumentsPage>(url, { params })
        .then((res) => res.data);
    },
  });

  useEventListener<DocumentModel>(
    ["documentCreated", "documentUpdated", "documentDeleted"],
    () => {
      queryClient.invalidateQueries({
        queryKey: [GET_DOCUMENTS_TABKE_KEY, params],
      });
    }
  );
  const data: DocumentsPage = query.data || PLACEHOLDER_PAGE;
  return { ...query, data };
};
