import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { ChecklistModel } from 'api/services/checklist/models';

import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useUpdateChecklistTemplate from 'api/services/checklist/mutations/UpdateChecklistTemplate';
import useDeleteChecklistTemplate from 'api/services/checklist/mutations/deleteChecklistTemplate';
import DropdownMenu from 'components/DropdownMenu';
import Swal from 'sweetalert2';

interface Props {
  checklist: ChecklistModel;
  onDelete: () => void;
}
interface FormValues {
  name: string;
}
const Title: FC<Props> = ({ checklist, onDelete }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const updateChecklistTemplate = useUpdateChecklistTemplate({ checklistUUID: checklist.uuid });
  const deleteCheclistTemplate = useDeleteChecklistTemplate({ uuid: checklist.uuid });
  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<FormValues>();

  useEffect(() => {
    reset({ name: checklist.name });
  }, [checklist]);

  const handleEnableEdit = () => {
    setIsEditing(true);
  };
  const handleDeleteTemplate = () => {
    Swal.fire({
      title: '¿Quieres eliminar esta plantilla?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCheclistTemplate.mutate(null, {
          onSuccess: () => {
            onDelete?.();
          },
        });
      }
    });
  };
  const handleCancelEdit = () => {
    reset();
    setIsEditing(false);
  };

  const onSubmit = handleSubmit((data) => {
    updateChecklistTemplate.mutate(data, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  });

  return (
    <Box>
      {isEditing ? (
        <Box display={'flex'} justifyContent={'space-between'} gap={2}>
          <Box flex={1}>
            <InputControlled
              control={control}
              name="name"
              placeholder="Nombre de la lista"
              size="large"
            />
          </Box>

          <Box display={'flex'} justifyContent={'space-between'} gap={1}>
            <SuiButtonTs
              variant="gradient"
              color="secondary"
              size="small"
              onClick={handleCancelEdit}
            >
              Cancelar
            </SuiButtonTs>
            <SuiButtonTs
              variant="gradient"
              color="success"
              size="small"
              disabled={!isDirty}
              onClick={onSubmit}
            >
              Guardar
            </SuiButtonTs>
          </Box>
        </Box>
      ) : (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box py={1}>
            <Typography variant="h5" fontWeight="bold" textTransform="none">
              {checklist.name}
            </Typography>
          </Box>
          <Box>
            <DropdownMenu
              items={[
                [
                  {
                    label: 'Editar título',
                    icon: <Edit />,
                    onClick: handleEnableEdit,
                  },
                ],
                [
                  {
                    label: 'Eliminar plantilla',
                    icon: <Delete />,
                    onClick: handleDeleteTemplate,
                  },
                ],
              ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Title;
