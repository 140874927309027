import { Delete, Edit, ThumbUp, ThumbUpAltOutlined } from '@mui/icons-material';
import { DocumentModel } from 'api/services/documents/models';
import useDeleteDocument from 'api/services/documents/mutations/DeleteDocument';
import useUpdateDocument from 'api/services/documents/mutations/UpdateDocument';
import { TicketModel } from 'api/services/tickets/models';
import { TicketQueryHelper } from 'api/services/tickets/queries/GetTicket';
import DropdownMenu from 'components/DropdownMenu';
import { useDocumentEditorDrawer } from 'components/EditFileDrawer/store';
import React, { FC } from 'react';
import Swal from 'sweetalert2';

interface Props {
  document: DocumentModel;
  ticket: TicketModel;
}
const DropdownMenuActions: FC<Props> = ({ document, ticket }) => {
  const { mutate: updateDocument } = useUpdateDocument({
    documentUUID: document.uuid,
    ticketUUID: ticket.uuid,
  });
  const { mutate: deleteDocument } = useDeleteDocument();
  const handleRecommend = () => {
    Swal.fire({
      title: document.is_recommended
        ? '¿Quieres dejar de recomendar esta cotización?'
        : '¿Quieres recomendar esta cotización?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        updateDocument(
          { is_recommended: !document.is_recommended },
          {
            onSuccess: () => {
              Swal.fire({
                title: 'Cotización marcada como recomendada!',
                color: 'success',
              });
            },
          }
        );
      }
    });
  };
  const handleEdit = () => {
    useDocumentEditorDrawer.setState({
      document: document,
      ticket: ticket,
      isOpen: true,
    });
  };
  const handleDelete = () => {
    Swal.fire({
      title: '¿Quieres eliminar este documento?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocument(
          { uuid: document.uuid },
          {
            onSuccess: () => {
              TicketQueryHelper.deleteDocument(document.uuid, ticket.uuid);
            },
          }
        );
      }
    });
  };
  return (
    <DropdownMenu
      items={[
        [
          {
            label: document.is_recommended ? 'No recomendar' : 'Recomendar',
            icon: document.is_recommended ? <ThumbUp /> : <ThumbUpAltOutlined />,
            onClick: handleRecommend,
          },
        ],
        [
          {
            label: 'Editar',
            icon: <Edit />,
            onClick: handleEdit,
          },
          {
            label: 'Eliminar',
            icon: <Delete />,
            onClick: handleDelete,
          },
        ],
      ]}
    />
  );
};

export default DropdownMenuActions;
