import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { TicketModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { TicketQueryHelper } from "../queries/GetTicket";
import { ISODateString } from "types/models";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";

interface Props {
  uuid: UUID;
}
interface Inputs {
  comment?: string;
  priority?: string;
  community?: string;
  is_after_sale?: boolean;
  title?: string;
  responsible?: string;
  assignees?: string[];
  due_date?: ISODateString;
  area_uuid?: UUID;
  commentaries_visible?: boolean;
}
export const useUpdateTicket = ({ uuid }: Props) => {
  return useMutation({
    mutationFn: async (input: Inputs) => {
      const url = `${API_URL}/tickets/edit/${uuid}/`;
      return await axios.put<TicketModel>(url, input).then((res) => {
        return res.data;
      });
    },
    onSuccess: (data) => {
      TicketQueryHelper.updateTicket(data);
      toastNotification.success({
        title: "Tarea actualizada",
        message: "La tarea ha sido actualizada correctamente.",
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TICKETS_TABLE],
      });
    },
  });
};
