import { Box, Divider, Grid, Typography } from '@mui/material';
import { SuiSelectTs } from 'components/SuiSelect';
import React, { FC, useEffect, useState } from 'react';
import { MaintenancesDataFilters, FiltersProps } from './types';
import useDebounce from 'utils/Hooks/useDebounce';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
import { useGetProviders } from 'api/services/providers/queries/getProviders';
import { useExportCenterFiltersStore } from '../store';

const Filters: FC<FiltersProps> = ({ setFilters: updateFilters }) => {
  const { dataAsOptions: communitiesOptions } = useGetCommunities();
  const { dataAsOptions: providerOptions } = useGetProviders();

  const { community, setCommunity } = useExportCenterFiltersStore();
  const [providerFilter, setProviderFilter] = useState(null);

  const debounceFilters = useDebounce(
    (value: MaintenancesDataFilters) => updateFilters(value),
    1000
  );
  useEffect(() => {
    debounceFilters({
      community,
      service_provider: providerFilter,
    });
  }, [community, providerFilter]);

  return (
    <Box>
      <Typography variant="h5" fontWeight="medium" style={{ marginBottom: 3 }}>
        Filtros
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid md={3} item sx={{ overflow: 'visible' }}>
          <Typography variant="h6" fontWeight={'medium'}>
            Comunidad
          </Typography>
          <SuiSelectTs
            size="small"
            value={community}
            placeholder="Comunidad"
            style={{ zIndex: 1000, width: '100%' }}
            onChange={(e) => setCommunity(e.value)}
            options={[{ label: 'Todas', value: null }, ...communitiesOptions]}
          />
        </Grid>
        <Grid md={3} item sx={{ overflow: 'visible' }}>
          <Typography variant="h6" fontWeight={'medium'}>
            Proveedores
          </Typography>
          <SuiSelectTs
            size="small"
            value={providerFilter}
            placeholder="Proveedor"
            style={{ zIndex: 1000, width: '100%' }}
            onChange={(e) => setProviderFilter(e.value)}
            options={[{ label: 'Todos', value: null }, ...providerOptions]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
