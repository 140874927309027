import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import { usePermissions } from "stores/authentication/helpers";
import EmergenciesTable from "./EmergenciesTable";
import CreateEmergencyButton from "./CreateEmergencyButton";
import { MaintenanceModel } from "api/services/maintenances/models";
import React from "react";

const EmergenciesTab: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const canEditMaintenance = usePermissions().maintenances.delete;

  return (
    <Card id="basic-info" sx={{ overflow: "scroll" }}>
      <Box>
        <Box display={"flex"} mb={2} justifyContent={"flex-end"}>
          {canEditMaintenance && (
            <CreateEmergencyButton maintenance={maintenance} />
          )}
        </Box>
        <EmergenciesTable maintenance={maintenance} />
      </Box>
    </Card>
  );
};

export default EmergenciesTab;
