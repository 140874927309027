import React, { useMemo } from "react";

import Grid from "@mui/material/Grid";

import { SuiButtonTs } from "components/SuiButton";
import {
  validate as validateRut,
  clean as cleanRut,
  format as formatRut,
} from "rut.js";
import { getPermission } from "utils";
import { getAuthUserProfile } from "stores/authentication/helpers";
import { useForm } from "react-hook-form";
import InputControlled from "components/forms/controlled/InputControlled";
import RadioSelectControlled from "components/forms/controlled/RadioSelectControlled";
import { isValidEmail, validatePassword } from "utils/validators.helper";
import { Box } from "@mui/material";
import useCreateUser from "api/services/user/mutations/createUser";
import useEventEmitter from "lib/events/useEventEmitter";
import { UserGroupsEnum } from "api/services/user/models.enum";
import { SlugString } from "types/models";
import { SuiTypographyTs } from "components/SuiTypography";

const CreateUserForm: React.FC<{
  group?: UserGroupsEnum;
  communitySlug?: SlugString;
}> = ({ group, communitySlug }) => {
  const userCreated = useEventEmitter("userCreated");
  const { mutate: createUser } = useCreateUser();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: useMemo(() => {
      return {
        group: group || "",
        first_name: "",
        email: "",
        phone: "",
        rut: "",
        password: "",
        community_slug: communitySlug,
      };
    }, [group, communitySlug]),
  });
  const onSubmit = handleSubmit((data) => {
    createUser(
      {
        first_name: data.first_name,
        email: data.email,
        phone: data.phone,
        rut: cleanRut(data.rut),
        management: getAuthUserProfile().management.slug,
        password: data.password,
        groups: [Number(data.group)],
        community_slug: data.community_slug,
      },
      {
        onSuccess: (data) => {
          userCreated.emit(data);
        },
      }
    );
  });

  return (
    <Box>
      <SuiTypographyTs variant="h5">Agregar Colaborador</SuiTypographyTs>
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputControlled
              label="Nombre"
              name="first_name"
              control={control}
              placeholder="Ingrese su nombre"
              rules={{ required: "*Este campo es requerido" }}
              requiredDot
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputControlled
              label="Email"
              name="email"
              type="email"
              control={control}
              placeholder="correo@ejemplo.cl"
              rules={{
                required: "*Este campo es requerido",
                validate: (value) =>
                  isValidEmail(value) || "Ingrese un correo válido",
              }}
              requiredDot
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputControlled
              label="Teléfono"
              name="phone"
              control={control}
              placeholder="+56991652881"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputControlled
              label="Rut"
              placeholder="11111111-1"
              name="rut"
              control={control}
              format={(value) => formatRut(value)}

            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: "auto" }}>
            <InputControlled
              label="Contraseña"
              type="password"
              name="password"
              control={control}
              placeholder="Ingresar contraseña"
              requiredDot
              rules={{
                required: "*Este campo es requerido",
                validate: (value) => {
                  const { isValid, errors } = validatePassword(value);
                  return isValid || errors[0];
                },
              }}
            />
          </Grid>
          {!group && communitySlug && (
            <Grid item xs={12} sm={6}>
              <RadioSelectControlled
                label="Rol"
                control={control}
                name="group"
                direction="row"
                rules={{ required: "Debe elegir una opción" }}
                options={[
                  { value: 5, label: "Conserje" },
                  { value: 6, label: "Mayordomo" },
                ]}
              />
            </Grid>
          )}
          {!group && !communitySlug && getPermission(["superadmin"]) && (
            <Grid item xs={12} sm={6}>
              <RadioSelectControlled
                label="Rol"
                control={control}
                name="group"
                direction="row"
                rules={{ required: "Debe elegir una opción" }}
                options={[
                  { value: 1, label: "Administrador" },
                  { value: 3, label: "Supervisor" },
                ]}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <div>
              <ul style={{ fontSize: 10, marginLeft: 12, marginTop: 5 }}>
                <li>
                  Su contraseña no puede asemejarse tanto a su otra información
                  personal.
                </li>
                <li>Su contraseña debe contener al menos 8 caracteres.</li>
                <li>
                  Su contraseña no puede ser una clave utilizada comúnmente.
                </li>
                <li>Su contraseña no puede ser completamente numérica.</li>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            color="success"
            variant="gradient"
            size="small"
            type="submit"
            disabled={!isValid}
          >
            Agregar colaborador
          </SuiButtonTs>
        </Box>
      </form>
    </Box>
  );
};

export default CreateUserForm;
