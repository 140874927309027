import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { TagModel } from "../models";
import { useMemo } from "react";
import { SelectOptionForm } from "types/forms";

const useGetDocumentsTags = () => {
  const query = useQuery({
    queryKey: ["GET_DOCUMENTS_TAGS"],
    queryFn: async () => {
      const url = `${API_URL}/documents/tags/`;
      return await axios.get<TagModel[]>(url).then((res) => res.data);
    },
  });

  const data = query.data || [];

  const dataAsOptions: SelectOptionForm[] = useMemo(() => {
    return data.map((tag) => ({ label: tag.name, value: tag.uuid }));
  }, [data]);

  return { ...query, data, dataAsOptions };
};

export default useGetDocumentsTags;
