import { ISODateString } from "types/models";
import * as yup from "yup";
interface Fields {
  name: string;
  start_date: ISODateString[];
  end_date: ISODateString[];
}
export const scheme: yup.SchemaOf<Fields> = yup.object().shape({
  name: yup.string().required(),
  start_date: yup.array().of(yup.string().required()).required(),
  end_date: yup.array().of(yup.string()).required(),
});
