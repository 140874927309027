import { Box, Stack } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import useCreateEmergency from "api/services/emergencies/mutations/createEmergency";
import InputControlled from "components/forms/controlled/InputControlled";
import RichTextEditorControlled from "components/forms/controlled/RichTextEditorControlled";
import { SuiButtonTs } from "components/SuiButton";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import { useForm } from "react-hook-form";

const CreateEmergencyForm: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const emergencyCreated = useEventEmitter("emergencyCreated");
  const { mutate: createEmergency } = useCreateEmergency();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      maintenance_uuid: maintenance.uuid,
      title: "",
      description: "",
    },
  });
  const onSubmit = handleSubmit((data) => {
    createEmergency(data, {
      onSuccess: (data) => {
        emergencyCreated.emit(data);
      },
    });
  });
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <h4>Crear nueva emergencia</h4>
        <InputControlled
          control={control}
          name="title"
          label="Título"
          rules={{ required: true }}
        />
        <RichTextEditorControlled
          label="Descripción"
          name="description"
          control={control}
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            color={"success"}
            variant={"gradient"}
            size="small"
            type="submit"
            disabled={!isValid}
          >
            Crear
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default CreateEmergencyForm;
