import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import CreateUserForm from "./forms/CreateUserForm";
import { Box } from "@mui/material";
import { SlugString } from "types/models";

interface CreateUserModalProps {
  communitySlug?: SlugString;
}

const CreateUserModal = forwardRef<ModalRefHandle, CreateUserModalProps>(
  ({ communitySlug }, ref) => {
    const modalRef = useRef<ModalRefHandle>(null);

    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
    }));

    useEventListener("userCreated", () => {
      modalRef.current?.close();
    });

    return (
      <BaseModal ref={modalRef}>
        <Box p={3} width={{ md: 650, xs: "100vw" }}>
          <CreateUserForm communitySlug={communitySlug} />
        </Box>
      </BaseModal>
    );
  }
);
CreateUserModal.displayName = "CreateUserModal";
export default CreateUserModal;
