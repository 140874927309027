import React from "react";
import { MaintenanceModel } from "api/services/maintenances/models";
import SwappiDataTable from "components/SwappiTable";
import EditEmergencyModal from "./EditEmergencyModal";
import useCreateColumnsHelper from "./table.helper";

const EmergenciesTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const columns = useCreateColumnsHelper();
  return (
    <div>
      <EditEmergencyModal />
      <SwappiDataTable columns={columns} data={maintenance.emergencies} />
    </div>
  );
};

export default EmergenciesTable;
