import { types, API_URL } from "./types";
import Axios from "axios";


export const getProvidersByManagement = (d) =>
{
    const url = `${API_URL}/users/manager/service-providers/${d}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetProvidersByManagement(outputData));
        })
    }
}

export const setGetProvidersByManagement = (data) => ({
    type: types.setGetProvidersByManagement,
    payload: data,
});

export const createProviderByManagement = (slug, d) =>
{
    const url = `${API_URL}/users/manager/service-providers/${slug}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then(({ data }) =>
        {
            if (data.error)
            {
                let notification = { status: "error", message: data.message, title: "Proveedor no creado" };
                dispatch(setNotification(notification));
            }
            else
            {
                outputData["data"] = data;
                outputData["status"] = 200;
                dispatch(setCreateProviderByManagement(outputData));
                let notification = { status: "success", message: "El proveedor ha sido creado con éxito!", title: "Proveedor creado!" };
                dispatch(setNotification(notification));
            }

        })
    }
}

export const setCreateProviderByManagement = (data) => ({
    type: types.setCreateProviderByManagement,
    payload: data,
});

export const deleteProvider = (slug, uuid) =>
{
    const url = `${API_URL}/users/manager/service-providers/${slug}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.delete(url, { data: { uuid: uuid } }).then(({ data }) =>
        {
            outputData["data"] = data;
            outputData["status"] = 200;
            dispatch(setDeleteProvider(outputData));
            let notification = { status: "warning", message: "El proveedor ha sido eliminado con éxito!", title: "Proveedor eliminado!" };
            dispatch(setNotification(notification));
        }
        ).catch(() =>
        {

        }
        )
    }
}

export const setDeleteProvider = (data) => ({
    type: types.setDeleteProvider,
    payload: data,
});

export const updateProvider = (slug, d) =>
{
    const url = `${API_URL}/users/manager/service-providers/${slug}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then(({ data }) =>
        {
            outputData["data"] = data;
            outputData["status"] = 200;
            let notification = { status: "info", message: "El proveedor ha sido actualizado con éxito!", title: "Proveedor actualizado!" };
            dispatch(setNotification(notification));
            dispatch(setUpdateProvider(outputData));
        }
        ).catch(() =>
        {

        }
        )
    }
}

export const setUpdateProvider = (data) => ({
    type: types.setUpdateProvider,
    payload: data,
});



export const setNotification = (data) => ({
    type: types.setNotification,
    payload: data,
})

export const getTicketsByProvider = (d) =>
{
    const url = `${API_URL}/tickets/by-provider/${d}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetTicketsByProvider(outputData));
        }
        )
    }
}

export const setGetTicketsByProvider = (data) => ({
    type: types.setGetTicketsByProvider,
    payload: data,
});

export const getMainetenanceByProvider = (d) =>
{
    const url = `${API_URL}/provider/${d}/maintenances/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetMaintenanceByProvider(outputData));
        }
        )
    }
}

export const setGetMaintenanceByProvider = (data) => ({
    type: types.setGetMaintenanceByProvider,
    payload: data,
});


export const getDocumentsByProvider = (d) =>
{
    const url = `${API_URL}/documents/by-provider/${d}`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetDocumentsByProvider(outputData));
        }
        )
    }
}

export const setGetDocumentsByProvider = (data) => ({
    type: types.setGetDocumentsByProvider,
    payload: data,
})


export const addCommunityToProvider = (slug, d) =>
{
    const url = `${API_URL}/users/service-provider/${slug}/assign-communities`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then(({ data }) =>
        {
            outputData["data"] = data;
            outputData["status"] = 200;
            dispatch(setAddCommunityToProvider(outputData));
            let notification = { status: "success", message: "La comunidad ha sido agregada con éxito!", title: "Comunidad agregada!" };
            dispatch(setNotification(notification));
        }
        ).catch(() => { })
    }
}

export const setAddCommunityToProvider = (data) => ({
    type: types.setAddCommunityToProvider,
    payload: data,
})


export const exportProvidersToFile = (d) =>
{
    const url = `${API_URL}/users/service-provider/export/`;
    return () =>
    {

        return Axios.post(url, d, { responseType: 'arraybuffer' })
            .then(res =>
            {
                if (d.type === "pdf")
                {
                    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'proveedores.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
                else if (d.type === "excel")
                {
                    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'proveedores.xls'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            })
    }
}


export const createContact = (slug, d) =>
{
    const url = `${API_URL}/service-provider/${slug}/create-contact/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then(({ data }) =>
        {
            if (data.error)
            {
                let notification = { status: "error", message: data.message, title: "Contacto no creado" };
                dispatch(setNotification(notification));
            }
            else
            {
                outputData["data"] = data;
                outputData["status"] = 200;
                dispatch(setCreateContact(outputData));
                let notification = { status: "success", message: "El contacto ha sido creado con éxito!", title: "Contacto creado!" };
                dispatch(setNotification(notification));
            }

        })
    }
}

export const setCreateContact = (data) => ({
    type: types.setCreateContact,
    payload: data,
})


export const getProviderByUuid = (uuid) =>
{
    const url = `${API_URL}/service-provider/${uuid}/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetProviderByUuid(outputData));
        }
        ).catch(() =>
        {
            outputData["data"] = [];
            outputData["status"] = 404;
            dispatch(setGetProviderByUuid(outputData));
        })
    }
}

export const setGetProviderByUuid = (data) => ({
    type: types.setGetProvider,
    payload: data,
})


export const importProviders = (d) =>
{
    const url = `${API_URL}/service-providers/import/`;
    return (dispatch) =>
    {
        let outputData = {};
        return Axios.post(url, d)
            .then(res =>
            {
                outputData["data"] = res.data;
                outputData["status"] = 200;
                dispatch(setImportProviders(outputData));
                let notification = { status: "success", message: `Se han importado ${res.data.created_rows} proveedores exitosamente!`, title: "Proveedores importados" };
                dispatch(setNotification(notification));
            })
    }
}

export const setImportProviders = (data) => ({
    type: types.setImportProviders,
    payload: data,
})