import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import { FcApproval, FcCancel } from "react-icons/fc";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

function checkObjectsForMonth(objList, desiredMonth) {
  for (let obj of objList) {
    const startDate = new Date(obj.start);
    const endDate = new Date(obj.end);
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;

    if (startMonth === desiredMonth || endMonth === desiredMonth) {
      return true;
    }
  }

  return false;
}

function getObjectStatus(objList, desiredMonth, year) {
  const currentDate = new Date();

  for (let obj of objList) {
    const startDate = new Date(obj.start);
    const endDate = new Date(obj.end);
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;

    if (
      (startMonth === desiredMonth || endMonth === desiredMonth) &&
      year === startDate.getFullYear()
    ) {
      if (obj.completed) {
        return 1;
      }

      if (endDate < currentDate) {
        return 0;
      }

      return 2;
    }
  }

  return 3;
}

function getObjectByMonth(objList, desiredMonth, year) {
  for (let obj of objList) {
    const startDate = new Date(obj.start);
    const endDate = new Date(obj.end);
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;

    if (
      (startMonth === desiredMonth || endMonth === desiredMonth) &&
      year === startDate.getFullYear()
    ) {
      return obj;
    }
  }

  return null;
}

// Soft UI Dashboard PRO React example components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "layouts/pages/profile/components/Header";
import Loading from "components/Loading";
import { getItemsByPlanification } from "redux/actions/analytics";
import { Card, CircularProgress, Divider, Tooltip } from "@mui/material";
import SuiBadge, { SuiBadgeTs } from "components/SuiBadge";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import {
  MdDelete,
  MdErrorOutline,
  MdOutlinePending,
  MdViewAgenda,
} from "react-icons/md";
import SuiTypography from "components/SuiTypography";
import CreateCategory from "./CreateCategory";
import CreateItemCategory from "./CreateItemCategory";
import CreateVisit from "layouts/pages/calendar/CreateVisit";
import VisitsModal from "layouts/pages/calendar/VisitView";
import {
  getPLanifications,
  deletePlanificationItem,
} from "redux/actions/analytics";
import Swal from "sweetalert2";
import SuiSelect from "components/SuiSelect";
import CreatePlanification from "./CreatePlanification";
import MaintenanceModal from "layouts/pages/maintenance/View/MaintenanceCard";
import { editPlanificationCategory } from "redux/actions/analytics";
import { getCommunitiesByUser } from "redux/actions/communities";
import { postExportAnualPlanification } from "redux/actions/analytics";
import { BsFilePdf } from "react-icons/bs";
import { isPermissionEnabled } from "utils";
import CreateMaintenanceModal from "layouts/pages/maintenance/Create";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { FaEye } from "react-icons/fa";
function AnualPlanifiaction(props) {
  const dispatch = useDispatch();
  const [firstCommunitiesRequest, setFirstCommunitiesRequest] = useState(false);
  const [loading, setloading] = useState(true);
  const [visit, setVisit] = useState(null);
  const [planifications, setPlanifications] = useState([]);
  const [planification, setPlanification] = useState(
    props.planification ? props.planification : null
  );
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState(null);

  const [exporting, setExporting] = useState(false);
  const anualPlanificationResponse = useSelector(
    (state) => state.analytics.getItemsByPlanification
  );

  const createCategoryResponse = useSelector(
    (state) => state.analytics.createPlanificationCategory
  );

  const createItemCategoryResponse = useSelector(
    (state) => state.analytics.createPlanificationItem
  );

  const postExportAnualPlanificationResponse = useSelector(
    (state) => state.analytics.postExportAnualPlanification
  );

  const createVisitResponse = useSelector(
    (state) => state.maintenance.createMaintenanceVisit
  );
  const markAsVisitedResponse = useSelector(
    (state) => state.communities.markAsVisited
  );
  const editVisitResponse = useSelector((state) => state.communities.editVisit);
  const deleteVisitResponse = useSelector(
    (state) => state.communities.deleteVisit
  );
  const planificationsResponse = useSelector(
    (state) => state.analytics.getPlanifications
  );
  const createPlanificationResponse = useSelector(
    (state) => state.analytics.createPlanification
  );
  const createMaintenanceResponse = useSelector(
    (state) => state.maintenance.createMaintenance
  );
  const getCommunitiesByUserResponse = useGetCommunities();
  const editPlanificationCategoryResponse = useSelector(
    (state) => state.analytics.editPlanificationCategory
  );
  const deletePlanificationCategoryResponse = useSelector(
    (state) => state.analytics.deletePlanificationCategory
  );

  const tooglePlanificationCategoryResponse = useSelector(
    (state) => state.analytics.togglePlanificationCategory
  );
  const deletePlanificationItemResponse = useSelector(
    (state) => state.analytics.deletePlanificationItem
  );

  const editPlanificationItemResponse = useSelector(
    (state) => state.analytics.editPlanificationItem
  );
  let { slug } = useParams();

  useEffect(() => {
    setloading(true);
    if (props.planification) {
      dispatch(getItemsByPlanification(planification.uuid));
    } else if (props.global && !props.comite) {
    } else {
      dispatch(getPLanifications({ community: slug }));
    }
  }, []);

  useEffect(() => {
    if (postExportAnualPlanificationResponse.data) {
      setExporting(false);
    }
  }, [postExportAnualPlanificationResponse]);
  useEffect(() => {
    if (community) {
      dispatch(getPLanifications({ community: community }));
    }
  }, [community]);

  useEffect(() => {
    if (!slug)
      if (getCommunitiesByUserResponse.data) {
        if (!community) setCommunity(getCommunitiesByUserResponse.data[0].slug);
        setCommunities(getCommunitiesByUserResponse.data);
      }
  }, [getCommunitiesByUserResponse]);

  useEffect(() => {
    if (tooglePlanificationCategoryResponse.data) {
      if (slug) {
        dispatch(getPLanifications({ community: slug }));
      } else {
        dispatch(getPLanifications({ community: community }));
      }
    }
  }, [tooglePlanificationCategoryResponse]);

  useEffect(() => {
    if (deletePlanificationItemResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [deletePlanificationItemResponse]);

  useEffect(() => {
    if (editPlanificationItemResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [editPlanificationItemResponse]);

  useEffect(() => {
    if (planificationsResponse.data) {
      setPlanifications(planificationsResponse.data);
      if (!planification && planificationsResponse.data.length > 0)
        setPlanification(planificationsResponse.data[0]);
      else {
        let getPlanification = planificationsResponse.data.find(
          (plan) => plan.uuid === planification.uuid
        );
        setPlanification(getPlanification);
      }
    }
  }, [planificationsResponse]);

  useEffect(() => {
    if (planification) {
      dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [planification]);

  useEffect(() => {
    if (anualPlanificationResponse.data) {
      setloading(false);
    }
  }, [anualPlanificationResponse]);

  useEffect(() => {
    if (createCategoryResponse && createCategoryResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [createCategoryResponse]);

  useEffect(() => {
    if (createItemCategoryResponse && createItemCategoryResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [createItemCategoryResponse]);

  useEffect(() => {
    if (createVisitResponse && createVisitResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [createVisitResponse]);

  useEffect(() => {
    if (markAsVisitedResponse && markAsVisitedResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [markAsVisitedResponse]);

  useEffect(() => {
    if (editVisitResponse && editVisitResponse.data) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [editVisitResponse]);

  useEffect(() => {
    if (deleteVisitResponse && deleteVisitResponse.data) {
      if (slug) {
        dispatch(getPLanifications({ community: slug }));
      } else {
        dispatch(getPLanifications({ community: community }));
      }
    }
  }, [deleteVisitResponse]);

  useEffect(() => {
    if (createPlanificationResponse && createPlanificationResponse.data) {
      if (slug) {
        dispatch(getPLanifications({ community: slug }));
      } else {
        dispatch(getPLanifications({ community: community }));
      }
    }
  }, [createPlanificationResponse]);

  useEffect(() => {
    if (
      deletePlanificationCategoryResponse &&
      deletePlanificationCategoryResponse.data
    ) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [deletePlanificationCategoryResponse]);

  useEffect(() => {
    if (
      editPlanificationCategoryResponse &&
      editPlanificationCategoryResponse.data
    ) {
      if (planification) dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [editPlanificationCategoryResponse]);

  useEffect(() => {
    if (createMaintenanceResponse && createMaintenanceResponse.data) {
      if (new Date() - createMaintenanceResponse.time < 2000)
        if (planification)
          dispatch(getItemsByPlanification(planification.uuid));
    }
  }, [createMaintenanceResponse]);

  return (
    <SuiBox style={{ padding: 20 }}>
      <VisitsModal visit={visit} />
      <SuiBox
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          alignItems: "flex-end",
        }}
      >
        {isPermissionEnabled("planification-createPlanification") &&
          !props.planification && <CreatePlanification community={community} />}
        {isPermissionEnabled("planification-getCategory") && (
          <CreateCategory
            planification={planification}
            categories={planification && planification.categories}
          />
        )}
      </SuiBox>
      <Grid container spacing={3}>
        {!props.planification && (
          <Grid item xs={12} sm={6}>
            <Card sx={{ padding: 3, overflow: "visible" }}>
              <SuiTypography variant="h5" fontWeight="medium">
                Filtros
              </SuiTypography>
              <Divider />
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Comunidad
              </SuiTypography>
              <SuiSelect
                value={community}
                onChange={(e) => setCommunity(e.value)}
                options={
                  communities &&
                  communities.map((community) => {
                    return {
                      label: community.name,
                      value: community.slug,
                    };
                  })
                }
              />
              <Divider />
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Año
              </SuiTypography>
              <SuiSelect
                value={planification}
                onChange={(e) => setPlanification(e.value)}
                options={planifications.map((planification) => {
                  return {
                    label: planification.year,
                    value: planification,
                  };
                })}
              />
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Card sx={{ padding: 3, marginLeft: 3 }}>
            <SuiTypography variant="h5" fontWeight="medium">
              Estados
            </SuiTypography>
            <Divider />
            <SuiBox
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <SuiBox
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <SuiTypography variant="h6" fontWeight="medium" mr={1}>
                  Completado
                </SuiTypography>
                <SuiBadge
                  badgeContent={<AiOutlineCheckCircle />}
                  color={"success"}
                  variant="gradient"
                  ml={1}
                  container
                />
              </SuiBox>
              <SuiBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 10,
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium" mr={1}>
                  Expirado
                </SuiTypography>
                <SuiBadge
                  badgeContent={<MdErrorOutline />}
                  color={"error"}
                  variant="gradient"
                  ml={1}
                  container
                />
              </SuiBox>
              <SuiBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 10,
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium" mr={1}>
                  Pendiente
                </SuiTypography>
                <SuiBadge
                  badgeContent={<AiOutlineWarning />}
                  color={"warning"}
                  variant="gradient"
                  container
                />
              </SuiBox>

              <SuiBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 10,
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium" mr={1}>
                  No agendado
                </SuiTypography>
                <SuiBadge
                  badgeContent={<MdViewAgenda />}
                  color={"light"}
                  variant="gradient"
                  ml={1}
                  container
                />
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      {loading &&
      planificationsResponse.data &&
      planificationsResponse.data.length !== 0 ? (
        <Loading />
      ) : (
        <SuiBox>
          {planificationsResponse.data &&
          planificationsResponse.data.length == 0 ? (
            <SuiTypography
              variant="h5"
              fontWeight="medium"
              textAlign="center"
              mt={10}
              mb={10}
            >
              No hay planificaciones anuales para esta comunidad
            </SuiTypography>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SuiBox height={30}>
                  {isPermissionEnabled("maintenances-createMaintenance") && (
                    <CreateMaintenanceModal community={community} />
                  )}
                </SuiBox>
                <SuiTypography
                  variant="h5"
                  fontWeight="medium"
                  textAlign="center"
                  p={2}
                >
                  Planificación Anual {planification && planification.year}
                </SuiTypography>
                <SuiTypography
                  variant="h6"
                  fontWeight="medium"
                  textAlign="right"
                  pb={1}
                  onClick={() => {
                    if (exporting) {
                      return;
                    }
                    dispatch(
                      postExportAnualPlanification({
                        community: slug ? slug : community,
                        years: [planification.year],
                        type: "pdf",
                      })
                    );
                    setExporting(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Exportar{" "}
                  {exporting ? <CircularProgress size={12} /> : <BsFilePdf />}
                </SuiTypography>
                <Card>
                  <TableContainer>
                    <Table aria-label="Tabla de productos">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Nombre</TableCell>
                          <TableCell align="center">Proveedor</TableCell>
                          <TableCell align="center">Presupuesto</TableCell>
                          <TableCell align="center">Ene</TableCell>
                          <TableCell align="center">Feb</TableCell>
                          <TableCell align="center">Mar</TableCell>
                          <TableCell align="center">Abr</TableCell>
                          <TableCell align="center">May</TableCell>
                          <TableCell align="center">Jun</TableCell>
                          <TableCell align="center">Jul</TableCell>
                          <TableCell align="center">Ago</TableCell>
                          <TableCell align="center">Sep</TableCell>
                          <TableCell align="center">Oct</TableCell>
                          <TableCell align="center">Nov</TableCell>
                          <TableCell align="center">Dic</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={1}></TableCell>
                          <TableCell align="center" colSpan={12}>
                            <SuiTypography variant="h6" fontWeight="medium">
                              Mantenciones Preventivas
                            </SuiTypography>
                          </TableCell>
                          <TableCell align="center" colSpan={3}></TableCell>
                        </TableRow>
                        {anualPlanificationResponse.data &&
                          anualPlanificationResponse.data.maintenances &&
                          anualPlanificationResponse.data.maintenances.map(
                            (item) => (
                              <TableRow key={item.name}>
                                <TableCell align="center">
                                  {item.area.name}
                                </TableCell>
                                <TableCell align="center">
                                  {item.service_provider &&
                                    (item.service_provider.executive_name ||
                                      item.service_provider.razon_social)}
                                </TableCell>
                                <TableCell align="center">
                                  {item.budgets &&
                                    planification &&
                                    item.budgets.length > 0 &&
                                    item.budgets.filter(
                                      (budget) =>
                                        budget.year === planification.year
                                    ).length > 0 &&
                                    parseFloat(
                                      item.budgets.filter(
                                        (budget) =>
                                          budget.year === planification.year
                                      )[0].amount
                                    ).toLocaleString("es-CL", {
                                      style: "currency",
                                      currency: "CLP",
                                    })}
                                </TableCell>
                                {
                                  // create array of 12 months
                                  Array.from(
                                    { length: 12 },
                                    (_, i) => i + 1
                                  ).map((month) => (
                                    <TableCell align="center">
                                      {getObjectStatus(
                                        item.visits,
                                        month,
                                        planification && planification.year
                                      ) === 1 ? (
                                        <SuiBadge
                                          badgeContent={
                                            <AiOutlineCheckCircle />
                                          }
                                          onClick={() =>
                                            setVisit(
                                              getObjectByMonth(
                                                item.visits,
                                                month,
                                                planification &&
                                                  planification.year
                                              )
                                            )
                                          }
                                          color={"success"}
                                          style={{ cursor: "pointer" }}
                                          variant="gradient"
                                          container
                                        />
                                      ) : getObjectStatus(
                                          item.visits,
                                          month,
                                          planification && planification.year
                                        ) === 0 ? (
                                        <SuiBadge
                                          badgeContent={<MdErrorOutline />}
                                          onClick={() =>
                                            setVisit(
                                              getObjectByMonth(
                                                item.visits,
                                                month,
                                                planification &&
                                                  planification.year
                                              )
                                            )
                                          }
                                          color={"error"}
                                          style={{ cursor: "pointer" }}
                                          variant="gradient"
                                          container
                                        />
                                      ) : getObjectStatus(
                                          item.visits,
                                          month,
                                          planification && planification.year
                                        ) === 2 ? (
                                        <SuiBadge
                                          badgeContent={<AiOutlineWarning />}
                                          onClick={() =>
                                            setVisit(
                                              getObjectByMonth(
                                                item.visits,
                                                month,
                                                planification &&
                                                  planification.year
                                              )
                                            )
                                          }
                                          color={"warning"}
                                          style={{ cursor: "pointer" }}
                                          variant="gradient"
                                          container
                                        />
                                      ) : (
                                        <CreateVisit
                                          anualPlanification={true}
                                          community={community}
                                          month={month}
                                          year={
                                            planification && planification.year
                                          }
                                          maintenance={item}
                                        />
                                      )}
                                    </TableCell>
                                  ))
                                }
                                <TableCell align="center">
                                  <a
                                    href={`/maintenances/${item.uuid}`}
                                    target="_blank"
                                  >
                                    <SuiBadgeTs
                                      badgeContent={<FaEye />}
                                      color={"primary"}
                                      variant="gradient"
                                      container
                                    />
                                  </a>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                      {planification &&
                        planification.categories.map((item, i) => (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={1}></TableCell>

                              <TableCell align="center" colSpan={12}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                  {item.name}
                                </SuiTypography>
                              </TableCell>
                              <TableCell align="center" colSpan={1}></TableCell>
                              <TableCell align="center" colSpan={1}></TableCell>
                              <TableCell align="center" colSpan={1}>
                                {isPermissionEnabled(
                                  "planification-createItem"
                                ) && (
                                  <CreateItemCategory
                                    category={item.uuid}
                                    planification={planification}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                            {anualPlanificationResponse.data &&
                              anualPlanificationResponse.data.items &&
                              anualPlanificationResponse.data.items
                                .filter(
                                  (planificationItem) =>
                                    planificationItem.category.uuid == item.uuid
                                )
                                .map((subItem, x) => (
                                  <TableRow key={x}>
                                    <TableCell align="center">
                                      {subItem.title}
                                    </TableCell>

                                    <TableCell align="center">
                                      {subItem.service_provider &&
                                        (subItem.service_provider
                                          .executive_name ||
                                          subItem.service_provider
                                            .razon_social)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {parseFloat(
                                        subItem.budget
                                      ).toLocaleString("es-CL", {
                                        style: "currency",
                                        currency: "CLP",
                                      })}{" "}
                                      {subItem.currency}
                                    </TableCell>
                                    {Array.from(
                                      { length: 12 },
                                      (_, i) => i + 1
                                    ).map((month, i) => (
                                      <TableCell align="center" key={i}>
                                        {getObjectStatus(
                                          subItem.visits,
                                          month,
                                          planification && planification.year
                                        ) === 1 ? (
                                          <SuiBadge
                                            badgeContent={
                                              <AiOutlineCheckCircle />
                                            }
                                            onClick={() =>
                                              setVisit(
                                                getObjectByMonth(
                                                  subItem.visits,
                                                  month,
                                                  planification &&
                                                    planification.year
                                                )
                                              )
                                            }
                                            color={"success"}
                                            variant="gradient"
                                            style={{ cursor: "pointer" }}
                                            container
                                          />
                                        ) : getObjectStatus(
                                            subItem.visits,
                                            month,
                                            planification && planification.year
                                          ) === 0 ? (
                                          <SuiBadge
                                            badgeContent={<MdErrorOutline />}
                                            onClick={() =>
                                              setVisit(
                                                getObjectByMonth(
                                                  subItem.visits,
                                                  month,
                                                  planification &&
                                                    planification.year
                                                )
                                              )
                                            }
                                            color={"error"}
                                            variant="gradient"
                                            style={{ cursor: "pointer" }}
                                            container
                                          />
                                        ) : getObjectStatus(
                                            subItem.visits,
                                            month,
                                            planification && planification.year
                                          ) === 2 ? (
                                          <SuiBadge
                                            badgeContent={<AiOutlineWarning />}
                                            onClick={() =>
                                              setVisit(
                                                getObjectByMonth(
                                                  subItem.visits,
                                                  month,
                                                  planification &&
                                                    planification.year
                                                )
                                              )
                                            }
                                            color={"warning"}
                                            variant="gradient"
                                            style={{ cursor: "pointer" }}
                                            container
                                          />
                                        ) : (
                                          <CreateVisit
                                            anualPlanification={true}
                                            community={community}
                                            month={month}
                                            year={
                                              planification &&
                                              planification.year
                                            }
                                            planificationItem={subItem}
                                          />
                                        )}
                                      </TableCell>
                                    ))}
                                    <TableCell align="center">
                                      {isPermissionEnabled(
                                        "planification-editItem"
                                      ) && (
                                        <CreateItemCategory
                                          edit={true}
                                          item={subItem}
                                          category={item.uuid}
                                          planification={planification}
                                        />
                                      )}
                                      {isPermissionEnabled(
                                        "planification-deleteItem"
                                      ) && (
                                        <Tooltip
                                          title="Eliminar Item"
                                          placement="top"
                                        >
                                          <SuiBadge
                                            badgeContent={<MdDelete />}
                                            color={"error"}
                                            variant="gradient"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              Swal.fire({
                                                title:
                                                  "¿Quieres eliminar este item?",
                                                showDenyButton: true,
                                                confirmButtonText: "Si",
                                                denyButtonText: `No`,
                                              }).then((result) => {
                                                /* Read more about isConfirmed, isDenied below */
                                                if (result.isConfirmed) {
                                                  dispatch(
                                                    deletePlanificationItem(
                                                      subItem.uuid
                                                    )
                                                  );
                                                  Swal.fire(
                                                    "Eliminado",
                                                    "",
                                                    "success"
                                                  );
                                                } else if (result.isDenied) {
                                                }
                                              })
                                            }
                                            container
                                          />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        ))}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          )}
          <Card sx={{ padding: 5, marginTop: 3 }}>
            <SuiTypography variant="h6" fontWeight="medium">
              Presupuesto
            </SuiTypography>
            <Divider />
            <SuiBox
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {parseFloat(
                anualPlanificationResponse.data &&
                  anualPlanificationResponse.data.total_budget_clp
              ).toLocaleString("es-CL")}{" "}
              CLP + UF:{" "}
              {parseFloat(
                anualPlanificationResponse.data &&
                  anualPlanificationResponse.data.total_budget_uf
              ).toLocaleString("es-CL")}
            </SuiBox>
          </Card>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default AnualPlanifiaction;
