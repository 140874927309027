import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import { SuiButtonTs } from "components/SuiButton";
import React, { useRef } from "react";
import CreateEmergencyForm from "./CreateEmergencyForm";
import { MaintenanceModel } from "api/services/maintenances/models";
import { Box } from "@mui/material";
import useEventListener from "lib/events/useEventListener";
import useMediaScreen from "utils/Hooks/useMedia";

const CreateEmergencyButton: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const { isMobile } = useMediaScreen();
  const modalRef = useRef<ModalRefHandle>();
  const onClick = () => {
    modalRef.current.open();
  };

  useEventListener("emergencyCreated", () => {
    modalRef.current.close();
  });

  return (
    <>
      <SuiButtonTs
        color={"success"}
        variant={"gradient"}
        size="small"
        onClick={onClick}
      >
        Nueva Emergencia
      </SuiButtonTs>
      <BaseModal ref={modalRef}>
        <Box p={2} width={isMobile ? "100vw" : 500}>
          <CreateEmergencyForm maintenance={maintenance} />
        </Box>
      </BaseModal>
    </>
  );
};

export default CreateEmergencyButton;
