import { types, API_URL } from "./types";
import Axios from "axios";
import { setNotification } from "./communities";

export const getDashboard = (params) =>
{
    const url = `${API_URL}/analytics/manager-dashboard/general`;

    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url, { params })
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setGetDashboard(outputData));
            })
            .catch((err) => { });
    };
};

export const setGetDashboard = (data) => ({
    type: types.setGetDashboard,
    payload: data,
});


export const getSupervisorDashboard = (params) =>
{
    const url = `${API_URL}/analytics/supervisor-dashboard/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.get(url, { params })
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setGetSupervisorDashboard(outputData));
            })
            .catch(() => { });
    };
}

export const setGetSupervisorDashboard = (data) => ({
    type: types.setGetSupervisorDashboard,
    payload: data,
});


export const getItemsByPlanification = (d) =>
{
    const url = `${API_URL}/analytics/planification/${d}/items/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.get(url)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setGetItemsByPlanifications(outputData));
            })
            .catch(() => { });
    };
}

export const setGetItemsByPlanifications = (data) => ({
    type: types.setGetItemsByPlanifications,
    payload: data,
});


export const createPlanificationItem = (uuid, d) =>
{
    const url = `${API_URL}/analytics/planification/${uuid}/item/create/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.post(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setCreatePlanificationItem(outputData));
                let notification = { status: "success", title: "Item creado", message: "El item se ha creado correctamente." };
                dispatch(setNotification(notification));
            })
            .catch(() => { });
    };
}


export const setCreatePlanificationItem = (data) => ({
    type: types.setCreatePlanificationItem,
    payload: data,
});


export const editPlanificationItem = (uuid, d) =>
{
    const url = `${API_URL}/analytics/planification/item/${uuid}/edit/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.put(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setEditPlanificationItem(outputData));
                let notification = { status: "warning", title: "Item editado", message: "El item se ha editado correctamente." };
                dispatch(setNotification(notification));
            })
            .catch(() => { });
    };
}

export const setEditPlanificationItem = (data) => ({
    type: types.setEditPlanificationItem,
    payload: data,
});


export const deletePlanificationItem = (uuid) =>
{
    const url = `${API_URL}/analytics/planification/item/${uuid}/delete/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.delete(url)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setDeletePlanificationItem(outputData));

                let notification = { status: "error", title: "Item eliminado", message: "El item se ha eliminado correctamente." };
                dispatch(setNotification(notification));

            })
            .catch(() => { });
    };
}

export const setDeletePlanificationItem = (data) => ({
    type: types.setDeletePlanificationItem,
    payload: data,
});


export const getPlanificationCategory = () =>
{
    const url = `${API_URL}/analytics/planification/category/`;
    console.log(url)

    return (dispatch) =>
    {
        let outputData = {};

        Axios.get(url)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;
                dispatch(setGetPlanificationCategory(outputData));
            })
            .catch(() => { });
    };
}


export const setGetPlanificationCategory = (data) => ({
    type: types.setGetPlanificationCategory,
    payload: data,
});


export const createPlanificationCategory = (d) =>
{
    const url = `${API_URL}/analytics/planification/category/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.post(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;

                dispatch(setCreatePlanificationCategory(outputData));

                let notification = { status: "success", title: "Categoría creada", message: "La categoría se ha creado correctamente." };
                dispatch(setNotification(notification));

            })
            .catch(() => { });
    };
}


export const setCreatePlanificationCategory = (data) => ({
    type: types.setCreatePlanificationCategory,
    payload: data,
});


export const editPlanificationCategory = (d) =>
{
    const url = `${API_URL}/analytics/planification/category/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.put(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;

                dispatch(setEditPlanificationCategory(outputData));

                let notification = { status: "warning", title: "Categoría editada", message: "La categoría se ha editado correctamente." };
                dispatch(setNotification(notification));

            })
            .catch(() => { });
    };
}


export const setEditPlanificationCategory = (data) => ({
    type: types.setEditPlanificationCategory,
    payload: data,
});


export const deletePlanificationCategory = (uuid) =>
{
    const url = `${API_URL}/analytics/planification/category/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.delete(url, { data: { uuid: uuid } })
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                outputData["message"] = data.message;

                dispatch(setDeletePlanificationCategory(outputData));

                let notification = { status: "error", title: "Categoría eliminada", message: "La categoría se ha eliminado correctamente." };
                dispatch(setNotification(notification));
            })
            .catch(() => { });
    };
}


export const setDeletePlanificationCategory = (data) => ({
    type: types.setDeletePlanificationCategory,
    payload: data,
});


export const getHelpCenter = () =>
{
    const url = `${API_URL}/help-center/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.get(url)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;

                dispatch(setGetHelpCenter(outputData));
            })
            .catch(() => { });
    };
}


export const setGetHelpCenter = (data) => ({
    type: types.setGetHelpCenter,
    payload: data,
});



export const getPLanifications = (d) =>
{
    const url = `${API_URL}/analytics/planifications/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.get(url, { params: d })
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;

                dispatch(setGetPLanifications(outputData));
            })
            .catch(() => { });
    };
}

export const setGetPLanifications = (data) => ({
    type: types.setGetPLanifications,
    payload: data,

})


/*

POST: /analytics/planification/toggle-category/
body:
{
  "planification": "02a9707c-f0bf-4054-aa06-db3e47556a66",
  "category": "e525a567-a827-4926-9793-c42b5f9399d1"
}
*/

export const togglePlanificationCategory = (d) =>
{
    const url = `${API_URL}/analytics/planification/toggle-category/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.post(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;

                dispatch(setTogglePlanificationCategory(outputData));

                let notification = { status: "success", title: "Categoría agregada", message: "La categoría se ha agregado correctamente." };
                dispatch(setNotification(notification));
            })
            .catch(() => { });
    };
}

export const setTogglePlanificationCategory = (data) => ({
    type: types.setTogglePlanificationCategory,
    payload: data,

})


export const createPlanification = (d) =>
{
    const url = `${API_URL}/analytics/planifications/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.post(url, d)
            .then(data =>
            {
                outputData["data"] = data.data;
                outputData["status"] = data.status;
                let notification = {
                    status: "success",
                    title: "Planificación creada",
                    message: "Planificación creada con éxito",
                };
                dispatch(setNotification(notification));
                dispatch(setCreatePlanification(outputData));


            })
            .catch(() => { });
    };
}

export const setCreatePlanification = (data) => ({
    type: types.setCreatePlanification,
    payload: data,
})


export const postExportAnualPlanification = (d) =>
{
    const url = `${API_URL}/analytics/planifications/export/`;

    return (dispatch) =>
    {
        let outputData = { data: true };

        Axios.post(url, d, { responseType: 'arraybuffer' })
            .then(res =>
            {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'plan_anual.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                dispatch(setPostExportAnualPlanification(outputData));


            })
            .catch(() => { });
    };
}

export const setPostExportAnualPlanification = (data) => ({
    type: types.setPostExportAnualPlanification,
    payload: data,
})