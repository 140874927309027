import { EmergencyModel } from "api/services/emergencies/models";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { useRef, useState } from "react";
import EditEmergencyForm from "./EditeEmergencyForm";
import { Box } from "@mui/material";
import useMediaScreen from "utils/Hooks/useMedia";

const EditEmergencyModal = () => {
  const { isMobile } = useMediaScreen();
  const modalRef = useRef<ModalRefHandle>();

  const [emergency, setEmergency] = useState<EmergencyModel>();

  useEventListener("editEmergency", (data: EmergencyModel) => {
    setEmergency(data);
    modalRef.current.open();
  });

  useEventListener("emergencyUpdated", () => {
    modalRef.current.close();
  });
  return (
    <BaseModal ref={modalRef}>
      <Box p={2} width={isMobile ? "100vw" : 500}>
        <h2>Editar emergencia</h2>
        <EditEmergencyForm emergency={emergency} />
      </Box>
    </BaseModal>
  );
};

export default EditEmergencyModal;
