/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import esLocale from "@fullcalendar/core/locales/es";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

function Calendar({ header, ...rest }) {
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: el.completed ? `event-success` : "event-info",
      }))
    : [];

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pt={2} px={2} lineHeight={1}>
        {header.title ? (
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </SuiTypography>
        ) : null}
        {header.date ? (
          <SuiTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </SuiTypography>
        ) : null}
      </SuiBox>
      <CalendarRoot p={2}>
        <FullCalendar
          // locale={esLocale}
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          themeSystem={"bootstrap"}
          eventLimit={true}
          dayMaxEventsRows={true}
          views={{
            dayGrid: {
              dayMaxEventRows: 3, // adjust to 6 only for timeGridWeek/timeGridDay
            },
          }}
          height="100%"
          headerToolbar={{
            start: "title",
            center: "dayGridMonth,timeGridWeek",
            end: "today prev,next",
          }}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
