import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import CreateReminder from "./Create";
import Reminder from "./Reminder";
import Pagination from "@mui/material/Pagination"; // Importa el componente Pagination de Material UI

import { mobileMaxWidth } from "utils";

function Reminders(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [remindersPerPage] = useState(3); // Ajusta esto según cuántos elementos quieras por página

  // Calcula los índices de los reminders que deben ser mostrados en la página actual
  const indexOfLastReminder = currentPage * remindersPerPage;
  const indexOfFirstReminder = indexOfLastReminder - remindersPerPage;
  const currentReminders = props.reminders.slice(indexOfFirstReminder, indexOfLastReminder);

  // Cambia la página actual
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Calcula el número total de páginas
  const pageCount = Math.ceil(props.reminders.length / remindersPerPage);

  const renderContent = () => {
    return (
      <SuiBox p={window.innerWidth <= mobileMaxWidth ? 0 : 4} pt={3}>
        <SuiBox style={{ display: "flex", justifyContent: "space-between" }}>
          <SuiTypography variant="h4" fontWeight="medium">
            Notas
          </SuiTypography>
          <CreateReminder />
        </SuiBox>

        <Grid container spacing={3}>
          {currentReminders.length === 0 && (
            <Grid item xs={12}>
              <SuiTypography variant="body2" fontWeight="medium" textAlign="center">
                No hay notas
              </SuiTypography>
            </Grid>
          )}
          {currentReminders.map((reminder, index) => {
            return <Reminder key={index} reminder={reminder} />;
          })}
        </Grid>
        {/* Añade la paginación debajo del Grid */}
        <SuiBox display="flex" justifyContent="center" mt={3}>
          <Pagination count={pageCount} page={currentPage} onChange={handleChangePage} />
        </SuiBox>
      </SuiBox>
    );
  };

  return <Card>{renderContent()}</Card>;
}

export default Reminders;
