import React from "react";
import { Box, Stack } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import { usePermissions } from "stores/authentication/helpers";
import CreateVisitsButton from "./CreateVisitsButton";
import CreateRegenerateVisitsButton from "./CreateRecurrentVisitsButton";
import VisitsTable from "./VisitView/VisistsTable";

interface Props {
  maintenance: MaintenanceModel;
}

const VisitsTab: React.FC<Props> = ({ maintenance }) => {
  const canCreateVisit = usePermissions().visits.create;

  return (
    <div>
      <Box display={"flex"} justifyContent={"flex-end"}>
        {canCreateVisit && (
          <Stack spacing={2}>
            <CreateVisitsButton maintenance={maintenance} />
            <CreateRegenerateVisitsButton maintenance={maintenance} />
          </Stack>
        )}
      </Box>

      <Box mt={2}>
        <VisitsTable maintenance={maintenance} />
      </Box>
    </div>
  );
};

export default VisitsTab;
