import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import React, { useState } from "react";
import useMediaScreen from "utils/Hooks/useMedia";

interface TabProps {
  label: string;
  content: React.ReactNode;
}
const SwappiTabs: React.FC<{ tabs: TabProps[] }> = ({ tabs }) => {
  const { isMobile } = useMediaScreen();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleChangeTab = (event, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        orientation={isMobile ? "vertical" : "horizontal"}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map((item) => (
          <Tab label={item.label} key={item.label} />
        ))}
      </Tabs>
      {tabs.map((item, idx) => (
        <TabPanel value={currentTab} index={idx} key={idx}>
          {item.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SwappiTabs;
