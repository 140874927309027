import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiButton from "components/SuiButton";
import { getPermission } from "utils";
import { mobileMaxWidth } from "utils";
import { isPermissionEnabled } from "utils";
import SuiBadge from "components/SuiBadge";
import { MdEdit, MdPlusOne } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "40%",
  p: 0,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "100%",
  p: 0,
};

export default function CreateItemCategory(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ marginBottom: props.edit ? 3 : 0 }}>
      {props.edit ? (
        <Tooltip title="Editar item" placement="top">
          <SuiBadge
            badgeContent={<MdEdit />}
            container
            style={{ cursor: "pointer" }}
            color="warning"
            variant="gradient"
            onClick={handleOpen}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Agregar item" placement="top">
          <SuiBadge
            badgeContent={<FaPlus />}
            container
            style={{ cursor: "pointer" }}
            color="success"
            variant="gradient"
            onClick={handleOpen}
          />
        </Tooltip>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <Form
            close={handleClose}
            category={props.category}
            planification={props.planification}
            edit={props.edit}
            item={props.item}
          />
        </Box>
      </Modal>
    </div>
  );
}
