import React, { FC, useEffect, useState } from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { Edit, EditOff } from '@mui/icons-material';
import HTMLReactParser from 'html-react-parser';
import { TicketModel } from 'api/services/tickets/models';
import { useForm } from 'react-hook-form';
import RichTextEditorControlled from 'components/forms/controlled/RichTextEditorControlled';
import SuiEditorRoot from 'components/SuiEditor/SuiEditorRoot';
import { SuiButtonTs } from 'components/SuiButton';
import { useUpdateTicket } from 'api/services/tickets/mutations/UpdateTicket';
import { usePermissions } from 'stores/authentication/helpers';

export interface TicketCommentCardProps {
  ticket: TicketModel;
}

interface FormData {
  comment: string;
}

const DescriptionCard: FC<TicketCommentCardProps> = ({ ticket }) => {
  const { canEditTask } = usePermissions();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutate: updateTicket } = useUpdateTicket({ uuid: ticket.uuid });
  const {
    control,
    reset: resetForm,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormData>();

  useEffect(() => {
    resetForm({
      comment: ticket.comment,
    });
  }, [ticket]);

  const onSubmit = handleSubmit((data) => {
    updateTicket(
      {
        comment: data.comment.trim(),
      },
      {
        onSuccess() {
          setIsEditing(false);
        },
      }
    );
  });

  const handleEnableEdit = () => {
    setIsEditing(true);
  };
  const handleDisableEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  return (
    <Card sx={{ p: 2, overflow: 'visible' }}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mb={1}>
        <Box>
          <Typography variant="h4">Descripción</Typography>
        </Box>
        {canEditTask && (
          <Box>
            <IconButton onClick={isEditing ? handleDisableEdit : handleEnableEdit}>
              {isEditing ? <EditOff /> : <Edit />}
            </IconButton>
          </Box>
        )}
      </Box>
      {!isEditing ? (
        <Box
          onClick={canEditTask ? handleEnableEdit : () => {}}
          sx={{
            p: 2,
            px: 5,
            border: '1px solid #C7C7C6',
            backgroundColor: '#F4F4F4',
            borderRadius: 2,
            minHeight: 100,
          }}
        >
          <SuiEditorRoot>{HTMLReactParser(ticket.comment)}</SuiEditorRoot>
        </Box>
      ) : (
        <Box>
          <Box>
            <RichTextEditorControlled control={control} name={'comment'} />
          </Box>
          <Box display={'flex'} justifyContent={'end'} mt={2}>
            <SuiButtonTs
              size="small"
              color="error"
              sx={{ mr: 1 }}
              variant="gradient"
              onClick={handleDisableEdit}
            >
              Cancelar
            </SuiButtonTs>
            <SuiButtonTs
              size="small"
              color="success"
              variant="gradient"
              disabled={!isDirty}
              onClick={onSubmit}
            >
              Guardar
            </SuiButtonTs>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default DescriptionCard;
