import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import useUpdateMaintenance from "api/services/maintenances/mutations/updateMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";

import { useGetProviders } from "api/services/providers/queries/getProviders";
import { SuiButtonTs } from "components/SuiButton";
import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import SelectControlled from "components/forms/controlled/SelectControlled";
import { UUID } from "crypto";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { scheme } from "./helper";
import { format } from "date-fns";

const periodicityOptions = [
  { label: "Diario", value: "diario" },
  { label: "Semanal", value: "semanal" },
  { label: "Mensual", value: "mensual" },
  { label: "Bimestral", value: "bimestral" },
  { label: "Trimestral", value: "trimestral" },
  { label: "Cuatrimestral", value: "cuatrimestral" },
  { label: "Semestral", value: "semestral" },
  { label: "Anual", value: "anual" },
];

interface Props {
  maintenance: MaintenanceModel;
  onSuccess: () => void;
}
const EditMaintenanceForm: React.FC<Props> = ({ maintenance, onSuccess }) => {
  const { dataAsOptions: providersOptions } = useGetProviders();
  const { mutate: updateMaintenance, isPending } = useUpdateMaintenance({
    uuid: maintenance.uuid,
  });
  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    reset({
      start_date: [maintenance.start_date],
      end_date: [maintenance.end_date],
      provider: maintenance.service_provider?.uuid || "",
      periodicity: maintenance.period || "",
    });
  }, [maintenance]);

  const onSubmit = handleSubmit((data) => {
    updateMaintenance(
      {
        start_date:
          data.start_date[0] && format(data.start_date[0], "yyyy-MM-dd"),
        end_date: data.end_date[0]
          ? format(data.end_date[0], "yyyy-MM-dd")
          : null,
        provider_uuid: data.provider as UUID,
        period: data.periodicity,
      },
      { onSuccess }
    );
  });
  return (
    <Box minWidth={400}>
      <Typography>Editar mantención</Typography>
      <form onSubmit={onSubmit}>
        <DatePickerControlled
          control={control}
          label="Fecha de inicio"
          name="start_date"
          options={{
            maxDate: watch("end_date")?.[0] && new Date(watch("end_date")[0]),
            enableTime: false,
          }}
        />
        <DatePickerControlled
          control={control}
          label="Fecha de expiración"
          name="end_date"
          options={{
            minDate:
              watch("start_date")?.[0] && new Date(watch("start_date")[0]),
            enableTime: false,
          }}
        />
        <SelectControlled
          control={control}
          label="Periodicidad"
          name="periodicity"
          options={periodicityOptions}
        />
        <SelectControlled
          control={control}
          label="Proveedor"
          name="provider"
          options={[{ label: "Sin proveedor", value: "" }, ...providersOptions]}
        />
        <Box display={"flex"} justifyContent={"end"} mt={2}>
          <SuiButtonTs
            disabled={!isDirty || !isValid || isPending}
            size="small"
            color="success"
            variant="gradient"
            type="submit"
          >
            Guardar
          </SuiButtonTs>
        </Box>
      </form>
    </Box>
  );
};

export default EditMaintenanceForm;
