import { Box } from "@mui/material";
import SuiEditorRoot from "components/SuiEditor/SuiEditorRoot";
import React, { FC, useMemo } from "react";
import ReactQuill from "react-quill";

interface Props {
  areaHeight?: string | number;
  [key: string]: any;
}
const RichTextEditor: FC<Props> = ({ areaHeight = 100, ...rest }) => {
  const area = useMemo(() => <Box minHeight={areaHeight} />, [areaHeight]);
  return (
    <SuiEditorRoot>
      <ReactQuill theme="snow" {...rest}>
        {area}
      </ReactQuill>
    </SuiEditorRoot>
  );
};

export default RichTextEditor;
