import React, { FC, useMemo } from 'react';
import Card from '@mui/material/Card';

import Checklist from '../../../../ChecklistsComponents/Checklist';
import { Box, Stack, Typography } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';

import { ChecklistModel } from 'api/services/checklist/models';
import { TicketQueryHelper } from 'api/services/tickets/queries/GetTicket';
import { usePermissions } from 'stores/authentication/helpers';

interface Props {
  ticket: TicketModel;
}
const ActivitiesChecklist: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();
  const checklists = useMemo(() => ticket.checklists || [], [ticket]);
  const onEditChecklist = (updatedChecklist: ChecklistModel) => {
    TicketQueryHelper.updateChecklist(updatedChecklist, ticket.uuid);
  };
  const onDeleteChecklist = (deletedChecklist: ChecklistModel) => {
    TicketQueryHelper.deleteChecklist(deletedChecklist.uuid, ticket.uuid);
  };
  return (
    <Box>
      <Card>
        <Box p={3}>
          <Typography mb={2} variant="h4" fontWeight="bold" textTransform="capitalize">
            Checklists
          </Typography>
          <Stack spacing={2}>
            {checklists.map((checklist, key) => (
              <Checklist
                key={key}
                checklist={checklist}
                objectUUID={ticket.uuid}
                editEnabled={canEditTask}
                onEdit={onEditChecklist}
                onDelete={onDeleteChecklist}
              />
            ))}
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};

export default ActivitiesChecklist;
