import { Box } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import { VisitModel } from "api/services/visits/models";
import { SuiTypographyTs } from "components/SuiTypography";
import React from "react";
import { dateToLocale } from "utils";
const VisitDetails: React.FC<{
  visit: VisitModel;
}> = ({ visit }) => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <SuiTypographyTs
        variant="h6"
        fontWeight="medium"
        textTransform="capitalize"
      >
        Inicio:{" "}
        <SuiTypographyTs variant="button" fontWeight="regular" color="text">
          {dateToLocale(visit.start)}
        </SuiTypographyTs>
      </SuiTypographyTs>
      <SuiTypographyTs
        variant="h6"
        fontWeight="medium"
        textTransform="capitalize"
      >
        Fin:{" "}
        <SuiTypographyTs variant="button" fontWeight="regular" color="text">
          {dateToLocale(visit.end)}
        </SuiTypographyTs>
      </SuiTypographyTs>
    </Box>
    {visit.completed && (
      <Box>
        <Box>
          <SuiTypographyTs
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            Comentario
          </SuiTypographyTs>
        </Box>
        <Box>
          <SuiTypographyTs variant="button" fontWeight="regular" color="text">
            {visit.comment}
          </SuiTypographyTs>
        </Box>
      </Box>
    )}
  </Box>
);

export default VisitDetails;
