import React from 'react';
import { Box, Divider, Modal, Typography } from '@mui/material';
import { useVisitReportModalStore } from './store';
import SimpleHTMLRenderer from 'components/HTMLRenderer';

import useMediaScreen from 'utils/Hooks/useMedia';
import { GetVisitReportDetail } from 'api/services/visits/queries';
import VisitReport from 'layouts/pages/calendar/report';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90vh',
  borderRadius: 5,
  p: 4,
  overflow: 'scroll',
};

const textBoxStyle = {
  bgcolor: 'whitesmoke',
  border: '#e3e3e3 1px solid',
  borderRadius: 2,
  p: 2,
  mt: 1,
};

const VisitsReportModal = () => {
  const { visitReport, isModalOpen, closeModal } = useVisitReportModalStore();

  // const { data: reportDetail } = GetVisitReportDetail({ uuid: visitReport?.uuid }, isModalOpen);
  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {visitReport && isModalOpen && <VisitReport uuid={visitReport.uuid} public={false} />}
        {/* <Box>
          <Typography variant="h3" fontWeight="medium">
            {visitReport?.title}
          </Typography>
          <Typography variant="h6" fontWeight="light">
            {visitReport?.community.name}
          </Typography>
        </Box>

        <Divider />
        <Box>
          <Typography variant="h4" fontWeight="medium">
            Descripción
          </Typography>
          <Box sx={textBoxStyle}>
            <SimpleHTMLRenderer htmlString={visitReport?.body} />
          </Box>
          <Typography variant="h4" fontWeight="medium" mt={2}>
            Checklists
          </Typography>
          <Box>
            {reportDetail.checklists.map((checklist, i) => (
              <Checklist checklist={checklist} key={i} public={false} />
            ))}
          </Box>
          <Typography fontWeight="medium" component="h4" mt={2}>
            Imágenes
          </Typography>
        </Box> */}
      </Box>
    </Modal>
  );
};

export default VisitsReportModal;
