import { useQuery } from "@tanstack/react-query";
import { UserImage } from "../models";
import apiClient from "api/config/axios";
import semaphore from "api/utils/Semaphore";

export const GET_PROFILE_KEY = "get_user_image";

const useGetUserImage = (uuid: string) => {
  const query = useQuery({
    staleTime: 1000 * 60 * 5, // 5 minutos
    enabled: !!uuid, // Solo habilitar si hay un uuid válido
    queryKey: [GET_PROFILE_KEY, uuid],
    queryFn: async () => {
      await semaphore.acquire(); // Adquirir un "permiso" del semáforo
      try {
        const url = `users/${uuid}/profile-picture/`;
        const profile = await apiClient
          .get<UserImage>(url)
          .then((res) => res.data);
        return profile;
      } catch (error) {
        // Manejo de errores opcional
        throw new Error("Error fetching user image");
      } finally {
        semaphore.release(); // Liberar el "permiso" del semáforo siempre, incluso si ocurre un error
      }
    },
  });

  const data = query.data?.url;

  return { ...query, data };
};

export default useGetUserImage;
