import { ISODateString } from 'types/models';

export function timeSince(modifiedDate: ISODateString): string {
  const now = new Date();
  const modified = new Date(modifiedDate);

  const diffInMilliseconds = now.getTime() - modified.getTime();
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  if (diffInYears >= 1) {
    return diffInYears === 1 ? 'hace 1 año' : `hace ${diffInYears} años`;
  } else if (diffInMonths >= 1) {
    return diffInMonths === 1 ? 'hace 1 mes' : `hace ${diffInMonths} meses`;
  } else if (diffInDays >= 1) {
    return diffInDays === 1 ? 'hace 1 día' : `hace ${diffInDays} días`;
  } else if (diffInHours >= 1) {
    return diffInHours === 1 ? 'hace 1 hora' : `hace ${diffInHours} horas`;
  } else if (diffInMinutes >= 1) {
    return diffInMinutes === 1 ? 'hace 1 minuto' : `hace ${diffInMinutes} minutos`;
  } else {
    return diffInSeconds === 1 ? 'hace 1 segundo' : `hace ${diffInSeconds} segundos`;
  }
}
export function validateDates(startDate: ISODateString, endDate: ISODateString): boolean {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return start < end;
}
