import { queryClient } from 'api/config/queryClient';
import { types, API_URL } from './types';
import Axios from 'axios';
import QUERY_KEYS from 'api/utils/keys';
import { GET_MAINTENANCES } from 'api/services/maintenances/queries/getMaintenancesList';

export const getMaintenance = (uuid) => {
  const url = `${API_URL}/maintenance/${uuid}`;
  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then((res) => {
      outputData['data'] = res.data;
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      console.log({ url, data: res.data });
      dispatch(setGetMaintenance(outputData));
    });
  };
};
export const getPublicMaintenance = (uuid) => {
  const url = `${API_URL}/maintenance/${uuid}`;
  return (dispatch) => {
    let outputData = {};

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        outputData['data'] = data;
        outputData['status'] = data.status; // Note: Fetch doesn't provide a 'message' property directly
        outputData['message'] = data.statusText; // Assuming `statusText` is what you meant by `message`
        dispatch(setGetMaintenance(outputData));
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
      });
  };
};

export const setGetMaintenance = (data) => ({
  type: types.setGetMaintenance,
  payload: data,
});

export const getMaintenanceByCommunity = (communityId) => {
  const url = `${API_URL}/community/${communityId}/maintenances/`;
  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then((res) => {
      outputData['data'] = res.data;
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      dispatch(setGetMaintenanceByCommunity(outputData));
    });
  };
};

export const setGetMaintenanceByCommunity = (data) => ({
  type: types.setGetMaintenanceByCommunity,
  payload: data,
});

export const createMaintenance = (d) => {
  const url = `${API_URL}/maintenance/new/`;

  return (dispatch) => {
    let outputData = {};

    Axios.post(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Mantención no creado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La mantención ha sido creada con éxito',
          title: 'Mantención creada',
        };
        dispatch(setNotification(notification));
        dispatch(setCreateMaintenance(outputData));
        queryClient.invalidateQueries([GET_MAINTENANCES]);
      }
    });
  };
};

export const setCreateMaintenance = (data) => ({
  type: types.setCreateMaintenance,
  payload: data,
});

export const createMaintenanceVisit = (d) => {
  const url = `${API_URL}/visit/new/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d)
      .then((res) => {
        if (res.data.error) {
          let notification = {
            status: 'error',
            message: res.data.message,
            title: 'Visita no creada',
          };
          dispatch(setNotification(notification));
        } else {
          outputData['data'] = res.data;
          outputData['status'] = res.status;
          outputData['message'] = res.message;
          let notification = {
            status: 'success',
            message: 'La visita a la mantención ha sido agendada',
            title: 'Visita agendada',
          };
          dispatch(setNotification(notification));
          dispatch(setCreateMaintenanceVisit(outputData));
        }
      })
      .catch(() => {
        let notification = {
          status: 'error',
          message: 'Debes ingresar todos los campos',
          title: 'Visita no creada',
        };
        dispatch(setNotification(notification));
      });
  };
};

export const setCreateMaintenanceVisit = (data) => ({
  type: types.setCreateMaintenanceVisit,
  payload: data,
});

export const setNotification = (data) => ({
  type: types.setNotification,
  payload: data,
});

export const editMaintenance = (slug, d) => {
  const url = `${API_URL}/maintenance/${slug}/edit`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Mantención no editada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La mantención ha sido editada con éxito',
          title: 'Mantención editada',
        };
        dispatch(setNotification(notification));
        dispatch(setEditMaintenance(outputData));
      }
    });
  };
};

export const setEditMaintenance = (data) => ({
  type: types.setEditMaintenance,
  payload: data,
});

export const deleteMaintenance = (slug) => {
  const url = `${API_URL}/maintenance/${slug}/delete`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Mantención no eliminada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La mantención ha sido eliminada con éxito',
          title: 'Mantención eliminada',
        };
        dispatch(setNotification(notification));
        dispatch(setDeleteMaintenance(outputData));
      }
    });
  };
};

export const setDeleteMaintenance = (data) => ({
  type: types.setDeleteMaintenance,
  payload: data,
});

export const createCertification = (d) => {
  const url = `${API_URL}/certification/new/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Certificación no creada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La certificación ha sido creada con éxito',
          title: 'Certificación creada',
        };
        dispatch(setNotification(notification));
        dispatch(setCreateCertification(outputData));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      }
    });
  };
};

export const setCreateCertification = (data) => ({
  type: types.setCreateCertification,
  payload: data,
});

export const deleteCertification = (slug) => {
  const url = `${API_URL}/certification/${slug}/delete/`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Certificación no eliminada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La certificación ha sido eliminada con éxito',
          title: 'Certificación eliminada',
        };
        dispatch(setNotification(notification));
        dispatch(setDeleteCertification(outputData));
      }
    });
  };
};

export const setDeleteCertification = (data) => ({
  type: types.setDeleteCertification,
  payload: data,
});

export const createEmergency = (d) => {
  const url = `${API_URL}/emergency/new/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Emergencia no creada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La emergencia ha sido creada con éxito',
          title: 'Emergencia creada',
        };
        dispatch(setNotification(notification));
        dispatch(setCreateEmergency(outputData));
      }
    });
  };
};

export const setCreateEmergency = (data) => ({
  type: types.setCreateEmergency,
  payload: data,
});

export const updateEmergency = (slug, d) => {
  const url = `${API_URL}/emergency/${slug}/edit`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Emergencia no editada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La emergencia ha sido editada con éxito',
          title: 'Emergencia editada',
        };
        dispatch(setNotification(notification));
        dispatch(setUpdateEmergency(outputData));
      }
    });
  };
};

export const setUpdateEmergency = (data) => ({
  type: types.setUpdateEmergency,
  payload: data,
});

export const deleteEmergency = (slug) => {
  const url = `${API_URL}/emergency/${slug}/delete/`;
  return (dispatch) => {
    let outputData = {};
    Axios.delete(url).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Emergencia no eliminada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La emergencia ha sido eliminada con éxito',
          title: 'Emergencia eliminada',
        };
        dispatch(setNotification(notification));
        dispatch(setDeleteEmergency(outputData));
      }
    });
  };
};

export const setDeleteEmergency = (data) => ({
  type: types.setDeleteEmergency,
  payload: data,
});

export const getMaintenancesByManagement = (slug, params) => {
  const url = `${API_URL}/management/${slug}/maintenances/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url, { params }).then((res) => {
      outputData['data'] = res.data;
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      dispatch(setGetMaintenancesByManagement(outputData));
    });
  };
};

export const setGetMaintenancesByManagement = (data) => ({
  type: types.setGetMaintenancesByManagement,
  payload: data,
});

export const getExportMaintenance = (slug, d) => {
  const url = `${API_URL}/maintenances/${slug}/export/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      outputData['data'] = { ok: true };
      dispatch(setGetExportMaintenance(outputData));
    });
  };
};

export const setGetExportMaintenance = (data) => ({
  type: types.setGetExportMaintenance,
  payload: data,
});

export const editEmergency = (slug, d) => {
  const url = `${API_URL}/emergency/${slug}/edit/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Emergencia no editada',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'La emergencia ha sido editada con éxito',
          title: 'Emergencia editada',
        };
        dispatch(setNotification(notification));
        dispatch(setEditEmergency(outputData));
      }
    });
  };
};

export const setEditEmergency = (data) => ({
  type: types.setEditEmergency,
  payload: data,
});

export const regenerateMaintenanceVisits = (d) => {
  const url = `${API_URL}/maintentance/regenerate-visits/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then((res) => {
      if (res.data.error) {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Visitas no regeneradas',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'Las visitas han sido regeneradas con éxito',
          title: 'Visitas regeneradas',
        };
        dispatch(setNotification(notification));
        dispatch(setRegenerateMaintenanceVisits(outputData));
      }
    });
  };
};

export const setRegenerateMaintenanceVisits = (data) => ({
  type: types.setRegenerateMaintenanceVisits,
  payload: data,
});

export const createMaintenanceBudget = (uuid, d) => {
  const url = `${API_URL}/maintenance/${uuid}/budgets/`;
  return (dispatch) => {
    let outputData = {};
    Axios.post(url, d).then((res) => {
      if (res.data.error) {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Presupuesto no creado',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'El presupuesto ha sido creado con éxito',
          title: 'Presupuesto creado',
        };
        dispatch(setNotification(notification));
        dispatch(setCreateMaintenanceBudget(outputData));
      }
    });
  };
};

export const setCreateMaintenanceBudget = (data) => ({
  type: types.setCreateMaintenanceBudget,
  payload: data,
});

export const editMaintenanceBudget = (uuid, d) => {
  const url = `${API_URL}/maintenance/${uuid}/budgets/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then((res) => {
      if (res.data.error) {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Presupuesto no editado',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'El presupuesto ha sido editado con éxito',
          title: 'Presupuesto editado',
        };
        dispatch(setNotification(notification));
        dispatch(setEditMaintenanceBudget(outputData));
      }
    });
  };
};

export const setEditMaintenanceBudget = (data) => ({
  type: types.setEditMaintenanceBudget,
  payload: data,
});

export const deleteMaintenanceBudget = (uuid, d) => {
  const url = `${API_URL}/maintenance/${uuid}/budgets/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, d).then((res) => {
      if (res.data.error) {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Presupuesto no eliminado',
        };
        dispatch(setNotification(notification));
      } else {
        outputData['data'] = res.data;
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        let notification = {
          status: 'success',
          message: 'El presupuesto ha sido eliminado con éxito',
          title: 'Presupuesto eliminado',
        };
        dispatch(setNotification(notification));
        dispatch(setDeleteMaintenanceBudget(outputData));
      }
    });
  };
};

export const setDeleteMaintenanceBudget = (data) => ({
  type: types.setDeleteMaintenanceBudget,
  payload: data,
});
