import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { ExportCenterFileInput } from './types';
import { parseExportCenterFileInput } from './helpers';
import { queryClient } from 'api/config/queryClient';

export const useExportCenterPdf = () =>
  useMutation({
    mutationKey: ['SetExportCenterPdf'],
    mutationFn: async (input: ExportCenterFileInput) => {
      const url = `${API_URL}/analytics/export-center/`;
      return axios
        .post(url, parseExportCenterFileInput(input), { responseType: 'arraybuffer' })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          queryClient.invalidateQueries({ queryKey: ['exportHistory'] });
          return res.data;
          
        });
    },
  });
