/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiDropzone from "components/SuiDropzone";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import SuiButton from "components/SuiButton";
import { useParams, useNavigate } from "react-router-dom";
import { createArea } from "redux/actions/communities";
import { useSelector, useDispatch } from "react-redux";

function Form({ close, buildings }) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [buildingSelected, setbuildingSelected] = useState(null);
  const [name, setName] = useState("");
  const handleCreateArea = () => {
    dispatch(
      createArea(slug, {
        name,
        building: buildingSelected,
      })
    );
    close();
  };
  return (
    <SuiBox>
      <SuiBox p={3} style={{ overflow: "show" }}>
        <SuiTypography variant="h5">Agregar área común</SuiTypography>
      </SuiBox>
      <SuiBox
        component="form"
        pb={3}
        px={3}
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateArea();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox pt={2}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Torre
              </SuiTypography>
              <SuiSelect
                placeholder="Seleccione torre"
                value={buildingSelected}
                onChange={(e) => {
                  setbuildingSelected(e.value);
                }}
                options={buildings}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Nombre del área"
              placeholder="Piscina"
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" onClick={handleCreateArea}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Form;
