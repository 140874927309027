import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Box } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import useUpdateVisit from "api/services/visits/mutations/updateVisit";
import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import InputControlled from "components/forms/controlled/InputControlled";
import { SuiButtonTs } from "components/SuiButton";
import { useForm } from "react-hook-form";
import useEventEmitter from "lib/events/useEventEmitter";
import { format } from "date-fns";

import React from "react";
import { scheme } from "./form.helper";

const CompleteVisitForm: React.FC<{ visit: VisitModel }> = ({ visit }) => {
  const { mutate: updateVisit, isPending } = useUpdateVisit({
    uuid: visit.uuid,
  });

  const visitCompleted = useEventEmitter("visitCompleted");
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(scheme),
  });

  const onSubmit = handleSubmit((data) => {
    updateVisit(
      {
        comment: data.comment,
        completed_on: format(
          data.completed_on[0],
          "yyyy-MM-dd HH:mm:ss.SSSSSSXXX"
        ),
      },
      {
        onSuccess: (data) => {
          visitCompleted.emit(data);
        },
      }
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <DatePickerControlled
          label="Fecha de visita"
          control={control}
          name="completed_on"
          options={{ maxDate: "today" }}
        />
        <InputControlled
          label="Comentario"
          placeholder="Comentar"
          control={control}
          name="comment"
          multiline={5}
        />
      </Stack>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <SuiButtonTs
          size="small"
          color="success"
          variant="gradient"
          type="submit"
          disabled={!isValid || isPending}
        >
          Confirmar
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default CompleteVisitForm;
