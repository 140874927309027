import "./css/tooltip.css";
function StandardTooltipContent({ task, fontSize, fontFamily }) {
  return (
    <div className={"tooltipDefaultContainer"}>
      <b style={{ fontSize: fontSize + 6 }}>{`${task.name}`}</b>
      {task.end.getTime() - task.start.getTime() !== 0 && (
        <p className={"tooltipDefaultContainerParagraph"}>{`Duración: ${~~(
          (task.end.getTime() - task.start.getTime()) /
          (1000 * 60 * 60 * 24)
        )} día(s)`}</p>
      )}
      <p className={"tooltipDefaultContainerParagraph"}>
        {!!task.progress &&
          `Inicio: ${task.start.getDate()}-${
            task.start.getMonth() + 1
          }-${task.start.getFullYear()}`}
      </p>
      <p className={"tooltipDefaultContainerParagraph"}>
        {!!task.progress &&
          `Fin:  ${task.end.getDate()}-${task.end.getMonth() + 1}-${task.end.getFullYear()}`}
      </p>
      <p className={"tooltipDefaultContainerParagraph"}>
        {!!task.progress && `Progreso: ${task.progress}%`}
      </p>
      <p className={"tooltipDefaultContainerParagraph"}>
        {!!task.progress && `Estado: ${task.status}`}
      </p>
      <p className={"tooltipDefaultContainerParagraph"}>
        {!!task.progress && `Prioridad: ${task.priority}`}
      </p>
    </div>
  );
}

export default StandardTooltipContent;
