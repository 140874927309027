import { useMutation } from "@tanstack/react-query";
import { IMessage } from "api/utils/queries";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { DocumentModel } from "../models";
import QUERY_KEYS from "api/utils/keys";
import { queryClient } from "api/config/queryClient";

interface Params {
  documentUUID: UUID;
  ticketUUID?: UUID;
}
interface Response extends IMessage {
  document: DocumentModel;
}
const useApproveDocument = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/documents/approve/${params.documentUUID}/`;
      return await axios.post<Response>(url).then((res) => res.data);
    },
    onSuccess: (data) => {
      if (params.ticketUUID) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.TICKET, params.ticketUUID],
        });
        // TicketQueryHelper.updateDocument(data.document, params.ticketUUID);
      }
      toastNotification.success({
        title: "Cotización aprobada",
        message: "La cotización ha sido aprobado con éxito!",
      });
    },
  });
};

export default useApproveDocument;
