import { EmergencyModel } from "api/services/emergencies/models";
import { ColumnTable } from "components/SwappiTable/types";
import React, { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";
import { parseDate } from "utils";
import EmergencyActions from "./EmergencyActions";
import { usePermissions } from "stores/authentication/helpers";
const useCreateColumnsHelper = () => {
  const canEditMaintenance = usePermissions().maintenances.delete;

  return useMemo(() => {
    const cols: ColumnTable<EmergencyModel>[] = [
      { header: "Título", accessorKey: "title", width: "20%" },
      {
        header: "Descripción",
        accessorKey: "description",
        width: "40%",
        cell: ({ getValue }) => ReactHtmlParser(getValue() as string),
      },
      {
        header: "Fecha",
        accessorKey: "created",
        width: "20%",
        cell: ({ getValue }) => parseDate(getValue() as string),
      },
    ];
    if (canEditMaintenance)
      cols.push({
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => <EmergencyActions emergency={row.original} />,
      });
    return cols;
  }, [canEditMaintenance]);
};

export default useCreateColumnsHelper;
