export class Semaphore {
  private limit: number;
  private activeCount: number;
  private queue: (() => void)[];

  constructor(limit: number) {
    this.limit = limit;
    this.activeCount = 0;
    this.queue = [];
  }

  async acquire(): Promise<void> {
    if (this.activeCount < this.limit) {
      this.activeCount++;
      return;
    }
    await new Promise<void>((resolve) => this.queue.push(resolve));
    this.activeCount++;
  }
  release(): void {
    this.activeCount--;
    if (this.queue.length > 0) {
      const resolve = this.queue.shift();
      resolve?.();
    }
  }
}

const semaphore = new Semaphore(2);
export default semaphore;
