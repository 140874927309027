import { types } from "../actions/types";

export const reportsReducer = (
    state = { createVisitReport: {}, getVisitReportPDF: {}, getVisitReport: {}, editVisitReport: {}, getVisitReports: {}, deleteVisitReport: {}, postExportVisitReports: {} },
    action
) =>
{
    switch (action.type)
    {
        case types.setCreateVisitReport:
            return {
                ...state,
                createVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitReport:
            return {
                ...state,
                getVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setEditVisitReport:
            return {
                ...state,
                editVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitReports:
            return {
                ...state,
                getVisitReports: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitReportPDF:
            return {
                ...state,
                getVisitReportPDF: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setDeleteVisitReport:

            return {
                ...state,
                deleteVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };

        case types.setPostExportVisitReports:
            return {
                ...state,
                postExportVisitReports: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        default:
            return state;
    }
};
