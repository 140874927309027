import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiButton from "components/SuiButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CreateArea(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ display: "inline-block" }}>
      <SuiButton variant="gradient" size="small" color="warning" onClick={handleOpen}>
        Editar Área Común
      </SuiButton>
      <Modal
        open={open}
        areaSelected={props.areaSelected}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={style}>
          <Form
            close={handleClose}
            areaUuid={props.selectedUuid}
            area={props.areaSelected}
            buildings={props.buildings}
          />
        </Box>
      </Modal>
    </div>
  );
}
