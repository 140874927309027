export const styleDesktop = {
  position: 'absolute',
  width: '800px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  maxHeight: '90vh',
  borderRadius: '10px',
  boxShadow: 24,
};

export const styleMobile = {
  position: 'absolute',
  width: '100%',
  height: '80vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
};
