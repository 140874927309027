import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiButton from "components/SuiButton";
import { getPermission } from "utils";
import SuiBadge from "components/SuiBadge";
import { MdViewAgenda } from "react-icons/md";
import { mobileMaxWidth } from "utils";
import { isPermissionEnabled } from "utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "40%",
  p: 0,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "100%",
  p: 0,
};

export default function CreateVisit(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={props.anualPlanification ? {} : { float: "right" }}>
      {!props.anualPlanification ? (
        getPermission(["superadmin", "supervisor", "administrador", "mayordomo", "conserje"]) && (
          <SuiButton
            variant="gradient"
            style={{ float: "right" }}
            size="small"
            color="success"
            onClick={handleOpen}
          >
            Agendar Visita
          </SuiButton>
        )
      ) : (
        <SuiBadge
          badgeContent={<MdViewAgenda />}
          color={"light"}
          variant="gradient"
          style={{ cursor: "pointer" }}
          container
          onClick={() => {
            if (isPermissionEnabled("visits-createVisit")) handleOpen();
          }}
        />
      )}

      <Modal
        style={{ overflow: "scroll" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <Form
            close={handleClose}
            maintenance={props.maintenance}
            anualPlanification={props.anualPlanification}
            month={props.month}
            community={props.community}
            year={props.year}
            planificationItem={props.planificationItem}
          />
        </Box>
      </Modal>
    </div>
  );
}
