import { CircularProgress, Tooltip } from "@mui/material";
import { useOpenDocument } from "api/services/documents/actions/openDocument";
import { SuiBadgeTs } from "components/SuiBadge";
import { UUID } from "crypto";
import React, { FC } from "react";
import { AiOutlineEye } from "react-icons/ai";
interface Props {
  uuid: UUID;
}
const DocumentUrl: FC<Props> = ({ uuid }) => {
  const { handleAction, isLoading } = useOpenDocument(uuid);

  return (
    <Tooltip title="Ver documento" placement="top">
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        container
        color="info"
        badgeContent={
          isLoading ? (
            <CircularProgress size="12px" color="inherit" />
          ) : (
            <AiOutlineEye size="12px" />
          )
        }
        onClick={handleAction}
      />
    </Tooltip>
  );
};

export default DocumentUrl;
