import AddChecklistDrawer from 'components/ChecklistsComponents/AddChecklistDrawer';
import ChecklistTemplateModal from 'components/ChecklistsComponents/AddChecklistDrawer/ChecklistTemplateModal';
import TicketModal from 'components/Tickets/TicketModal';
import React from 'react';

const ModalsAndDrawers = () => {
  return (
    <>
      <TicketModal />
      <AddChecklistDrawer />
      <ChecklistTemplateModal />
    </>
  );
};

export default ModalsAndDrawers;
