import axios from "axios";
import { API_URL } from "redux/actions/types";
import { CommunityGanttModel, CommunityModel } from "../models";
import { useQuery } from "@tanstack/react-query";
import { SelectOptionForm } from "types/forms";
import QUERY_KEYS from "api/utils/keys";

export interface Params {
  slug: string;
}

export const useGetCommunityPublicGantt = (params: Params, enabled = true) => {
  return useQuery<CommunityGanttModel>({
    enabled,
    queryKey: [QUERY_KEYS.GET_COMMUNITY_PUBLIC_GANTT],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const url = `${API_URL}/public-gantt/${params.slug}/`;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    },
  });
};
