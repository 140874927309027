import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { UUID } from 'crypto';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/actions/tickets';
import { API_URL } from 'redux/actions/types';

interface Params {
  objectUUID: UUID;
  checklistUUID: UUID;
  itemUUID: UUID;
}
const useDeleteChecklistItem = (params: Params) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/checklist-item/delete/${params.itemUUID}`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      dispatch(
        setNotification({
          status: 'warning',
          message: 'El item del checklist ha sido eliminado',
          title: 'Item eliminado',
        })
      );
    },
    onError(error, variables, context) {
      console.log({ error, variables, context });
    },
  });
};

export default useDeleteChecklistItem;
