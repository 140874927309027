import React from 'react';
import { CommentaryModel } from '../models';
import { UUID } from 'crypto';
import { useMutation } from '@tanstack/react-query';
import { API_URL } from 'redux/actions/types';
import axios from 'axios';
import { TicketQueryHelper } from '../queries/GetTicket';

interface Params {
  ticketUUID?: UUID;
  commentaryUUID: UUID;
}
interface Input {
  text: string;
}
const useEditTicketCommentary = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/comment/edit/${params.commentaryUUID}`;
      return await axios
        .put<CommentaryModel>(url, input)
        .then((res) => res.data)
        .then((data) => {
          if (params.ticketUUID) TicketQueryHelper.updateCommentary(data, params.ticketUUID);
        });
    },
  });
};

export default useEditTicketCommentary;
