/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import { FaEye } from 'react-icons/fa';

function BuildingCard({ title, count, percentage, dropdown }) {
  return (
    <Card>
      <SuiBox
        color="white"
        bgColor="light"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        opacity={1}
        p={2}
      >
        <Grid container>
          <Grid item xs={7}>
            <SuiBox mb={0.5} lineHeight={1}>
              <SuiTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Nombre
              </SuiTypography>
            </SuiBox>
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="bold">
                {title.toUpperCase()}
              </SuiTypography>
              <SuiTypography variant="button" fontWeight="bold" color={percentage.color}>
                {percentage.value}&nbsp;
                <SuiTypography variant="button" fontWeight="regular" color="secondary">
                  {percentage.label}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={5}>
            <SuiBox width="100%" textAlign="right" lineHeight={1}>
              <SuiButton
                width="100%"
                variant="outlined"
                margin="0 auto"
                size="small"
                color="info"
                onClick={() => {
                  navigate(`/communities/${slug}/new-building`);
                }}
              >
                <FaEye />
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
BuildingCard.defaultProps = {
  percentage: {
    color: 'success',
    value: '',
    label: '',
  },
  dropdown: false,
};

// Typechecking props for the DefaultStatisticsCard
BuildingCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
      value: PropTypes.string,
    }),
  ]),
};

export default BuildingCard;
