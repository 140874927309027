import { queryClient } from 'api/config/queryClient';
import { setNotification } from './communities';
import { types, API_URL } from './types';
import Axios from 'axios';
import QUERY_KEYS from 'api/utils/keys';

// create post request with axios named createPoll

export const createPoll = (data) => {
  const url = `${API_URL}/polls/create/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data)
      .then((res) => {
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        let notification = {
          status: 'success',
          message: 'La encuesta ha sido creada.',
          title: 'Votación creada',
        };
        dispatch(setNotification(notification));
        dispatch(setCreatePoll(outputData));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      })
      .catch((res) => {
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        let notification = {
          status: 'error',
          message: res.data.message,
          title: 'Votación no creada',
        };
        dispatch(setNotification(notification));
        dispatch(setCreatePoll(outputData));
      });
  };
};

// create action named setCreatePoll
export const setCreatePoll = (data) => ({
  type: types.setCreatePoll,
  payload: data,
});

// create post request for vote /api/votes/votes/create/

export const createVotePoll = (data) => {
  const url = `${API_URL}/polls/votes/create/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      let notification = {
        status: 'success',
        message: 'La encuesta ha sido votada.',
        title: 'Encuesta votada',
      };
      dispatch(setNotification(notification));
      dispatch(setCreateVote(outputData));
    });
  };
};

// create action named setCreateVote

export const setCreateVote = (data) => ({
  type: types.setCreateVote,
  payload: data,
});

export const getPollByUuid = (uuid) => {
  const url = `${API_URL}/polls/poll/${uuid}/`;

  return (dispatch) => {
    let outputData = {};

    return fetch(url)
      .then((response) => {
        outputData['status'] = response.status;
        outputData['message'] = response.statusText;

        if (response.ok) {
          return response.json().then((data) => {
            outputData['data'] = data;
            dispatch(setGetPollByUuid(outputData));
          });
        } else {
          dispatch(setGetPollByUuid(outputData));
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        dispatch(setGetPollByUuid(outputData));
      });
  };
};

export const setGetPollByUuid = (data) => ({
  type: types.setGetPollByUuid,
  payload: data,
});

export const createPublicVote = (token, data) => {
  const url = `${API_URL}/polls/votes/create/${token}/`;

  return (dispatch) => {
    let outputData = {};

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        outputData['status'] = response.status;
        outputData['message'] = response.statusText;

        if (response.ok) {
          let notification = {
            status: 'success',
            message: 'La encuesta ha sido votada.',
            title: 'Encuesta votada',
          };
          dispatch(setNotification(notification));
          return response.json().then((responseData) => {
            outputData['data'] = responseData;
            dispatch(setCreatePublicVote(outputData));
          });
        } else {
          let notification = {
            status: 'error',
            message: 'Ya votaste en esta encuesta.',
            title: 'Voto no emitido',
          };
          dispatch(setNotification(notification));
        }
      })
      .catch((error) => {
        let notification = {
          status: 'error',
          message: 'Ya votaste en esta encuesta.',
          title: 'Voto no emitido',
        };
        dispatch(setNotification(notification));
      });
  };
};

export const setCreatePublicVote = (data) => ({
  type: types.setCreatePublicVote,
  payload: data,
});

export const deletePoll = (uuid) => {
  const url = `${API_URL}/polls/delete/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.delete(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      let notification = {
        status: 'success',
        message: 'La encuesta ha sido eliminada.',
        title: 'Encuesta eliminada',
      };
      dispatch(setNotification(notification));
      dispatch(setDeletePoll(outputData));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
    });
  };
};

export const setDeletePoll = (data) => ({
  type: types.setDeletePoll,
  payload: data,
});
