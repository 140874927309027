import { types, API_URL } from './types';
import Axios from 'axios';
import { setNotification } from './communities';

export const createVisitReport = (params) => {
  const url = `${API_URL}/analytics/visit/report/`;

  return (dispatch) => {
    let outputData = {};
    Axios.post(url, params)
      .then((data) => {
        outputData['status'] = data.status;
        outputData['message'] = Date.now();
        outputData['data'] = data.data;
        dispatch(setCreateVisitReport(outputData));
      })
      .catch(() => {});
  };
};

export const setCreateVisitReport = (data) => ({
  type: types.setCreateVisitReport,
  payload: data,
});

export const getVisitReport = (uuid) => {
  const url = `${API_URL}/analytics/visit/report/${uuid}/`;
  return (dispatch) => {
    let outputData = {};

    Axios.get(url)
      .then((data) => {
        outputData['data'] = data.data;
        outputData['status'] = data.status;
        outputData['message'] = data?.message;
        dispatch(setGetVisitReport(outputData));
      })
      .catch((err) => {});
  };
};

export const setGetVisitReport = (data) => ({
  type: types.setGetVisitReport,
  payload: data,
});

export const getVisitReports = (params) => {
  const url = `${API_URL}/analytics/visit/report/all/`;
  return (dispatch) => {
    let outputData = {};
    Axios.get(url, { params })
      .then((data) => {
        outputData['data'] = data.data;
        outputData['status'] = data.status;
        outputData['message'] = data?.message;
        dispatch(setGetVisitReports(outputData));
      })
      .catch((err) => {});
  };
};

export const setGetVisitReports = (data) => ({
  type: types.setGetVisitReports,
  payload: data,
});

export const editVisitReport = (params) => {
  const url = `${API_URL}/analytics/visit/report/`;
  return (dispatch) => {
    let outputData = {};
    Axios.put(url, params)
      .then((data) => {
        outputData['status'] = data.status;
        outputData['message'] = data?.message;
        outputData['data'] = data.data;
        dispatch(setEditVisitReport(outputData));
        let notification = {
          status: 'warning',
          message: 'El reporte fue editado.',
          title: 'Reporte editado',
        };
        dispatch(setNotification(notification));
      })
      .catch((err) => {});
  };
};

export const setEditVisitReport = (data) => ({
  type: types.setEditVisitReport,
  payload: data,
});

export const getVisitReportPDF = (slug) => {
  const url = `${API_URL}/analytics/visit/report/${slug}/export/`;
  return (dispatch) => {
    return Axios.post(url, {}, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `visita-administracion-${slug}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(setGetVisitReportPDF({ status: 200, message: 'Success', data: res.data }));
    });
  };
};

export const setGetVisitReportPDF = (data) => ({
  type: types.setGetVisitReportPDF,
  payload: data,
});

export const deleteVisitReport = (uuid) => {
  const url = `${API_URL}/analytics/visit/report/${uuid}/delete`;
  return (dispatch) => {
    return Axios.delete(url)
      .then((data) => {
        let notification = {
          status: 'error',
          message: 'El reporte fue eliminado.',
          title: 'Reporte eliminado',
        };
        dispatch(setNotification(notification));
        dispatch(
          setDeleteVisitReport({ status: data.status, message: data.message, data: data.data })
        );
      })
      .catch((err) => {});
  };
};

export const setDeleteVisitReport = (data) => ({
  type: types.setDeleteVisitReport,
  payload: data,
});

export const postExportVisitReports = (params) => {
  const url = `${API_URL}/analytics/visit/reports/export/`;
  return (dispatch) => {
    return Axios.post(url, params, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `visitas-administracion.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(setPostExportVisitReports({ status: 200, message: 'Success', data: res.data }));
    });
  };
};

export const setPostExportVisitReports = (data) => ({
  type: types.setPostExportVisitReports,
  payload: data,
});
