import { CommentaryModel } from 'api/services/tickets/models';

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export function sortByModified(arr: CommentaryModel[], order: OrderBy): CommentaryModel[] {
  return arr.sort((a, b) => {
    const dateA = new Date(a.modified).getTime();
    const dateB = new Date(b.modified).getTime();

    if (order === OrderBy.ASC) {
      return dateA - dateB;
    } else if (order === OrderBy.DESC) {
      return dateB - dateA;
    } else {
      throw new Error('Invalid order parameter. Use "asc" or "desc".');
    }
  });
}
