import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import SuiInputTs from "components/SuiInput/SuiInputTs";
import { SuiSelectTs } from 'components/SuiSelect';
import { FiltersProps } from './types';
import useDebounce from 'utils/Hooks/useDebounce';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
import { checksFilters } from './constants';
import { useExportCenterFiltersStore } from '../store';

const Filters: FC<FiltersProps> = ({ setFilters }) => {
  const { dataAsOptions: communitiesOptions } = useGetCommunities();

  const [inputName, setInputName] = useState<string>('');
  const [checkedType, setCheckedType] = useState<string>('');
  const { community, setCommunity } = useExportCenterFiltersStore();

  const debounceFilters = useDebounce(() => {
    setFilters({
      name: inputName,
      communities: community,
      type: checkedType,
    });
  }, 1000);

  const controlSetFilters = useRef(false);
  useEffect(() => {
    if (controlSetFilters.current) debounceFilters();
    else controlSetFilters.current = true;
  }, [inputName, checkedType, community]);

  return (
    <Box>
      <Typography variant="h4" fontWeight="medium">
        Filtros
      </Typography>
      <Divider />
      <Box>
        <Typography variant="h6" fontWeight="medium">
          Nombre
        </Typography>
        <SuiInputTs
          placeholder="Buscar..."
          value={inputName}
          onChange={(e) => setInputName(e.target.value)}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="h6" fontWeight="medium">
          Comunidad
        </Typography>
        <SuiSelectTs
          placeholder="Comunidad"
          onChange={(e) => setCommunity(e.value)}
          value={community}
          options={[{ label: 'Todas', value: null }, ...communitiesOptions]}
        />
        <Divider />
        <Typography variant="h6" fontWeight="medium">
          Tipo de documento
        </Typography>
        {checksFilters.map((item) => {
          return (
            <Box key={item.slug} sx={{ px: 2 }}>
              <FormControlLabel
                label={item.name}
                control={
                  <Checkbox
                    checked={checkedType === item.slug}
                    onChange={(e) => setCheckedType(e.target.checked ? item.slug : '')}
                  />
                }
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Filters;
