import React, { useState } from 'react';
import { FileDownload } from '@mui/icons-material';
import { useExportCenterStore } from '../store';
import { useExportCenterPdf } from 'api/services/analytics/mutations';
import { SuiButtonTs } from 'components/SuiButton';
import { CircularProgress } from '@mui/material';

const DownloadButton = () => {
  const {
    tickets,
    maintenances,
    visits: visitsReports,
    documents,
    observationsMaintenances,
    observationsGeneral,
  } = useExportCenterStore();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useExportCenterPdf();

  const handleDownload = () => {
    setIsLoading(true);
    mutate({
      tickets,
      maintenances,
      reports: visitsReports,
      documents,
      maintenance_observations: observationsMaintenances,
      general_observations: observationsGeneral,
    }, {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };
  return (
    <SuiButtonTs
      variant="gradient"
      color="success"
      size="small"
      endIcon={<FileDownload />}
      onClick={handleDownload}
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size={16} /> : 'Descargar'}
    </SuiButtonTs>
  );
};

export default DownloadButton;
