import {
  BudgetModel,
  MaintenanceModel,
} from "api/services/maintenances/models";
import { ColumnTable } from "components/SwappiTable/types";
import { useMemo } from "react";
import { usePermissions } from "stores/authentication/helpers";
import TableActions from "./TableActions";

export const createColumnsHelper = (maintenance: MaintenanceModel) => {
  const canEditMaintenance = usePermissions().maintenances.edit;
  return useMemo(() => {
    const columns: ColumnTable<BudgetModel>[] = [
      { header: "Año", accessorKey: "year", width: "25%" },
      { header: "Moneda", accessorKey: "currency", width: "25%" },
      { header: "Presupuesto", accessorKey: "amount", width: "25%" },
    ];

    if (canEditMaintenance)
      columns.push({
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => (
          <TableActions budget={row.original} maintenance={maintenance} />
        ),
      });

    return columns;
  }, []);
};
