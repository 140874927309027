import { TicketPriority, TicketStatus } from 'api/services/tickets/models';
import { SelectOptionForm } from 'types/forms';

export const ORDER_BY_OPTIONS = [
  { value: 'none', label: 'Ninguno' },
  { value: 'modified', label: 'Modificación' },
  { value: 'title', label: 'Título' },
  { value: 'date', label: 'Fecha Término' },
  { value: 'created', label: 'Fecha Creación' },
  { value: 'status', label: 'Estado' },
  { value: 'priority', label: 'Prioridad' },
];

export const PRIORITY_OPTIONS: SelectOptionForm[] = [
  { value: TicketPriority.critical, label: 'crítica' },
  { value: TicketPriority.high, label: 'alta' },
  { value: TicketPriority.medium, label: 'media' },
  { value: TicketPriority.low, label: 'baja' },
];
export const STATUS_OPTIONS: SelectOptionForm[] = [
  { label: TicketStatus.Cotizando, value: 'cotizando' },
  { label: TicketStatus.Creada, value: 'creada' },
  { label: TicketStatus.EnProgreso, value: 'en progreso' },
  { label: TicketStatus.EnVotacion, value: 'en votación' },
  { label: TicketStatus.FinDeServicio, value: 'fin de servicio' },
  { label: TicketStatus.InicioDeServicio, value: 'inicio de servicio' },
  { label: TicketStatus.RecepcionDeServicio, value: 'recepción de servicio' },
];
