import { useMutation } from "@tanstack/react-query";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
interface Params {
  maintenanceUUID: UUID;
  budgetUUID: UUID;
}

const useDeleteMaintenanceBudget = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/maintenance/${params.maintenanceUUID}/budgets/${params.budgetUUID}`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Presupuesto eliminado",
        message: "El presupuesto ha sido eliminado correctamente.",
      });
    },
  });
};

export default useDeleteMaintenanceBudget;
