import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import React from "react";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { PollModel } from "../models";
import { TicketQueryHelper } from "api/services/tickets/queries/GetTicket";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  pollUUID: UUID;
  ticketUUID?: UUID;
}
interface Input {
  start_date: Date;
  end_date: Date;
}
const useUpdatePoll = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/polls/update/${params.pollUUID}/`;
      return await axios.put<PollModel>(url, input).then((res) => res.data);
    },
    onSuccess: (data) => {
      if (params.ticketUUID)
        TicketQueryHelper.updatePoll(data, params.ticketUUID);
      toastNotification.success({
        title: "Votación actualizada",
        message: "Se ha actualizado correctamente la votación.",
      });
    },
  });
};

export default useUpdatePoll;
