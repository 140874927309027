import { JSX } from "react";

export enum GroupRole {
  Administrador = "administrador",
  Comite = "comite",
  Supervisor = "supervisor",
  Copropietario = "copropietario",
  Conserje = "conserje",
  Mayordomo = "mayordomo",
  Superadmin = "superadmin",
  SwappiAdmin = "swappi-admin",
  SwappiExecutive = "swappi-executive",
}
export interface SwappiRouteProps {
  type?: "collapse" | "title" | "divider";
  name?: string;
  key: string;
  icon?: JSX.Element;
  component?: JSX.Element;
  path?: string;
  href?: string;
  title?: string;
  permission?: GroupRole[];
  nested?: SwappiRouteProps[];
}
