/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SuiInput from "components/SuiInput";
import { useDispatch } from "react-redux";
import { updateUserInAdmin } from "redux/actions/users";
import { Checkbox } from "@mui/material";
import { getPermission } from "utils";
import useUpdateUser from "api/services/user/mutations/updateUser";
import useEventEmitter from "lib/events/useEventEmitter";

import {
  validate as validateRut,
  clean as cleanRut,
  format as formatRut,
} from "rut.js";

function Form(props) {
  const labels = [];
  const values = [];
  const dispatch = useDispatch();
  const { socialMediaColors } = colors;
  const [first_name, setfirst_name] = useState(props.memberProp.first_name);
  const [phone, setphone] = useState(props.memberProp.phone);
  const [rut, setRut] = useState(props.memberProp.rut);
  const [member, setmember] = useState({});
  const [edit, setedit] = useState(true);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadFile, setuploadFile] = useState(false);
  const [file, setFile] = useState(null);
  const { size } = typography;
  const { mutate: updateUser } = useUpdateUser(props?.memberProp?.uuid);
  const userUpdated = useEventEmitter("userUpdated");

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  useEffect(() => {
    setmember(props.memberProp);
    setfirst_name(props.memberProp.first_name);
    setphone(props.memberProp.phone);
    setRut(props.memberProp.rut);
  }, [props.memberProp]);



  const onSubmit = (data) => {
    updateUser(
      {
        first_name: data.first_name,
        phone: data.phone,
        rut: cleanRut(data.rut),
        weekly_report_notification: data.weekly_report_notification,
      },
      {
        onSuccess: (data) => userUpdated.emit(data),
      }
    );
  }
  return (
    <Card>
      <SuiBox p={2}>
        <SuiBox>
          <SuiTypography
            variant="h4"
            fontWeight="bold"
            textTransform="capitalize"
            textAlign="center"
          >
            Editar usuario
          </SuiTypography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Email: &nbsp;
              </SuiTypography>
            </Grid>
            <Grid item xs={8}>
              <SuiTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{member.email}
              </SuiTypography>
            </Grid>
            <Grid item xs={4}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Nombre: &nbsp;{" "}
              </SuiTypography>
            </Grid>
            <Grid item xs={8}>
              <SuiInput
                size="small"
                value={first_name}
                onChange={(e) => setfirst_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize" pr={2}>
                Teléfono: &nbsp;
              </SuiTypography>
            </Grid>
            <Grid item xs={8}>
              <SuiInput value={phone} onChange={(e) => setphone(e.target.value)} size="small" />
            </Grid>

            <Grid item xs={4}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Rut: &nbsp;
              </SuiTypography>
            </Grid>
            <Grid item xs={8}>
              <SuiInput size="small" value={rut} onChange={(e) => setRut(e.target.value)} />
            </Grid>

          </Grid>
          {edit && (
            <SuiBox display="flex" pb={3} pr={2} justifyContent="center">
              <SuiButton
                style={{ marginTop: 15, float: "right" }}
                color="warning"
                variant="gradient"
                size="small"
                onClick={() => {
                  if (edit) {
                    const formData = {
                      email: member.email,
                      first_name: first_name,
                      phone: phone,
                      rut: rut,
                      weekly_report_notification: false,
                    };
                    onSubmit(formData);

                  }
                }}
              >
                Guardar
              </SuiButton>
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Form;
