import { Box, Grid, Stack } from "@mui/material";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { UserModel } from "api/services/user/models";
import useUpdateUserCommunities from "api/services/user/mutations/updateUserCommunities";
import CheckboxControlled from "components/forms/controlled/CheckboxControlled";
import { SuiBoxTs } from "components/SuiBox";
import { SuiButtonTs } from "components/SuiButton";
import SuiInputTs from "components/SuiInput/SuiInputTs";
import eventEmitter from "lib/events/EventEmitter";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
function arrayToObjectKeys(arr: string[]): Record<string, boolean> {
  return arr.reduce((obj, key) => {
    obj[key] = true;
    return obj;
  }, {} as Record<string, boolean>);
}

function getEnabledKeys(obj: Record<string, boolean>): string[] {
  return Object.entries(obj)
    .filter(([, value]) => value === true)
    .map(([key]) => key);
}
const ManageUserCommunitiesForm: React.FC<{ user: UserModel }> = ({ user }) => {
  const { mutate: updateUserCommunities } = useUpdateUserCommunities(user.uuid);
  const [name, setName] = useState("");
  const { data: communities } = useGetCommunities();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return arrayToObjectKeys(
        user.communities.map((community) => community.slug)
      );
    }, [user.communities]),
  });
  const onSubmit = handleSubmit((data) => {
    updateUserCommunities(
      {
        communities: getEnabledKeys(data),
      },
      {
        onSuccess: (data) => {
          console.log(data);
          eventEmitter.publish("userUpdated", data);
        },
      }
    );
  });
  return (
    <form onSubmit={onSubmit}>
      <SuiBoxTs pb={2}>
        <SuiInputTs
          size="small"
          label="Nombre"
          placeholder="Buscar comunidad"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </SuiBoxTs>
      <Stack>
        <Grid container spacing={3}>
          {communities.map((community) => {
          if (community.name.toLowerCase().includes(name.toLowerCase()))
            return (
              <Grid item key={community.slug} xs={6} md={4}>
                <CheckboxControlled
                  variant="start-item"
                  label={community.name}
                  name={community.slug}
                  control={control}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
      <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
        <SuiButtonTs
          color="success"
          variant="gradient"
          size="small"
          type="submit"
          disabled={!isDirty}
        >
          Guardar cambios
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default ManageUserCommunitiesForm;
