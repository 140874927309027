import { useMutation } from "@tanstack/react-query";
import { EmergencyModel } from "../models";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

interface Input {
  title: string;
  description: string;
}

const useUpdateEmeregency = (emergencyUUID: UUID) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/emergency/${emergencyUUID}/edit/`;
      return await axios.put<EmergencyModel>(url, input);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Emergencia actualizada",
        message: "La emergencia ha sido actualizada",
      });
    },
  });
};

export default useUpdateEmeregency;
