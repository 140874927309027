import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AddTicketForm from './Form';
import { SuiButtonTs } from 'components/SuiButton';
import { getPermission, isPermissionEnabled } from 'utils';
import { styleDesktop, styleMobile } from './styles';
import useMediaScreen from 'utils/Hooks/useMedia';

const CreateTicketModal = (props) => {
  const { isMobile } = useMediaScreen();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const scrollContainerRef = useRef(null);

  const handleScrollButtonClick = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;

      // Calculamos la diferencia entre la altura total del contenido y la altura visible del contenedor
      const scrollDistance = scrollContainer.scrollHeight - scrollContainer.clientHeight;
      console.log(scrollDistance);

      // Hacemos el scroll hasta el final del contenido
      scrollContainer.scrollTo({
        top: scrollDistance,
        behavior: 'smooth', // Esto crea un desplazamiento suave animado
      });
    }
  };

  const { current: hasRequiredRole } = useRef(
    getPermission(['superadmin', 'supervisor', 'administrador', 'mayordomo', 'conserje', 'comite'])
  );

  return (
    <>
      {hasRequiredRole && isPermissionEnabled('tasks-createTask') && (
        <SuiButtonTs variant="gradient" color="success" size="small" onClick={handleOpen}>
          Crear Tarea
        </SuiButtonTs>
      )}
      <Modal
        style={{ overflow: 'visible' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box ref={scrollContainerRef} sx={isMobile ? styleMobile : styleDesktop}>
          <AddTicketForm
            scroll={handleScrollButtonClick}
            handleClose={handleClose}
            community={props.community}
            visitReport={props.visitReport}
            areaUuid={props.areaUuid}
            maintenance={props.maintenance}
            maintenanceUuid={props.maintenanceUuid}
            actionPlanView={props.actionPlanView}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
