import { BookmarkAdd, Delete, Edit, EditOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ChecklistModel } from 'api/services/checklist/models';
import useCreateChecklistTemplateFromChecklist from 'api/services/checklist/mutations/CreateChecklistTemplateFromChecklist';
import useDeleteChecklist from 'api/services/checklist/mutations/DeleteChecklist';
import useUpdateChecklist from 'api/services/checklist/mutations/UpdateChecklist';
import InputControlled from 'components/forms/controlled/InputControlled';
import DropdownMenu, { DropdownMenuItem } from 'components/DropdownMenu';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UUID } from 'crypto';

interface Props {
  objectUUID: UUID;
  onEdit?: (checklist: ChecklistModel) => void;
  onDelete?: (checklist: ChecklistModel) => void;
  checklist: ChecklistModel;
  editEnabled?: boolean;
}
interface ChecklistForm {
  name: string;
}
const ChecklistTitle: FC<Props> = ({
  checklist,
  objectUUID,
  onEdit,
  editEnabled = false,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<ChecklistForm>({});
  const { mutate: udpateChecklist } = useUpdateChecklist({
    checklistUUID: checklist.uuid,
    objectUUID,
  });
  const { mutate: deleteChecklist } = useDeleteChecklist({
    checklistUUID: checklist.uuid,
    objectUUID,
  });

  const { mutate: addChecklistTemplate } = useCreateChecklistTemplateFromChecklist({
    checklistUUID: checklist.uuid,
  });
  useEffect(() => {
    reset({ name: checklist.name });
  }, [checklist]);

  const handleCancelEdit = () => {
    reset();
    setIsEditing(false);
  };
  const handleEdit = () => {
    setIsEditing(true);
  };

  const onSubmit = handleSubmit((data) =>
    udpateChecklist(data, {
      onSuccess: (data) => {
        onEdit?.(data);
        setIsEditing(false);
      },
    })
  );

  const items1: DropdownMenuItem[] = [
    {
      label: isEditing ? 'Cancelar edición' : 'Editar',
      icon: isEditing ? <EditOff /> : <Edit />,
      onClick: () => (isEditing ? handleCancelEdit() : handleEdit()),
    },
    {
      label: 'Eliminar lista',
      icon: <Delete />,
      onClick: () => {
        deleteChecklist(null, {
          onSuccess: () => {
            onDelete?.(checklist);
          },
        });
      },
    },
  ];
  const items2: DropdownMenuItem[] = [
    {
      label: 'Guardar plantilla',
      icon: <BookmarkAdd />,
      onClick: () => addChecklistTemplate({ name: checklist.name }),
    },
  ];
  return (
    <Box>
      {isEditing ? (
        <Box display={'flex'} justifyContent={'space-between'} gap={2}>
          <Box sx={{ flexGrow: 1 }}>
            <InputControlled control={control} name="name" placeholder="Nombre de la lista" />
          </Box>

          <Box display={'flex'} justifyContent={'space-between'} gap={1}>
            <SuiButtonTs
              variant="gradient"
              color="secondary"
              size="small"
              onClick={handleCancelEdit}
            >
              Cancelar
            </SuiButtonTs>
            <SuiButtonTs
              variant="gradient"
              color="success"
              size="small"
              disabled={!isDirty}
              onClick={onSubmit}
            >
              Guardar
            </SuiButtonTs>
          </Box>
        </Box>
      ) : (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box py={1}>
            <Typography variant="h5" fontWeight="bold" textTransform="none">
              {checklist.name}
            </Typography>
          </Box>
          {editEnabled && (
            <Box>
              <DropdownMenu items={[items2, items1]} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ChecklistTitle;
