import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { format } from "date-fns";

function DateRangePicker({ setDates }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");

  const handleStartDateChange = (event) => {
    const value = event.target.value;
    setStartDate(value);
    validateDates(value, endDate);
  };

  const handleEndDateChange = (event) => {
    const value = event.target.value;
    setEndDate(value);
    validateDates(startDate, value);
  };

  const validateDates = (start, end) => {
    const formattedStartDate = isNaN(new Date(start)) ? "" : format(new Date(start), "yyyy-MM-dd");
    const formattedEndDate = isNaN(new Date(end)) ? "" : format(new Date(end), "yyyy-MM-dd");

    setDates({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" gap={2} alignItems="center">
        <TextField
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          fullWidth
        />
        <TextField
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          fullWidth
          disabled={!startDate}
          inputProps={{
            min: startDate || undefined,
          }}
        />
      </Box>
      {error && <Box color="red">{error}</Box>}
      <Box sx={{fontSize:10}}>
        <strong>Rango seleccionado:</strong>{" "}
        {startDate && endDate
          ? `${format(new Date(startDate), "dd-MM-yyyy")} a ${format(
              new Date(endDate),
              "dd-MM-yyyy"
            )}`
          : "No se ha seleccionado un rango."}
      </Box>
    </Box>
  );
}

export default DateRangePicker;
