/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { useSelector, useDispatch } from 'react-redux';
// Soft UI Dashboard PRO React example components
import AreaCard from 'examples/Cards/CommunitiesCards/AreaCard';
import Calendar from './Calendar';
// General page components
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import Header from 'layouts/pages/profile/components/Header';
import { getMaintenanceByCommunity } from 'redux/actions/maintenance';

// Soft UI Dashboard PRO React example components
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Create from './Create';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MaintenanceModal from './View/MaintenanceCard';
import Loading from 'components/Loading';
import { getPermission } from 'utils';
import { getMainetenanceByProvider } from 'redux/actions/providers';
import { isPermissionEnabled } from 'utils';
import Maintenances from './AdminMaintenances';

function Maintenance(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const maintenanceResponse = useSelector((state) => state.maintenance.getMaintenanceByCommunity);
  const editVisitResponse = useSelector((state) => state.communities.editVisit);
  const deleteVisitResponse = useSelector((state) => state.communities.deleteVisit);
  const markAsVisitedResponse = useSelector((state) => state.communities.markAsVisited);
  const maintenanceProviderResponse = useSelector(
    (state) => state.providers.getMaintenanceByProvider
  );
  const createMaintenanceResponse = useSelector((state) => state.maintenance.createMaintenance);
  const createMaintenanceVisitResponse = useSelector(
    (state) => state.maintenance.createMaintenanceVisit
  );
  const [maintenances, setmaintenances] = useState({ maintenances: [], visits: [] });
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (props.providerUuid) {
      dispatch(getMainetenanceByProvider(props.providerUuid));
    } else dispatch(getMaintenanceByCommunity(slug));
  }, []);

  useEffect(() => {
    if (editVisitResponse.data) {
      if (props.providerUuid) {
        dispatch(getMainetenanceByProvider(props.providerUuid));
      } else dispatch(getMaintenanceByCommunity(slug));
    } else if (deleteVisitResponse.data) {
      if (props.providerUuid) {
        dispatch(getMainetenanceByProvider(props.providerUuid));
      } else dispatch(getMaintenanceByCommunity(slug));
    } else if (markAsVisitedResponse.data) {
      if (props.providerUuid) {
        dispatch(getMainetenanceByProvider(props.providerUuid));
      } else dispatch(getMaintenanceByCommunity(slug));
    }
  }, [editVisitResponse, deleteVisitResponse, markAsVisitedResponse]);

  useEffect(() => {
    if (slug) dispatch(getMaintenanceByCommunity(slug));
  }, [slug]);

  useEffect(() => {
    if (maintenanceResponse.data !== undefined && !props.providerUuid) {
      setmaintenances(maintenanceResponse.data);
      setloading(false);
    } else if (maintenanceProviderResponse) {
      if (maintenanceProviderResponse.data !== undefined) {
        setmaintenances(maintenanceProviderResponse.data);
        setloading(false);
      }
    }
  }, [maintenanceResponse, maintenanceProviderResponse]);

  useEffect(() => {
    if (createMaintenanceResponse.data !== undefined) {
      dispatch(getMaintenanceByCommunity(slug));
    }
  }, [createMaintenanceResponse]);

  useEffect(() => {
    if (createMaintenanceVisitResponse.data !== undefined) {
      dispatch(getMaintenanceByCommunity(slug));
    }
  }, [createMaintenanceVisitResponse]);

  useEffect(() => {
    setloading(true);
  }, [props.selected, slug]);

  return (
    <SuiBox>
      <Grid container spacing={3} mb={2}>
      </Grid>
        <SuiBox>
          <Maintenances community={slug} />
        </SuiBox>
    </SuiBox>
  );
}

export default Maintenance;
