import { Box } from '@mui/material';
import { PollChoiceModel, PollModel } from 'api/services/polls/models';
import colors from 'assets/theme/base/colors';
import React, { FC } from 'react';

interface Props {
  poll: PollModel;
  pollOption: PollChoiceModel;
  isMostVoted?: boolean;
}
const VotesCount: FC<Props> = ({ poll, pollOption, isMostVoted = false }) => {
  const boxColor = isMostVoted ? colors.success.main : colors.grey[600];
  const bgColor = isMostVoted && colors.success.main;
  const fontColor = isMostVoted ? colors.white.main : colors.grey[600];
  return (
    <Box>
      <Box
        textAlign={'center'}
        sx={{
          color: fontColor,
          bgcolor: bgColor,
          border: `solid ${boxColor} 1px`,
          borderRadius: 1,
        }}
      >
        {pollOption.votes.length}/{poll.max_votes} Votos
      </Box>
    </Box>
  );
};

export default VotesCount;
