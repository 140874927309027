import { Box } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode | any;
  variant?: "primary" | "success";
  size?: string | number;
}

const variantConfig = {
  success: {
    background: "linear-gradient(180deg, #80e32a, #20cd49)",
    color: "white",
  },
  primary: {
    background: "linear-gradient(310deg, #2152ff, #21d4fd)",
    color: "white",
  },
};

const CircleContainer: React.FC<Props> = ({
  children,
  variant = "primary",
  size = "3.625rem",
}) => {
  return (
    <Box
      borderRadius="50%"
      width={size}
      height={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={variantConfig[variant]}
    >
      {children}
    </Box>
  );
};

export default CircleContainer;
