/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
import Footer from 'examples/Footer';
import EventCalendar from 'examples/Calendar';
import SuiTypography from 'components/SuiTypography';
// Calendar application components
import Header from 'layouts/pages/profile/components/Header';
import { useNavigate, useParams } from 'react-router-dom';
// Data
import { getVisitsByCommunity } from 'redux/actions/communities';
import { getVisitsByManagement } from 'redux/actions/communities';
import CreateVisit from './CreateVisit';
import VisitsModal from './VisitView';
import SuiButton from 'components/SuiButton';
import { createVisitReport } from 'redux/actions/reports';
import { isPermissionEnabled } from 'utils';
import LoadingView from 'components/Loading';
import { useAuthenticationStore } from 'stores/authentication';
import { useVisits } from 'api/services/calendar/queries/getVisits';
import CreateGeneralVisitForm from './CreateVisit/CreateForm';
import VisitView from 'components/pages/maintenances/view/tabs/VisitsTab/VisitView';
import BaseModal from "components/ui/BaseModal"; // Importa solo BaseModal

const parseVisits = (visits, slug) => {
  let profile = useAuthenticationStore.getState().userProfile;
  /* filter visits by community in profile.communities by slug */
  let filteredVisits = visits.filter((visit) => {
    return profile.communities.some((community) => {
      return community.slug === visit.community;
    });
  });
  filteredVisits.map((visit) => {
    if (!slug)
      visit.title =
        visit.title +
        ' (' +
        visit.community.charAt(0).toUpperCase() +
        visit.community.slice(1) +
        ')';
  });
  return filteredVisits;
};


const getFirstAndLastDayOfMonth = () => {
  const now = new Date();
  const primerDia = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
  const ultimoDia = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
  return { primerDia, ultimoDia };
};

function Calendar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [visits, setvisits] = useState([]);
  const [visit, setvisit] = useState(null);
  let { slug } = useParams();
  const [start, setStart] = useState(getFirstAndLastDayOfMonth().primerDia);
  const [end, setEnd] = useState(getFirstAndLastDayOfMonth().ultimoDia);
  const [communities, setCommunities] = useState(slug? slug : "");
  const modalRef = useRef(null); // Cambia el tipo a null
  const { data: visitsData, isLoading } = useVisits(start, end, communities);

  useEffect(() => {
    const { primerDia, ultimoDia } = getFirstAndLastDayOfMonth();
    
    setStart(primerDia);
    setEnd(ultimoDia);

  }, []);

  

  const parsedVisits = useMemo(() => {
    if (visitsData) {
      return parseVisits(visitsData, slug);
    }
    return [];
  }, [visitsData, slug]);

  const handleDatesSet = (dateInfo) => {
    const primerDia = dateInfo.start;
    const ultimoDia = dateInfo.end;

    setStart(primerDia.toISOString().split('T')[0]);
    setEnd(ultimoDia.toISOString().split('T')[0]);
  };

  const handleEventClick = (e) => {
    let eventProps = e.event._def.extendedProps;
    let event = {
      title: e.event._def.title,
      ...eventProps,
      start: e.event._instance.range.start,
      end: e.event._instance.range.end,
    };
    setvisit(event);
    modalRef.current?.open();
  };

  return (
    <SuiBox pt={3}>
      <SuiTypography variant="h4" fontWeight="medium" mt={1} textAlign="center">
        Calendario
      </SuiTypography>
      {isPermissionEnabled('visits-createVisit') && (
        <SuiBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
          <CreateGeneralVisitForm community={slug} />
        </SuiBox>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
          {useMemo(
            () => (
              <EventCalendar
                eventClick={handleEventClick}
                initialView="dayGridMonth"
                events={parsedVisits}
                selectable
                editable
                datesSet={handleDatesSet}
              />
            ),
            [parsedVisits]
          )}
        </Grid>
      </Grid>
      <BaseModal ref={modalRef}>
        <SuiBox p={2}>
          {visit && <VisitView visit={visit} modalRef={modalRef} />}
        </SuiBox>
      </BaseModal>
    </SuiBox>
  );
}

export default Calendar;
