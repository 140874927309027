import React, { FC } from "react";
import { Box, Typography, Switch } from "@mui/material";
import { Control, useController, UseControllerProps } from "react-hook-form";
import { SelectOptionForm } from "types/forms";

interface SwitchProps extends UseControllerProps {
  label?: string;
  control: Control<any>;
  options?: SelectOptionForm[];
}

const SwitchControlled: FC<SwitchProps> = ({
  name,
  control,
  rules,
  label,
  disabled,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <Box>
        <Switch {...field} checked={field.value} disabled={disabled} />
      </Box>
    </Box>
  );
};

export default SwitchControlled;
