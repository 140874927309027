import { useMediaQuery } from '@mui/material';

const useMediaScreen = () => {
  const isMobile = useMediaQuery('(max-width:960px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isDesktop = useMediaQuery('(min-width:961px)');
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useMediaScreen;
