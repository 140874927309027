import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { Email, ISODateString, SlugString } from "types/models";
import { TicketModel } from "../models";
import { IMessage } from "api/utils/queries";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  communitySlug: SlugString;
  isRecurrent?: boolean;
}

interface Inputs {
  title: string;
  comment: string;
  service_ticket: boolean;
  priority: string;
  is_after_sale: boolean;
  pre_assignees: Email[];
  maintenance_uuid: UUID | undefined;
  area_uuid: UUID | undefined;
  report: any;
  responsible: Email;
  due_date: ISODateString;
  period: ISODateString;
  start_date: ISODateString;
  end_date: ISODateString;
}
const useCreateTicket = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Inputs) => {
      const { communitySlug, isRecurrent } = params;
      const endpointAction = isRecurrent ? "create-recurrent" : "create";
      const url = `${API_URL}/tickets/${communitySlug}/${endpointAction}/`;
      return await axios
        .post<TicketModel | IMessage>(url, input)
        .then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Se ha creado una tarea",
        message: "Se ha creado la tarea de forma correcta",
      });
    },
  });
};

export default useCreateTicket;
