import { Box, Collapse, Typography } from '@mui/material';
import { DocumentModel } from 'api/services/documents/models';
import React, { FC, useState } from 'react';
interface Props {
  document: DocumentModel;
}
const Observation: FC<Props> = ({ document }) => {
  const [isExpaned, setIsExpanded] = useState<boolean>(false);

  return (
    <Box>
      <Typography
        variant="button"
        fontWeight="bold"
        onClick={() => setIsExpanded((prev) => !prev)}
        textTransform="none"
        sx={{
          cursor: 'pointer',
        }}
      >
        {isExpaned ? 'Ocultar' : 'Ver'} observación
      </Typography>
      <Collapse in={isExpaned}>
        <Typography variant="caption" textTransform="none">
          {document.observations}
        </Typography>
      </Collapse>
    </Box>
  );
};

export default Observation;
