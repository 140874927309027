import { types } from '../actions/types';

export const usersReducer = (
  state = {
    getUsers: null,
    createUser: {},
    updateUser: {},
    deleteUser: null,
    getUserProfile: null,
    getUsersByManagement: {},
    addCommunityToSupervisor: {},
    updateUserProfile: {},
    configNotifications: {},
    deactiveUser: {},
    updateUserWithAdmin: {},
    getTicketCrew: {},
    updateAdminLogo: {},
    getTicketUsers: {},
    getCommunityStaff: {},
    getUsersCommunities: {},
    getComiteByCommunitySlug: {},
    weeklyReportConfig: {},
    getFAQ: {},
    createReminder: {},
    updateReminder: {},
    deleteReminder: {},
  },
  action
) => {
  switch (action.type) {
    case types.setGetUsers:
      return {
        ...state,
        getUsers: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setCreateUser:
      return {
        createUser: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setUpdateUser:
      return {
        ...state,
        updateUser: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setDeleteUser:
      return {
        ...state,
        deleteUser: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setGetUserProfile:
      return {
        ...state,
        getUserProfile: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setGetUsersByManagement:
      return {
        ...state,
        getUsersByManagement: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
        getCommunityStaff: {},
      };
    case types.setAddCommunityToSupervisor:
      return {
        ...state,
        addCommunityToSupervisor: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setUpdateUserProfile:
      return {
        ...state,
        updateUserProfile: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setConfigNotificactions:
      return {
        ...state,
        configNotifications: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setDeactiveUser:
      return {
        ...state,
        deactiveUser: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setUpdateUserWithAdmin:
      return {
        ...state,
        updateUserWithAdmin: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetTicketCrewUsers:
      return {
        ...state,
        getTicketCrew: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setUpdateAdminLogo:
      return {
        ...state,
        updateAdminLogo: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetTicketUsers:
      return {
        ...state,
        getTicketUsers: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setGetCommunityStaff:
      return {
        ...state,
        getCommunityStaff: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
        getUsersByManagement: {},
      };
    case types.setGetComiteByCommunitySlug:
      return {
        ...state,
        getComiteByCommunitySlug: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setGetUsersCommunities:
      return {
        ...state,
        getUsersCommunities: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setWeeklyReportConfig:
      return {
        ...state,
        weeklyReportConfig: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setGetFAQ:
      return {
        ...state,
        getFAQ: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case types.setGetDTEs:
      return {
        ...state,
        getDTEs: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
        },
      };

    case types.setCreateReminder:
      return {
        ...state,
        createReminder: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setUpdateReminder:
      return {
        ...state,
        updateReminder: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };
    case types.setDeleteReminder:
      return {
        ...state,
        deleteReminder: {
          status: action.payload.status,
          message: action.payload.message,
          data: action.payload.data,
          time: new Date(),
        },
      };

    default:
      return state;
  }
};
