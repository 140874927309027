import React from "react";
import useAddObjectChecklistDrawerStore from "./store";
import { Box, Divider, Drawer } from "@mui/material";
import { MODAL_ZINDEX } from "utils/contants/modal";
import { Close } from "@mui/icons-material";
import NewChecklistForm from "./NewChecklistForm";
import ChecklistTemplatesList from "./ChecklistTemplatesList";
import useEventListener from "lib/events/useEventListener";
import { CLOSE_CHECKLIST_TEMPLATE_MODAL } from "./ChecklistTemplateModal";

const { setState } = useAddObjectChecklistDrawerStore;
const AddChecklistDrawer = () => {
  const { isOpen, objectUUID } = useAddObjectChecklistDrawerStore();

  const handleOnClose = () => {
    setState({ isOpen: false });
  };

  useEventListener(CLOSE_CHECKLIST_TEMPLATE_MODAL, () => {
    setState({ isOpen: true });
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      sx={{ zIndex: MODAL_ZINDEX + 1 }}
    >
      <Box>
        <Box position="relative">
          <Box position="absolute" right={0} p={2}>
            <Close onClick={handleOnClose} />
          </Box>
        </Box>
        <Box p={3}>
          <NewChecklistForm objectUUID={objectUUID} />
          <Divider />
          <ChecklistTemplatesList objectUUID={objectUUID} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddChecklistDrawer;
