import React from 'react';
import { SuiButtonTs } from 'components/SuiButton';

import './style.css';

import { TicketModel } from 'api/services/tickets/models';
import useMediaScreen from 'utils/Hooks/useMedia';
import useAddObjectChecklistDrawerStore from 'components/ChecklistsComponents/AddChecklistDrawer/store';
import { TicketQueryHelper } from 'api/services/tickets/queries/GetTicket';

interface Props {
  ticket: TicketModel;
}
const AddChecklistButton: React.FC<Props> = ({ ticket }) => {
  const { isMobile } = useMediaScreen();

  const openChecklistDrawer = useAddObjectChecklistDrawerStore((state) => state.openDrawner);
  const handleOnOpenDrawer = () => {
    openChecklistDrawer(ticket.uuid, (checklist) => {
      TicketQueryHelper.addChecklist(checklist, ticket.uuid);
    });
  };

  return (
    <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
      <SuiButtonTs color="dark" variant={'text'} size="small" onClick={handleOnOpenDrawer}>
        Agregar Checklist
      </SuiButtonTs>{' '}
    </div>
  );
};

export default AddChecklistButton;
