/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import { getProvidersByManagement } from "redux/actions/providers";
import { getAreas } from "redux/actions/communities";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiDatePicker from "components/SuiDatePicker";
// Data
import SuiButton from "components/SuiButton";
import { createUnit } from "redux/actions/communities";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createMaintenance } from "redux/actions/maintenance";
import { getCommunitiesByUser } from "redux/actions/communities";
import { createPlanificationCategory } from "redux/actions/analytics";
import SuiInput from "components/SuiInput";
import { getPlanificationCategory } from "redux/actions/analytics";
import { Checkbox, Divider, Icon } from "@mui/material";
import { togglePlanificationCategory } from "redux/actions/analytics";
import SuiBadge from "components/SuiBadge";
import { FaEdit, FaTimes } from "react-icons/fa";
import { deletePlanificationCategory } from "redux/actions/analytics";
import Swal from "sweetalert2";
import { editPlanificationCategory } from "redux/actions/analytics";
import { isPermissionEnabled } from "utils";
import { getBuildingsByCommunity } from "redux/actions/communities";
import { editBuilding } from "redux/actions/communities";
import { deleteBuilding } from "redux/actions/communities";

function ManageBuildings(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [name, setName] = useState(props.item.name);
  const [edit, setEdit] = useState(false);

  return (
    <SuiBox style={{ display: "flex", justifyContent: "space-between" }} pb={1}>
      {edit ? (
        <SuiBox>
          <SuiInput
            value={name}
            size="small"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </SuiBox>
      ) : (
        <SuiTypography component="label" variant="caption" fontWeight="bold">
          {props.item.name}
        </SuiTypography>
      )}
      <SuiBox>
        {" "}
        <SuiBadge
          variant="gradient"
          color="warning"
          style={{ marginRight: 3 }}
          size="xs"
          container
          badgeContent={<FaEdit />}
          onClick={() => {
            if (edit) {
              dispatch(editBuilding(props.item.uuid, { name: name }));
            }
            setEdit(!edit);
          }}
        />
        <SuiBadge
          variant="gradient"
          color="error"
          size="xs"
          onClick={() => {
            Swal.fire({
              title: "¿Estás seguro?",
              text: "No podrás revertir esta acción!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, eliminar!",
              cancelButtonText: "No, cancelar!",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(deleteBuilding(props.item.uuid));
                Swal.fire("Eliminado!", "La torre ha sido eliminada.", "success");
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
                Swal.fire("Cancelado", "La torre no ha sido eliminada :)", "error");
              }
            });
          }}
          container
          badgeContent={<FaTimes />}
        />
      </SuiBox>
    </SuiBox>
  );
}

export default ManageBuildings;
