import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import SuiBox from "components/SuiBox";
import { useDispatch, useSelector } from "react-redux";
import SuiButton from "components/SuiButton";
import { getCommunitiesByUser } from "redux/actions/communities";
import { Card, Grid } from "@mui/material";
import { addCommunityToSupervisor } from "redux/actions/users";
import { addCommunityToProvider } from "redux/actions/providers";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";

export default function CheckboxesGroup(props) {
  const dispatch = useDispatch();
  const communitiesResponse = useGetCommunities();
  const [markedCommunities, setMarkedCommunities] = React.useState(
    props.member.communities ? props.member.communities.map((community) => community.slug) : []
  );
  const [communities, setcommunities] = React.useState([]);

  React.useEffect(() => {
    if (communitiesResponse.data) {
      setcommunities(communitiesResponse.data);
    }
  }, [communitiesResponse]);

  const handleChange = (event) => {
    if (markedCommunities.includes(event.target.name)) {
      setMarkedCommunities(
        markedCommunities.filter((community) => community !== event.target.name)
      );
      return;
    }
    setMarkedCommunities([...markedCommunities, event.target.name]);
  };

  React.useEffect(() => {
    setMarkedCommunities(
      props.member.communities ? props.member.communities.map((community) => community.slug) : []
    );
  }, [props.member]);

  const handleSubmmit = () => {
    let d = {
      communities: markedCommunities,
    };
    dispatch(addCommunityToProvider(props.member.uuid, d));
    props.handleClose();
  };

  return (
    <SuiBox sx={{ display: "flex" }}>
      <Card sx={{ p: 3 }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Asignar Comunidades</FormLabel>
          <FormGroup sx={{ p: 3 }}>
            <Grid container spacing={3}>
              {communities.map((community, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={markedCommunities.includes(community.slug) ? true : false}
                          onChange={handleChange}
                          name={community.slug}
                        />
                      }
                      label={community.name}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
        </FormControl>
        <SuiButton color="success" variant="gradient" size="small" onClick={handleSubmmit}>
          Guardar
        </SuiButton>
      </Card>
    </SuiBox>
  );
}
