import React, { FC } from 'react';
import { Close, Done, Error, Info, Warning } from '@mui/icons-material';
import { Box, Card, Divider, IconButton, Paper, Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import toast from 'react-hot-toast';
interface Props {
  title?: string;
  message?: string;
  status?: 'success' | 'error' | 'info';
  handleClose?: () => void;
}
interface Input {
  title?: string;
  message?: string;
}

const ICONS = {
  error: <Error fontSize="inherit" />,
  success: <Done fontSize="inherit" />,
  info: <Info fontSize="inherit" />,
};

export const toastNotification = {
  error: (input: Input) =>
    toast.custom((t) => (
      <ToastNotification status="error" {...input} handleClose={() => toast.dismiss(t.id)} />
    )),
  success: (input: Input) =>
    toast.custom((t) => (
      <ToastNotification status="success" {...input} handleClose={() => toast.dismiss(t.id)} />
    )),
  info: (input: Input) =>
    toast.custom((t) => (
      <ToastNotification status="info" {...input} handleClose={() => toast.dismiss(t.id)} />
    )),
};
const ToastNotification: FC<Props> = ({ title, message, status, handleClose }) => {
  const textColor = colors[status].main;
  return (
    <Paper sx={{ p: 2, minWidth: '21.875rem', borderRadius: 2, zIndex: 99999999999 }}>
      <Box>
        <Box display={'flex'} alignItems="center" lineHeight={0}>
          <Typography
            flex={1}
            variant="button"
            fontWeight="medium"
            color={textColor}
            textTransform={'none'}
          >
            <Box display={'flex'} alignItems="center" lineHeight={0} gap={1}>
              {ICONS[status] && <Box>{ICONS[status]}</Box>}
              <Box>{title}</Box>
            </Box>
          </Typography>
          <Box>
            <IconButton onClick={handleClose} size="small">
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box lineHeight={0}>
          <Typography
            variant="button"
            fontWeight="regular"
            color={colors.dark.main}
            textTransform={'none'}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ToastNotification;
