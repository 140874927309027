import React, { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { SuiButtonTs } from "components/SuiButton";
import EditMaintenanceForm from "../forms/EditMaintenanceForm";
import { MaintenanceModel } from "api/services/maintenances/models";

interface Props {
  maintenance: MaintenanceModel;
}
const EditMaintenanceInfoDialog: FC<Props> = ({ maintenance }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <section>
      <SuiButtonTs
        onClick={() => setIsOpen(true)}
        style={{ float: "right", marginRight: 5 }}
        variant="gradient"
        color="warning"
        size="small"
      >
        Editar
      </SuiButtonTs>
      <Dialog
        open={isOpen}
        PaperProps={{ sx: { overflow: "visible" } }}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent sx={{ overflowY: "visible" }}>
          <EditMaintenanceForm
            maintenance={maintenance}
            onSuccess={() => setIsOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default EditMaintenanceInfoDialog;
