import { Box } from '@mui/material';
import { PollChoiceModel } from 'api/services/polls/models';
import { SuiTypographyTs } from 'components/SuiTypography';
import React, { FC, useState } from 'react';
import { parseDate } from 'utils';
interface Props {
  pollOption: PollChoiceModel;
}
const VotesResults: FC<Props> = ({ pollOption }) => {
  const [show, setShow] = useState(false);
  const { votes } = pollOption;

  return (
    <div>
      <SuiTypographyTs
        variant="body2"
        fontWeight="medium"
        textAlign="center"
        pt={0}
        style={{ cursor: 'pointer' }}
        onClick={() => setShow(!show)}
      >
        {!show ? 'Ver' : 'Ocultar'} Votos
      </SuiTypographyTs>
      {show && (
        <Box p={1} style={{ border: '1px solid black', borderRadius: '5px' }}>
          {votes.map((vote) => (
            <SuiTypographyTs key={vote.uuid} variant="body2" fontSize="12px" textAlign="center">
              {vote.user.first_name}- {parseDate(vote.created)}
            </SuiTypographyTs>
          ))}
        </Box>
      )}
    </div>
  );
};

export default VotesResults;
