import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography, Modal, Button } from "@mui/material";
import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import InputControlled from "components/forms/controlled/InputControlled";
import { SuiButtonTs } from "components/SuiButton";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { scheme } from "./form.helpers";
import useEventEmitter from "lib/events/useEventEmitter";
import useCreateVisit from "api/services/visits/mutations/createVisit";
import { queryClient } from "api/config/queryClient";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { SuiSelectTs } from "components/SuiSelect";

interface Props {
  community?: string;
}

export const EVENT_VISIT_CREATED = "visitCreated";
export const GET_VISITS_KEY = "GetVisits";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "40%",
  p: 4,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  bgcolor: "background.paper",
  width: "100%",
  p: 4,
};

const CreateGeneralVisitForm: React.FC<Props> = ({ community }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: communities } = useGetCommunities();
  const communitiesOptions = communities?.map((community) => ({
    label: community.name,
    value: community.slug,
  }));

  const [selectedCommunity, setSelectedCommunity] = useState(community || null);

  useEffect(() => {
    if (community) {
      setSelectedCommunity(community);
    }
  }, [community]);

  const eventVisitCreated = useEventEmitter(EVENT_VISIT_CREATED);
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = useForm({
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    console.log("Form errors:", errors);
  }, [errors]);

  const { mutate: createVisit } = useCreateVisit();
  const onSubmit = handleSubmit((data) => {
    createVisit(
      {
        title: data.name,
        start: data.start_date[0] && format(data.start_date[0], "yyyy-MM-dd"),
        end: data.end_date[0] ? format(data.end_date[0], "yyyy-MM-dd") : null,
        community: selectedCommunity,
      },
      {
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === GET_VISITS_KEY,
              });
          eventVisitCreated.emit(data);
          handleClose();
        },
      }
    );
  });

  return (
    <>
      <SuiButtonTs variant="gradient" color="success" size="small" onClick={handleOpen}>
        Agendar Visita
      </SuiButtonTs>
      <Modal open={open} onClose={handleClose} style={{ overflow: "scroll" }}>
        <Box sx={window.innerWidth <= 600 ? styleMobile : style}>
          <form onSubmit={onSubmit}>
            <Typography variant="h6">Agregar visita</Typography>
            <Stack spacing={1}>
              {!community && (
                <SuiSelectTs
                  size="small"
                  placeholder="Seleccionar comunidad"
                  options={communitiesOptions}
                  onChange={(e) => setSelectedCommunity(e.value)}
                  value={selectedCommunity}
                />
              )}
              <InputControlled
                label="Nombre"
                control={control}
                name="name"
                placeholder="Nombre de visita"
              />
              <DatePickerControlled
                control={control}
                label="Fecha de inicio"
                name="start_date"
                options={{
                  maxDate: watch("end_date")?.[0] && new Date(watch("end_date")[0]),
                  enableTime: false,
                }}
              />
              <DatePickerControlled
                control={control}
                label="Fecha de expiración"
                name="end_date"
                options={{
                  minDate: watch("start_date")?.[0] && new Date(watch("start_date")[0]),
                  enableTime: false,
                }}
              />
              <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
                <SuiButtonTs
                  size="small"
                  color="success"
                  variant="gradient"
                  type="submit"
                  disabled={!isDirty || !isValid}
                >
                  Guardar
                </SuiButtonTs>
              </Box>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateGeneralVisitForm;