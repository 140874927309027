import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { getPermission } from 'utils';
import { mobileMaxWidth } from 'utils';
import { isPermissionEnabled } from 'utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  width: '40%',
  p: 0,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  width: '100%',
  p: 0,
};

export default function CreateMaintenanceModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ float: 'right' }}>
      {isPermissionEnabled('maintenances-createMaintenance') && (
        <SuiButton variant="gradient" size="small" color="success" onClick={handleOpen}>
          Agregar Mantención
        </SuiButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={''}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <Form community={props.community} close={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}
