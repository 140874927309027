import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";
import SuiButton from "components/SuiButton";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import FilePreview from "examples/Cards/DocumentCard/FilePreview";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  overflow: "scroll",
  bgcolor: "background.paper",
  maxHeight: "90%",
  width: "40%",
  p: 0,
};

export default function FilePreviewModal({ document }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ float: "left" }}>
      <AiOutlineEye onClick={handleOpen} style={{ cursor: "pointer" }} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={style}>
          <Card id="basic-info" sx={{ overflow: "scroll" }}>
            <FilePreview
              weight="100%"
              height={"25vh"}
              type={"file"}
              url={document.file}
              file={document}
            />
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
