import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ChecklistModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { checklistTemplatesHelper } from "../queries/GetChecklistTemplates";

interface Params {
  checklistUUID: UUID;
}
interface Input {
  name?: string;
}
const useUpdateChecklistTemplate = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/templates/checklists/${params.checklistUUID}/edit/`;
      return await axios
        .put<ChecklistModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      checklistTemplatesHelper.updateChecklist(data);
      toastNotification.success({
        title: "Template actualizado",
        message: "Template actualizado correctamente",
      });
    },
  });
};

export default useUpdateChecklistTemplate;
