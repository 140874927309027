/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiDropzone from "components/SuiDropzone";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import SuiButton from "components/SuiButton";
import { useParams, useNavigate } from "react-router-dom";
import { createBuilding } from "redux/actions/communities";
import { useSelector, useDispatch } from "react-redux";
import ManageBuildings from "./ManageBuildings";
import { getBuildingsByCommunity } from "redux/actions/communities";

function Form({ close }) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [buildingSelected, setbuildingSelected] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const getBuildingsByCommunityResponse = useSelector(
    (state) => state.communities.getBuildingsByCommunity
  );
  const deleteBuildingResponse = useSelector((state) => state.communities.deleteBuilding);
  const editBuildingResponse = useSelector((state) => state.communities.editBuilding);

  const [name, setName] = useState("");
  const handleCreateArea = () => {
    dispatch(
      createBuilding(slug, {
        name,
        community_slug: slug,
      })
    );
    close();
  };

  useEffect(() => {
    getBuildingsByCommunity(slug)(dispatch);
  }, []);

  useEffect(() => {
    if (getBuildingsByCommunityResponse.data) {
      setBuildings(getBuildingsByCommunityResponse.data);
    }
  }, [getBuildingsByCommunityResponse]);

  useEffect(() => {
    if (deleteBuildingResponse.data) {
      if (new Date() - deleteBuildingResponse.time < 2000) getBuildingsByCommunity(slug)(dispatch);
    }
  }, [deleteBuildingResponse]);

  useEffect(() => {
    if (editBuildingResponse.data) {
      if (new Date() - editBuildingResponse.time < 2000) getBuildingsByCommunity(slug)(dispatch);
    }
  }, [editBuildingResponse]);

  return (
    <Card id="basic-info" sx={{ overflow: "scroll" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Agregar Torre</SuiTypography>
      </SuiBox>
      <SuiBox
        component="form"
        pb={3}
        px={3}
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateArea();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox pt={2}>
              <FormField
                label="Nombre de la torre"
                placeholder="Torre 1"
                onChange={(e) => setName(e.target.value)}
              />
            </SuiBox>
          </Grid>
          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" size="small" onClick={handleCreateArea}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
      <SuiBox px={3}>
        {buildings.map((item) => (
          <ManageBuildings item={item} />
        ))}
      </SuiBox>
    </Card>
  );
}

export default Form;
