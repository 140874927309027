import { MaintenanceModel } from "api/services/maintenances/models";
import SwappiDataTable from "components/SwappiTable";
import React from "react";
import { createColumns } from "./table.helper";

const CertificationsTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const columns = createColumns();

  return (
    <>
      <SwappiDataTable columns={columns} data={maintenance.certifications} />
    </>
  );
};
export default CertificationsTable;
