import { useMutation } from "@tanstack/react-query";
import { ErrorMessage } from "api/utils/errors";
import QUERY_KEYS from "api/utils/keys";
import { TicketQueryHelper } from "api/services/tickets/queries/GetTicket";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { queryClient } from "api/config/queryClient";
import { API_URL } from "redux/actions/types";

interface Params {
  pollUUID: UUID;
  ticketUUID?: UUID;
}
const useArchivePoll = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/polls/delete/${params.pollUUID}/`;
      return await axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      if (params.ticketUUID)
        TicketQueryHelper.archivePoll(params.pollUUID, params.ticketUUID);
      toastNotification.success({
        title: "Votación archivada",
        message: "La encuesta ha sido archivada correctamente",
      });
    },
    onError: (error: ErrorMessage) => {
      toastNotification.error({
        title: "Error al archivar votación",
        message: error.data.message,
      });
    },
  });
};

export default useArchivePoll;
