import { Box, Grid } from "@mui/material";
import { CommunityModel } from "api/services/communities/models";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import React, { useRef } from "react";
import { ModalRefHandle } from "components/ui/BaseModal";
import SuiBox, { SuiBoxTs } from "components/SuiBox";
import { SuiButtonTs } from "components/SuiButton";
import ManageUserCommunitiesModal from "../users/management/ManageUserCommunitiesModal";
import { UserModel } from "api/services/user/models";
import { useGetCommunitiesByUser } from "../../../api/services/communities/queries/GetCommunities";

const CommunitiesViewer: React.FC<{
  communities: CommunityModel[];
  user: UserModel;
}> = ({ communities, user }) => {
  const manageCommunitiesModalRef = useRef<ModalRefHandle>(null);
  const getCommunitiesByUser = useGetCommunitiesByUser(true, user.uuid);

  const content = () => {
    if (communities.length === 0)
      return (
        <Box textAlign={"center"}>
          <h4>No hay comunidades para mostrar</h4>
        </Box>
      );

    return (
      <div>
        <Grid container spacing={3}>
          {!getCommunitiesByUser.isLoading &&
            getCommunitiesByUser?.data?.map((community) => {
              return (
                <Grid item key={community.slug} xs={12} md={6} xl={3}>
                  <SimpleBlogCard
                    community={community}
                    title={community.name}
                    editable={false}
                    action={{
                      type: "internal",
                      route: "/communities/" + community.slug,
                      color: "info",
                      label: "Ver",
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  };
  return (
    <SuiBoxTs>
       <ManageUserCommunitiesModal
          ref={manageCommunitiesModalRef}
          user={user}
        />
      {" "}
      <SuiBoxTs display="flex" justifyContent="flex-end">
        <SuiButtonTs
          size="small"
          color="info"
          variant="gradient"
          onClick={() => {
            manageCommunitiesModalRef.current.open();
          }}
        >
          Asignar Comunidades
        </SuiButtonTs>
      </SuiBoxTs>
      {content()}
    </SuiBoxTs>
  );
};

export default CommunitiesViewer;
