import { ISODateString } from "types/models";
import * as yup from "yup";
interface Form {
  title: string;
  start: ISODateString[];
  end: ISODateString[];
  comment: string;
}
export const scheme: yup.SchemaOf<Form> = yup.object().shape({
  title: yup.string(),
  start: yup.array().of(yup.string()),
  end: yup.array().of(yup.string()),
  comment: yup.string(),
});
