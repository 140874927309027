import AdminCommunities from 'layouts/pages/communities/adminCommunities';
import React from 'react';

const CommunitiesPage = () => {
  return (
    <>
      <AdminCommunities />
    </>
  );
};

export default CommunitiesPage;
