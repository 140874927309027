import { types, API_URL } from './types';
import Axios from 'axios';
import { queryClient } from 'api/config/queryClient';
import QUERY_KEYS from 'api/utils/keys';

/* GET TICKETS BY COMMUNITY */
export const getTicketsByCommunity = (slug, params) => {
  return (dispatch) => {
    const url = `${API_URL}/tickets/${slug}`;
    let outputData = {};
    return Axios.get(url, params).then((res) => {
      console.log('getTicketsByCommunity', { url, params, data: res.data });
      outputData['data'] = res.data;
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      dispatch(setGetTicketsByCommunity(outputData));
    });
  };
};

/* SET GET TICKETS BY COMMUNITY */
export const setGetTicketsByCommunity = (data) => ({
  type: types.setGetTicketsByCommunity,
  payload: data,
});

/* CREATE TICKET */
export const createTicket = (slug, d) => {
  const url = `${API_URL}/tickets/${slug}/create/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      if (res.data.error) {
        dispatch(setCreateTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: 'Solicitud ' + d.title + ' ha sido creado exitosamente!',
          title: 'Solicitud creada',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setCreateTicket(outputData));
        dispatch(setNotification(notification));
      }
    });
  };
};

/* SET CREATE TICKET */
export const setCreateTicket = (data) => ({
  type: types.setCreateTicket,
  payload: data,
});

/** getTicketByUuId */
export const getTicketByUuId = (uuId) => {
  return (dispatch) => {
    const url = `${API_URL}/tickets/by-uuid/${uuId}`;
    let outputData = {};
    return Axios.get(url).then((res) => {
      outputData['data'] = res.data;
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      dispatch(setGetTicketByUuId(outputData));
    });
  };
};

/** setGetTicketByUuId */
export const setGetTicketByUuId = (data) => ({
  type: types.setGetTicketByUuId,
  payload: data,
});

/* ADD COMMENT TO TICKET */
export const addCommentToTicket = (slug, d) => {
  const url = `${API_URL}/tickets/comment/add/ticket/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: 'Comentario creado exitosamente!',
          title: 'Comentario creado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setAddCommentToTicket(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET, slug]);
      }
    });
  };
};

/* SET ADD COMMENT TO TICKET */
export const setAddCommentToTicket = (data) => ({
  type: types.setAddCommentToTicket,
  payload: data,
});

export const deleteCommentFromTicket = (slug, d) => {
  const url = `${API_URL}/tickets/comment/delete/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.delete(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: 'Comentario eliminado exitosamente!',
          title: 'Comentario eliminado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setDeleteCommentFromTicket(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      }
    });
  };
};

export const setDeleteCommentFromTicket = (data) => ({
  type: types.setDeleteCommentFromTicket,
  payload: data,
});

/* ADD CHECKLIST TO TICKET */
export const addChecklistToTicket = (slug, d) => {
  const url = `${API_URL}/add-checklist/object/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: 'El checklist ha sido agregado correctamente a la solicitud',
          title: 'Checklist agregado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setAddChecklistToTicket(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET, slug]);
      }
    });
  };
};

export const setAddChecklistToTicket = (data) => ({
  type: types.setAddChecklistToTicket,
  payload: data,
});

export const addItemToCheckList = (slug, d) => {
  const url = `${API_URL}/tickets/checklist-item/add/checklist/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: 'Item agregado a checklist correctamente!',
          title: 'Item agregado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setAddItemToCheckList(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      }
    });
  };
};

export const setAddItemToCheckList = (data) => ({
  type: types.setAddItemToCheckList,
  payload: data,
});

export const markChecklistItem = (slug) => {
  const url = `${API_URL}/tickets/checklist-item/mark-as-done/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, {}).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'success',
          message: `Item marcado como ${res.data.done ? 'listo' : 'no hecho'}, guardado con éxito!`,
          title: 'Estado item checklist',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setMarkChecklistItem(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET, slug]);
      }
    });
  };
};

export const setMarkChecklistItem = (data) => ({
  type: types.setMarkChecklistItem,
  payload: data,
});

export const updateItemName = (slug, d) => {
  const url = `${API_URL}/tickets/checklist-item/rename/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'info',
          message: 'Item actualizado correctamente',
          title: 'Item Actualizado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setUpdateItemName(outputData));
        dispatch(setNotification(notification));
      }
    });
  };
};

export const setUpdateItemName = (data) => ({
  type: types.setUpdateItemName,
  payload: data,
});

export const deleteChecklistItem = (slug) => {
  const url = `${API_URL}/tickets/checklist-item/delete/${slug}`;
  return async (dispatch) => {
    console.log({ toDelete: slug });
    return await Axios.delete(url).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        const { status, message, data } = res;
        dispatch(setDeleteChecklistItem({ status, message, data }));
        dispatch(
          setNotification({
            status: 'warning',
            message: 'El item del checklist ha sido eliminado',
            title: 'Item eliminado',
          })
        );
        console.log('deleteChecklistItem', { data });
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
        return slug;
      }
    });
  };
};

export const setDeleteChecklistItem = (data) => ({
  type: types.setDeleteChecklistItem,
  payload: data,
});

export const deleteChecklist = (slug) => {
  const url = `${API_URL}/tickets/delete-checklist/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.delete(url).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'warning',
          message: 'El checklist ha sido eliminado de la solicitud',
          title: 'Checklist eliminado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setDeleteChecklist(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      }
    });
  };
};

export const setDeleteChecklist = (data) => ({
  type: types.setDeleteChecklist,
  payload: data,
});

export const ticketGoForward = (slug, d) => {
  const url = `${API_URL}/tickets/${slug}/go-forward/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      if (res.data.error) {
        let notification = {
          status: 'error',
          message: 'No se pudo avanzar el ticket',
          title: 'Ticket no actualizado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setTicketGoForward(outputData));
        dispatch(setNotification(notification));
      } else {
        let notification = {
          status: 'info',
          message: 'El estado de la solicitud ha sido actualizada a ' + res.data.ticket.status,
          title: 'Estado actualizado',
        };
        queryClient.invalidateQueries([QUERY_KEYS.TICKET, slug]);
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setTicketGoForward(outputData));
        dispatch(setNotification(notification));
      }
    });
  };
};

export const setTicketGoForward = (data) => ({
  type: types.setTicketGoForward,
  payload: data,
});

export const ticketGoBack = (slug) => {
  const url = `${API_URL}/tickets/${slug}/go-back/`;
  return (dispatch) => {
    return Axios.post(url).then((res) => {
      const { status, message, data } = res;
      console.log('ticket go back', { data });
      if (res.data.error) {
        dispatch(setTicketGoBack({ status, message, data }));
        dispatch(
          setNotification({
            status: 'error',
            message: 'No se pudo retroceder el ticket',
            title: 'Ticket no actualizado',
          })
        );
      } else {
        queryClient.invalidateQueries([QUERY_KEYS.TICKET, slug]);
        dispatch(setTicketGoBack({ status, message, data }));
        dispatch(
          setNotification({
            status: 'info',
            message: 'El estado de la solicitud ha sido actualizada a  ' + res.data.ticket.status,
            title: 'Estado actualizado',
          })
        );
      }
    });
  };
};

export const setTicketGoBack = (data) => ({
  type: types.setTicketGoBack,
  payload: data,
});

export const updateTicketArea = (slug, d) => {
  const url = `${API_URL}/tickets/add-area/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'info',
          message: 'El área ha sido actualizada correctamente.',
          title: 'Área actualizada',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setUpdateTicketArea(outputData));
        dispatch(setNotification(notification));
      }
    });
  };
};

export const setUpdateTicketArea = (data) => ({
  type: types.setUpdateTicketArea,
  payload: data,
});

export const addDocumentToTicket = (d) => {
  const url = `${API_URL}/documents/add/`;

  return (dispatch) => {
    let outputData = {};
    let headers = { 'Content-Type': 'multipart/form-data' };
    Axios.post(url, d, { headers })
      .then((res) => {
        if (res.data.error) {
          let notification = {
            status: 'error',
            message: res.data.message,
            title: 'Documento no creado',
          };
          outputData['status'] = res.status;
          outputData['message'] = res.message;
          outputData['data'] = res.data;
          dispatch(setAddDocumentToTicket(outputData));
          dispatch(setNotification(notification));
        } else {
          outputData['status'] = res.status;
          outputData['message'] = res.message;
          outputData['data'] = res.data;
          let notification = {
            status: 'success',
            message: 'El documento ha sido agregado con éxito!',
            title: 'Documento agregado',
          };
          dispatch(setNotification(notification));
          dispatch(setAddDocumentToTicket(outputData));
        }
      })
      .catch(() => {
        outputData.error = true;
        outputData.message = 'Something went wrong, please try again';
        outputData.status = 'danger';
      });
  };
};

export const setAddDocumentToTicket = (data) => ({
  type: types.setAddDocumentToTicket,
  payload: data,
});

export const setNotification = (data) => ({
  type: types.setNotification,
  payload: data,
});

export const assignTicketToUser = (slug, d) => {
  let url = `${API_URL}/tickets/${slug}/assign-user/`;

  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'info',
          message: 'El usuario ha sido asignado correctamente.',
          title: 'Usuario asignado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setAssignTicketToUser(outputData));
        dispatch(setNotification(notification));
      }
    });
  };
};

export const setAssignTicketToUser = (data) => ({
  type: types.setAssignTicketToUser,
  payload: data,
});

export const getTicketsByManagement = (slug, params) => {
  const url = `${API_URL}/tickets/by-management/${slug}`;
  return async (dispatch) => {
    let outputData = {};
    return Axios.get(url, { params }).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setGetTicketsByManagement(outputData));
      }
    });
  };
};

export const setGetTicketsByManagement = (data) => ({
  type: types.setGetTicketsByManagement,
  payload: data,
});

export const updateTicket = (slug, d) => {
  console.log({ slug, d });
  const url = `${API_URL}/tickets/edit/${slug}/`;
  return (dispatch) => {
    return Axios.put(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        const { status, message, data } = res;
        dispatch(setUpdateTickets({ status, message, data }));
        dispatch(
          setNotification({
            status: 'info',
            message: 'La solicitud ha sido actualizada.',
            title: 'Solicitud actualizada',
          })
        );
        queryClient.setQueryData([QUERY_KEYS.TICKET, res.data.uuid], res.data);
      }
    });
  };
};

export const setUpdateTickets = (data) => ({
  type: types.setUpdateTickets,
  payload: data,
});

export const editChecklistName = (slug, d) => {
  const url = `${API_URL}/tickets/edit-checklist/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, d).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'info',
          message: 'El nombre del checklist ha sido actualizado.',
          title: 'Checklist actualizado',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setEditChecklistName(outputData));
        dispatch(setNotification(notification));
        // WARNING

        // queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      }
    });
  };
};

export const setEditChecklistName = (data) => ({
  type: types.setEditChecklistName,
  payload: data,
});

export const archiveTicket = (slug) => {
  const url = `${API_URL}/tickets/archive/${slug}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url).then((res) => {
      if (res.data.error) {
        //dispatch(setAddCommentToTicket(outputData));
      } else {
        let notification = {
          status: 'info',
          message: 'La solicitud ha sido archivada.',
          title: 'Solicitud archivada',
        };
        outputData['status'] = res.status;
        outputData['message'] = res.message;
        outputData['data'] = res.data;
        dispatch(setArchiveTicket(outputData));
        dispatch(setNotification(notification));
        queryClient.invalidateQueries([QUERY_KEYS.TICKETS_TABLE]);
      }
    });
  };
};

export const setArchiveTicket = (data) => ({
  type: types.setArchiveTicket,
  payload: data,
});

export const getPublicTicket = (slug) => {
  const url = `${API_URL}/tickets/public/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        outputData['status'] = 200;
        outputData['message'] = 'OK';
        outputData['data'] = data;
        dispatch(setGetPublicTicket(outputData));
      })
      .catch((error) => {
        dispatch(setGetPublicTicket(error));
      });
  };
};

export const setGetPublicTicket = (data) => ({
  type: types.setGetPublicTicket,
  payload: data,
});

export const getTicketsExcelManagement = (slug) => {
  const url = `${API_URL}/tickets/export/management/${slug}`;
  return () => {
    return Axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/vnd.ms-excel' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xls'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const setGetTicketsExcelManagement = (data) => ({
  type: types.setGetTicketsExcelManagement,
  payload: data,
});

export const getTicketsExcelCommunity = (slug) => {
  const url = `${API_URL}/tickets/export/community/${slug}`;
  return () => {
    return Axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/vnd.ms-excel' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xls'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const setGetTicketsExcelCommunity = (data) => ({
  type: types.setGetTicketsExcelCommunity,
  payload: data,
});

export const getTicketsPDFManagement = (slug) => {
  const url = `${API_URL}/tickets/export/management/${slug}/pdf`;
  return () => {
    return Axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const setGetTicketsPDFManagement = (data) => ({
  type: types.setGetTicketsPDFManagement,
  payload: data,
});

export const getTicketsPDFCommunity = (slug) => {
  const url = `${API_URL}/tickets/export/community/${slug}/pdf`;
  return () => {
    return Axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const setGetTicketsPDFCommunity = (data) => ({
  type: types.setGetTicketsPDFCommunity,
  payload: data,
});

export const getTicketsTable = (d) => {
  const url = `${API_URL}/tickets/export/`;
  return (dispatch) => {
    return Axios.post(url, d, { responseType: 'arraybuffer' }).then((res) => {
      if (d.type === 'pdf') {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else if (d.type === 'excel') {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      dispatch(setGetTicketsPDFCommunity({ data: true }));
    });
  };
};

export const getTicketPDF = (slug, d, code) => {
  const url = `${API_URL}/tickets/${slug}/export/`;
  return (dispatch) => {
    return Axios.post(url, d, { responseType: 'arraybuffer' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', code + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(setGetTicketPDF({ data: true }));
    });
  };
};

export const setGetTicketPDF = (data) => ({
  type: types.setGetTicketPDF,
  payload: data,
});

export const editComment = (slug, data) => {
  const url = `${API_URL}/tickets/comment/edit/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setEditComment(outputData));
      let notification = {
        status: 'info',
        message: 'El comentario ha sido actualizado.',
        title: 'Comentario actualizado',
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
    });
  };
};

export const setEditComment = (data) => ({
  type: types.setEditComment,

  payload: data,
});

export const createChecklistTemplate = (data) => {
  const url = `${API_URL}/tickets/templates/checklists/create`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setCreateChecklistTemplate(outputData));
      let notification = {
        status: 'success',
        message: 'El template de checklist ha sido creado.',
        title: 'Template creado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setCreateChecklistTemplate = (data) => ({
  type: types.setCreateChecklistTemplate,
  payload: data,
});

export const getChecklistsTemplate = () => {
  const url = `${API_URL}/tickets/templates/checklists/all/`;
  return (dispatch) => {
    let outputData = [];
    return Axios.get(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setGetChecklistsTemplate(outputData));
    });
  };
};

export const setGetChecklistsTemplate = (data) => ({
  type: types.setGetChecklistsTemplate,
  payload: data,
});

export const createChecklistTemplateItem = (uuid, data) => {
  const url = `${API_URL}/tickets/templates/checklists/${uuid}/add-item/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setCreateChecklistTemplateItem(outputData));
      let notification = {
        status: 'success',
        message: 'El template de checklist ha sido creado.',
        title: 'Template creado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setCreateChecklistTemplateItem = (data) => ({
  type: types.setCreateChecklistTemplateItem,
  payload: data,
});

export const editChecklistTemplateItem = (uuid, data) => {
  const url = `${API_URL}/tickets/templates/checklist-items/${uuid}/edit/`;
  return (dispatch) => {
    return Axios.put(url, data).then((res) => {
      dispatch(setEditChecklistTemplateItem(res.data));
      let notification = {
        status: 'info',
        message: 'El template de checklist ha sido actualizado.',
        title: 'Template actualizado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setEditChecklistTemplateItem = (data) => ({
  type: types.setEditChecklistTemplateItem,
  payload: data,
});

export const editChecklistTemplate = (uuid, data) => {
  const url = `${API_URL}/tickets/templates/checklists/${uuid}/edit/`;
  return (dispatch) => {
    return Axios.put(url, data).then((res) => {
      dispatch(setEditChecklistTemplate(res.data));
      let notification = {
        status: 'info',
        message: 'El template de checklist ha sido actualizado.',
        title: 'Template actualizado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setEditChecklistTemplate = (data) => ({
  type: types.setEditChecklistTemplate,
  payload: data,
});

export const deleteChecklistTemplate = (uuid) => {
  const url = `${API_URL}/tickets/templates/checklists/${uuid}/delete/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.delete(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setDeleteChecklistTemplate(outputData));
      let notification = {
        status: 'error',
        message: 'El template de checklist ha sido eliminado.',
        title: 'Template eliminado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setDeleteChecklistTemplate = (data) => ({
  type: types.setDeleteChecklistTemplate,
  payload: data,
});

export const deleteChecklistTemplateItem = (uuid) => {
  const url = `${API_URL}/tickets/templates/checklist-item/${uuid}/delete`;
  return (dispatch) => {
    return Axios.delete(url).then((res) => {
      dispatch(setDeleteChecklistTemplateItem(res.data));
      let notification = {
        status: 'error',
        message: 'El template de checklist ha sido eliminado.',
        title: 'Template eliminado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setDeleteChecklistTemplateItem = (data) => ({
  type: types.setDeleteChecklistTemplateItem,
  payload: data,
});

export const saveChecklistAsTemplate = (uuid, data) => {
  const url = `${API_URL}/tickets/save-template/checklist/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setSaveChecklistAsTemplate(res.data));
      let notification = {
        status: 'success',
        message: 'El checklist ha sido guardado como checklist',
        title: 'Template creado',
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
    });
  };
};

export const setSaveChecklistAsTemplate = (data) => ({
  type: types.setSaveChecklistAsTemplate,
  payload: data,
});

export const addChecklistToTicketFromTemplate = (uuid, data) => {
  const url = `${API_URL}/tickets/checklists/create/template/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;

      dispatch(setAddChecklistToTicketFromTemplate(outputData));
      let notification = {
        status: 'success',
        message: 'El checklist ha sido agregado al ticket',
        title: 'Template creado',
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
    });
  };
};

export const setAddChecklistToTicketFromTemplate = (data) => ({
  type: types.setAddChecklistToTicketFromTemplate,
  payload: data,
});

export const getChecklistTemplate = (uuid) => {
  const url = `${API_URL}/tickets/templates/checklists/${uuid}/`;
  return (dispatch) => {
    return Axios.get(url).then((res) => {
      dispatch(setGetChecklistTemplate(res.data));
    });
  };
};

export const setGetChecklistTemplate = (data) => ({
  type: types.setGetChecklistTemplate,
  payload: data,
});

export const getUFPrice = () => {
  return (dispatch) => {
    fetch('https://mindicador.cl/api')
      .then(function (response) {
        return response.json();
      })
      .then(function (dailyIndicators) {
        let outputData = {};
        outputData['data'] = { uf: dailyIndicators.uf.valor };
        dispatch(setGetUFPrice(outputData));
      });
  };
};

export const setGetUFPrice = (data) => ({
  type: types.setGetUFPrice,
  payload: data,
});

export const updateTicketTimeline = (uuid) => {
  const url = `${API_URL}/tickets/${uuid}/change-timeline/`;
  return (dispatch) => {
    return Axios.post(url).then((res) => {
      const { status, message, data } = res;
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
      dispatch(setUpdateTicketTimeline({ status, message, data }));
    });
  };
};

export const setUpdateTicketTimeline = (data) => ({
  type: types.setUpdateTicketTimeline,
  payload: data,
});

export const showTicketToComitee = (uuid) => {
  const url = `${API_URL}/tickets/${uuid}/show-to-comitee/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.put(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setShowTicketToComitee(outputData));
      let notification = {
        status: 'success',
        message: 'La visualización del ticket ha sido modificada',
        title: 'Visualización modificada',
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
    });
  };
};

export const setShowTicketToComitee = (data) => ({
  type: types.setShowTicketToComitee,
  payload: data,
});

export const finishTicketTimeline = (uuid) => {
  const url = `${API_URL}/tickets/${uuid}/finish-timeline/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setFinishTicketTimeline(outputData));
      let notification = {
        status: 'success',
        message: 'El ticket ha sido finalizado',
        title: 'Ticket finalizado',
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
    });
  };
};

export const setFinishTicketTimeline = (data) => ({
  type: types.setFinishTicketTimeline,
  payload: data,
});

export const getMembersForTicketAssignment = (uuid) => {
  console.log({ uuid });
  const url = `${API_URL}/tickets/${uuid}/possible-assignees/`;
  let outputData = {};
  return (dispatch) => {
    return Axios.get(url).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;
      dispatch(setGetMembersForTicketAssignment(outputData));
    });
  };
};

export const setGetMembersForTicketAssignment = (data) => ({
  type: types.setGetMembersForTicketAssignment,
  payload: data,
});

export const createTicketRecurrent = (slug, data) => {
  data.community_slug = slug;
  const url = `${API_URL}/tickets/create-recurrent/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, data).then((res) => {
      outputData['status'] = res.status;
      outputData['message'] = res.message;
      outputData['data'] = res.data;

      dispatch(setCreateTicketRecurrent(outputData));
      let notification = {
        status: 'success',
        message: 'El ticket recurrente ha sido creado',
        title: 'Ticket recurrente creado',
      };
      dispatch(setNotification(notification));
    });
  };
};

export const setCreateTicketRecurrent = (data) => ({
  type: types.setCreateTicketRecurrent,
  payload: data,
});

export const toggleNotifyTimeline = (uuid) => {
  const url = `${API_URL}/tickets/${uuid}/toggle-step-notifications/`;
  return (dispatch) => {
    return Axios.post(url).then((res) => {
      const { status, message, data } = res;
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
      dispatch(setToggleNotifyTimeline({ status, message, data }));
      dispatch(
        setNotification({
          status: 'success',
          message: 'La notificación ha sido modificada',
          title: 'Notificación modificada',
        })
      );
    });
  };
};

export const setToggleNotifyTimeline = (data) => ({
  type: types.setToggleNotifyTimeline,
  payload: data,
});

export const assignAllComitees = (uuid) => {
  const url = `${API_URL}/tickets/${uuid}/assign-to-comitee/`;
  return (dispatch) => {
    return Axios.put(url).then((res) => {
      const { status, message, data } = res;
      queryClient.invalidateQueries([QUERY_KEYS.TICKET, uuid]);
      dispatch(setAssignAllComitees({ status, message, data }));
      dispatch(
        setNotification({
          status: 'success',
          message: 'El ticket ha sido asignado',
          title: 'Ticket asignado',
        })
      );
    });
  };
};

export const setAssignAllComitees = (data) => ({
  type: types.setAssignAllComitees,
  payload: data,
});
