import { Dialog, DialogContent } from '@mui/material';
import React, { useState, JSX, FC } from 'react';

interface Props {
  content: JSX.Element | any;
  renderButton: (cb: () => void) => JSX.Element | any;
}

const ShowMoreDialog: FC<Props> = ({ content, renderButton }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  return (
    <section>
      {renderButton(handleOpen)}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </section>
  );
};

export default ShowMoreDialog;
