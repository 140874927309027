import { useState, useEffect, FC } from "react";

import { SuiTypographyTs } from "components/SuiTypography";
import { SuiButtonTs } from "components/SuiButton";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { Box, CircularProgress, ListItem, ListItemButton } from "@mui/material";
import DropdownMenu, { DropdownMenuItem } from "components/DropdownMenu";
import { Delete } from "@mui/icons-material";

import {
  ChecklistItemModel,
  ChecklistModel,
} from "api/services/checklist/models";
import { ColorsTheme } from "types/styles.enum";
import { useForm } from "react-hook-form";
import InputControlled from "components/forms/controlled/InputControlled";
import useUpdateChecklistTemplateItem from "api/services/checklist/mutations/UpdateChecklistTemplateItem";
import useDeleteChecklistTemplateItem from "api/services/checklist/mutations/DeleteChecklistTemplateItem";
const { borderWidth } = borders;

interface Props {
  item: ChecklistItemModel;
  checklist: ChecklistModel;
}
const color = ColorsTheme.Info;
const borderStyle = `${borderWidth[3]} solid ${colors[color].main}`;

interface FormValues {
  name: string;
}
const Item: FC<Props> = ({ item, checklist }) => {
  const [isEditing, setIsEditing] = useState(false);
  const updateItem = useUpdateChecklistTemplateItem({
    itemUUID: item.uuid,
    checklistUUID: checklist.uuid,
  });
  const deleteItem = useDeleteChecklistTemplateItem({
    itemUUID: item.uuid,
    checklistUUID: checklist.uuid,
  });
  const { control, reset, handleSubmit } = useForm<FormValues>();

  useEffect(() => {
    reset({ name: item.name });
  }, [item]);

  const onSubmit = handleSubmit((data) => {
    updateItem.mutate(
      { name: data.name },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      }
    );
  });
  const onDelete = () => {
    deleteItem.mutate(null, {});
  };
  const onDisableEdit = () => {
    setIsEditing(false);
  };
  const onEnableEdit = () => {
    setIsEditing(true);
  };
  const menuItems: DropdownMenuItem[] = [
    {
      label: "Eliminar",
      icon: <Delete />,
      onClick: onDelete,
    },
  ];

  return (
    <ListItem sx={{ my: 1 }}>
      {isEditing ? (
        <Box display={"flex"} sx={{ flex: 1, gap: 1 }}>
          <Box>
            <SuiTypographyTs variant="button" fontWeight="medium">
              Nombre:
            </SuiTypographyTs>
          </Box>
          <Box sx={{ flex: 1 }}>
            <InputControlled
              control={control}
              name="name"
              placeholder="Actividad"
            />
          </Box>
          <Box>
            <SuiButtonTs
              sx={{ mr: 1 }}
              variant="gradient"
              color="secondary"
              size="small"
              onClick={onDisableEdit}
            >
              Cancelar
            </SuiButtonTs>
            <SuiButtonTs
              variant="gradient"
              color="success"
              size="small"
              onClick={onSubmit}
            >
              <Box width={80}>
                {deleteItem.isPending ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  "Guardar"
                )}
              </Box>
            </SuiButtonTs>
          </Box>
        </Box>
      ) : (
        <ListItemButton sx={{ gap: 2, borderLeft: borderStyle, py: 0 }}>
          <Box sx={{ flex: 1 }} onClick={() => true && onEnableEdit()}>
            <SuiTypographyTs variant="button" fontWeight="medium">
              {item.name || <br />}
            </SuiTypographyTs>
          </Box>
          {isEditing === false && <DropdownMenu items={menuItems} />}
        </ListItemButton>
      )}
    </ListItem>
  );
};

export default Item;
