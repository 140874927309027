import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import InputControlled from "components/forms/controlled/InputControlled";
import { SuiButtonTs } from "components/SuiButton";
import React from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { scheme } from "./form.helpers";
import useEventEmitter from "lib/events/useEventEmitter";
import useCreateVisit from "api/services/visits/mutations/createVisit";
import { queryClient } from "api/config/queryClient";
import { GET_MAINTENANCE_KEY } from "api/services/maintenances/queries/getMaintenance";

interface Props {
  maintenance: MaintenanceModel;
}

export const EVENT_VISIT_CREATED = "visitCreated";

const CreateMaintenanceVisitForm: React.FC<Props> = ({ maintenance }) => {
  const eventVisitCreated = useEventEmitter(EVENT_VISIT_CREATED);
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(scheme),
  });
  const { mutate: createVisit } = useCreateVisit();
  const onSubmit = handleSubmit((data) => {
    createVisit(
      {
        title: data.name,
        start: data.start_date[0] && format(data.start_date[0], "yyyy-MM-dd"),
        end: data.end_date[0] ? format(data.end_date[0], "yyyy-MM-dd") : null,
        community: maintenance.community.slug,
        maintenance: maintenance.uuid,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: [GET_MAINTENANCE_KEY, maintenance.uuid],
          });
          eventVisitCreated.emit(data);
        },
      }
    );
  });
  return (
    <form onSubmit={onSubmit}>
      <Typography>Agregar visita</Typography>
      <Stack spacing={1}>
        <InputControlled
          label="Nombre"
          control={control}
          name="name"
          placeholder="Nombre de visita"
        />
        <DatePickerControlled
          control={control}
          label="Fecha de inicio"
          name="start_date"
          options={{
            maxDate: watch("end_date")?.[0] && new Date(watch("end_date")[0]),
            enableTime: false,
          }}
        />
        <DatePickerControlled
          control={control}
          label="Fecha de expiración"
          name="end_date"
          options={{
            minDate:
              watch("start_date")?.[0] && new Date(watch("start_date")[0]),
            enableTime: false,
          }}
        />

        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <SuiButtonTs
            disabled={!isDirty || !isValid}
            size="small"
            color="success"
            variant="gradient"
            type="submit"
          >
            Guardar
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default CreateMaintenanceVisitForm;
