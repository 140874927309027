import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./api/config/authAxios";
import { Provider, connect } from "react-redux";

import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

import { SoftUIControllerProvider } from "context";
import { rootReducer } from "./redux/reducers/rootReducer";
import App from "App";
import { queryClient } from "api/config/queryClient";

// @ts-expect-error expect error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const mapStateToProps = () => {
  return {
    isAuthenticated: !(
      localStorage.getItem("access-token") === "null" ||
      localStorage.getItem("access-token") === "undefined" ||
      localStorage.getItem("access-token") === null
    ),
  };
};

// Soft UI Context Provider

const Component = () => {
  return (
    <SoftUIControllerProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SoftUIControllerProvider>
  );
};
const Container = connect(mapStateToProps)(Component);

const Application = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Container />
    </Provider>
  </QueryClientProvider>
);
// eslint-disable-next-line react/no-deprecated
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<Application />);
