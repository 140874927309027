/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// Images
import img from "assets/images/principal-login.svg";
import { resetPassword } from "redux/actions/auth";
import { resetPasswordConfirm } from "redux/actions/auth";

function Cover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changePasswordResponse = useSelector((state) => state.auth.resetPasswordConfirm);
  const { uuid, token } = useParams();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    if (changePasswordResponse.data) {
      navigate("/auth/login");
    }
  }, [changePasswordResponse]);
  return (
    <CoverLayout title="Recuperar contraseña" image={img} top={30}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Contraseña
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="correo"
            placeholder="Ingresa tu contraseña"
            onChange={(e) => setPassword(e.target.value)}
          />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Repetir Contraseña
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="correo"
            placeholder="Repite tu contraseña"
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                resetPasswordConfirm(uuid, token, { password, password_repeat: repeatPassword })
              );
            }}
          >
            Recuperar
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default Cover;
