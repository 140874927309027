export function customSort(arr) {
  // Verificar si el arreglo contiene algún objeto con name = "superadmin"
  const hasSuperadmin = arr.some((obj) => {
    if (obj.groups && Array.isArray(obj.groups)) {
      return obj.groups.some((group) => group.name === "superadmin");
    }
    return false;
  });

  // Si no hay "superadmin" en el arreglo, realizar un sort alfabético normal
  if (!hasSuperadmin) {
    return arr.sort((a, b) => a.groups[0].name.localeCompare(b.groups[0].name));
  }

  // Clonar el arreglo para evitar modificar el original
  const sortedArr = [...arr];

  // Realizar el sort personalizado
  sortedArr.sort((a, b) => {
    const aIsSuperadmin = a.groups.some((group) => group.name === "superadmin");
    const bIsSuperadmin = b.groups.some((group) => group.name === "superadmin");

    if (aIsSuperadmin) {
      return -1; // Colocar "superadmin" al principio
    } else if (bIsSuperadmin) {
      return 1; // Colocar "superadmin" al principio
    } else {
      return a.groups[0].name.localeCompare(b.groups[0].name); // Ordenar alfabéticamente por name
    }
  });

  return sortedArr;
}
