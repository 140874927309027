import { types, API_URL } from './types';
import Axios from 'axios';
import { setNotification } from './communities';
import { getPermission } from 'utils';

export const getActivity = (params) => {
  const url = `${API_URL}/activity/logs/`;

  return async (dispatch) => {
    let outputData = {};

    try {
      const { status, data } = await Axios.get(url, { params });
      outputData['status'] = status;
      outputData['data'] = data;
    } catch (err) {
      const { status, data } = err?.response || {};
      outputData['status'] = status;
      outputData['message'] = data?.message;
    }

    dispatch(setGetActivity(outputData));
  };
};

export const setGetActivity = (data) => {
  return {
    type: types.setGetActivity,
    payload: data,
  };
};

export const getReadActivity = (params) => {
  const url = `${API_URL}/activity/logs/read/`;
  return async (dispatch) => {
    let outputData = {};
    try {
      const { status, data } = await Axios.get(url, { params });
      outputData['status'] = status;
      outputData['data'] = data;
      dispatch(setGetReadActivity(outputData));
    } catch (err) {
      const { status, data } = err?.response || {};
      outputData['status'] = status;
      outputData['message'] = data?.message;
    }
  };
};

export const setGetReadActivity = (data) => {
  return {
    type: types.setGetReadActivity,
    payload: data,
  };
};

export const markAsRead = (notifications) => {
  const url = `${API_URL}/activity/mark-as-read/`;
  return async (dispatch) => {
    let outputData = {};

    try {
      const { status, data } = await Axios.post(url, { uuids: notifications });
      outputData['status'] = status;
      outputData['data'] = data;
      let notification = {
        status: 'success',
        message: 'Las notificaciones han sido marcadas como leidas!',
        title: 'Notificaciones leidas',
      };
      dispatch(setNotification(notification));
      dispatch(setMarkAsRead(outputData));
    } catch (err) {
      const { status, data } = err?.response || {};
      outputData['status'] = status;
      outputData['message'] = data?.message;
    }
  };
};

export const setMarkAsRead = (data) => {
  return {
    type: types.setMarkAsRead,
    payload: data,
  };
};

export const markAllAsRead = () => {
  const url = `${API_URL}/activity/mark-all-as-read/`;
  return async (dispatch) => {
    let outputData = {};

    try {
      const { status, data } = await Axios.post(url);
      outputData['status'] = status;
      outputData['data'] = data;
      let notification = {
        status: 'success',
        message: 'Las notificaciones han sido marcadas como leidas!',
        title: 'Notificaciones leidas',
      };
      dispatch(setNotification(notification));
      dispatch(setMarkAllAsRead(outputData));
    } catch (err) {
      const { status, data } = err?.response || {};
      outputData['status'] = status;
      outputData['message'] = data?.message;
    }
  };
};

export const setMarkAllAsRead = (data) => {
  return {
    type: types.setMarkAllAsRead,
    payload: data,
  };
};

export const getActivityUnreadCount = () => {
  const url = `${API_URL}/activity/count/`;
  console.log(url);
  return async (dispatch) => {
    let outputData = {};
    try {
      if (getPermission(['superadmin', 'administrador', 'supervisor'])) {
        const { status, data } = await Axios.get(url);
        outputData['status'] = status;
        outputData['data'] = data;
        dispatch(setGetActivityUnreadCount(outputData));
      }
    } catch (err) {
      console.log(err);
      const { status, data } = err?.response || {};
      outputData['status'] = status;
      outputData['message'] = data?.message;
    }
  };
};

export const setGetActivityUnreadCount = (data) => {
  return {
    type: types.setGetActivityUnreadCount,
    payload: data,
  };
};
