import { Delete } from "@mui/icons-material";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { useOpenDocument } from "api/services/documents/actions/openDocument";
import { CertificationModel } from "api/services/certifications/models";
import EditFileDrawer from "components/EditFileDrawer";
import { SuiBadgeTs } from "components/SuiBadge";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { usePermissions } from "stores/authentication/helpers";
import useDeleteCertification from "api/services/certifications/mutations/deleteCertification";
import useEventEmitter from "lib/events/useEventEmitter";
import Swal from "sweetalert2";

const CertificationActions: React.FC<{
  certification: CertificationModel;
}> = ({ certification }) => {
  const document = certification.documents[0];
  const canEditDocuments = usePermissions().documents.edit;
  const { handleAction, isLoading } = useOpenDocument(document.uuid);

  const { mutate: deleteCertification } = useDeleteCertification(
    certification.uuid
  );
  const certificationDeleted = useEventEmitter("certificationDeleted");

  const handleDelete = () => {
    Swal.fire({
      title: "¿Quieres eliminar esta certificación?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCertification(null, {
          onSuccess: (data) => {
            certificationDeleted.emit(data);
          },
        });
      }
    });
  };
  return (
    <Box display={"flex"} gap={2}>
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        container
        color="info"
        onClick={handleAction}
        badgeContent={
          isLoading ? (
            <CircularProgress size="12px" color="inherit" />
          ) : (
            <AiOutlineEye size="12px" />
          )
        }
      />
      {canEditDocuments && (
        <>
          <SuiBadgeTs
            style={{ cursor: "pointer" }}
            color="error"
            variant="gradient"
            container
            onClick={handleDelete}
            badgeContent={<Delete />}
          />
          <EditFileDrawer
            color="warning"
            button={true}
            slugType={document.type.slug}
            file={document}
            community={document.community}
          />
        </>
      )}
    </Box>
  );
};

export default CertificationActions;
