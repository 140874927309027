import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BudgetModel } from "../models";
import { API_URL } from "redux/actions/types";
import { UUID } from "crypto";
interface Input {
  amount: number;
  currency: string;
  year: number;
}

const useCreateMaintenanceBudget = (maintenanceUUID: UUID) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/maintenance/${maintenanceUUID}/budgets/`;
      return await axios.post<BudgetModel>(url, input).then((res) => res.data);
    },
  });
};

export default useCreateMaintenanceBudget;
