import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { UserModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { KEY_GET_USER_STAFF } from "../queries/getUsersStaff";
const useUpdateUserPermissions = (userUUID: UUID) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["useUpdateUserPermissions", userUUID],
    mutationFn: async (input) => {
      const body = {permissions: input}
      const url = `${API_URL}/users/${userUUID}/permissions/`;
      return axios.put<UserModel>(url, body).then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_GET_USER_STAFF] });
      toastNotification.success({
        title: "Permisos actualizados",
        message: "Permisos del usuario actualizado",
      });
    },
  });
};

export default useUpdateUserPermissions;
