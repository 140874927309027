/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';
import SuiProgress from 'components/SuiProgress';
import TicketModal from 'components/Tickets/TicketDetailModal';

// General page component
import { useParams, useNavigate } from 'react-router-dom';
import { getTicketsByCommunity } from 'redux/actions/tickets';
import { ticketStatusColor } from 'utils';
import { ticketPriorityColor } from 'utils';
import Loading from 'components/Loading';
import { BiArchiveIn } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { archiveTicket } from 'redux/actions/tickets';
import { getPermission } from 'utils';
import SuiAvatar from 'components/SuiAvatar';
import Tooltip from '@mui/material/Tooltip';
import TicketsGantt from 'layouts/pages/charts/TicketsGantt';
import SuiSelect from 'components/SuiSelect';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { mobileMaxWidth } from 'utils';
import SuiInput from 'components/SuiInput';
import AddTicketModal from 'components/Tickets/AddTicketModal';

const parseDate = (unix_timestamp) => {
  var date = new Date(unix_timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = '0' + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = '0' + date.getSeconds();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formattedTime =
    day +
    '/' +
    month +
    '/' +
    year +
    ' ' +
    hours +
    ':' +
    minutes.substr(-2) +
    ':' +
    seconds.substr(-2);

  return formattedTime;
};

const renderMembers = (members) => (
  <SuiBox display="flex">
    {members.map((member, i) => (
      <Tooltip key={i} title={member.first_name} placement="bottom">
        <SuiAvatar
          src={member.profile_picture}
          alt={member.first_name}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            ml: -1.25,
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: 'pointer',
            position: 'relative',
            '&:hover, &:focus': {
              zIndex: '10',
            },
          })}
          style={{ backgroundColor: 'black' }}
        />
      </Tooltip>
    ))}
  </SuiBox>
);

const unitsTableData = (tickets, completed, dispatch, afterSale) => {
  let columns = [
    { Header: 'Título', accessor: 'title', width: '50%' },
    { Header: 'Responsables', accessor: 'assignees' },
    { Header: 'Prioridad', accessor: 'priority' },
    { Header: 'Estado', accessor: 'status' },
    { Header: 'Fecha Inicio', accessor: 'created' },
    { Header: 'Fecha término', accessor: 'end_date' },

    //{ Header: "Area", accessor: "area", width: "18%" },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  let rows = tickets.map((ticket, i) => {
    let date = Date.parse(ticket.created);
    return {
      code: ticket.code,
      title: <TicketModal uuid={ticket.uuid} title={ticket.title} community={ticket.community} />,
      assignees: renderMembers(ticket.assignees),
      status: (
        <SuiBadge
          badgeContent={ticket.status}
          color={ticketStatusColor[ticket.status]}
          size="xs"
          container
        />
      ),
      priority: (
        <SuiBadge
          badgeContent={ticket.priority}
          color={ticketPriorityColor[ticket.priority]}
          size="xs"
          container
        />
      ),
      documents: ticket.document_number && ticket.document_number > 0 ? 'Si' : 'No',
      progress: (
        <SuiProgress
          style={{ width: '100%' }}
          value={(parseFloat(ticket.progress) * 100).toFixed()}
          variant="gradient"
          label
        />
      ),
      checklists_progress: (
        <SuiProgress
          style={{ width: '100%' }}
          value={(parseFloat(ticket.checklists_progress) * 100).toFixed()}
          variant="gradient"
          label
        />
      ),
      created: ticket.created && parseDate(date),
      end_date: ticket.due_date && parseDate(Date.parse(ticket.due_date)),
      area: ticket.area ? ticket.area.name : '',
      actions: (
        <SuiBox display="inline-block">
          <TicketModal uuid={ticket.uuid} community={ticket.community} />
          {getPermission([
            'superadmin',
            'administrador',
            'supervisor',
            'mayordomo',
            'conserje ',
          ]) && (
            <SuiBadge
              container
              badgeContent={<BiArchiveIn size="16px" />}
              size="xs"
              style={{ cursor: 'pointer', marginTop: 2 }}
              onClick={() => {
                Swal.fire({
                  title: 'Quieres eliminar esta tarea?',
                  showDenyButton: true,
                  confirmButtonText: 'Si',
                  denyButtonText: `No`,
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    dispatch(archiveTicket(ticket.uuid));
                    Swal.fire('Archivado!', '', 'Success');
                  } else if (result.isDenied) {
                    Swal.fire('Tarea no archivada', '', 'error');
                  }
                });
              }}
              color={'error'}
            ></SuiBadge>
          )}
        </SuiBox>
      ),
    };
  });
  return { columns, rows };
};

function ActionPlanTicketsTable(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const tickets = props.tickets;
  const createTicket = useSelector((state) => state.tickets.createTicket);
  const updateTicketResponse = useSelector((state) => state.tickets.updateTickets);
  const archiveTicketResponse = useSelector((state) => state.tickets.archiveTicket);
  const createTicketRecurrent = useSelector((state) => state.tickets.createTicketRecurrent);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataTableFull, setdataTableFull] = useState(unitsTableData(props.tickets, true, dispatch));
  const [totalEntries, setTotalEntries] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  useEffect(() => {
    let params = { page, page_size: pageSize };
  }, []);

  useEffect(() => {
    if (tickets) {
      setdataTableFull(unitsTableData(tickets, true, dispatch));
    }
  }, [tickets]);

  useEffect(() => {
    if (createTicket.data)
      if (new Date() - createTicket.time < 2000) dispatch(getTicketsByCommunity(slug));
  }, [createTicket]);

  useEffect(() => {
    if (createTicketRecurrent.data)
      if (new Date() - createTicketRecurrent.time < 2000) dispatch(getTicketsByCommunity(slug));
  }, [createTicketRecurrent]);

  useEffect(() => {
    if (archiveTicketResponse.data) dispatch(getTicketsByCommunity(slug));
  }, [archiveTicketResponse]);

  const renderContent = () => {
    return (
      <SuiBox p={window.innerWidth <= mobileMaxWidth ? 0 : 4} pt={3}>
        <SuiTypography variant="h4" fontWeight="medium">
          Tareas
          <AddTicketModal actionPlanView={props.viewType ? 'monthly' : 'weekly'} />
        </SuiTypography>
        <p style={{ fontSize: 15, fontWeight: 100 }}>
          * sólo se mostrarán tareas que tengan fecha de término o que estén próximas a iniciar.
        </p>

        <DataTable pageSize={5} table={dataTableFull} />
      </SuiBox>
    );
  };

  return <Card>{renderContent()}</Card>;
}

export default ActionPlanTicketsTable;
