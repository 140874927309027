import React from "react";
import { useParams } from "react-router-dom";
import stylesHeader from "./css/header.css";

function TaskListHeaderDefault({ headerHeight, rowWidth, fontFamily, fontSize }) {
  const { slug } = useParams();
  return (
    <div className={"ganttTable"} style={{ fontSize: fontSize, width: "100%" }}>
      <div
        className={"ganttTable_Header"}
        style={{
          height: headerHeight - 2,
          width: "100%",
        }}
      >
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            width: !slug ? "20%" : "25%",
          }}
        >
          &nbsp;Nombre
        </div>
        {!slug && (
          <div
            className={"ganttTable_HeaderItem"}
            style={{
              width: !slug ? "20%" : "25%",
            }}
          >
            &nbsp;Comunidad
          </div>
        )}
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            width: !slug ? "20%" : "25%",
          }}
        >
          &nbsp;Desde
        </div>
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            width: !slug ? "20%" : "25%",
          }}
        >
          &nbsp;Hasta
        </div>
        <div
          className={"ganttTable_HeaderItem"}
          style={{
            width: !slug ? "20%" : "25%",
          }}
        >
          &nbsp;Ver
        </div>
      </div>
    </div>
  );
}

export default TaskListHeaderDefault;
