import { Dialog, DialogContent, Modal } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";

interface DialogProps {
  children: any | React.ReactNode | React.JSX.Element;
  onClose?: () => void;
  fullWidth?: boolean;
}

export interface DialogHandle {
  open: () => void;
  close: () => void;
}

const BaseDialog = forwardRef<DialogHandle, DialogProps>(
  ({ onClose, children, fullWidth = false }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    return (
      <Dialog open={isOpen} onClose={handleClose} fullWidth={fullWidth}>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  }
);

BaseDialog.displayName = "BaseDialog";
export default BaseDialog;
