import React, { FC } from 'react';
import { Archive } from '@mui/icons-material';
import { PollModel } from 'api/services/polls/models';
import useArchivePoll from 'api/services/polls/mutations/ArchivePoll';
import { TicketModel } from 'api/services/tickets/models';
import { SuiButtonTs } from 'components/SuiButton';
import Swal from 'sweetalert2';

interface Props {
  ticket: TicketModel;
  poll: PollModel;
}
const DeletePollButton: FC<Props> = ({ ticket, poll }) => {
  const { mutate: archivePoll } = useArchivePoll({ pollUUID: poll.uuid, ticketUUID: ticket.uuid });
  const handleOnArchive = () => {
    Swal.fire({
      title: '¿Quieres archivar esta votación?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        archivePoll();
      }
    });
  };
  return (
    <SuiButtonTs variant="gradient" color="error" size="small" onClick={handleOnArchive}>
      <Archive sx={{ mr: 1 }} /> Eliminar
    </SuiButtonTs>
  );
};

export default DeletePollButton;
