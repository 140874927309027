// creat  react  component with hooks

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getActivity } from "redux/actions/activity";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/pages/profile/components/Header";
import { useSelector } from "react-redux";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import { ActivityRow } from "../ActivityRow";
import SuiButton from "components/SuiButton";
import { markAsRead } from "redux/actions/activity";
import { markAllAsRead } from "redux/actions/activity";
import { getReadActivity } from "redux/actions/activity";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";
export const ReadActivity = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pagination = { variant: "gradient", color: "info" };
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  const [readedNotifications, setReadedNotifications] = useState([]);
  const readNotificationsResponse = useSelector((state) => state.activity.getReadActivity);
  const markAsReadResponse = useSelector((state) => state.activity.markAsRead);
  const markAllAsReadResponse = useSelector((state) => state.activity.markAllAsRead);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [canNext, setCanNext] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);

  const activityModalClosedResponse = useSelector((state) => state.helper.activityModalClosed);

  useEffect(() => {
    doRequest();
  }, []);

  const doRequest = () => {
    let params = {
      page: page,
      page_size: pageSize,
      communities: props.communities.map((c) => c.value).toString(),
      action_user: props.member,
    };
    props.setLoading(true);
    dispatch(getReadActivity(params));
  };

  useEffect(() => {
    if (activityModalClosedResponse.time - new Date() < 1000) doRequest();
  }, [activityModalClosedResponse]);

  useEffect(() => {
    if (new Date() - readNotificationsResponse.time < 10000)
      if (readNotificationsResponse.data) doRequest();
  }, [page, pageSize, props.communities, props.member]);

  useEffect(() => {
    if (new Date() - markAsReadResponse.time < 2000) if (markAsReadResponse.data) doRequest();
  }, [markAsReadResponse]);

  useEffect(() => {
    if (new Date() - markAllAsReadResponse.time < 2000) if (markAllAsReadResponse.data) doRequest();
  }, [markAllAsReadResponse]);

  useEffect(() => {
    if (readNotificationsResponse.data) {
      setCanNext(readNotificationsResponse.data.next);
      setTotalEntries(readNotificationsResponse.data.count);
      setCanPrev(readNotificationsResponse.data.previous);
      setReadedNotifications(readNotificationsResponse.data.results);
      props.setLoading(false);
    }
  }, [readNotificationsResponse]);

  return props.loading ? (
    <></>
  ) : (
    <Card sx={{ mt: 2, p: 2 }}>
      <SuiBox p={2}>
        <SuiTypography variant="h4" fontWeight="medium" textAlign="center" pb={2}>
          Leídos
        </SuiTypography>
        {readedNotifications.length > 0 && (
          <Grid container spacing={3}>
            {readedNotifications.map((notification, index) => (
              <Grid item xs={12} key={index}>
                <ActivityRow
                  pushOrPopNotification={() => {}}
                  selectedNotifications={[]}
                  activity={notification}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {readedNotifications.length > 0 && (
          <SuiBox>
            <SuiBox
              pt={2}
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="flex-end"
              alignItems={{ xs: "flex-end", sm: "center" }}
            >
              <SuiPagination
                variant={pagination.variant ? pagination.variant : "gradient"}
                color={pagination.color ? pagination.color : "info"}
              >
                {canPrev && (
                  <SuiPagination item onClick={() => setPage(page - 1)}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                  </SuiPagination>
                )}
                <SuiBox width="5rem" mx={1}>
                  <SuiInput
                    inputProps={{ type: "number", min: 1, max: 3 }}
                    value={page}
                    onChange={(e) => setPage(e.target.value)}
                  />
                </SuiBox>
                {canNext && (
                  <SuiPagination item onClick={() => setPage(parseInt(page) + 1)}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                  </SuiPagination>
                )}
              </SuiPagination>
            </SuiBox>
            <SuiTypography variant="button">
              Mostrando {(page - 1) * pageSize + 1} a{" "}
              {page * pageSize > totalEntries ? totalEntries : page * pageSize} de {totalEntries}{" "}
              entradas de página
            </SuiTypography>
          </SuiBox>
        )}
        {readedNotifications.length === 0 && (
          <SuiTypography variant="body2" fontWeight="medium" textAlign="center" pb={2}>
            No hay notificaciones no leídas
          </SuiTypography>
        )}
      </SuiBox>
    </Card>
  );
};
