import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/config/queryClient";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { GET_MAINTENANCE_KEY } from "../../maintenances/queries/getMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";

import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";

interface Inputs {
  title: string;
  start: ISODateString;
  end: ISODateString;
  community?: string;
  maintenance?: UUID;
}
const useCreateVisit = () => {
  return useMutation({
    mutationFn: async (input: Inputs) => {
      const url = `${API_URL}/visit/new/`;
      return await axios
        .post<MaintenanceModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Visita creada",
        message: "La visita se ha creado correctamente.",
      });
    },
  });
};

export default useCreateVisit;
