import { MaintenanceModel } from "api/services/maintenances/models";
import React from "react";
import Documents from "layouts/pages/documents";
import DocumentsTable from "./DocumentsTable";
import { useAuthenticate } from "stores/authentication/helpers";

const DocumentsTab: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const { isAuthenticated } = useAuthenticate();
  return (
    <div>
      {isAuthenticated ? (
        <Documents maintenanceProp={maintenance} />
      ) : (
        <DocumentsTable maintenance={maintenance} />
      )}
    </div>
  );
};

export default DocumentsTab;
