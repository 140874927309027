const commonPasswords = ["password", "123456", "qwerty", "admin", "letmein"]; // Add more common passwords as needed

export const validatePassword = (
  password: string
): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];

  if (password.length < 8) {
    errors.push("La contraseña debe contener al menos 8 caracteres.");
  }

  if (commonPasswords.includes(password.toLowerCase())) {
    errors.push("La contraseña no puede ser una clave utilizada comúnmente.");
  }

  if (/^\d+$/.test(password)) {
    errors.push("La contraseña no puede ser completamente numérica.");
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
};

export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
