import { types, API_URL } from "./types";
import Axios from "axios";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";
import eventEmitter from "lib/events/EventEmitter";

export const getDocumentsTags = () => {
  const url = `${API_URL}/documents/tags/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.get(url).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setGetDocumentsTags(outputData));
    });
  };
};

export const setGetDocumentsTags = (data) => {
  return {
    type: types.setGetDocumentsTags,
    payload: data,
  };
};

export const approveDocument = (uuid, d) => {
  const url = `${API_URL}/documents/approve/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.post(url, d).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      let notification = {
        status: "success",
        message: "La cotización ha sido aprobado con éxito!",
        title: "Cotización aprobada",
      };
      dispatch(setNotification(notification));
      dispatch(setApproveDocument(outputData));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
    });
  };
};

export const setApproveDocument = (data) => {
  return {
    type: types.setApproveDocument,
    payload: data,
  };
};

export const setNotification = (data) => ({
  type: types.setNotification,
  payload: data,
});

export const getDocumentsByArea = (uuid) => {
  const url = `${API_URL}/documents/by-area/${uuid}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.get(url).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setGetDocumentsByArea(outputData));
    });
  };
};

export const setGetDocumentsByArea = (data) => {
  return {
    type: types.setGetDocumentsByArea,
    payload: data,
  };
};

export const getDocumentsByCommunity = (slug) => {
  const url = `${API_URL}/documents/by-community/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.get(url).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setGetDocumentsByCommunity(outputData));
    });
  };
};

export const setGetDocumentsByCommunity = (data) => {
  return {
    type: types.setGetDocumentsByCommunity,
    payload: data,
  };
};

export const getDocumentsByManagement = (slug, params) => {
  const url = `${API_URL}/documents/by-management/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.get(url, { params }).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setGetDocumentsByManagement(outputData));
    });
  };
};

export const setGetDocumentsByManagement = (data) => {
  return {
    type: types.setGetDocumentsByManagement,
    payload: data,
  };
};

export const updateDocument = (slug, data) => {
  const url = `${API_URL}/documents/edit/${slug}/`;
  return (dispatch) => {
    return Axios.put(url, data).then((res) => {
      const { data: updatedDocument, status, message } = res;
      const outputData = { data: updatedDocument, status, message };
      dispatch(setUpdateDocument(outputData));
      let notification = {
        status: "success",
        message: "El documento ha sido actualizado con éxito!",
        title: "Documento actualizado",
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
      eventEmitter.publish("documentUpdated", updatedDocument);
    });
  };
};

export const setUpdateDocument = (data) => {
  return {
    type: types.setUpdateDocument,
    payload: data,
  };
};

export const addDocument = (d) => {
  const url = `${API_URL}/documents/add/`;

  return (dispatch) => {
    let outputData = {};
    let headers = { "Content-Type": "multipart/form-data" };
    Axios.post(url, d, { headers })
      .then((res) => {
        if (res.data.error) {
          let notification = {
            status: "error",
            message: res.data.message,
            title: "Documento no creado",
          };
          outputData["status"] = res.status;
          outputData["message"] = res.message;
          outputData["data"] = res.data;
          dispatch(setAddDocument(outputData));
          dispatch(setNotification(notification));
        } else {
          outputData["status"] = res.status;
          outputData["message"] = res.message;
          outputData["data"] = res.data;

          let notification = {
            status: "success",
            message: "El documento ha sido agregado con éxito!",
            title: "Documento agregado",
          };
          dispatch(setNotification(notification));
          dispatch(setAddDocument(outputData));
          queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
          eventEmitter.publish("documentCreated", res.data);
        }
      })
      .catch(() => {
        outputData.error = true;
        outputData.message = "Something went wrong, please try again";
        outputData.status = "danger";
      });
  };
};

export const setAddDocument = (data) => ({
  type: types.setAddDocument,
  payload: data,
});

export const deleteDocument = (uuid, key) => {
  const url = `${API_URL}/documents/delete/${uuid}/`;
  return (dispatch) => {
    let outputData = {};
    return Axios.delete(url, { data: { key } }).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setDeleteDocument(outputData));
      let notification = {
        status: "error",
        message: "El documento ha sido eliminado con éxito!",
        title: "Documento eliminado",
      };
      dispatch(setNotification(notification));
      queryClient.invalidateQueries([QUERY_KEYS.TICKET]);
    });
  };
};

export const setDeleteDocument = (data) => {
  return {
    type: types.setDeleteDocument,
    payload: data,
  };
};

export const getDocumentsTagsByManagement = (slug) => {
  const url = `${API_URL}/documents/tags/management/${slug}`;
  return (dispatch) => {
    let outputData = {};
    return Axios.get(url).then((res) => {
      outputData["data"] = res.data;
      outputData["status"] = res.status;
      outputData["message"] = res.message;
      dispatch(setGetDocumentsTagsByManagement(outputData));
    });
  };
};

export const setGetDocumentsTagsByManagement = (data) => {
  return {
    type: types.setGetDocumentsTagsByManagement,
    payload: data,
  };
};
