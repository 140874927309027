/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect, useRef, FC } from 'react';

// Soft UI Dashboard PRO React example components
import TimelineList from 'examples/Timeline/TimelineList';
import TimelineItem from 'examples/Timeline/TimelineItem';
import ReactHtmlParser from 'react-html-parser';
import { SuiTypographyTs } from 'components/SuiTypography';
import { parseDate } from 'utils';
import { Box, Card, CircularProgress } from '@mui/material';
import { SuiBadgeTs } from 'components/SuiBadge';
import { TicketModel, TicketStatus } from 'api/services/tickets/models';
import useTicketProgressPrevStep from 'api/services/tickets/mutations/TicketProgressPrevStep';
import NextStepForm from './NextStepForm';
import FinishButton from './FinishButton';
import { usePermissions } from 'stores/authentication/helpers';

interface Props {
  ticket: TicketModel;
}

const Timeline: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();
  const [isNextStepFormEnabled, setIsNextStepFormEnabled] = useState<boolean>(false);
  const { mutate: setPrevStepTicket, isPending: isSubmitingPrev } = useTicketProgressPrevStep({
    uuid: ticket.uuid,
  });

  const showFinishButton = canEditTask && !ticket.finished_on;
  const showActionsButtons = canEditTask && !isNextStepFormEnabled;

  const onEnableNextStepForm = () => {
    if (!canEditTask) return;
    setIsNextStepFormEnabled(true);
  };
  const onDisableNextStepForm = () => {
    if (!canEditTask) return;
    setIsNextStepFormEnabled(false);
  };

  const submitPrevStep = () => {
    if (!canEditTask) return;
    setPrevStepTicket();
  };

  return (
    <Card sx={{ p: 2 }}>
      <Box>
        <SuiTypographyTs variant="h6" mb={2}>
          Progreso: {(parseFloat(ticket.progress) * 100).toFixed(0)}%
        </SuiTypographyTs>
      </Box>
      <Box>
        <TimelineList>
          {ticket.steps.map((step, i) => {
            return (
              <TimelineItem
                key={step.uuid}
                color={
                  ticket.status == step.name ? 'warning' : step.status == '3' ? 'success' : 'error'
                }
                icon={'circle'}
                title={step.name.toUpperCase()}
                dateTime={step.status == '3' ? parseDate(step.modified) : ''}
                description={step.comment == null ? '' : ReactHtmlParser(step.comment)}
                lastItem={i === ticket.steps.length - 1}
              />
            );
          })}
        </TimelineList>
      </Box>
      <Box>
        {showActionsButtons && (
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              {ticket.status !== TicketStatus.Creada && (
                <SuiBadgeTs
                  container
                  size="xs"
                  variant={'contained'}
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => !isSubmitingPrev && submitPrevStep()}
                  badgeContent={isSubmitingPrev ? <CircularProgress size={15} /> : 'Retroceder'}
                />
              )}
            </Box>
            <Box>
              {!ticket.finished_on && (
                <SuiBadgeTs
                  container
                  size="xs"
                  variant={'contained'}
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => !isSubmitingPrev && onEnableNextStepForm()}
                  badgeContent={isSubmitingPrev ? <CircularProgress size={15} /> : 'Avanzar'}
                />
              )}
            </Box>
          </Box>
        )}
        {isNextStepFormEnabled && (
          <Box mb={1}>
            <NextStepForm
              ticket={ticket}
              onSuccess={onDisableNextStepForm}
              onCancel={onDisableNextStepForm}
            />
          </Box>
        )}
        {showFinishButton && (
          <Box>
            <FinishButton ticket={ticket} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default Timeline;
