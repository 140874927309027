import React, { useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SelectTicketsTable from './SelectTicketTable';
import SelectMaintenancesTable from './SelectMaintenancesTable';
import SelectVisitsTable from './SelectVisitsTable';
import SelectDocumentsTable from './SelectDocumentsTable';
import CommentaryMaintenances from './CommentaryMaintenances';
import CommentaryGeneral from './CommentaryGeneral';
import ExportFile from './ExportFile';
import ExportFileStepper from 'components/Stepper/SwappiStepper';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { AiOutlineInbox } from 'react-icons/ai';
import { MdEngineering } from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';
import { SiReadthedocs } from 'react-icons/si';
import { SuiButtonTs } from 'components/SuiButton';
import { useExportCenterFiltersStore } from './store';
import VisitsReportModal from 'components/VisitsReports/VisitsReportModal';
import ExportHistoryTable from './History';
import ExportHistoryModal from './History/Modal';
const steps = [
  {
    label: 'Tareas',
    icon: <AiOutlineInbox />,
    component: <SelectTicketsTable />,
  },
  {
    label: 'Mantenciones',
    icon: <MdEngineering />,
    component: <SelectMaintenancesTable />,
  },
  {
    label: 'Comentarios de Mantenciones',
    icon: <CommentOutlinedIcon />,
    component: <CommentaryMaintenances />,
  },
  {
    label: 'Visitas en Terreno',
    icon: <TbReportSearch />,
    component: <SelectVisitsTable />,
  },
  {
    label: 'Documentos',
    icon: <SiReadthedocs />,
    component: <SelectDocumentsTable />,
  },
  {
    label: 'Comentarios Generales',
    icon: <CommentOutlinedIcon />,
    component: <CommentaryGeneral />,
  },
  {
    label: 'Confirmar y Exportar',
    icon: <DownloadSharpIcon />,
    component: <ExportFile />,
  },
];

const DataExportCenter = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    return () => {
      useExportCenterFiltersStore.setState({ community: null });
    };
  }, []);

  return (
    <Box>
      <Box style={{display: 'flex', justifyContent: 'space-between  '}}>
        <Box></Box>
        <Typography textAlign="center" variant="h4" fontWeight="medium" mt={1}>
          Centro de Exportación
        </Typography>
        <ExportHistoryModal />
      </Box>
      <Divider />
      <ExportFileStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      {steps[activeStep].component}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', my: 2 }}>
        <SuiButtonTs
          variant="gradient"
          color="light"
          disabled={activeStep === 0}
          startIcon={<ChevronLeft />}
          onClick={() => setActiveStep((step) => step - 1)}
        >
          Anterior
        </SuiButtonTs>
        <SuiButtonTs
          variant="gradient"
          color="info"
          disabled={activeStep === steps.length - 1}
          endIcon={<ChevronRight />}
          onClick={() => setActiveStep((step) => step + 1)}
        >
          Siguiente
        </SuiButtonTs>
      </Box>
      <VisitsReportModal />
    </Box>
  );
};

export default DataExportCenter;
