/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import YouTube from "react-youtube";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import SuiButton from "components/SuiButton";
// Project page components
import Header from "layouts/pages/profile/components/Header";

import { useDispatch, useSelector } from "react-redux";

import { getCommunitiesByUser } from "../../../redux/actions/communities";
import SuiInput from "components/SuiInput";
import { getPermission } from "utils";
import LoadingView from "components/Loading";
import { getHelpCenter } from "redux/actions/analytics";
import { Card, Divider } from "@mui/material";
import { getFAQ } from "redux/actions/users";
import { ExpandMore } from "@mui/icons-material";

function HelpCenter() {
  // ComplexProjectCard dropdown menu state
  const helpCenterResponse = useSelector((state) => state.analytics.getHelpCenter);
  const faqResponse = useSelector((state) => state.users.getFAQ);
  const dispatch = useDispatch();
  const faqRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(getHelpCenter());
    dispatch(getFAQ());
  }, []);

  useEffect(() => {
    if (helpCenterResponse) {
      setLoading(false);
    }
  }, [helpCenterResponse]);


  return (
    <DashboardLayout>
      <Header />
      <SuiBox pt={5} pb={2}>
        <SuiButton
          color="secondary"
          variant="gradient"
          size="xs"
          onClick={scrollToFAQ}>
          <Icon>
            arrow_downward
          </Icon>
          <SuiTypography variant="button" fontWeight="medium" color="white">Preguntas Frecuentes </SuiTypography>
        </SuiButton>

        {loading && <LoadingView />}

        {!loading && (
          <SuiBox mt={{ xs: 1, lg: 3 }} mb={1}>
            {helpCenterResponse.data &&
              helpCenterResponse.data.playlists.map((playlists, i) => (
                <Grid key={i} container spacing={3} mb={3}>
                  <Grid item xs={12} md={12}>
                    <SuiTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                      {playlists.name}
                    </SuiTypography>
                  </Grid>
                  {playlists.capacitations.map((capacitation, i) => (
                    <Grid key={i} item xs={12} sm={6}>
                      <div style={{ margin: "0 auto" }}>
                        <YouTube
                          opts={{
                            width: "90%",
                            margin: "0 auto",
                            height: "300px",
                          }}
                          videoId={capacitation.url}
                        />
                      </div>

                      <SuiTypography
                        variant="h6"
                        fontWeight="medium"
                        textTransform="capitalize"
                        textAlign="center"
                      >
                        {capacitation.name}
                      </SuiTypography>
                    </Grid>
                  ))}
                  <Divider />
                </Grid>
              ))}
          </SuiBox>
        )}
      </SuiBox>
      <SuiBox p={1}>
        <SuiButton
            color="secondary"
            variant="gradient"
            size="xs"
            onClick={scrollToTop}>
            <Icon>
              arrow_upward
            </Icon>
            <SuiTypography variant="button" fontWeight="medium" color="white">Videos</SuiTypography>
          </SuiButton>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1}>
          <SuiTypography
            variant="h5"
            fontWeight="medium"
            textTransform="capitalize"
            style={{ p: 2 }}
            ref={faqRef}
            id="faq-title"
          >
            Preguntas Frecuentes
          </SuiTypography>
          <Grid container spacing={1} mb={3}>
            {faqResponse.data &&
              faqResponse.data.map((faq, i) => (
                <Grid item xs={12} md={12} key={i}>
                  <Card>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content">
                        {faq.question}
                      </AccordionSummary>
                      <AccordionDetails>
                        <SuiTypography variant="body2">{faq.answer}</SuiTypography>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HelpCenter;
