import React, { FC } from 'react';
import { Box, Card } from '@mui/material';
import SuiEditor from 'components/SuiEditor';
import { useExportCenterStore } from '../store';

const CommentaryGeneral = () => {
  const { observationsGeneral, setObservationGeneral } = useExportCenterStore();

  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <SuiEditor value={observationsGeneral} onChange={setObservationGeneral} />
      </Card>
    </Box>
  );
};

export default CommentaryGeneral;
