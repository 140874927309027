import { combineReducers } from "redux";
import
{
  languageReducer,
  authReducer,
  usersReducer,
  notificationsReducer,
  communitiesReducer,
  ticketsReducer,
  documentsReducer,
  providersReducer,
  analyticsReducer,
  maintenanceReducer,
  reportsReducer,
  activityReducer,
  pollsReducer,
  helperReducer
} from "./index";


export const rootReducer = combineReducers({
  lang: languageReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  users: usersReducer,
  notifications: notificationsReducer,
  communities: communitiesReducer,
  tickets: ticketsReducer,
  providers: providersReducer,
  documents: documentsReducer,
  maintenance: maintenanceReducer,
  reports: reportsReducer,
  activity: activityReducer,
  polls: pollsReducer,
  helper: helperReducer
});
