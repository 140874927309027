import { Box, Typography } from '@mui/material';
import useGetChecklistTemplates from 'api/services/checklist/queries/GetChecklistTemplates';
import { TicketModel } from 'api/services/tickets/models';
import LoadingView from 'components/Loading';
import React, { FC } from 'react';
import TemplateItem from './TemplateItem';
import { UUID } from 'crypto';
interface Props {
  objectUUID: UUID;
}

const ChecklistTemplatesList: FC<Props> = ({ objectUUID }) => {
  const { data, isLoading } = useGetChecklistTemplates();

  const templates = data || [];
  if (isLoading) return <LoadingView />;
  return (
    <Box>
      <Typography component="h4" fontWeight="bold">
        Plantillas
      </Typography>
      <Box sx={{ overflowY: 'scroll' }}>
        {templates.map((item) => (
          <TemplateItem key={item.uuid} checklist={item} objectUUID={objectUUID} />
        ))}
      </Box>
    </Box>
  );
};

export default ChecklistTemplatesList;
