import { ChecklistModel } from 'api/services/checklist/models';
import { UUID } from 'crypto';
import { create } from 'zustand';

interface StoreState {
  isOpen: boolean;
  checklistUUID: UUID;
  onClose: () => void;
  openModal: (checklistUUID: UUID) => void;
  closeModal: () => void;
}

const useChecklistTemplateModalStore = create<StoreState>((set) => ({
  isOpen: false,
  checklistUUID: undefined,
  onClose: undefined,
  openModal: (checklistUUID) => set({ checklistUUID, isOpen: true }),
  closeModal: () => set({ isOpen: false }),
}));
export default useChecklistTemplateModalStore;
