/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiEditor from "components/SuiEditor";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import Checkbox from "@mui/material/Checkbox";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiInput from "components/SuiInput";
// Data
import SuiButton from "components/SuiButton";
import { createTicket } from "redux/actions/tickets";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCommunitiesByUser } from "redux/actions/communities";
import { getPermission } from "utils";
import { getMembersForTicketAssignment } from "redux/actions/tickets";
import SuiDatePicker from "components/SuiDatePicker";
import { createTicketRecurrent } from "redux/actions/tickets";
import { createReminder } from "redux/actions/users";
import { updateReminder } from "redux/actions/users";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";

function Form(props) {
  const dispatch = useDispatch();
  const [title, settitle] = useState(props.update ? props.reminder.title : "");
  const [comment, setcomment] = useState(props.update ? props.reminder.body : "");
  const { slug } = useParams();
  const [complexTimeline, setcomplexTimeline] = useState(false);
  const [isAfterSale, setisAfterSale] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const communitiesResponse = useGetCommunities();
  const getMembersForTicketAssignmentResponse = useSelector(
    (state) => state.tickets.getMembersForTicketAssignment
  );
  const [selectedDate, setSelectedDate] = useState(props.update ? props.reminder.due_date : null);

  const [communities, setcommunities] = useState([]);
  const [community, setcommunity] = useState(
    props.update ? props.reminder.community && props.reminder.community.slug : ""
  );
  const handleSubmit = (e) => {
    let form = {
      title: title,
      body: comment,
      community_slug: community,
      due_date: selectedDate,
    };
    if (props.update) {
      dispatch(updateReminder(form, props.reminder.uuid));
    } else {
      dispatch(createReminder(form));
    }
    props.handleClose();
  };


  useEffect(() => {
    if (communitiesResponse.data !== undefined) {
      let options = [];
      if (getPermission(["superadmin", "administrador"])) {
        communitiesResponse.data.map((community) => {
          options.push({ label: community.name, value: community.slug });
        });
      } else {
        // filter comunities response by assignee
        communitiesResponse.data.map((community) => {
          options.push({ label: community.name, value: community.slug });
        });
      }

      setcommunities(options);
    }
  }, [communitiesResponse]);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{props.update ? "Editar" : "Crear"} Nota</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <SuiTypography variant="button">Título</SuiTypography>
            <SuiInput value={title} onChange={(e) => settitle(e.target.value)} size="small" />
          </Grid>

          <Grid item xs={12} sm={12} pt={0}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Comunidad
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              onChange={(e) => setcommunity(e.value)}
              placeholder="Comunidad"
              options={communities}
              value={community}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Fecha de expiración
              </SuiTypography>
            </SuiBox>
            <SuiDatePicker
              size="small"
              defaultValue={selectedDate}
              onChange={(e) => setSelectedDate(e[0].toISOString().split("T")[0])}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Descripción
              </SuiTypography>
            </SuiBox>
            <SuiEditor
              label="Descripción"
              value={comment}
              placeholder="Descripción"
              onChange={(e) => setcomment(e)}
              multiline
              rows={5}
            />
          </Grid>
          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" onClick={handleSubmit}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Form;
