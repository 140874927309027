/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import { updateProvider } from 'redux/actions/providers';
// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import { useDispatch, useSelector } from 'react-redux';
// NewProduct page components
import GeneralInfo from './components/GeneralInfo';
import BankAccountInfo from './components/BankAccountInfo';
import { getAuthUserProfile } from 'stores/authentication/helpers';

function getSteps() {
  return ['1. Información General', '2. Datos Bancarios'];
}

function Form(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const [razonSocial, setrazonSocial] = useState(props.provider.razon_social);
  const [giro, setgiro] = useState(props.provider.giro);
  const [rut, setrut] = useState(props.provider.rut);
  const [name, setname] = useState(props.provider.executive_name);
  const [email, setemail] = useState(props.provider.email);
  const [phone, setphone] = useState(props.provider.phone);
  const [accountNumber, setaccountNumber] = useState(props.provider.account_number);
  const [accountRut, setaccountRut] = useState(props.provider.account_rut);
  const [accountBank, setaccountBank] = useState(props.provider.account_bank);
  const [accountType, setaccountType] = useState(props.provider.account_type);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleUpdateProvider = () => {
    let form = {
      uuid: props.provider.uuid,
      razon_social: razonSocial,
      giro: giro,
      rut: rut,
      executive_name: name,
      email: email,
      phone: phone,
      account_number: accountNumber,
      contacts: props.provider.contacts,
      account_rut: accountRut,
      account_bank: accountBank,
      account_type: accountType,
    };
    let profile = getAuthUserProfile();
    dispatch(updateProvider(profile.management.slug, form));
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <GeneralInfo
            razonSocial={razonSocial}
            setrazonSocial={setrazonSocial}
            giro={giro}
            setgiro={setgiro}
            rut={rut}
            setrut={setrut}
            name={name}
            setname={setname}
            email={email}
            setemail={setemail}
            phone={phone}
            setphone={setphone}
          />
        );
      case 1:
        return (
          <BankAccountInfo
            accountNumber={accountNumber}
            setaccountNumber={setaccountNumber}
            accountRut={accountRut}
            setaccountRut={setaccountRut}
            accountBank={accountBank}
            setaccountBank={setaccountBank}
            accountType={accountType}
            setaccountType={setaccountType}
          />
        );
      default:
        return null;
    }
  }

  return (
    <SuiBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ overflow: 'visible' }}>
            <SuiBox p={2}>
              <SuiBox>
                {getStepContent(activeStep)}
                <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <SuiBox />
                  ) : (
                    <SuiButton variant="gradient" color="secondary" onClick={handleBack}>
                      Volver
                    </SuiButton>
                  )}
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      if (!isLastStep) {
                        handleNext();
                      } else {
                        handleUpdateProvider();
                        props.handleClose();
                      }
                    }}
                  >
                    {isLastStep ? 'Actualizar' : 'Siguiente'}
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Form;
