import { ISODateString } from 'types/models';
import * as yup from 'yup';
interface FormValues {
  start_date: ISODateString[];
  end_date: ISODateString[];
}
export const scheme: yup.SchemaOf<FormValues> = yup.object().shape({
  start_date: yup.array().of(yup.string().required()).required(),
  end_date: yup.array().of(yup.string()).required(),
});
