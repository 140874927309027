import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { TicketModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { ErrorMessage } from "api/utils/errors";
import { TicketQueryHelper } from "../queries/GetTicket";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";

interface Params {
  uuid: UUID;
}
interface Input {
  comment: string;
}
const useTicketProgressNextStep = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/${params.uuid}/go-forward/`;
      return await axios.post<TicketModel>(url, input).then((res) => res.data);
    },
    onSuccess(updatedTicket) {
      TicketQueryHelper.updateTicket(updatedTicket);
      toastNotification.success({
        title: "Estado actualizado",
        message: `El estado de la solicitud ha sido actualizada a ${updatedTicket.status}`,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TICKETS_TABLE],
      });
    },
    onError(error: ErrorMessage) {
      toastNotification.error({
        title: "Error al cambiar el estado de la tarea",
        message: error.data.message,
      });
    },
  });
};

export default useTicketProgressNextStep;
