import { Box, Card, CircularProgress, Typography } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import SuiBadge, { SuiBadgeTs } from 'components/SuiBadge';
import React, { FC, useEffect, useState } from 'react';
import { ticketPriorityColor } from 'utils';
import { useForm } from 'react-hook-form';
import DropdownMenu from 'components/DropdownMenu';
import { Edit } from '@mui/icons-material';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
import SelectControlled from 'components/forms/controlled/SelectControlled';
import { SuiButtonTs } from 'components/SuiButton';
import { useUpdateTicket } from 'api/services/tickets/mutations/UpdateTicket';
import { usePermissions } from 'stores/authentication/helpers';
import { useNavigate } from 'react-router-dom';

interface Props {
  ticket: TicketModel;
}

interface FormValues {
  community: string;
  priority: string;
}

const priorityOptions = [
  { label: 'Baja', value: 'baja' },
  { label: 'Media', value: 'media' },
  { label: 'Alta', value: 'alta' },
  { label: 'Crítica', value: 'crítica' },
];

const CommunityCard: FC<Props> = ({ ticket }) => {
  const navigate  = useNavigate();
  const { canEditTask } = usePermissions();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { dataAsOptions: communitiesOptions } = useGetCommunities(isEditing);
  const { mutate: updateTicket, isPending: isSubmiting } = useUpdateTicket({ uuid: ticket.uuid });
  const { control, reset, formState, handleSubmit } = useForm<FormValues>();

  useEffect(() => {
    reset({
      community: ticket.community.slug,
      priority: ticket.priority,
    });
  }, [ticket]);

  const onEnableEdit = () => {
    if (!canEditTask) return;
    setIsEditing(true);
  };
  const onDisableEdit = () => {
    if (!canEditTask) return;
    setIsEditing(false);
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    updateTicket(
      {
        community: data.community,
        priority: data.priority,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      }
    );
  });
  return (
    <Card sx={{ p: 3, overflow: 'visible' }}>
      <Box>
        {isEditing === false ? (
          <Box display={'flex'}>
            <Box flex={1}>
              <Typography variant="h4" style={{ marginBottom: 10 }} onClick={() => onEnableEdit()}>
                {ticket?.community && ticket?.community.name}
              </Typography>
            </Box>
            <Box>
              {canEditTask && (
                <DropdownMenu
                  items={[{ label: 'Editar', icon: <Edit />, onClick: onEnableEdit }]}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <SelectControlled
              control={control}
              name="community"
              options={[...communitiesOptions]}
            />
          </Box>
        )}

        <Typography variant="h6" style={{ marginBottom: 10 }}>
          ID: {ticket?.code}
        </Typography>
      </Box>
      {isEditing ? (
        <Box mb={1}>
          <Typography variant="caption" fontWeight="bold">
            Prioridad:
          </Typography>
          <SelectControlled control={control} name="priority" options={priorityOptions} />
        </Box>
      ) : (
        <Box mb={1} onClick={onEnableEdit}>
          <Typography variant="caption" fontWeight="bold">
            Prioridad:
          </Typography>
          <SuiBadgeTs
            style={{ cursor: 'pointer' }}
            color={ticketPriorityColor[ticket?.priority]}
            mr={1}
            badgeContent={ticket?.priority}
          />
        </Box>
      )}
      {ticket?.is_after_sale && (
        <SuiBadgeTs color={'info'} style={{ marginLeft: '-0.5rem' }} badgeContent={'Postventa'} />
      )}

      {ticket?.show_comitee && (
        <Box style={{ marginTop: '1px' }}>
          <SuiBadgeTs color={'primary'} style={{ marginLeft: '-0.5rem' }} badgeContent={'Comité'} />
        </Box>
      )}
      {ticket.report && (
        <SuiBadgeTs
          container
          badgeContent={"Visita en terreno"}
          size="xs"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (isMobile) navigate('/visits/report/' + ticket.report);
            else window.open('/visits/report/' + ticket.report, '_blank');
          }}
          bgcolor={'info'}
        ></SuiBadgeTs>
      )}
      {isEditing && (
        <Box display={'flex'} justifyContent={'space-between'}>
          <SuiButtonTs
            variant="gradient"
            color="secondary"
            size="small"
            // disabled={!formState.isDirty}
            onClick={onDisableEdit}
          >
            <Box width={80}>Cancelar</Box>
          </SuiButtonTs>
          <SuiButtonTs
            variant="gradient"
            color="success"
            size="small"
            disabled={!formState.isDirty}
            onClick={onSubmit}
          >
            <Box width={80}>
              {isSubmiting ? <CircularProgress size={16} color="inherit" /> : 'Guardar'}
            </Box>
          </SuiButtonTs>
        </Box>
      )}
    </Card>
  );
};

export default CommunityCard;
