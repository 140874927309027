import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { useGetUF } from 'api/services/others/queries/GetUf';
import { TicketModel } from 'api/services/tickets/models';
import React, { FC, useMemo } from 'react';
import DeletePollButton from './DeletePollButton';
import DocumentPricingOption from './DocumentPricingOption';
import { documentValueInClp } from 'utils/helpers/documents/currency';
import { getPollChoiceWithMostVotes } from 'utils/helpers/documents/polls';
import { usePermissions } from 'stores/authentication/helpers';

import PollPeriod from './PollPeriod';

interface Props {
  ticket: TicketModel;
}
const ActivePolls: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();

  const { data: UF } = useGetUF();
  const [currentPoll] = ticket.polls;

  const { pollOptionsSorted, lowestPricing } = useMemo(() => {
    if (UF === undefined)
      return {
        pollOptionsSorted: [],
        lowestPricing: 0,
      };
    const pollOptionsSorted = currentPoll.poll_choices
      .filter((choice) => choice.document.type.slug === 'cotizacion')
      .sort((choiceA, choiceB) => {
        const [docA, docB] = [choiceA.document, choiceB.document];
        const aPrice = documentValueInClp(docA, UF.valor);
        const bPrice = documentValueInClp(docB, UF.valor);
        return aPrice - bPrice;
      });
    return {
      pollOptionsSorted,
      lowestPricing: documentValueInClp(pollOptionsSorted[0].document, UF.valor),
    };
  }, [ticket, UF]);

  const mostVotedOption = useMemo(() => {
    return getPollChoiceWithMostVotes(currentPoll);
  }, [currentPoll]);
  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        textTransform="capitalize"
        mb={2}
        textAlign="center"
      >
        Votación Cotizaciones
      </Typography>
      <Divider />
      <Card sx={{ p: 3 }}>
        <Box display={'flex'}>
          <Box flex={1}>
            <Box>
              <Typography variant="h5" pb={2} fontWeight="bold" textTransform="capitalize">
                {currentPoll.question}
              </Typography>
            </Box>
          </Box>

          <Box>{canEditTask && <DeletePollButton ticket={ticket} poll={currentPoll} />}</Box>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <PollPeriod ticket={ticket} poll={currentPoll} />
          </Box>
          <Box>
            <Typography variant="h6" pb={2} fontWeight="bold" textTransform="capitalize">
              UF hoy: {UF?.valor.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={1}>
          {pollOptionsSorted.map((option) => {
            return (
              <Grid item key={option.uuid} sm={4} xs={12}>
                <DocumentPricingOption
                  ticket={ticket}
                  poll={currentPoll}
                  pollOption={option}
                  referenceValueCLP={lowestPricing}
                  mostVoted={mostVotedOption?.uuid === option.uuid}
                />
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </Box>
  );
};

export default ActivePolls;
