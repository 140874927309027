/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';
import { getProvidersByManagement } from 'redux/actions/providers';
import { getAreas } from 'redux/actions/communities';
// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiDatePicker from 'components/SuiDatePicker';
// Data
import SuiButton from 'components/SuiButton';
import { createUnit } from 'redux/actions/communities';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createMaintenance } from 'redux/actions/maintenance';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { useAuthenticationStore } from 'stores/authentication';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';

function Form(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const providersResponse = useSelector((state) => state.providers.getProvidersByManagement);
  const getAreasResponse = useSelector((state) => state.communities.areas);
  const getCommunitiesByUserResponse = useGetCommunities();
  const communities = getCommunitiesByUserResponse.data?.map((community) => {
    return { label: community.name, value: community.slug };
  });
  const [areas, setareas] = useState([]);
  const [providers, setproviders] = useState([]);
  const [provider, setprovider] = useState();
  const [area, setarea] = useState();
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [period, setperiod] = useState();
  const [community, setcommunity] = useState();

  useEffect(() => {
    if (providersResponse.data !== undefined) {
      let options = providersResponse.data.map((provider) => {
        return { label: provider.executive_name || provider.razon_social, value: provider.uuid };
      });
      setproviders(options);
    }
  }, [providersResponse]);

  useEffect(() => {
    let profile = useAuthenticationStore.getState().userProfile;
    dispatch(getProvidersByManagement(profile.management.slug));
    if (!slug && !props.community) {
    } else if (slug) {
      dispatch(getAreas(slug));
    } else if (props.community) {
      dispatch(getAreas(props.community));
    }
  }, []);

  useEffect(() => {
    if (community) {
      dispatch(getAreas(community));
    }
  }, [community]);


  useEffect(() => {
    if (getAreasResponse.data) {
      let options = getAreasResponse.data.map((area) => {
        return {
          label: area.building ? area.name + ' ' + area.building.name : area.name,
          value: area.uuid,
        };
      });
      console.log(options);
      setareas(options);
    }
  }, [getAreasResponse]);

  const handleSubmit = async () => {
    let form = {
      area: area,
      service_provider: provider,
      start_date: startDate[0].toISOString().split('T')[0],
      end_date: endDate ? endDate[0].toISOString().split('T')[0] : null,
      period: period,
    };
    // TODO FIX TO QUERY
    const result = await createMaintenance(form);
    dispatch(result);
    props.close();
  };

  return (
    <SuiBox>
      <Card id="basic-info" style={{ overflow: 'visible' }}>
        <SuiBox p={3}>
          <SuiTypography variant="h5">Agregar Mantención</SuiTypography>
        </SuiBox>
        <SuiBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            {!slug && !props.community && (
              <Grid item xs={12} sm={6}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Comunidad
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  size="small"
                  onChange={(e) => setcommunity(e.value)}
                  value={community}
                  placeholder="Comunidad"
                  options={communities}
                />
                <SuiTypography variant="caption" fontWeight="regular" textColor="text">
                  *Campo obligatorio
                </SuiTypography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Proveedor
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                size="small"
                onChange={(e) => setprovider(e.value)}
                value={provider}
                placeholder="Proveedor"
                options={providers}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Área común
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                size="small"
                value={area}
                onChange={(e) => setarea(e.value)}
                placeholder="Área común"
                options={areas}
              />
              <SuiTypography variant="caption" fontWeight="regular" textColor="text">
                *Campo obligatorio
              </SuiTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Fecha de inicio
                </SuiTypography>
              </SuiBox>
              <SuiDatePicker
                size="small"
                onChange={(e) => {
                  setstartDate(e);
                }}
                input={{ placeholder: 'Seleccionar Fecha' }}
              />
              <SuiTypography variant="caption" fontWeight="regular" textColor="text">
                *Campo obligatorio
              </SuiTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Fecha de expiración
                </SuiTypography>
              </SuiBox>
              <SuiDatePicker
                size="small"
                onChange={(e) => setendDate(e)}
                input={{ placeholder: 'Seleccionar Fecha' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Periodicidad
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                size="small"
                onChange={(e) => setperiod(e.value)}
                value={period}
                placeholder="Periodicidad"
                options={[
                  { label: 'Diario', value: 'diario' },
                  { label: 'Semanal', value: 'semanal' },
                  { label: 'Mensual', value: 'mensual' },
                  { label: 'Bimestral', value: 'bimestral' },
                  { label: 'Trimestral', value: 'trimestral' },
                  { label: 'Cuatrimestral', value: 'cuatrimestral' },
                  { label: 'Semestral', value: 'semestral' },
                  { label: 'Anual', value: 'anual' },
                ]}
              />
              <SuiTypography variant="caption" fontWeight="regular" textColor="text">
                *Campo obligatorio
              </SuiTypography>
            </Grid>
          </Grid>
          <SuiBox mt={2}>
            <SuiButton variant={'gradient'} color="success" size="small" onClick={handleSubmit}>
              Crear
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Form;
