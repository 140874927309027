import { UUID } from 'crypto';
import { ExportCenterFileInput, ExportCenterPdfParams } from './types';

export const parseExportCenterFileInput = (input: ExportCenterFileInput): ExportCenterPdfParams => {
  return {
    tickets: Object.keys(input.tickets) as UUID[],
    documents: Object.keys(input.documents) as UUID[],
    reports: Object.keys(input.reports) as UUID[],
    maintenances: Object.keys(input.maintenances) as UUID[],
    maintenance_observations: input.maintenance_observations,
    general_observations: input.general_observations,
    start_date: '2023-10-01',
    end_date: '2023-10-30',
  };
};
