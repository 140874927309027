import React, { useEffect, useMemo } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import "assets/css/custom.css";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import routes, { generateRouteComponent } from "routes";
import PublicTicket from "pages/tickets/public/[uuid]";
import Notifications from "layouts/pages/notifications";
import VisitReport from "layouts/pages/calendar/report";
import ResetPassword from "layouts/authentication/reset-password/cover";
import ChangePassword from "layouts/authentication/reset-password/change-password";
import { LayoutWrapper } from "components/Layout/LayoutWrapper";
import PublicFilePoll from "components/Tickets/TicketView/PublicFilePoll";
import { Toaster } from "react-hot-toast";
import ModalsAndDrawers from "components/ModalsAndDrowers";
import LoginPage from "pages/login";
import PublicGuard from "components/routes/Guards/PublicGuard";
import AuthGuard from "components/routes/Guards/AuthGuard";
import RoleLanding from "routes/RoleRedirect";
import PrivacyPolicy from "components/PrivacyPolicy";
import PublicCommunityGantt from "pages/public-gantt";
import PublicMaintenance from "pages/public/maintenances/[uuid]";

const App = () => {
  const renderedRoutes = useMemo(
    () => generateRouteComponent(routes),
    [routes]
  );
  return (
    <ThemeProvider theme={theme}>
      <section>
        <Toaster position="bottom-right" />
        <ModalsAndDrawers />
        <Notifications />
        <CssBaseline />
      </section>

      <Routes>
        <Route path="/">
          <Route element={<PublicGuard redirect="/app" />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/change-password/:uuid/:token"
              element={<ChangePassword />}
            />
          </Route>
          <Route element={<AuthGuard redirect="/login" />}>
            <Route element={<LayoutWrapper />}>
              <Route path="/app" element={<RoleLanding />} />
              {renderedRoutes}
            </Route>
          </Route>
          <Route path="/policy-privacy" element={<PrivacyPolicy />} />
          <Route path="/ticket/public/:uuid" element={<PublicTicket />} />
          <Route
            path="/public/visits/report/:uuid"
            element={<VisitReport public={true} />}
          />
          {/* maintenances/08adbf0f-e95d-45dd-b35f-3e6ee602093c */}
          <Route path="/public" element={<>waos</>} />
          <Route
            path="/public/file/polls/:uuid/:token"
            element={<PublicFilePoll />}
          />
          <Route
            path="/public/maintenance/:uuid"
            element={<PublicMaintenance />}
          />
          <Route
            path="/public/community/:slug/gantt"
            element={<PublicCommunityGantt />}
          />
          <Route index element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
