import React, { FC, useEffect, useState } from "react";
import { Box, Card, Grid, IconButton } from "@mui/material";
import { SuiTypographyTs } from "components/SuiTypography";
import { parseDate } from "utils";
import MemberList from "components/Tickets/MemberList";
import { Edit, EditOff } from "@mui/icons-material";
import { SuiButtonTs } from "components/SuiButton";
import { useGetManagementStaff } from "api/services/staff/queries/getManagementStaff";
import useGetCommunityArea from "api/services/communities/queries/GetCommunityArea";
import { useForm } from "react-hook-form";
import InputControlled from "components/forms/controlled/InputControlled";
import SelectControlled from "components/forms/controlled/SelectControlled";
import DatePickerControlled from "components/forms/controlled/DatePickerControlled";
import { UUID } from "crypto";
import { ISODateString, Email } from "types/models";
import { SelectOptionForm } from "types/forms";
import { TicketModel } from "api/services/tickets/models";
import { useUpdateTicket } from "api/services/tickets/mutations/UpdateTicket";
import MultiSelectControlled from "components/forms/controlled/MultiSelectControlled";
import { usePermissions } from "stores/authentication/helpers";

interface TicketTitleForm {
  title: string;
  responsible: Email;
  due_date: ISODateString[];
  area: UUID;
  assignees: SelectOptionForm[];
}

interface Props {
  ticket: TicketModel;
}

const TicketTitleCard: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { dataAsOptions: staffOptions } = useGetManagementStaff({
    enabled: isEditing,
  });
  const { dataAsOptions: areasOptions } = useGetCommunityArea(
    { slug: ticket.community.slug },
    isEditing
  );

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { isDirty },
  } = useForm<TicketTitleForm>({
    mode: "onChange",
  });
  useEffect(() => {
    resetForm({
      title: ticket.title,
      responsible: ticket.responsible?.email || "",
      due_date: [ticket.due_date],
      area: ticket.area?.uuid || ("" as UUID),
      assignees: ticket.assignees.map(({ email, first_name }) => ({
        value: email,
        label: first_name,
      })),
    });
  }, [ticket]);

  const onEnableEdit = () => {
    if (!canEditTask) return;
    setIsEditing(true);
  };
  const onDisableEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  const updateTicket = useUpdateTicket({
    uuid: ticket.uuid,
  });
  const onSubmit = handleSubmit((data: TicketTitleForm) => {
    updateTicket.mutate(
      {
        title: data.title,
        responsible: data.responsible || null,
        assignees: data.assignees.map((item) => item.value as string),
        area_uuid: data.area || null,
        due_date: data.due_date[0],
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      }
    );
  });

  return (
    <Card sx={{ overflow: "visible" }}>
      <Box p={2}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"}>
            {isEditing ? (
              <InputControlled
                control={control}
                name="title"
                placeholder="Título"
                size="medium"
              />
            ) : (
              <SuiTypographyTs variant="h3" onClick={onEnableEdit}>
                {ticket.title}
              </SuiTypographyTs>
            )}

            <SuiTypographyTs variant="caption" color="secondary">
              Creado por: {ticket.user?.first_name}
            </SuiTypographyTs>
            <SuiTypographyTs variant="caption" color="secondary">
              Fecha de creación: {parseDate(ticket.created)}
            </SuiTypographyTs>
          </Box>

          {canEditTask && (
            <Box>
              <IconButton onClick={isEditing ? onDisableEdit : onEnableEdit}>
                {isEditing ? <EditOff /> : <Edit />}
              </IconButton>
            </Box>
          )}
        </Box>
        <Box>
          <Grid container mt={1} spacing={1}>
            <Grid item xs={3}>
              <SuiTypographyTs variant="h6" color="secondary">
                Responsable
              </SuiTypographyTs>
              {isEditing ? (
                <SelectControlled
                  control={control}
                  name="responsible"
                  placeholder="Responsable"
                  options={[
                    { label: "Sin asignar", value: "" },
                    ...staffOptions,
                  ]}
                />
              ) : (
                <SuiTypographyTs variant="h5" onClick={onEnableEdit}>
                  {ticket.responsible?.first_name || "Sin asignar"}
                </SuiTypographyTs>
              )}
            </Grid>
            <Grid xs={4} item>
              <SuiTypographyTs variant="h6" color="secondary">
                Fecha de término
              </SuiTypographyTs>
              {isEditing ? (
                <DatePickerControlled control={control} name="due_date" />
              ) : (
                <SuiTypographyTs variant="h5" onClick={onEnableEdit}>
                  {ticket.due_date ? parseDate(ticket.due_date) : "-"}
                </SuiTypographyTs>
              )}
            </Grid>

            <Grid item xs={5}>
              <SuiTypographyTs variant="h6" color="secondary">
                Equipo
              </SuiTypographyTs>
              {isEditing ? (
                <MultiSelectControlled
                  control={control}
                  placeholder="Equipo"
                  name="assignees"
                  options={[...staffOptions]}
                />
              ) : (
                <Box pl={1} onClick={onEnableEdit}>
                  <MemberList members={ticket.assignees} />
                </Box>
              )}
            </Grid>
            <Grid item xs={3}>
              <SuiTypographyTs variant="h6" color="secondary">
                Área común
              </SuiTypographyTs>
              {isEditing ? (
                <SelectControlled
                  control={control}
                  name="area"
                  placeholder="Área común"
                  options={[
                    { label: "Sin asignar", value: "" },
                    ...areasOptions,
                  ]}
                />
              ) : (
                <SuiTypographyTs variant="h5" onClick={onEnableEdit}>
                  {ticket.area?.name || "Sin asignar"}
                </SuiTypographyTs>
              )}
            </Grid>
            <Grid item xs>
              {isEditing && (
                <Box display={"flex"} justifyContent={"end"} mt={"auto"}>
                  <SuiButtonTs
                    variant="gradient"
                    color="secondary"
                    size="small"
                    onClick={onDisableEdit}
                    sx={{ mr: 1 }}
                  >
                    Cancelar
                  </SuiButtonTs>
                  <SuiButtonTs
                    variant="gradient"
                    color="success"
                    size="small"
                    disabled={!isDirty || updateTicket.isPending}
                    onClick={onSubmit}
                  >
                    Guardar cambios
                  </SuiButtonTs>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default TicketTitleCard;
