import { types } from "../actions/types";

export const communitiesReducer = (
    state = {
        getCommunitiesByUser: [],
        getCommunity: {},
        createBuilding: {},
        units: {},
        areas: {},
        createArea: {},
        createUnit: {},
        getVisitsByCommunity: {},
        getVisitsByManagement: {},
        markAsVisited: {},
        getBuildingsByCommunity: {},
        editVisit: {},
        editArea: {},
        deleteArea: {},
        deleteVisit: {},
        updateCommunityImage: {},
        createVisitReport: {},
        cpdateVisitReport: {},
        getVisitReport: {},
        getCommunityGantt: {},
        editBuilding: {},
        deleteBuilding: {},
        getRegionsByCountry: {},
        getCommunesByRegions: {},
        getActionPlan: {},
        importAreas: {},
    },
    action
) =>
{
    switch (action.type)
    {

        case types.setGetCommunitiesByUser:
            return {
                ...state,
                getCommunitiesByUser: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
                createBuilding: {}
            };
        case types.setGetCommunity:
            return {
                ...state,
                getCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
                createBuilding: {}
            };
        case types.setCreateBuilding:
            return {
                ...state,
                createBuilding: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetUnits:
            return {
                ...state,
                units: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetAreas:
            return {
                ...state,
                areas: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setCreateArea:
            return {
                ...state,
                createArea: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setCreateUnit:
            return {
                ...state,
                createUnit: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitsByCommunity:
            return {
                ...state,
                getVisitsByCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitsByManagement:
            return {
                ...state,
                getVisitsByManagement: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setMarkAsVisited:
            return {
                ...state,
                markAsVisited: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                },
            };
        case types.setGetBuildingsByCommunity:
            return {
                ...state,
                getBuildingsByCommunity: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setEditVisit:
            return {
                ...state,
                editVisit: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                },
            };
        case types.setDeleteVisit:
            return {
                ...state,
                deleteVisit: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                },
            };
        case types.setEditArea:
            return {
                ...state,
                editArea: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setDeleteArea:
            return {
                ...state,
                deleteArea: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };


        case types.setUpdateCommunityImage:
            return {
                ...state,
                updateCommunityImage: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setCreateVisitReport:
            return {
                ...state,
                createVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setUpdateVisitReport:
            return {
                ...state,
                updateVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetVisitReport:
            return {
                ...state,
                getVisitReport: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setGetCommunityGantt:
            return {
                ...state,
                getCommunityGantt: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            };
        case types.setEditBuilding:
            return {
                ...state,
                editBuilding: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date()
                },
            };
        case types.setDeleteBuilding:
            return {
                ...state,
                deleteBuilding: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                },
            };
        case types.setGetRegionsByCountry:
            return {
                ...state,
                getRegionsByCountry: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            }
        case types.setGetCommunesByRegions:
            return {
                ...state,
                getCommunesByRegions: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            }
        case types.setGetActionPlan:
            return {
                ...state,
                getActionPlan: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                },
            }
        case types.setImportAreas:
            return {
                ...state,
                importAreas: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    time: new Date(),
                },
            }
        default:
            return state;
    }
};
