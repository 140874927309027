import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { queryClient } from "api/config/queryClient";
import { API_URL } from "redux/actions/types";
import { TicketModel } from "../models";
import QUERY_KEYS from "api/utils/keys";
import { toastNotification } from "components/ui/ToastNotification";
import { IMessage } from "api/utils/queries";

interface Props {
  ticketUUID: UUID;
}
type Response = IMessage<TicketModel>;

export const useToggleTimelineNotifications = ({ ticketUUID: uuid }: Props) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${uuid}/toggle-step-notifications/`;
      return await axios.put<Response>(url).then((res) => res.data);
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEYS.TICKET, uuid], data.data);
      toastNotification.success({
        title: "Notificación modificada",
        message: "La notificación ha sido modificada",
      });
    },
  });
};
