import { Add } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { SuiTypographyTs } from "components/SuiTypography";
import CircleContainer from "components/ui/CircleContainer";
import React from "react";

const CreateUserTeamCard: React.FC = () => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      width={120}
      height={"100%"}
      p={1}
    >
      <CircleContainer variant="success">
        <Add />
      </CircleContainer>
      <SuiTypographyTs
        mt={1}
        textAlign={"center"}
        variant="button"
        color="text"
        fontWeight="regular"
      >
        Agregar Colaborador
      </SuiTypographyTs>
    </Stack>
  );
};

export default CreateUserTeamCard;
