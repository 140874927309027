import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { UserModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
import { SlugString } from "types/models";
import { queryClient } from "api/config/queryClient";
interface Input {
  communities: SlugString[];
}
const useUpdateUserCommunities = (userUUID: UUID) => {
  return useMutation({
    mutationKey: ["useUpdateUserPermissions", userUUID],
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/users/supervisor/communities`;
      return axios
        .put<UserModel>(url, {
          ...input,
          user: userUUID,
        })
        .then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Comunidades actualizadas",
        message:
          "Las comunidades asignadas al usuario han sido actualizadas con exito",
      });
      queryClient.invalidateQueries({queryKey:['QUERY_KEYS.GET_COMMUNITIES_BY_USER', userUUID]});
    },
  });
};

export default useUpdateUserCommunities;
