import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { toastNotification } from "components/ui/ToastNotification";
import { ChecklistModel } from "../../checklist/models";
import { UUID } from "crypto";

interface Params {
  objectUUID: UUID;
}
interface Input {
  name: string;
}

const useAddObjectChecklist = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/add-checklist/object/${params.objectUUID}`;
      return axios.post<ChecklistModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Checklist agregada",
        message: "La checklist ha sido agregada con exito.",
      });
    },
  });
};

export default useAddObjectChecklist;
