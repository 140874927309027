/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// NewProduct page components
import FormField from "../FormField";

function GeneralInfo(props) {
  return (
    <SuiBox>
      <SuiTypography variant="h5">Información Proveedor</SuiTypography>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.name}
              type="text"
              label="Nombre"
              onChange={(e) => props.setname(e.target.value)}
              placeholder="Pedro Pérez"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.giro}
              type="text"
              label="Giro"
              onChange={(e) => props.setgiro(e.target.value)}
              placeholder="Sin giro"
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.email}
              type="text"
              label="Email"
              onChange={(e) => props.setemail(e.target.value)}
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.phone}
              type="text"
              label="Teléfono"
              onChange={(e) => props.setphone(e.target.value)}
              placeholder=""
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.rut}
              type="text"
              label="Rut"
              onChange={(e) => props.setrut(e.target.value)}
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={props.razonSocial}
              onChange={(e) => props.setrazonSocial(e.target.value)}
              type="text"
              label="Razón Social"
              placeholder=""
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default GeneralInfo;
