import { ChecklistModel } from 'api/services/checklist/models';
import { UUID } from 'crypto';
import { create } from 'zustand';

interface StoreState {
  isOpen: boolean;
  objectUUID: UUID;
  openDrawner: (objectUUID: UUID, onAddSuccess?: (checklist: ChecklistModel) => void) => void;
  closeDrawer: () => void;
  onAddSuccess?: (checklist: ChecklistModel) => void;
  onClose?: () => void;
}

const useAddObjectChecklistDrawerStore = create<StoreState>((set) => ({
  isOpen: false,
  objectUUID: undefined,
  onAddSuccess: undefined,
  openDrawner: (objectUUID: UUID, onSuccess?: (checklist: ChecklistModel) => void) =>
    set({ isOpen: true, objectUUID, onAddSuccess: onSuccess }),
  closeDrawer: () => set({ isOpen: false, onAddSuccess: undefined }),
  onClose: undefined,
}));
export default useAddObjectChecklistDrawerStore;
