import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

const useDeleteCertification = (certificationId: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/certification/${certificationId}/delete/`;
      return axios.delete(url).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Certificación eliminada",
        message: "La certificación ha sido eliminada con éxito",
      });
    },
  });
};

export default useDeleteCertification;
