import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuiButton from "components/SuiButton";
import { mobileMaxWidth } from "utils";
import AnualPlanifiaction from "layouts/pages/communities/community/anual-planification";
import { Tooltip } from "@mui/material";
import SuiBox from "components/SuiBox";
import { AiOutlineEye } from "react-icons/ai";
import SuiBadge from "components/SuiBadge";
import { setActivityModalClosed } from "redux/actions/helper";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  height: "80vh",
  overflow: "scroll",
  boxShadow: 24,
};

const styleMobile = {
  position: "absolute",
  width: "100%",
  height: "80vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

export default function AnualPlanModal(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(setActivityModalClosed({ time: new Date() }));
    setOpen(false);
  };

  return (
    <SuiBox ml={1}>
      <Tooltip title="Ver Planificación" placement="top">
        <SuiBadge
          badgeContent={<AiOutlineEye size="16px" />}
          size="xs"
          container
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        />
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <AnualPlanifiaction planification={props.planification} />
        </Box>
      </Modal>
    </SuiBox>
  );
}
