import { TicketModel } from 'api/services/tickets/models';
import { create } from 'zustand';

interface CreatePollDrawer {
  isOpen: boolean;
  ticket: TicketModel;
  open: (ticket: TicketModel) => void;
  close: () => void;
}

const useCreatePollDrawerStore = create<CreatePollDrawer>((set) => ({
  isOpen: false,
  ticket: undefined,
  open: (ticket: TicketModel) => set({ isOpen: true, ticket }),
  close: () => set({ isOpen: false }),
}));

export default useCreatePollDrawerStore;
