// creat  react  component with hooks

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getActivity } from "redux/actions/activity";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/pages/profile/components/Header";
import { useSelector } from "react-redux";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import { ActivityRow } from "../ActivityRow";
import SuiButton from "components/SuiButton";
import { markAsRead } from "redux/actions/activity";
import { markAllAsRead } from "redux/actions/activity";
import { getReadActivity } from "redux/actions/activity";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";
import { getActivityUnreadCount } from "redux/actions/activity";
export const UnReadActivity = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationsResponse = useSelector((state) => state.activity.getActivity);
  const markAsReadResponse = useSelector((state) => state.activity.markAsRead);
  const markAllAsReadResponse = useSelector((state) => state.activity.markAllAsRead);

  const pagination = { variant: "gradient", color: "info" };
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [canNext, setCanNext] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);

  const activityModalClosedResponse = useSelector((state) => state.helper.activityModalClosed);

  useEffect(() => {
    if (notificationsResponse.data) {
      setCanNext(notificationsResponse.data.next);
      setTotalEntries(notificationsResponse.data.count);
      setCanPrev(notificationsResponse.data.previous);
      setUnreadNotifications(notificationsResponse.data.results);
      props.setLoading(false);
    }
  }, [notificationsResponse]);

  useEffect(() => {
    doRequest();
  }, []);

  useEffect(() => {
    if (activityModalClosedResponse.time - new Date() < 1000) {
      dispatch(markAsRead(selectedNotifications));
      setSelectedNotifications([]);
    }
  }, [activityModalClosedResponse]);

  useEffect(() => {
    if (new Date() - notificationsResponse.time < 2000) console.log(notificationsResponse.data);
    if (notificationsResponse.data) {
      doRequest();
    }
  }, [page, pageSize, props.communities, props.member]);

  useEffect(() => {
    if (new Date() - markAsReadResponse.time < 2000)
      if (markAsReadResponse.data) {
        doRequest();
        dispatch(getActivityUnreadCount());
      }
  }, [markAsReadResponse]);

  useEffect(() => {
    if (new Date() - markAllAsReadResponse.time < 2000)
      if (markAllAsReadResponse.data) {
        doRequest();
        setPage(1);
        dispatch(getActivityUnreadCount());
      }
  }, [markAllAsReadResponse]);

  const doRequest = () => {
    let params = {
      page: page,
      page_size: pageSize,
      communities: props.communities.map((c) => c.value).toString(),
      action_user: props.member,
    };
    props.setLoading(true);
    dispatch(getActivity(params));
  };

  const pushOrPopNotification = (elemento) => {
    setSelectedNotifications((prevLista) => [...prevLista, elemento]); // Agrega el elemento si no existe
  };

  return props.loading ? (
    <></>
  ) : (
    <SuiBox>
      <SuiBox display="flex" justifyContent="flex-end">
        {selectedNotifications.length > 0 && (
          <SuiBox p={1}>
            <SuiButton
              color="primary"
              size="small"
              variant="gradient"
              onClick={() => {
                dispatch(markAsRead(selectedNotifications));
                setSelectedNotifications([]);
              }}
            >
              Marcar como leidas
            </SuiButton>
          </SuiBox>
        )}
        {unreadNotifications.length > 0 && (
          <SuiBox p={1}>
            <SuiButton
              color="info"
              size="small"
              variant="gradient"
              onClick={() => dispatch(markAllAsRead())}
            >
              Marcar todas como leidas
            </SuiButton>
          </SuiBox>
        )}
      </SuiBox>
      <Card sx={{ mt: 2, p: 2 }}>
        <SuiBox p={2}>
          <SuiTypography variant="h4" fontWeight="medium" textAlign="center" pb={2}>
            No leídos
          </SuiTypography>
          {unreadNotifications.length > 0 && (
            <Grid container spacing={3}>
              {unreadNotifications.map((notification, index) => (
                <Grid item xs={12} key={index}>
                  <ActivityRow
                    pushOrPopNotification={pushOrPopNotification}
                    selectedNotifications={selectedNotifications}
                    activity={notification}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {unreadNotifications.length > 0 && (
            <SuiBox>
              <SuiBox
                pt={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="flex-end"
                alignItems={{ xs: "flex-end", sm: "center" }}
              >
                <SuiPagination
                  variant={pagination.variant ? pagination.variant : "gradient"}
                  color={pagination.color ? pagination.color : "info"}
                >
                  {canPrev && (
                    <SuiPagination item onClick={() => setPage(page - 1)}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </SuiPagination>
                  )}
                  <SuiBox width="5rem" mx={1}>
                    <SuiInput
                      inputProps={{ type: "number", min: 1, max: 3 }}
                      value={page}
                      onChange={(e) => setPage(e.target.value)}
                    />
                  </SuiBox>
                  {canNext && (
                    <SuiPagination item onClick={() => setPage(parseInt(page) + 1)}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </SuiPagination>
                  )}
                </SuiPagination>
              </SuiBox>
              <SuiTypography variant="button">
                Mostrando {(page - 1) * pageSize + 1} a{" "}
                {page * pageSize > totalEntries ? totalEntries : page * pageSize} de {totalEntries}{" "}
                entradas de página
              </SuiTypography>
            </SuiBox>
          )}
          {unreadNotifications.length === 0 && (
            <SuiTypography variant="body2" fontWeight="medium" textAlign="center" pb={2}>
              No hay notificaciones por leer
            </SuiTypography>
          )}
        </SuiBox>
        <Divider />
      </Card>
    </SuiBox>
  );
};
