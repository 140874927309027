import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SuiButtonTs } from "components/SuiButton";
import { Tooltip } from "@mui/material";
import { AiOutlineEye, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { SuiBadgeTs } from "components/SuiBadge";
import { setActivityModalClosed } from "redux/actions/helper";
import { useDispatch, useSelector } from "react-redux";
import TicketView from "../TicketView";
import useMediaScreen from "utils/Hooks/useMedia";
import { mobileStyle, styleDesktop } from "./styles";

const TicketModal = ({ ...rest }) => {
  const { isMobile } = useMediaScreen();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const createTicketResponse = useSelector(
    (state: any) => state.tickets.createTicket
  );
  const dispatch = useDispatch();
  const [community, setcommunity] = useState(null);
  const [uuid, setUuid] = useState(
    createTicketResponse.data ? createTicketResponse.data.uuid : null
  );
  const handleClose = () => {
    setOpen(false);
    dispatch(setActivityModalClosed({ time: new Date() }));
    if (rest.report) {
      rest.updateReport();
    }
    if (typeof rest.update === "function") rest.update();
  };

  useEffect(() => {
    if (rest.selectedUuid) {
      setUuid(rest.selectedUuid);
      setcommunity(rest.selectedTicket.community);
      handleOpen();
    }
  }, [rest.selectedUuid]);

  useEffect(() => {
    if (rest.create) {
      if (createTicketResponse.data) {
        if (createTicketResponse.data.uuid !== uuid) {
          setUuid(createTicketResponse.data.uuid);
          setcommunity(createTicketResponse.data.community);
          handleOpen();
        }
      }
    }
  }, [createTicketResponse.data]);
  const navButtonStyle = {
    position: "absolute",
    top: "50%",
    zIndex: 1200, // Asegúrate de que está sobre cualquier otro elemento dentro del modal
  };

  const handlePrev = () => rest.handlePrev();

  const handleNext = () => rest.handleNext();

  const PrevButton = () => (
    <SuiButtonTs
      variant="gradient"
      color="info"
      size="small"
      onClick={handlePrev}
      sx={{ ...navButtonStyle, left: -30 }}
      startIcon={<AiOutlineLeft size="20px" />}
    />
  );
  const NextButton = () => (
    <SuiButtonTs
      variant="gradient"
      color="info"
      size="small"
      onClick={handleNext}
      sx={{ ...navButtonStyle, right: -30, color: "black" }}
      endIcon={<AiOutlineRight size="20px" />}
    />
  );
  return (
    <>
      {!rest.create && (
        <Tooltip key={rest.uuid} title={"Ver Tarea"} placement="top">
          {rest.title ? (
            <Box sx={{ cursor: "pointer" }} onClick={handleOpen}>
              {rest.title}
            </Box>
          ) : (
            <SuiBadgeTs
              container
              badgeContent={<AiOutlineEye size="16px" />}
              size="xs"
              style={{ cursor: "pointer" }}
              onClick={handleOpen}
              bgcolor={"info"}
            />
          )}
        </Tooltip>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isMobile ? mobileStyle : styleDesktop}>
          {rest.handlePrev && <PrevButton />}
          <TicketView
            // community={rest.create ? community : rest.community}
            ticketUUID={rest.create ? uuid : rest.uuid}
            onArchiveTicket={handleClose}
          />
          {rest.handleNext && <NextButton />}
        </Box>
      </Modal>
    </>
  );
};
export default TicketModal;
