import React, { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import { TicketModel } from "api/services/tickets/models";
import useTicketDocumentsColumns from "./columns";
import SwappiDataTable from "components/SwappiTable";
import { DocumentModel } from "api/services/documents/models";

interface Props {
  ticket: TicketModel;
}
const TicketDocumentsTable: FC<Props> = ({ ticket }) => {
  const data = ticket.documents;
  const { columns } = useTicketDocumentsColumns({ ticket });
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h4" fontWeight="bold" textTransform="capitalize">
        Documentos
      </Typography>
      <Box>
        <SwappiDataTable columns={columns} data={data} />
      </Box>
    </Card>
  );
};

export default TicketDocumentsTable;
