import { MaintenanceModel } from "api/services/maintenances/models";

import { SuiButtonTs } from "components/SuiButton";
import React, { useRef } from "react";
import DialogContainer, {
  DialogContainerRef,
} from "components/DialogContainer";
import CreateMaintenanceVisitForm, {
  EVENT_VISIT_CREATED,
} from "components/pages/maintenances/forms/CreateMaintenanceVisitForm";
import useEventListener from "lib/events/useEventListener";

interface Props {
  maintenance: MaintenanceModel;
}

const CreateVisitsButton: React.FC<Props> = ({ maintenance }) => {
  const dialogRef = useRef<DialogContainerRef>();
  const handleOpen = () => dialogRef.current.handleOpen();
  useEventListener(EVENT_VISIT_CREATED, () => {
    dialogRef.current.handleClose();
  });
  return (
    <div>
      <SuiButtonTs
        onClick={handleOpen}
        size="small"
        color="success"
        variant="gradient"
        type="submit"
      >
        Agregar visita
      </SuiButtonTs>
      <DialogContainer ref={dialogRef}>
        <CreateMaintenanceVisitForm maintenance={maintenance} />
      </DialogContainer>
    </div>
  );
};

export default CreateVisitsButton;
