/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiEditor from "components/SuiEditor";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import Checkbox from "@mui/material/Checkbox";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiInput from "components/SuiInput";
// Data
import SuiButton from "components/SuiButton";
import { createTicket } from "redux/actions/tickets";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCommunitiesByUser } from "redux/actions/communities";
import { getPermission } from "utils";
import { createVisitReport } from "redux/actions/reports";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";

function Form(props) {
  const dispatch = useDispatch();
  const [title, settitle] = useState("");
  const [comment, setcomment] = useState("");
  const [priority, setpriority] = useState("baja");
  const { slug } = useParams();
  const [complexTimeline, setcomplexTimeline] = useState(false);
  const [isAfterSale, setisAfterSale] = useState(false);
  const communitiesResponse = useGetCommunities();
  const [communities, setcommunities] = useState([]);
  const [community, setcommunity] = useState("");
  const handleSubmit = (e) => {
    let form = {};
    if (title.length > 0) {
      form.title = title;
    }
    if (props.community) {
      form.community = props.community;
    } else if (!slug && community) {
      form.community = community;
    } else if (slug) {
      form.community = slug;
    }
    dispatch(createVisitReport(form));

    props.handleClose();
  };


  useEffect(() => {
    if (communitiesResponse.data !== undefined) {
      let options = [];
      if (getPermission(["superadmin", "administrador"])) {
        communitiesResponse.data.map((community) => {
          options.push({ label: community.name, value: community.slug });
        });
      } else {
        // filter comunities response by assignee
        communitiesResponse.data.map((community) => {
          options.push({ label: community.name, value: community.slug });
        });
      }

      setcommunities(options);
    }
  }, [communitiesResponse]);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Crear Reporte Visita</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <SuiTypography variant="button">Título</SuiTypography>
            <SuiInput value={title} onChange={(e) => settitle(e.target.value)} size="small" />
          </Grid>
          {!slug && !props.community ? (
            <Grid item xs={12} sm={12} p={2} pt={0}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Comunidad
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                size="small"
                onChange={(e) => setcommunity(e.value)}
                placeholder="Comunidad"
                options={communities}
                value={community}
              />
            </Grid>
          ) : (
            ""
          )}
          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" onClick={handleSubmit}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Form;
