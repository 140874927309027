import Swal from 'sweetalert2';
import React, { useMemo, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { AiOutlineInbox } from "react-icons/ai";
import { MdEngineering } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { SiReadthedocs } from "react-icons/si";
import {
  getDocumentsColumns,
  getMaintenancesColumns,
  getTicketColumns,
  getVisitsReportsColumns,
} from "./helpers";
import SwappiDataTable from "components/SwappiTable";
import { useExportCenterStore } from "../store";
import DownloadButton from "./DownloadButton";
import { SuiButtonTs } from "components/SuiButton";
import { Clear } from "@mui/icons-material";

const ExportFile = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const exportFormStore = useExportCenterStore();
  const {
    tickets,
    maintenances,
    visits: visitsReports,
    documents,
  } = exportFormStore;

  const ticketColumns = useMemo(
    () => getTicketColumns(exportFormStore),
    [tickets]
  );
  const maintenancesColumns = useMemo(
    () => getMaintenancesColumns(exportFormStore),
    [maintenances]
  );
  const visitReportsColumns = useMemo(
    () => getVisitsReportsColumns(exportFormStore),
    [visitsReports]
  );
  const documentsColumns = useMemo(
    () => getDocumentsColumns(exportFormStore),
    [documents]
  );

  const items = [
    {
      label: "Tareas",
      count: Object.keys(tickets).length,
      icon: <AiOutlineInbox />,
      tab: (
        <Box>
          <Typography variant="h4" fontWeight="medium" sx={{ mb: 2 }}>
            Tareas Seleccionadas {Object.keys(tickets).length}
          </Typography>
          <SwappiDataTable
            columns={ticketColumns}
            data={Object.keys(tickets).map((key) => tickets[key])}
            onEmpty={"Sin Tickets selecionados"}
          />
        </Box>
      ),
    },
    {
      label: "Mantenciones",
      count: Object.keys(maintenances).length,
      icon: <MdEngineering />,
      tab: (
        <Box>
          <Typography variant="h4" fontWeight="medium" sx={{ mb: 2 }}>
            Mantenciones Seleccionadas {Object.keys(maintenances).length}
          </Typography>
          <SwappiDataTable
            columns={maintenancesColumns}
            data={Object.keys(maintenances).map((key) => maintenances[key])}
            onEmpty={"Sin mantenciones seleccionadas"}
          />
        </Box>
      ),
    },
    {
      label: "Visitas",
      count: Object.keys(visitsReports).length,
      icon: <TbReportSearch />,
      tab: (
        <Box>
          <Typography variant="h4" fontWeight="medium" sx={{ mb: 2 }}>
            Visitas Seleccionadas {Object.keys(visitsReports).length}
          </Typography>
          <SwappiDataTable
            columns={visitReportsColumns}
            data={Object.keys(visitsReports).map((key) => visitsReports[key])}
            onEmpty={"Sin Visitas en Terreno seleccionadas"}
          />
        </Box>
      ),
    },
    {
      label: "Documentos",
      count: Object.keys(documents).length,
      icon: <SiReadthedocs />,
      tab: (
        <Box>
          <Typography variant="h4" fontWeight="medium" sx={{ mb: 2 }}>
            Visitas Seleccionadas {Object.keys(documents).length}
          </Typography>
          <SwappiDataTable
            columns={documentsColumns}
            data={Object.keys(documents).map((key) => documents[key])}
            onEmpty={"Sin Documentos seleccionadas"}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Grid container mt={2} direction="row" justifyContent="space-between">
        {items.map((item, idx) => {
          return (
            <Grid item xs={12} sm={2.6} key={item.label}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => setCurrentTab(idx)}
              >
                <MiniStatisticsCard
                  border={
                    idx === currentTab
                      ? "2px solid #2979ff"
                      : "2px solid rgba(255,255,255,0.5)"
                  }
                  title={{ text: item.label, fontWeight: "bold" }}
                  count={item.count}
                  icon={item.icon}
                />
              </Box>
            </Grid>
          );
        })}
        <Grid
          item
          sx={{
            display: "block",
          }}
        >
          <Box sx={{ paddingBottom: 2 }}>
            <SuiButtonTs variant="gradient" color="error" size="small" onClick={() => {
              Swal.fire({
                title: '¿Estás seguro?',
                text: '¡No podrás revertir esto!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, limpiar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  exportFormStore.clearAll();
                  Swal.fire(
                    '¡Limpiado!',
                    'El formulario ha sido limpiado.',
                    'success'
                  );
                }
              });
            }}>
              Limpiar
              <Clear />
            </SuiButtonTs>
          </Box>
          <Box>
            <DownloadButton />
          </Box>
        </Grid>
      </Grid>
      <Card sx={{ mt: 2, p: 2 }}>{items[currentTab].tab}</Card>
    </Box>
  );
};

export default ExportFile;
