import { useRef, useEffect, useCallback } from 'react';

const useDebounce = <F extends (...args: any[]) => any>(callback: F, delay: number): F => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return useCallback(
    ((...args: Parameters<F>) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    }) as F,
    [callback, delay]
  );
};

export default useDebounce;
