import { Box, Divider, Tooltip } from "@mui/material";
import { TicketModel } from "api/services/tickets/models";
import { SuiTypographyTs } from "components/SuiTypography";
import React, { FC, Fragment, useMemo, useState } from "react";
import { OrderBy, sortByModified } from "utils/helpers/tickets/commentaries";
import Commentary from "../Commentary";
import DropdownMenu, { DropdownMenuItem } from "components/DropdownMenu";
import { Sort } from "@mui/icons-material";
import ShowMoreContainer from "components/ShowMore";

interface CommentariesListProsp {
  ticket: TicketModel;
}
const CommentariesList: FC<CommentariesListProsp> = ({ ticket }) => {
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.DESC);
  const commentariesCount = ticket.comments.length;
  const commentariesOrdered = useMemo(() => {
    return sortByModified(ticket.comments, orderBy);
  }, [orderBy, ticket.comments]);
  const sortOptions: DropdownMenuItem[] = [
    {
      label: "Mas recientes primeros",
      selected: orderBy === OrderBy.DESC,
      onClick() {
        setOrderBy(OrderBy.DESC);
      },
    },
    {
      label: "Mas antiguos primeros",
      selected: orderBy === OrderBy.ASC,
      onClick() {
        setOrderBy(OrderBy.ASC);
      },
    },
  ];
  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} gap={3}>
        <SuiTypographyTs pb={2} variant="h4" sx={{ p: 0 }}>
          {commentariesCount} comentarios
        </SuiTypographyTs>
        <DropdownMenu
          items={sortOptions}
          buttonSx={{ p: 0, borderRadius: 0 }}
          buttonIcon={
            <Tooltip placement="bottom" title={"Ordenar los comentarios"}>
              <SuiTypographyTs pb={2} variant="h5" sx={{ p: 0, px: 1 }}>
                <Sort sx={{ p: 0 }} />
                Ordernar por
              </SuiTypographyTs>
            </Tooltip>
          }
        />
      </Box>
      <Divider />

      <Box gap={2} display={"flex"} flexDirection={"column"}>
        {commentariesCount === 0 && (
          <Box textAlign={"center"}>Sin comentarios</Box>
        )}
        {commentariesOrdered.map((item) => {
          return (
            <Fragment key={`${item.uuid}`}>
              <Commentary commentary={item} ticket={ticket} />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default CommentariesList;
