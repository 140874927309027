/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useRef } from "react";
// @mui material components
import Card from "@mui/material/Card";
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React data variables
import { Box, IconButton, Stack } from "@mui/material";
import UserTeamCard from "components/modules/team/UserTeamCard";
import CreateUserTeamCard from "components/modules/team/CreateUserTeamCard";
import CreateUserModal from "components/modules/users/management/CreateUserModal";
import { UserModel } from "api/services/user/models";
import { ModalRefHandle } from "components/ui/BaseModal";
import { SlugString } from "types/models";

const buttonStyle = {
  p: 0,
  borderRadius: "10px",
  borderColor: "red",
  boxSizing: "border-box",
};

interface Props {
  users: UserModel[];
  userSelected: UserModel;
  communitySlug?: SlugString;
  onSelect: (user: UserModel) => void;
}

const TeamUsersList: React.FC<Props> = ({
  users,
  userSelected,
  onSelect,
  communitySlug,
}) => {
  const modalRef = useRef<ModalRefHandle>(null);
  const handleSelect = (user) => {
    onSelect(user);
  };

  const handleCreateCreate = () => {
    modalRef.current.open();
  };

  return (
    <>
      <CreateUserModal ref={modalRef} communitySlug={communitySlug} />
      <Card sx={{ overflowX: "auto", p: 2 }}>
        <Stack direction={"row"} alignItems="stretch">
          <Box
            component={IconButton}
            sx={buttonStyle}
            onClick={handleCreateCreate}
          >
            <CreateUserTeamCard />
          </Box>

          {users.map((user) => {
            const isSelected = user.uuid === userSelected?.uuid;
            return (
              <Box
                key={user.uuid}
                component={IconButton}
                disabled={isSelected}
                onClick={() => handleSelect(user)}
                sx={{
                  ...buttonStyle,
                  border: `solid 1px ${
                    isSelected ? colors.success.main : "transparent"
                  }`,
                }}
              >
                <UserTeamCard user={user} />
              </Box>
            );
          })}
        </Stack>
      </Card>
    </>
  );
};

export default TeamUsersList;
