import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'api/utils/keys';
import { UUID } from 'crypto';
import { CommentaryModel, TicketModel } from '../models';
import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { ChecklistItemModel, ChecklistModel } from 'api/services/checklist/models';
import { queryClient } from 'api/config/queryClient';
import { DocumentModel } from 'api/services/documents/models';
import { axiosPublic } from 'api/config/axios';
import { PollModel } from 'api/services/polls/models';

interface Params {
  uuid: UUID;
}
export const useGetTicket = (params: Params) => {
  return useQuery<TicketModel>({
    enabled: !!params.uuid,
    staleTime: 1000 * 60 * 5,
    queryKey: [QUERY_KEYS.TICKET, params.uuid],
    queryFn: async () => {
      const currentUrl = window.location.href;
      if (currentUrl.includes('/ticket/public/')) {
        const url = `${API_URL}/tickets/public/${params.uuid}`;
        return await axiosPublic(url).then((res) => res.data);
      } else {
        const url = `${API_URL}/tickets/by-uuid/${params.uuid}`;
        return await axios.get<{ ticket: TicketModel }>(url).then((res) => res.data.ticket);
      }
    },
  });
};

export const TicketQueryHelper = {
  updateTicket: async (ticket: TicketModel) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticket.uuid], ticket);
  },
  addChecklist: async (newChecklist: ChecklistModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedChecklists = [...prev.checklists, newChecklist];
      return { ...prev, checklists: updatedChecklists } as TicketModel;
    });
  },
  updateChecklist: async (checklist: ChecklistModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedTicket = { ...prev };
      const updatedList = [...prev.checklists];
      const index = updatedTicket.checklists.findIndex(({ uuid }) => uuid === checklist.uuid);
      updatedList[index] = checklist;
      updatedTicket.checklists = updatedList;
      return { ...updatedTicket };
    });
  },
  deleteChecklist: async (checklistUUID: UUID, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      const updatedTicket = { ...prev };
      const checklistFiltered = updatedTicket.checklists.filter(
        ({ uuid }) => uuid !== checklistUUID
      );
      updatedTicket.checklists = checklistFiltered;
      return updatedTicket;
    });
  },
  addChecklistItem: async (
    checklistItem: ChecklistItemModel,
    checklistUUID: UUID,
    ticketUUID: UUID
  ) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedChecklists = prev.checklists.map((checklist) => {
        if (checklist.uuid === checklistUUID) {
          return {
            ...checklist,
            items: [...checklist.items, checklistItem],
          };
        }
        return checklist;
      });
      return { ...prev, checklists: updatedChecklists };
    });
  },
  updateChecklistItem: async (
    updatedItem: ChecklistItemModel,
    checklistUUID: UUID,
    ticketUUID: UUID
  ) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedChecklists = prev.checklists.map((checklist) => {
        if (checklist.uuid === checklistUUID) {
          return {
            ...checklist,
            items: checklist.items.map((item) =>
              item.uuid === updatedItem.uuid ? updatedItem : item
            ),
          };
        }
        return checklist;
      });
      return { ...prev, checklists: updatedChecklists };
    });
  },
  deleteChecklistItem: async (itemUUID: UUID, checklistUUID: UUID, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedChecklists = prev.checklists.map((checklist) => {
        if (checklist.uuid === checklistUUID) {
          return {
            ...checklist,
            items: checklist.items.filter((item) => item.uuid !== itemUUID),
          };
        }
        return checklist;
      });
      return { ...prev, checklists: updatedChecklists };
    });
  },
  addCommentary: async (commentary: CommentaryModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedComments = [...prev.comments, commentary];
      return { ...prev, comments: updatedComments };
    });
  },
  updateCommentary: async (updatedCommentary: CommentaryModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;

      const updatedComments = prev.comments.map((comment) => {
        if (comment.uuid !== updatedCommentary.uuid) return comment;
        return updatedCommentary;
      });
      return { ...prev, comments: updatedComments };
    });
  },
  deleteCommentary: async (commentaryUUID: UUID, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;

      const updatedComments = prev.comments.filter((comment) => comment.uuid !== commentaryUUID);
      return { ...prev, comments: updatedComments };
    });
  },
  updateDocument: async (inputDocument: DocumentModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedDocuments = prev.documents.map((document) => {
        if (document.uuid === inputDocument.uuid) return inputDocument;
        else return document;
      });
      return { ...prev, documents: updatedDocuments };
    });
  },
  deleteDocument: async (documentUUID: UUID, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      const updatedDocuments = prev.documents.filter((document) => document.uuid !== documentUUID);
      return { ...prev, documents: updatedDocuments };
    });
  },
  updatePoll: async (inputPoll: PollModel, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      if (!prev) return;
      let found: boolean = false;
      const updatedPolls = prev.polls.map((poll) => {
        if (poll.uuid === inputPoll.uuid) {
          found = true;
          return inputPoll;
        }
        return poll;
      });
      if (found) {
        return { ...prev, polls: updatedPolls };
      }
      return { ...prev, polls: [...updatedPolls, inputPoll] };
    });
  },
  archivePoll: async (pollUUID: UUID, ticketUUID: UUID) => {
    return await queryClient.setQueryData([QUERY_KEYS.TICKET, ticketUUID], (prev: TicketModel) => {
      const updatedPolls = prev.polls.filter(({ uuid }) => uuid !== pollUUID);
      return { ...prev, polls: updatedPolls } as TicketModel;
    });
  },
};
