import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { VisitModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
interface Params {
  uuid: UUID;
}
interface Input {
  comment: string;
  completed_on: ISODateString;
  title: string;
  start: ISODateString;
  end: ISODateString;
}
const useUpdateVisit = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Partial<Input>) => {
      const url = `${API_URL}/visit/${params.uuid}/edit`;
      return axios.put<VisitModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Visita completada",
        message: "La visita ha sido modificada con éxito!",
      });
    },
  });
};

export default useUpdateVisit;
