import { Box, Drawer } from '@mui/material';
import React from 'react';
import useCreatePollDrawerStore from './store';
import CreatePollForm from '../CreatePollForm';

const CreatePollFormDrawer = () => {
  const { isOpen, close, ticket } = useCreatePollDrawerStore();
  return (
    <Drawer anchor={'right'} open={isOpen} onClose={close} sx={{ zIndex: 1301 }}>
      <Box sx={{ p: 2 }}>
        <CreatePollForm ticket={ticket} onSuccess={close} />
      </Box>
    </Drawer>
  );
};

export default CreatePollFormDrawer;
