import { MaintenanceModel } from "api/services/maintenances/models";
import useDeleteMaintenance from "api/services/maintenances/mutations/deleteMaintenance";
import { SuiButtonTs } from "components/SuiButton";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import Swal from "sweetalert2";

const DeleteMaintenanceButton: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const { mutate: deleteMaintenance } = useDeleteMaintenance(maintenance.uuid);
  const maintenanceDeleted = useEventEmitter("maintenanceDeleted");

  return (
    <SuiButtonTs
      onClick={() =>
        Swal.fire({
          title: "¿Quieres eliminar esta mantención?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            deleteMaintenance(null, {
              onSuccess: (data) => maintenanceDeleted.emit(data),
            });
          }
        })
      }
      style={{ float: "right" }}
      variant="gradient"
      color="error"
      size="small"
    >
      Eliminar
    </SuiButtonTs>
  );
};

export default DeleteMaintenanceButton;
