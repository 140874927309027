import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import {
  GetVisitReportDetailParams,
  GetVisitReportParams,
  VisitReportDetail,
  VisitsReportPage,
} from './types';
import { useQuery } from '@tanstack/react-query';

export const GetVisitsReports = (params: GetVisitReportParams) => {
  return useQuery<VisitsReportPage>({
    queryKey: ['GetVisitsReports', params],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const url = `${API_URL}/analytics/visit/report/all/`;
      return await axios
        .get<VisitsReportPage>(url, { params })
        .then((res) => res.data)
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
  });
};

export const GetVisitReportDetail = (params: GetVisitReportDetailParams, enabled: boolean) => {
  return useQuery<VisitReportDetail>({
    enabled: !!params?.uuid && enabled,
    queryKey: ['GetVisitReportDetail', params],
    staleTime: 1000 * 60 * 1,
    queryFn: async () => {
      const { uuid } = params;
      const url = `${API_URL}/analytics/visit/report/${uuid}/`;
      return await axios
        .get<VisitReportDetail>(url, { params })
        .then((res) => res.data)
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
  });
};
