import { DocumentModel } from 'api/services/documents/models';
const taxValue = 0.19;
export function documentValueInClp(document: DocumentModel, ufValue: number) {
  const { currency, price, tax } = document;
  return currency === 'UF'
    ? price * ufValue
    : currency === 'CLP' && tax === '+ IVA'
    ? price * (1 + taxValue)
    : price;
}

export function getDocumentsReferenceValue(documents: DocumentModel[], ufValue: number) {
  return documents.reduce((min, document) => {
    const value = documentValueInClp(document, ufValue);
    return value < min ? value : min;
  }, documentValueInClp(documents[0], ufValue));
}
