import { Send } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import useAddObjectChecklist from 'api/services/tickets/mutations/AddObjectChecklist';
import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useAddObjectChecklistDrawerStore from '../store';
import { UUID } from 'crypto';

interface Props {
  objectUUID: UUID;
}

const NewChecklistForm: FC<Props> = ({ objectUUID }) => {
  const closeDrawer = useAddObjectChecklistDrawerStore((state) => state.closeDrawer);
  const onAddSuccess = useAddObjectChecklistDrawerStore((state) => state.onAddSuccess);

  const addChecklist = useAddObjectChecklist({ objectUUID: objectUUID });
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: '' },
  });

  const handleOnSubmit = handleSubmit((data) => {
    addChecklist.mutate(
      { name: data.name },
      {
        onSuccess: (data) => {
          onAddSuccess?.(data);
          closeDrawer();
          reset();
        },
      }
    );
  });
  return (
    <Box>
      <Typography component="h4" fontWeight="bold" mb={2}>
        Crear Checklist
      </Typography>
      <Box mb={1}>
        <InputControlled
          label="Nombre del Checklist"
          name="name"
          placeholder="Ingresar nombre"
          control={control}
          size="large"
        />
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <SuiButtonTs
          mr={2}
          variant="gradient"
          color="success"
          size="small"
          onClick={handleOnSubmit}
        >
          Crear <Send sx={{ ml: 1 }} />
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default NewChecklistForm;
