import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiBadge from "components/SuiBadge";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import { useGetComiteByCommunitySlug } from "api/services/user/queries/getComitee";
import DataTable from "examples/Tables/DataTable";
import LoadingView from "components/Loading";
import { deactiveUser, getComiteByCommunitySlug } from "redux/actions/users";
import { isPermissionEnabled } from "utils";
import EditUser from "layouts/pages/admin-team/EditUser";
import Create from "layouts/pages/admin-team/components/Post/Members/Create";
import ManageUserPermissionsModal from "components/modules/users/management/ManageUserPermissionsModal";
import { useAuthenticationStore } from "stores/authentication";
import ModalRefHandle from "components/ui/BaseModal";
import useUpdateUser from "api/services/user/mutations/updateUser";
import useDeactivateUser from "api/services/user/mutations/deactivateUser";
import useEventEmitter from "lib/events/useEventEmitter";
import DeleteComite from "./DeleteComite";
function Comite(props) {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const deleteUserReponse = useSelector((state) => state.users.deactiveUser);
  const updateUserReponse = useSelector((state) => state.users.updateUserWithAdmin);
  const managePermissionsModalRef = useRef(ModalRefHandle);
  const [user, setUser] = useState(null);
  const { data, isLoading } = useGetComiteByCommunitySlug(slug);
  const userDeactivated = useEventEmitter("userDeactivated");
  const { mutate: deactivateUser } = useDeactivateUser();

  const unitsTableData = (users) => {
    const columns = [
      { Header: "Nombre", accessor: "name", width: "20%" },
      { Header: "Email", accessor: "email", width: "30%" },
      { Header: "Rut", accessor: "rut", width: "30%" },
      { Header: "Teléfono", accessor: "phone", width: "15%" },
      { Header: "Acciones", accessor: "actions" },
    ];

    const rows = users.map((user) => ({
      name: user.first_name,
      email: user.email,
      rut: user.rut,
      phone: user.phone,
      actions: (
        <SuiBox display="flex" justifyContent="center">
          {isPermissionEnabled("committee-editCommittee") && (
            <Tooltip title="Editar" placement="top">
              <EditUser user={user} />
            </Tooltip>
          )}
          {isPermissionEnabled("committee-editCommittee") && (
            <Tooltip title="Editar permisos" placement="top">
              <SuiBadge
                style={{ cursor: "pointer" }}
                variant="gradient"
                color="info"
                badgeContent={<Icon size="12px">edit</Icon>}
                sx={{ marginRight: 1 }}
                onClick={() => {
                  setUser(user);
                  managePermissionsModalRef.current.open();
                }}
              />
            </Tooltip>
          )}
          {isPermissionEnabled("committee-deleteCommittee") && (
            <DeleteComite user={user} />
          )}
        </SuiBox>
      ),
    }));

    return { columns, rows };
  };

  const team = useMemo(() => {
    if (!data) return { columns: [], rows: [] };
    const table = unitsTableData(data);
    console.log(table);
    return table;
  }, [data]);

  useEffect(() => {
    if (updateUserReponse && new Date() - updateUserReponse.time < 2000) {
      dispatch(getComiteByCommunitySlug(slug));
    }
  }, [updateUserReponse]);

  useEffect(() => {
    if (deleteUserReponse && new Date() - deleteUserReponse.time < 2000) {
      dispatch(getComiteByCommunitySlug(slug));
    }
  }, [deleteUserReponse]);

  return (
    <Card sx={{ overflow: "scroll" }}>
      <SuiBox width="100%" pt={3} pb={2.5} px={3}>
        {isPermissionEnabled("committee-createCommittee") && <Create comite={true} />}
        <ManageUserPermissionsModal ref={managePermissionsModalRef} user={user} />
        <Grid container wrap="nowrap">
          {isLoading && <LoadingView />}
          {!isLoading && <DataTable table={team} />}
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Comite;