import Box from "@mui/material/Box";
import { usePermissions } from "stores/authentication/helpers";
import CreateBudgetButton from "./CreateBudgetButton";
import BudgetsTable from "./BudgetsTable";

const BudgetsTab = ({ maintenance }) => {
  const canEdit = usePermissions().maintenances.edit;
  return (
    <Box>
      {canEdit && (
        <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
          <CreateBudgetButton maintenance={maintenance} />
        </Box>
      )}
      <BudgetsTable maintenance={maintenance} />
    </Box>
  );
};
export default BudgetsTab;
