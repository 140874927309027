import { Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  BudgetModel,
  MaintenanceModel,
} from "api/services/maintenances/models";
import useDeleteMaintenanceBudget from "api/services/maintenances/mutations/deleteMaintenanceBudget";
import { SuiBadgeTs } from "components/SuiBadge";
import useEventEmitter from "lib/events/useEventEmitter";
import React from "react";
import Swal from "sweetalert2";

const TableActions: React.FC<{
  budget: BudgetModel;
  maintenance: MaintenanceModel;
}> = ({ budget, maintenance }) => {
  const editBudget = useEventEmitter("editBudget");
  const budgetDeleted = useEventEmitter("budgetDeleted");
  const { mutate: deleteBudget } = useDeleteMaintenanceBudget({
    maintenanceUUID: maintenance.uuid,
    budgetUUID: budget.uuid,
  });
  const handleEditBudget = () => {
    editBudget.emit(budget);
  };
  const handleDeleteBudget = () => {
    Swal.fire({
      title: "¿Quieres eliminar este presupuesto?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteBudget(null, {
          onSuccess: (data) => {
            budgetDeleted.emit(data);
          },
        });
      }
    });
  };
  return (
    <Box display={"flex"} gap={1}>
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        onClick={handleEditBudget}
        badgeContent={<Edit />}
        color="warning"
        variant="gradient"
        container
      />
      <SuiBadgeTs
        style={{ cursor: "pointer" }}
        onClick={handleDeleteBudget}
        badgeContent={<Delete />}
        color="error"
        variant="gradient"
        container
      />
    </Box>
  );
};

export default TableActions;
