const QUERY_KEYS = {
  TICKETS_TABLE: "TICKETS_LIST",
  TICKET: "TICKET_BY_UUID",
  TICKET_PDF: "TICKET_PDF",
  GET_UF: "GET_UF",
  GET_COMMUNITIES: "GET_COMMUNITIES",
  GET_COMMUNITIES_AREAS: "GET_COMMUNITIES_AREAS",
  GET_CHECKLIST_TEMPLATE: "TEMPLATE",
  GET_COMMUNITY_PUBLIC_GANTT: "GET_COMMUNITY_PUBLIC_GANTT",
  GET_COMMUNITY_IMAGE: "GET_COMMUNITY_IMAGE",
};

export default QUERY_KEYS;
