import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { ProviderModel } from '../types';
import { useQuery } from '@tanstack/react-query';
import { getAuthUserProfile } from 'stores/authentication/helpers';

export const useGetProviders = () => {
  const query = useQuery<ProviderModel[]>({
    queryKey: ['GetProvidersByManagement'],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const { slug } = getAuthUserProfile().management;
      const url = `${API_URL}/users/manager/service-providers/${slug}`;
      return await axios
        .get<ProviderModel[]>(url)
        .then((res) => res.data)
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
  });
  const data = query.data || [];
  const dataAsOptions = data.map((item) => ({
    label: item.executive_name || item.razon_social,
    value: item.uuid,
  }));

  return { ...query, dataAsOptions, data };
};
