import { useQuery } from "@tanstack/react-query";
import semaphore from "api/utils/Semaphore";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { SlugString } from "types/models";
const useGetCommunityImage = (communitySlug: SlugString) => {
  return useQuery({
    queryKey: ["get_community_image", communitySlug],
    staleTime: 1000 * 60 * 10,
    enabled: !!communitySlug,
    queryFn: async () => {
      await semaphore.acquire();
      const url = `${API_URL}/community/${communitySlug}/picture/`;
      return await axios
        .get(url)
        .then((res) => res.data)
        .catch(() => ({ url: undefined }))
        .finally(() => {
          semaphore.release();
        });
    },
  });
};

export default useGetCommunityImage;
