/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Update from "./Update";

// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";
import Create from "./Create";

import { getProvidersByManagement } from "redux/actions/providers";
import { useDispatch, useSelector } from "react-redux";
import SuiButton from "components/SuiButton";
// Data
import DataTable from "examples/Tables/DataTable";
import Header from "../profile/components/Header";
import { Card } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Swal from "sweetalert2";
import Icon from "@material-ui/core/Icon";
import { deleteProvider } from "redux/actions/providers";
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportProvidersToFile } from "redux/actions/providers";
import { getPermission } from "utils";
import { isPermissionEnabled } from "utils";
import Import from "./Import";
import { mobileMaxWidth } from "utils";
import { useAuthenticationStore } from "stores/authentication";

const providersTableData = (providers, navigate, dispatch) => {
  let columns = [
    { Header: "Nombre", accessor: "executive_name", width: "10%" },
    { Header: "Razón Social", accessor: "razon_social", width: "20%" },
    { Header: "Rut", accessor: "rut", width: "10%" },
    { Header: "Giro", accessor: "giro", width: "15%" },
    { Header: "Email", accessor: "email", width: "15%" },
    { Header: "Teléfono", accessor: "phone", width: "10%" },
    { Header: "Acciones", accessor: "actions", width: "5%" },
  ];
  if (providers.data === undefined) {
    return { columns, rows: [] };
  }
  let rows = providers.data.map((provider, i) => {
    return {
      code: provider.code,
      executive_name: (
        <p
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/providers/${provider.uuid}`, { state: { provider } })
          }
        >
          {provider.executive_name}
        </p>
      ),
      title: provider.title,
      email: provider.email,
      phone: provider.phone,
      giro: provider.giro,
      rut: provider.rut,
      razon_social: provider.razon_social,
      actions: (
        <div>
          <SuiButton
            color="info"
            variant="gradient"
            style={{ width: "100%", marginBottom: "2px" }}
            size="small"
            onClick={() =>
              navigate(`/providers/${provider.uuid}`, { state: { provider } })
            }
          >
            Ver
            <AiOutlineEye />
          </SuiButton>
          {isPermissionEnabled("providers-editProvider") && (
            <Update provider={provider} />
          )}
          {getPermission(["superadmin", "administrador", "supervisor"]) &&
            isPermissionEnabled("providers-deleteProvider") && (
              <SuiButton
                style={{ width: "100%", marginBottom: "2px" }}
                onClick={() =>
                  Swal.fire({
                    title: "¿Quieres eliminar este proveedor?",
                    showDenyButton: true,
                    confirmButtonText: "Si",
                    denyButtonText: `No`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      let profile =
                        useAuthenticationStore.getState().userProfile;
                      dispatch(
                        deleteProvider(profile.management.slug, provider.uuid)
                      );
                      Swal.fire("Eliminado", "", "success");
                    } else if (result.isDenied) {
                    }
                  })
                }
                color="error"
                variant="gradient"
                size="small"
              >
                Eliminar
                <Icon
                  sx={{ cursor: "pointer", float: "right" }}
                  fontSize="small"
                >
                  delete
                </Icon>
              </SuiButton>
            )}
        </div>
      ),
    };
  });
  return { columns, rows };
};

function Providers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columns, setcolumns] = useState([]);
  const [rows, setrows] = useState([]);

  const providers = useSelector(
    (state) => state.providers.getProvidersByManagement
  );
  const createProviderResponse = useSelector(
    (state) => state.providers.createProviderByManagement
  );
  const updateProviderResponse = useSelector(
    (state) => state.providers.updateProvider
  );
  const deleteProviderResponse = useSelector(
    (state) => state.providers.deleteProvider
  );
  const importProvidersResponse = useSelector(
    (state) => state.providers.importProviders
  );
  // Dropdown menu template for the ComplexProjectCard

  useEffect(() => {
    let profile = useAuthenticationStore.getState().userProfile;
    dispatch(getProvidersByManagement(profile.management.slug));
  }, []);

  useEffect(() => {
    if (providers)
      if (providers.data !== undefined) {
        let data = providersTableData(providers, navigate, dispatch);
        setcolumns(data.columns);
        setrows(data.rows);
      }
  }, [providers]);

  useEffect(() => {
    if (createProviderResponse)
      if (createProviderResponse.data !== undefined) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getProvidersByManagement(profile.management.slug));
      }
  }, [createProviderResponse]);

  useEffect(() => {
    if (updateProviderResponse)
      if (updateProviderResponse.data !== undefined) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getProvidersByManagement(profile.management.slug));
      }
  }, [updateProviderResponse]);

  useEffect(() => {
    if (deleteProviderResponse)
      if (deleteProviderResponse.data !== undefined) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getProvidersByManagement(profile.management.slug));
      }
  }, [deleteProviderResponse]);

  useEffect(() => {
    if (importProvidersResponse)
      if (importProvidersResponse.data !== undefined) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getProvidersByManagement(profile.management.slug));
      }
  }, [importProvidersResponse]);

  return (
    <SuiBox p={3}>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <SuiBox width={"100%"} mt={5} mb={2}>
            <SuiTypography variant="h4" fontWeight="medium" textAlign="center">
              Proveedores
            </SuiTypography>

            {isPermissionEnabled("providers-createProvider") && (
              <SuiBox
                p={1}
                display="flex"
                justifyContent={
                  window.innerWidth >= mobileMaxWidth ? "end" : "center"
                }
              >
                <Create />
              </SuiBox>
            )}
            {isPermissionEnabled("providers-createProvider") && (
              <SuiBox
                p={1}
                display="flex"
                justifyContent={
                  window.innerWidth >= mobileMaxWidth ? "end" : "center"
                }
              >
                <Import />
              </SuiBox>
            )}
            <SuiBox
              p={1}
              display="flex"
              justifyContent={
                window.innerWidth >= mobileMaxWidth ? "end" : "center"
              }
            >
              <SuiButton
                size="small"
                variant="gradient"
                onClick={(e) => {
                  dispatch(
                    exportProvidersToFile({
                      type: "pdf",
                      management:
                        useAuthenticationStore.getState().userProfile.management
                          .slug,
                    })
                  );
                }}
                color="primary"
              >
                <AiOutlineFilePdf
                  style={{ cursor: "pointer", marginRight: 5 }}
                />
              </SuiButton>
              <SuiButton
                size="small"
                variant="gradient"
                onClick={(e) => {
                  dispatch(
                    exportProvidersToFile({
                      type: "excel",
                      management:
                        useAuthenticationStore.getState().userProfile.management
                          .slug,
                    })
                  );
                }}
              >
                <RiFileExcel2Line style={{ cursor: "pointer" }} />
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <Card style={{ width: "100%", padding: 15 }}>
            <DataTable table={{ columns, rows }} />
          </Card>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Providers;
