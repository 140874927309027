import useGetMaintenance from "api/services/maintenances/queries/getMaintenance";
import LoadingView from "components/Loading";
import { UUID } from "crypto";
import MaintenanceView, {
  EVENT_MAINTENANCE_DELETED,
} from "layouts/pages/maintenance/View";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useEventListener from "lib/events/useEventListener";

const MaintenanceDetailPage: React.FC = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const {
    data: maintenance,
    isLoading,
    refetch,
  } = useGetMaintenance(uuid as UUID);

  useEventListener(
    [
      "visitUpdated",
      "visitCompleted",
      "emergencyCreated",
      "emergencyUpdated",
      "emergencyDeleted",
      "budgetCreated",
      "budgetUpdated",
      "budgetDeleted",
      "documentCreated",
      "documentUpdated",
      "documentDeleted",
    ],
    () => {
      refetch();
    }
  );

  useEventListener(EVENT_MAINTENANCE_DELETED, () => {
    navigate("/maintenances");
  });

  if (isLoading) return <LoadingView />;

  return (
    <div>
      <MaintenanceView maintenance={maintenance} />
    </div>
  );
};

export default MaintenanceDetailPage;
