/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";
import SuiSnackbar from "components/SuiSnackbar";
import { useSelector } from "react-redux";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const notification = useSelector((state) => state.notifications.notification);
  const [type, settype] = useState();
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const alertContent = (name) => (
    <SuiTypography variant="body2" color="white">
      A simple {name} alert with{" "}
      <SuiTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        an example link
      </SuiTypography>
      . Give it a click if you like.
    </SuiTypography>
  );

  const renderSuccessSB = () => (
    <SuiSnackbar
      color="success"
      icon="check"
      title={notification ? notification.title : ""}
      content={notification ? notification.message : ""}
      dateTime={""}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = () => (
    <SuiSnackbar
      icon="notifications"
      title={notification ? notification.title : ""}
      content={notification ? notification.message : ""}
      dateTime={""}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = () => (
    <SuiSnackbar
      color="warning"
      icon="star"
      title={notification ? notification.title : ""}
      content={notification ? notification.message : ""}
      dateTime={""}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = () => (
    <SuiSnackbar
      color="error"
      icon="warning"
      title={notification ? notification.title : ""}
      content={notification ? notification.message : ""}
      dateTime={""}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (notification == undefined) {
      return;
    } else if (notification.status === "success") {
      setSuccessSB(true);
    } else if (notification.status === "info") {
      setInfoSB(true);
    } else if (notification.status === "warning") {
      setWarningSB(true);
    } else if (notification.status === "error") {
      setErrorSB(true);
    }
  }, [notification]);
  return (
    <div>
      {renderSuccessSB()}
      {renderInfoSB()}
      {renderWarningSB()}
      {renderErrorSB()}
    </div>
  );
}

export default Notifications;
