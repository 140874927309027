import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/config/queryClient";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { GET_MAINTENANCE_KEY } from "../queries/getMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";

import { toastNotification } from "components/ui/ToastNotification";

interface Props {
  uuid: UUID;
}

interface Inputs {
  start_date: ISODateString;
  end_date: ISODateString;
  provider_uuid: UUID;
  period: string;
}
const useUpdateMaintenance = (props: Props) => {
  return useMutation({
    mutationFn: async (input: Partial<Inputs>) => {
      const url = `${API_URL}/maintenance/${props.uuid}/edit`;
      return await axios
        .put<MaintenanceModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      queryClient.setQueryData([GET_MAINTENANCE_KEY, props.uuid], data);
      toastNotification.success({
        title: "Mantención actualizada",
        message:
          "Los datos de la mantención han sido actualizados con correctamente.",
      });
    },
  });
};

export default useUpdateMaintenance;
