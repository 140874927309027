import React, { useState, useRef, useEffect, JSX, FC } from 'react';
import { Collapse, Button, Link, Typography } from '@mui/material';

interface ComponentProps {
  children: JSX.Element | any;
  height?: number;
  showMoreText?: string;
  showLessText?: string;
}
const ShowMoreLess: FC<ComponentProps> = ({
  children,
  height = 100,
  showMoreText = '...Mostrar mas',
  showLessText = 'Mostrar menos',
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [containerH, setContainerH] = useState<number>(height);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    const contentHeight = contentRef.current.scrollHeight;
    if (contentHeight <= height) {
      setShowButton(false);
      setContainerH(contentHeight);
    } else {
      setShowButton(true);
      setContainerH(height);
    }
  }, [children, height]);

  return (
    <div>
      <Collapse in={expanded} collapsedSize={containerH}>
        <div ref={contentRef}>{children}</div>
      </Collapse>

      {showButton && (
        <Typography
          variant="caption"
          fontWeight={'bold'}
          onClick={handleToggle}
          sx={{ cursor: 'pointer', color: 'gray' }}
        >
          {expanded ? showLessText : showMoreText}
        </Typography>
      )}
    </div>
  );
};

export default ShowMoreLess;
