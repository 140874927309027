import { types } from '../actions/types';

export const maintenanceReducer = (
  state = {
    createMaintenance: {},
    createMaintenanceVisit: {},
    getMaintenanceByCommunity: {},
    deleteMaintenance: {},
    getMaintenance: {},
    createCertification: {},
    createEmergency: {},
    updateEmergency: {},
    deleteEmergency: {},
    deleteCertification: {},
    getMaintenancesByManagement: {},
    getExportMaintenance: {},
    editEmergency: {},
    regenerateMaintenanceVisits: {},
    createMaintenanceBudget: {},
    deleteMaintenanceBudget: {},
    editMaintenanceBudget: {},
    editMaintenance: {},
  },
  action
) => {
  switch (action.type) {
    case types.setCreateMaintenance:
      return {
        ...state,
        createMaintenance: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setCreateMaintenanceVisit:
      return {
        ...state,
        createMaintenanceVisit: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setGetMaintenanceByCommunity:
      return {
        ...state,
        getMaintenanceByCommunity: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
        deleteMaintenance: {},
      };
    case types.setEditMaintenance:
      return {
        ...state,
        editMaintenance: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setDeleteMaintenance:
      return {
        ...state,
        deleteMaintenance: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setGetMaintenance:
      return {
        ...state,
        getMaintenance: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setCreateCertification:
      return {
        ...state,
        createCertification: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setCreateEmergency:
      return {
        ...state,
        createEmergency: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setUpdateEmergency:
      return {
        ...state,
        updateEmergency: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setDeleteEmergency:
      return {
        ...state,
        deleteEmergency: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };

    case types.setDeleteCertification:
      return {
        ...state,
        deleteCertification: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setGetMaintenancesByManagement:
      return {
        ...state,
        getMaintenancesByManagement: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setGetExportMaintenance:
      return {
        ...state,
        getExportMaintenance: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setEditEmergency:
      return {
        ...state,
        editEmergency: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setRegenerateMaintenanceVisits:
      return {
        ...state,
        regenerateMaintenanceVisits: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setCreateMaintenanceBudget:
      return {
        ...state,
        createMaintenanceBudget: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setEditMaintenanceBudget:
      return {
        ...state,
        editMaintenanceBudget: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };
    case types.setDeleteMaintenanceBudget:
      return {
        ...state,
        deleteMaintenanceBudget: {
          data: action.payload.data,
          status: action.payload.status,
          message: action.payload.message,
          time: new Date(),
        },
      };

    default:
      return state;
  }
};
