/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Fragment, FC } from 'react';

// @mui material components
import Divider from '@mui/material/Divider';

// Soft UI Dasboard PRO Material components

import { SuiBoxTs } from 'components/SuiBox';
import { SuiTypographyTs } from 'components/SuiTypography';
// General page components
import ChecklistItem from './ChecklistItem';
import { SuiProgressTs } from 'components/SuiProgress';

import ChecklistTitle from './ChecklistTitle';
import { Box, List, Paper } from '@mui/material';
import NewChecklistItem from './NewChecklistItem';
import { ChecklistModel } from 'api/services/checklist/models';
import { UUID } from 'crypto';

interface Props {
  objectUUID: UUID;
  checklist: ChecklistModel;
  editEnabled?: boolean;
  onEdit?: (checklist: ChecklistModel) => void;
  onDelete?: (checklist: ChecklistModel) => void;
}
const Checklist: FC<Props> = ({ checklist, editEnabled, objectUUID, onEdit, onDelete }) => {
  const checklistItemsSorted = checklist.items?.sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
  );

  const totalItems = checklist.items.length;
  const totalItemsDone = checklist.items.filter((item) => item.done).length;
  const completedPercent = ((totalItemsDone / totalItems) * 100).toFixed(0);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box>
        <ChecklistTitle
          checklist={checklist}
          objectUUID={objectUUID}
          editEnabled={editEnabled}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Box>

      <Divider />
      <Box>
        <Box mb={2}>
          <SuiTypographyTs variant="button" fontWeight="medium" textColor="text">
            {totalItems > 0 ? completedPercent : '-'}% Completado
          </SuiTypographyTs>
          <SuiProgressTs value={totalItems > 0 ? completedPercent : 0} variant="determinate" />
        </Box>
        <SuiBoxTs component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <List>
            {checklistItemsSorted.map((item, index, arr) => {
              return (
                <Fragment key={`${item.uuid}-${index}`}>
                  <ChecklistItem
                    item={item}
                    checklist={checklist}
                    objectUUID={objectUUID}
                    editEnabled={editEnabled}
                    onEdit={onEdit}
                  />
                  {index < arr.length - 1 && (
                    <Divider variant="inset" component="li" sx={{ my: 0 }} />
                  )}
                </Fragment>
              );
            })}
          </List>
        </SuiBoxTs>
      </Box>
      {editEnabled && (
        <Box mt={2}>
          <NewChecklistItem checklist={checklist} objectUUID={objectUUID} onEdit={onEdit} />
        </Box>
      )}
    </Paper>
  );
};

export default Checklist;
