import { useMemo, FC, useRef, useState } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiTypographyTs } from "components/SuiTypography";
import EditFileDrawer, {
  EditFileDrawerHandles,
} from "components/EditFileDrawer";
import { TicketModel } from "api/services/tickets/models";
import { usePermissions } from "stores/authentication/helpers";
import useMediaScreen from "utils/Hooks/useMedia";
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import ShowMoreLess from "components/ShowMore";
import DropdownMenu from "components/DropdownMenu";
import { Edit } from "@mui/icons-material";
import { useGetDocumentUrls } from "api/services/documents/queries/getDocumentUrl";

interface Props {
  ticket: TicketModel;
}

const TicketDocumentsImagesCard: FC<Props> = ({ ticket }) => {
  const { canEditTask } = usePermissions();
  const { isMobile } = useMediaScreen();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const drawerRef = useRef<EditFileDrawerHandles>(null);

  const imageDocuments = useMemo(() => {
    return ticket.documents
      .filter((doc) => doc.type.slug === "imagen")
      .sort((one, two) => (one > two ? -1 : 1));
  }, [ticket]);

  const uuids = imageDocuments.map((doc) => doc.uuid);
  const documentQueries = useGetDocumentUrls(uuids, true);

  const galleryItems: ReactImageGalleryItem[] = useMemo(() => {
    return documentQueries
      .map((query) => {
        const url = query.data?.url;
        return {
          original: url,
          thumbnail: url,
          thumbnailHeight: 100,
          thumbnailWidth: 100,
          originalHeight: 400,
        };
      })
      .filter((item) => item.original && item.thumbnail);
  }, [documentQueries]);

  const selectedDocument = imageDocuments[selectedIndex];

  const handleEditDocument = () => drawerRef.current?.toggleDrawer();

  return (
    <Card sx={{ p: 2 }}>
      <Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography fontWeight="medium" variant="h4" flex={1}>
              Imágenes
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} data-section="images-gallery">
            {galleryItems.length > 0 ? (
              <ReactImageGallery
                items={galleryItems}
                onSlide={setSelectedIndex}
                infinite={false}
                showPlayButton={false}
                showIndex={false}
                showBullets={isMobile}
                showNav={isMobile}
                showThumbnails={!isMobile}
              />
            ) : (
              <Typography>No hay imágenes disponibles</Typography>
            )}
          </Grid>
          <Grid item sm={6} xs={12} data-section="documents-info">
            {selectedDocument ? (
              <Box>
                <Box display={"flex"} mb={1}>
                  <Box flex={1}>
                    <Typography variant="h4" fontWeight="bold">
                      {selectedDocument.name}
                    </Typography>
                  </Box>
                  {canEditTask && (
                    <EditFileDrawer
                      ref={drawerRef}
                      file={selectedDocument}
                      slugType="imagen"
                      community={ticket.community}
                      onlyDrawer
                    />
                  )}
                  <Box>
                    <DropdownMenu
                      items={[
                        [
                          {
                            label: "Editar información",
                            icon: <Edit />,
                            onClick: handleEditDocument,
                          },
                        ],
                      ]}
                    />
                  </Box>
                </Box>
                <Stack>
                  <SuiBadgeTs
                    badgeContent={selectedDocument.status}
                    size={"small"}
                    variant="gradient"
                    container
                    color={
                      selectedDocument.status == "Antes" ? "error" : "success"
                    }
                  />
                </Stack>
                <Stack direction={"row"} spacing={1} my={2}>
                  {selectedDocument.tags.map((tag) => (
                    <SuiBadgeTs
                      key={tag.uuid}
                      variant="gradient"
                      color={"info"}
                      size={"small"}
                      container
                      badgeContent={tag.name}
                    />
                  ))}
                </Stack>
                <Typography mb={1} variant="h5" fontWeight="bold">
                  Descripción
                </Typography>
                <ShowMoreLess height={360}>
                  <Typography
                    variant="button"
                    fontWeight={"regular"}
                    textTransform={"none"}
                  >
                    {selectedDocument.observations}
                  </Typography>
                </ShowMoreLess>
              </Box>
            ) : (
              <Typography>
                No hay información del documento seleccionada
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default TicketDocumentsImagesCard;
