import { useMutation } from "@tanstack/react-query";
import { EmergencyModel } from "../models";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

interface Input {
  maintenance_uuid: UUID;
  title: string;
  description: string;
}

const useCreateEmergency = () => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/emergency/new/`;
      return await axios.post<EmergencyModel>(url, input);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Emergencia creada",
        message: "La emergencia ha sido creada con exito",
      });
    },
  });
};

export default useCreateEmergency;
