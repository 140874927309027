import React from 'react';
import { Box, Card } from '@mui/material';
import SuiEditor from 'components/SuiEditor';
import { useExportCenterStore } from '../store';

const CommentaryMaintenances = () => {
  const { observationsMaintenances, setObservationMaintenances } = useExportCenterStore();
  return (
    <Box>
      <Card sx={{ p: 2 }}>
        <SuiEditor value={observationsMaintenances} onChange={setObservationMaintenances} />
      </Card>
    </Box>
  );
};

export default CommentaryMaintenances;
