/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-flatpickr components
import Flatpickr from 'react-flatpickr';

// react-flatpickr styles
import 'flatpickr/dist/flatpickr.css';

import { es } from 'flatpickr/dist/l10n/es.js';

// Soft UI Dashboard PRO React components
import SuiInput from 'components/SuiInput';

let changeOptions = (options, values) => {
  let newOpt = options;
  values.map((value) => {
    newOpt[value.key] = value.value;
  });
  return newOpt;
};

const options = {
  locale: {
    firstDayOfWeek: 1,
    weekdays: {
      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    },
    months: {
      shorthand: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Оct',
        'Nov',
        'Dic',
      ],
      longhand: [
        'Enero',
        'Febrero',
        'Мarzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
  },
  enableTime: true,
};
function SuiDatePicker({ input = {}, error = false, ...rest }) {
  // concat rest.options with options
  return (
    <Flatpickr
      {...rest}
      style={{ zIndex: 1000000 }}
      data-enable-time
      options={{ ...options, ...rest.options }}
      render={({ defaultValue }, ref) => (
        <SuiInput
          {...input}
          error={error}
          size="small"
          defaultValue={defaultValue ? defaultValue : ''}
          placeholder="Seleccionar una fecha"
          inputRef={ref}
        />
      )}
    />
  );
}

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDatePicker;
