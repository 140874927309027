import { UserModel } from "api/services/user/models";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ManageUserCommunitiesForm from "./forms/ManageUserCommunitiesForm";
import { Box, Divider } from "@mui/material";
import { SuiTypographyTs } from "components/SuiTypography";

interface Props {
  user: UserModel;
}

const ManageUserCommunitiesModal = forwardRef<ModalRefHandle, Props>(
  ({ user }, ref) => {
    const modalRef = useRef<ModalRefHandle>();

    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
    }));

    useEventListener("userUpdated", () => {
      modalRef.current?.close();
    });

    return (
      <BaseModal ref={modalRef}>
        <Box p={3} width={{ md: 650, xs: "100vw" }}>
          <SuiTypographyTs variant="h4" fontWeight="medium">
            Asignar comunidades
          </SuiTypographyTs>
          <Divider />
          {user && <ManageUserCommunitiesForm user={user} />}
        </Box>
      </BaseModal>
    );
  }
);
ManageUserCommunitiesModal.displayName = "ManageUserCommunitiesModal";
export default ManageUserCommunitiesModal;
