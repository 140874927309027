import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Stack } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import useAddVisitDocument from "api/services/visits/mutations/addVisitDocument";
import InputControlled from "components/forms/controlled/InputControlled";
import { SuiButtonTs } from "components/SuiButton";
import { useForm } from "react-hook-form";
import useEventEmitter from "lib/events/useEventEmitter";
import { scheme } from "./form.helper";

export const MAINTENANCE_VISIT_FILE_UPLOADED =
  "MAINTENANCE_VISIT_FILE_UPLOADED";

const FormUploadFile: React.FC<{
  visit: VisitModel;
}> = ({ visit }) => {
  const { mutate: addDocument, isPending } = useAddVisitDocument({
    uuid: visit.uuid,
  });
  const newFileUploaded = useEventEmitter(MAINTENANCE_VISIT_FILE_UPLOADED);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: {
      name: visit.title,
      file: null,
    },
  });
  const isSubmitDisabled = !isValid || isPending;
  const onSubmit = handleSubmit((data) => {
    addDocument(data, {
      onSuccess: (data) => {
        newFileUploaded.emit(data);
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <InputControlled
          label="Nombre"
          placeholder="Nombre del documento"
          control={control}
          name="name"
        />
        <InputControlled
          label="Documento"
          placeholder="Selecionar documento"
          control={control}
          name="file"
          type="file"
        />
      </Stack>
      <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
        {isPending && <CircularProgress color="secondary" size={"small"} />}
        <SuiButtonTs
          size="small"
          color="success"
          variant="gradient"
          type="submit"
          disabled={isSubmitDisabled}
        >
          Subir documento
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default FormUploadFile;
