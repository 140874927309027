/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// Soft UI Dasboard PRO Material components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';
import SuiProgress from 'components/SuiProgress';

// General page component
import { useParams, useNavigate } from 'react-router-dom';
import { getTicketsByCommunity } from 'redux/actions/tickets';
import { parseDate, ticketStatusColor } from 'utils';
import { ticketPriorityColor } from 'utils';
import Loading from 'components/Loading';
import { BiArchiveIn } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { getPermission } from 'utils';
import SuiAvatar from 'components/SuiAvatar';
import Tooltip from '@mui/material/Tooltip';
import TicketsGantt from 'layouts/pages/charts/TicketsGantt';
import SuiSelect from 'components/SuiSelect';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { mobileMaxWidth } from 'utils';
import SuiInput from 'components/SuiInput';
import VisitsModal from 'layouts/pages/calendar/VisitView';
import CreateVisit from 'layouts/pages/calendar/CreateVisit';

const unitsTableData = (visits, setVisit) => {
  let columns = [
    { Header: 'Título', accessor: 'title', width: '30%' },
    { Header: 'Fecha Inicio', accessor: 'start' },
    { Header: 'Fecha expiración', accessor: 'end' },
    { Header: 'Comunidad', accessor: 'community' },
    { Header: 'Completado', accessor: 'completed' },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  let rows = visits
    ? visits.map((visit, i) => {
        let start = parseDate(visit.start);
        let end = parseDate(visit.end);
        let completed = visit.completed ? (
          <SuiBadge
            container
            color="success"
            variant="gradient"
            badgeContent="Completado"
            size="xs"
          />
        ) : (
          <SuiBadge
            container
            color="warning"
            variant="gradient"
            badgeContent="Pendiente"
            size="xs"
          />
        );
        let actions = (
          <SuiBadge
            container
            color="info"
            variant="gradient"
            badgeContent="Ver"
            style={{ cursor: 'pointer' }}
            size="xs"
            onClick={() => setVisit(visit)}
          />
        );
        return {
          title: visit.title,
          start: start,
          end: end,
          community: visit.community.name,
          completed: completed,
          actions: actions,
        };
      })
    : [];
  return { columns, rows };
};

function ActionPlanVisitTable(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [visit, setVisit] = useState(null);
  const [dataTableFull, setdataTableFull] = useState(unitsTableData(props.visits, setVisit));
  useEffect(() => {
    setdataTableFull(unitsTableData(props.visits, setVisit));
  }, [props.visits]);

  const renderContent = () => {
    return (
      <SuiBox p={window.innerWidth <= mobileMaxWidth ? 0 : 4} pt={3}>
        <VisitsModal visit={visit} />
        <SuiBox display="flex" justifyContent="space-between" mb={2}>
          <SuiTypography variant="h4" fontWeight="medium">
            Visitas
          </SuiTypography>
          <CreateVisit />
        </SuiBox>
        <p style={{ fontSize: 15, fontWeight: 100 }}>
          * sólo se mostrarán visitas que tengan fecha de expiración próxima.
        </p>
        <DataTable pageSize={5} canSearch={false} table={dataTableFull} />
      </SuiBox>
    );
  };

  return <Card>{renderContent()}</Card>;
}

export default ActionPlanVisitTable;
