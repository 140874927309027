import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { useDispatch } from "react-redux";
import { setNotification } from "redux/actions/tickets";
import { API_URL } from "redux/actions/types";
import { ChecklistTemplateModel } from "../models";
import { queryClient } from "api/config/queryClient";
import QUERY_KEYS from "api/utils/keys";
import { toastNotification } from "components/ui/ToastNotification";

interface Params {
  checklistUUID: UUID;
}
interface Input {
  name: string;
}
const useCreateChecklistTemplateFromChecklist = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/tickets/save-template/checklist/${params.checklistUUID}/`;
      return await axios
        .post<ChecklistTemplateModel>(url, input)
        .then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      });
      toastNotification.success({
        title: "Template creado",
        message: "El checklist ha sido guardado como checklist",
      });
    },
  });
};

export default useCreateChecklistTemplateFromChecklist;
