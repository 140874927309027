import React, { FC, useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import { useGetMaintenancesList } from "api/services/maintenances/queries/getMaintenancesList";
import SwappiDataTable from "components/SwappiTable";
import { MaintenancesDataFilters } from "./types";
import Filters from "./Filters";
import { getColumns } from "./helpers";
import { useExportCenterFiltersStore, useExportCenterStore } from "../store";

const PAGE = 1;
const PAGE_SIZE = 20;

const SelectMaintenancesTable = () => {
  const [tablePage, setTablePage] = useState<number>(PAGE);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [dataFilters, setDataFilters] = useState<MaintenancesDataFilters>({
    community: useExportCenterFiltersStore.getState().community,
    service_provider: null,
  });

  const {
    maintenances: selectedMaintenances,
    addMaintenances,
    removeMaintenances,
  } = useExportCenterStore();

  const { data, isLoading } = useGetMaintenancesList({
    page_size: pageSize,
    page: tablePage,
    community: dataFilters.community,
    service_provider: dataFilters.service_provider,
  });
  console.log("data?.results", data?.results);
  const columns = useMemo(() => {
    return getColumns({
      data,
      selectedMaintenances,
      addMaintenances,
      removeMaintenances,
    });
  }, [data, selectedMaintenances]);

  return (
    <Box>
      <Card sx={{ p: 2, mb: 2, overflow: "visible" }}>
        <Filters setFilters={setDataFilters} />
      </Card>
      <Card sx={{ px: 2, overflow: "visible" }}>
        <Box>
          <SwappiDataTable
            columns={columns}
            isLoading={isLoading}
            defaultPageSize={pageSize}
            onChangePageSize={setPageSize}
            data={data?.results || []}
            pagination={{
              count: data?.count,
              previous: data?.previous,
              next: data?.next,
              currentPage: tablePage,
              onChangePage: setTablePage,
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default SelectMaintenancesTable;
