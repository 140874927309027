/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';
import SuiTagInput from 'components/SuiTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';
import SuiDropzone from 'components/SuiDropzone';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';
import SuiButton from 'components/SuiButton';
import { useParams, useNavigate } from 'react-router-dom';
import { createArea } from 'redux/actions/communities';
import { useSelector, useDispatch } from 'react-redux';
import { editArea } from 'redux/actions/communities';
import SuiEditor from 'components/SuiEditor';

function Form({ close, buildings, areaUuid, area }) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [buildingSelected, setbuildingSelected] = useState(null);
  const [specifications, setSpecifications] = useState(area.specifications);
  const [name, setName] = useState(area.name);
  const handleEditArea = () => {
    dispatch(
      editArea(slug, {
        name,
        building: buildingSelected,
        area_uuid: area.uuid,
        specifications,
      })
    );
    close();
  };

  useEffect(() => {
    setbuildingSelected(area.building ? area.building.uuid : null);
    setName(area.name);
  }, []);

  useEffect(() => {
    setbuildingSelected(area.building ? area.building.uuid : null);
    setName(area.name);
  }, [area]);
  return (
    <Card id="basic-info" sx={{ overflow: 'scroll' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Editar área común</SuiTypography>
      </SuiBox>
      <SuiBox
        component="form"
        pb={3}
        px={3}
        onSubmit={(e) => {
          e.preventDefault();
          handleEditArea();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox pt={2}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Torre
              </SuiTypography>
              <SuiSelect
                placeholder="Seleccione torre"
                option={buildingSelected}
                onChange={(e) => {
                  setbuildingSelected(e.value);
                }}
                options={buildings}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Nombre del área"
              placeholder="Piscina"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Especificaciones
            </SuiTypography>
            <SuiEditor
              style={{ minWidth: 400 }}
              label="Especificaciónes"
              value={specifications}
              placeholder="Descripción"
              onChange={(e) => setSpecifications(e)}
              multiline
              rows={5}
            />
          </Grid>
          <SuiBox p={3}>
            <SuiButton variant="gradient" color="success" onClick={handleEditArea}>
              Guardar
            </SuiButton>
          </SuiBox>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Form;
