import { SelectedDocumentsState } from "../types";
import {
  DocumentModel,
  SwappiDocumentsPage,
} from "api/services/documents/models";
import { Box, Checkbox, Tooltip } from "@mui/material";
import { SuiBadgeTs } from "components/SuiBadge";
import DocumentActions from "components/DocumentsTable/DocumentActions";
import { typeColorMapping } from "./constants";
import { ColumnTable } from "components/SwappiTable/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ColumnsRequired {
  data: SwappiDocumentsPage;
  selectedDocuments: SelectedDocumentsState;
  addDocuments: (item: DocumentModel[]) => void;
  removeDocuments: (item: DocumentModel[]) => void;
}

export const getColumns = (
  input: ColumnsRequired
): ColumnTable<DocumentModel>[] => {
  const { data, selectedDocuments, removeDocuments, addDocuments } = input;

  const handleCheckbox = (checked: boolean, item: DocumentModel) => {
    if (checked) addDocuments([item]);
    else removeDocuments([item]);
  };
  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll) addDocuments(data.results);
    else removeDocuments(data.results);
  };

  const validateCheckAll = () =>
    data?.results.every((document) => {
      return selectedDocuments[document.uuid] !== undefined;
    });
  const allChecked = validateCheckAll();

  return [
    {
      header: () => (
        <Box onClick={() => handleCheckAll(!allChecked)} display={"flex"}>
          <Checkbox checked={allChecked} />
          <Tooltip
            title="Esta opción selecciona solo la hoja actual"
            placement="top"
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      ),
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Checkbox
          checked={selectedDocuments[row.original.uuid] !== undefined}
          onChange={(value) =>
            handleCheckbox(value.target.checked, row.original)
          }
        />
      ),
    },
    {
      header: "Nombre",
      accessorKey: "name",
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community?.name,
    },
    {
      header: "Tipo",
      accessorKey: "type",
      cell: ({ row }) => (
        <SuiBadgeTs
          color={typeColorMapping[row.original.type.name] || "error"}
          size="xs"
          badgeContent={row.original.type.name}
        />
      ),
    },
    {
      header: "Etiquetas",
      accessorKey: "tags",
      cell: ({ row }) => (
        <Box>
          {row.original.tags.map((tag, i) => {
            return (
              <SuiBadgeTs
                key={i}
                color="light"
                size="xs"
                badgeContent={tag.name}
              />
            );
          })}
        </Box>
      ),
    },
    {
      header: "Acciones",
      accessorKey: "actions",
      cell: ({ row }) => <DocumentActions document={row.original} />,
    },
  ];
};
