import { useMutation } from "@tanstack/react-query";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import axios from "axios";

const useExportMaintenancePDF = (maintenanceUUID: UUID) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/maintenances/${maintenanceUUID}/export/`;

      return await axios
        .post(url, { type: "pdf" }, { responseType: "arraybuffer" })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", maintenanceUUID + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    },
    onError(error) {
      console.log({ error });
    },
  });

  return { ...mutation, action: mutation.mutate };
};

export default useExportMaintenancePDF;
