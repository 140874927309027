/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getDashboard } from "redux/actions/analytics";
import SuiButton from "components/SuiButton";
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import TaskListHeaderDefault from "./header";
import TaskListTableDefault from "./list";
import { Card, Checkbox } from "@mui/material";
import StandardTooltipContent from "./tooltip";
import SuiBadge from "components/SuiBadge";

function TicketsGantt({ rows }) {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState(ViewMode.Day);
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [showList, setShowList] = useState(true);
  const [showName, setShowName] = useState(true);
  const [showCommunity, setShowCommunity] = useState(true);
  const [showStartDate, setShowStartDate] = useState(true);
  const [showEndDate, setShowEndDate] = useState(true);
  const [showSeen, setShowSeen] = useState(true);
  useEffect(() => {}, []);

  return (
    <Card sx={{ padding: 5, marginBottom: 5 }}>
      <SuiBox style={{ paddingBottom: 10 }}>
        <SuiTypography variant="h6" fontWeight="medium">
          Columnas
          <SuiBadge
            style={{ float: "right" }}
            color="info"
            variant="gradient"
            size="small"
            badgeContent={"BETA"}
          />
        </SuiTypography>
        <Grid container>
          {/*<Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showName} onClick={() => setShowName(!showName)} />
            <SuiTypography variant="button" fontWeight="regular">
              Nombre
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showCommunity} onClick={() => setShowCommunity(!showCommunity)} />
            <SuiTypography variant="button" fontWeight="regular">
              Comunidad
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showStartDate} onClick={() => setShowStartDate(!showStartDate)} />
            <SuiTypography variant="button" fontWeight="regular">
              Desde
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showEndDate} onClick={() => setShowEndDate(!showEndDate)} />
            <SuiTypography variant="button" fontWeight="regular">
              Hasta
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showList} onClick={() => setShowSeen(!showSeen)} />
            <SuiTypography variant="button" fontWeight="regular">
              Ver
            </SuiTypography>
        </Grid>*/}
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showSeen} onClick={() => setShowList(!showList)} />
            <SuiTypography variant="button" fontWeight="regular">
              Ocultar todas
            </SuiTypography>
          </Grid>
        </Grid>
        <br />
        <SuiBadge
          size="small"
          color={viewMode === ViewMode.Day ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Day)}
          badgeContent={"Vista de día"}
        />

        <SuiBadge
          size="small"
          color={viewMode === ViewMode.Week ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Week)}
          badgeContent={"Vista de semana"}
        />
        <SuiBadge
          size="small"
          color={viewMode === ViewMode.Month ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Month)}
          badgeContent={"Vista de mes"}
        />
      </SuiBox>
      {rows.length > 0 && (
        <Gantt
          tasks={rows}
          locale={"es-CL"}
          viewMode={viewMode}
          TaskListHeader={TaskListHeaderDefault}
          TooltipContent={StandardTooltipContent}
          fontSize={14}
          columnFilter={{ showCommunity, showEndDate, showName, showSeen, showStartDate }}
          TaskListTable={TaskListTableDefault}
          listCellWidth={showList ? "155px" : ""}
          columnWidth={viewMode === ViewMode.Day ? 70 : viewMode === ViewMode.Week ? 100 : 150}
          headerHeight={50}
          rowWidth={70}
        />
      )}
    </Card>
  );
}

export default TicketsGantt;
