import { Typography } from "@mui/material";
import { DocumentModel } from "api/services/documents/models";
import { SuiTypographyTs } from "components/SuiTypography";
import { ColumnTable } from "components/SwappiTable/types";
import { useMemo } from "react";
import DocumentActions from "./DocumentActions";
import { TicketModel } from "api/services/tickets/models";
import { useNavigate } from "react-router-dom";

interface Props {
  ticket: TicketModel;
}
const useTicketDocumentsColumns = ({ ticket }: Props) => {
  const navigate = useNavigate();
  const columns = useMemo(() => {
    return [
      { header: "Nombre", accessorKey: "name" },
      {
        header: "Tipo",
        accessorKey: "type",
        cell: ({ row }) => {
          const document = row.original;
          return document.type.name;
        },
      },
      {
        header: "Proveedor",
        accessorKey: "provider",
        cell: ({ row }) => {
          const document = row.original;
          const { service_provider } = document;
          return (
            <SuiTypographyTs
              variant="button"
              fontWeight="regular"
              color="info"
              style={{ cursor: "pointer" }}
              onClick={() =>
                document.service_provider &&
                navigate(`/providers/${document.service_provider.uuid}`, {
                  state: { provider: document.service_provider },
                })
              }
            >
              {service_provider?.executive_name ||
                service_provider?.razon_social ||
                ""}
            </SuiTypographyTs>
          );
        },
      },
      {
        header: "Precio",
        accessorKey: "price",
        cell: ({ row }) => {
          const document = row.original;
          return "$" + document.price.toLocaleString("es-CL");
        },
      },
      {
        header: "Acciones",
        accessorKey: "file",

        cell: ({ row }) => {
          const document = row.original;
          return <DocumentActions document={document} ticket={ticket} />;
        },
      },
    ] as ColumnTable<DocumentModel>[];
  }, []);
  return { columns };
};

export default useTicketDocumentsColumns;
