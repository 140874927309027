import React from "react";
import loadingImage from "assets/images/logo-iso.png";

const LoadingView = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "70vh",
      width: "100%",
    }}
  >
    <img
      src={loadingImage}
      alt="Loading..."
      style={{
        height: "50px",
        animation: "spin 1s linear infinite",
      }}
    />
  </div>
);

const styles = `
  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`;

const applyStyles = () => {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styles;
  document.head.appendChild(styleElement);
};

applyStyles();

export default LoadingView;
