import { SxProps } from '@mui/material';

export const styleDesktop: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: '#f8f9fa',
  width: '80%',
};

export const mobileStyle: SxProps = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  maxHeight: '90vh',
  p: 0,
  width: '100%',
};

export const contentContainer: SxProps = {
  maxHeight: '90vh',
};
