import axios from "axios";
import { API_URL } from "redux/actions/types";
import { MaintenancesPage } from "../models";
import { useQuery } from "@tanstack/react-query";
import { UUID } from "crypto";
import { getAuthUserProfile } from "stores/authentication/helpers";

export const GET_MAINTENANCES = "GetMaintenancesByManagement";

export interface Params {
  page: number;
  page_size: number;
  o?: string;
  community?: string;
  service_provider?: UUID;
}

export const useGetMaintenancesList = (params: Params) => {
  return useQuery<MaintenancesPage>({
    staleTime: 1000 * 60 * 5,
    queryKey: [GET_MAINTENANCES, params],
    queryFn: async () => {
      const { slug } = getAuthUserProfile().management;
      const url = `${API_URL}/management/${slug}/maintenances/`;
      return await axios
        .get<MaintenancesPage>(url, {
          params,
        })
        .then((res) => res.data);
    },
  });
};
