import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";

const useDeleteEmergency = (emergencyUUID: UUID) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/emergency/${emergencyUUID}/delete/`;
      return await axios.delete(url);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Emergencia eliminada",
        message: "La emergencia ha sido eliminada correctamente",
      });
    },
  });
};

export default useDeleteEmergency;
