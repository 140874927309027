import { Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import BaseDialog, { DialogHandle } from "components/ui/BaseDialog";
import React, { useRef } from "react";
import EditVisitForm from "./EditVisitForm";
import { VisitModel } from "api/services/visits/models";
import useEventListener from "lib/events/useEventListener";

interface Props {
  visit: VisitModel;
}

const EditVisitButton: React.FC<Props> = ({ visit }) => {
  const dialogRef = useRef<DialogHandle>();
  const handleOpen = () => {
    dialogRef.current.open();
  };

  useEventListener("visitUpdated", () => {
    dialogRef.current.close();
  });
  return (
    <Box>
      <Tooltip title={"Editar datos de la visita"} placement="top">
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      </Tooltip>
      <BaseDialog fullWidth ref={dialogRef}>
        <EditVisitForm visit={visit} />
      </BaseDialog>
    </Box>
  );
};

export default EditVisitButton;
