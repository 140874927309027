import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { PollModel } from 'api/services/polls/models';
import { TicketModel } from 'api/services/tickets/models';
import React, { FC, useState } from 'react';
import EditPollForm from './EditPollForm';
import { parseDate } from 'utils';
import { Edit } from '@mui/icons-material';
import { usePermissions } from 'stores/authentication/helpers';
interface Props {
  ticket: TicketModel;
  poll: PollModel;
}

const PollPeriod: FC<Props> = ({ ticket, poll }) => {
  const { canEditTask } = usePermissions();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnOpen = () => {
    setIsOpen(true);
  };
  const handleOnClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Box>
        <Typography variant="h6" textTransform="none" fontWeight={'light'}>
          <b>Periodo:</b> {parseDate(poll.start_date)} - {parseDate(poll.end_date)}{' '}
          {canEditTask && (
            <IconButton onClick={handleOnOpen}>
              <Edit />
            </IconButton>
          )}
        </Typography>
      </Box>

      <Dialog open={isOpen} onClose={handleOnClose}>
        <Box p={3} width={300}>
          <EditPollForm ticket={ticket} poll={poll} onClose={handleOnClose} />
        </Box>
      </Dialog>
    </>
  );
};

export default PollPeriod;
