import { Box } from "@mui/material";
import { DocumentModel, TagModel } from "api/services/documents/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { ColumnTable } from "components/SwappiTable/types";
import React, { useMemo } from "react";
import TicketModal from "components/Tickets/TicketDetailModal";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import TableActions from "./TableActions";
import { GoLinkExternal } from "react-icons/go";

const colorsMap = {
  Cotización: "info",
  Factura: "warning",
  Cheque: "success",
  Otro: "dark",
  Contrato: "primary",
  "Comprobante de pago": "dark",
};
const TagsList: React.FC<{ tags: TagModel[] }> = ({ tags }) => {
  return (
    <Box display={"flex"} gap={2}>
      {tags.map((tag) => {
        return (
          <SuiBadgeTs
            color="light"
            size="xs"
            key={tag.name}
            badgeContent={tag.name}
          />
        );
      })}
    </Box>
  );
};

const DocumentOrigin: React.FC<{ document: DocumentModel }> = ({
  document,
}) => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} gap={1}>
      {document.report && (
        <SuiBadgeTs
          container
          badgeContent={<AiOutlineEye size="16px" />}
          size="xs"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("/visits/report/" + document.report, "_blank");
          }}
          color={"info"}
        />
      )}
      {document.ticket && (
        <TicketModal uuid={document.ticket} community={document.community} />
      )}
      {document.maintenance && (
        <SuiBadgeTs
          onClick={() => navigate(`/maintenances/${document.maintenance}`)}
          container
          badgeContent={<GoLinkExternal size={16} />}
          size="xs"
          style={{ cursor: "pointer" }}
          color={"info"}
        />
      )}
    </Box>
  );
};

export const createColumnsHelper = () => {
  return useMemo(() => {
    const columns: ColumnTable<DocumentModel>[] = [
      {
        header: "Nombre",
        accessorKey: "name",
        width: "35%",
      },
      {
        header: "Comunidad",
        accessorKey: "community",
        width: "20%",
        cell: ({ row }) => row.original.community?.name,
      },
      {
        header: "Tipo",
        accessorKey: "type",
        // width: "20%",
        cell: ({ row }) => (
          <SuiBadgeTs
            size="xs"
            color={colorsMap[row.original.type.name] || "error"}
            badgeContent={row.original.type.name}
          />
        ),
      },
      {
        header: "Etiquetas",
        accessorKey: "tags",
        cell: ({ row }) => <TagsList tags={row.original.tags} />,
      },
      {
        header: "Origen",
        accessorKey: "father",
        cell: ({ row }) => <DocumentOrigin document={row.original} />,
      },
      {
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => <TableActions document={row.original} />,
      },
    ];
    return columns;
  }, []);
};
