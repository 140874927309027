import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";

import EditMaintenanceInfoDialog from "components/pages/maintenances/EditMaitenanceInfoDialog";
import { usePermissions } from "stores/authentication/helpers";

interface Props {
  maintenance: MaintenanceModel;
}

const GeneralTab: FC<Props> = ({ maintenance }) => {
  const canEditMaintenance = usePermissions().maintenances.edit;
  return (
    <section>
      {canEditMaintenance && (
        <EditMaintenanceInfoDialog maintenance={maintenance} />
      )}
      <Box component="form" p={3} ml={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Grid item xs={12}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Fecha de inicio:
              </Typography>

              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.start_date}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Fecha de expiración:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.end_date}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Periodicidad:
              </Typography>

              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.period}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={12} md={6}>
            <Grid item xs={12} md={6}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Nombre:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.service_provider &&
                  maintenance.service_provider.executive_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Razón Social:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.service_provider &&
                  maintenance.service_provider.razon_social}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Email:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.service_provider &&
                  maintenance.service_provider.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Rut:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.service_provider &&
                  maintenance.service_provider.rut}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="label" variant="caption" fontWeight="bold">
                Teléfono:
              </Typography>
              <Typography variant="button" fontWeight="regular" color="text">
                {maintenance.service_provider &&
                  maintenance.service_provider.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default GeneralTab;
