/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from 'react';

// @mui material components

// Soft UI Dashboard PRO React components
import { SuiTypographyTs } from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Soft UI Dashboard PRO React icons

// Soft UI Dashboard PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';
import { SuiBadgeTs } from 'components/SuiBadge';
import { getPermission } from 'utils';

import { useAuthenticationStore } from 'stores/authentication';
import { Box } from '@mui/material';

// Images

const Header = () => {
  const hasAccess = useMemo(() => getPermission(['comite', 'mayordomo', 'conserje']), []);
  const profile = useAuthenticationStore((state) => state.userProfile);
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <Box position="relative">
      <DashboardNavbar light />
      {hasAccess && (
        <Box mt={3} mb={1} display={'flex'} justifyContent={'center'}>
          <SuiBadgeTs
            color="info"
            variant="gradient"
            badgeContent={
              <SuiTypographyTs
                fontWeight="bold"
                textTransform="capitalize"
                variant="h5"
                color={'white'}
                noWrap
              >
                {profile?.communities[0]?.name}
              </SuiTypographyTs>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default Header;
