/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
// Soft UI Dashboard PRO React components
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import SuiInput from 'components/SuiInput';
import SuiButton from 'components/SuiButton';
// Soft UI Dashboard PRO React example components
import { addDocumentToTicket } from 'redux/actions/tickets';
import { useDispatch, useSelector } from 'react-redux';
import { getProvidersByManagement } from 'redux/actions/providers';
import { useParams } from 'react-router-dom';
import SuiSelect from 'components/SuiSelect';
import SuiTypography from 'components/SuiTypography';
import SuiDatePicker from 'components/SuiDatePicker';
import { createMaintenanceVisit } from 'redux/actions/maintenance';
import { getUsersByManagement } from 'redux/actions/users';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { prototype } from 'dropzone';
import { getAuthUserProfile } from 'stores/authentication/helpers';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';

function obtenerPrimerUltimoDiaMes(mes, año) {
  var primerDia = new Date(año, mes - 1, 1); // Establecer el día en 1
  var ultimoDia = new Date(año, mes, 0); // Establecer el día en 0 para retroceder un día

  return {
    primerDia: primerDia, // Obtener el día del primer día del mes
    ultimoDia: ultimoDia, // Obtener el día del último día del mes
  };
}

function CreateVisit(props) {
  const dispatch = useDispatch();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const providersResponse = useSelector((state) => state.providers.getProvidersByManagement);
  const usersResponse = useSelector((state) => state.users.getUsersByManagement);
  const [communities, setcommunities] = useState([]);
  const communitiesResponse = useGetCommunities();
  const [community, setcommunity] = useState(props.community ? props.community : null);
  const [users, setusers] = useState([]);
  const [selectedUser, setselectedUser] = useState(null);
  const [providers, setproviders] = useState([]);
  const [file, setFile] = useState(null);
  const [proveedor, setproveedor] = useState(null);
  const [name, setname] = useState('');
  const [start, setstart] = useState();
  const [end, setend] = useState();
  const { slug } = useParams();

  useEffect(() => {
    let profile = getAuthUserProfile();
    dispatch(getProvidersByManagement(profile.management.slug));
    dispatch(getUsersByManagement(profile.management.slug));
  }, []);

  const handleSubmission = () => {
    let form = {
      title: name,
      start: start,
      end: end,
      service_provider: proveedor,
      user: selectedUser,
      community: slug ? slug : community,
    };
    if (props.planificationItem) {
      form['planification_item'] = props.planificationItem.uuid;
    }
    if (props.maintenance) {
      form['maintenance'] = props.maintenance.uuid;
    }
    dispatch(createMaintenanceVisit(form));
    props.close();
  };

  useEffect(() => {
    if (usersResponse.data !== undefined) {
      let options = usersResponse.data.map((user) => {
        return { label: user.first_name, value: user.email };
      });
      setusers(options);
    }
  }, [usersResponse]);

  useEffect(() => {
    if (providersResponse.data !== undefined) {
      let options = providersResponse.data.map((provider) => {
        return { label: provider.executive_name || provider.razon_social, value: provider.uuid };
      });
      setproviders(options);
    }
  }, [usersResponse]);

  useEffect(() => {
    if (communitiesResponse.data !== undefined) {
      let options = communitiesResponse.data.map((community) => {
        return { label: community.name, value: community.slug };
      });
      setcommunities(options);
    }
  }, [communitiesResponse]);

  const renderInputs = () => {
    return (
      <SuiBox pt={2}>
        <SuiTypography component="h6">Agendar Visita</SuiTypography>
        <Grid container width="100%">
          <Grid item xs={12} sm={6} p={1}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Nombre
            </SuiTypography>
            <SuiInput size="small" onChange={(e) => setname(e.target.value)} value={name} />
          </Grid>
          {!slug && !props.anualPlanification ? (
            <Grid item xs={12} sm={6} p={1}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Comunidad
              </SuiTypography>
              <SuiSelect
                size="small"
                onChange={(e) => setcommunity(e.value)}
                placeholder="Comunidad"
                options={communities}
                value={community}
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} sm={6} p={1}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Fecha inicio
            </SuiTypography>
            <SuiDatePicker
              options={
                props.anualPlanification && {
                  minDate: obtenerPrimerUltimoDiaMes(props.month, props.year).primerDia,
                  maxDate: obtenerPrimerUltimoDiaMes(props.month, props.year).ultimoDia,
                }
              }
              onChange={(e) => setstart(e[0].toISOString().split('T')[0])}
            />
          </Grid>
          <Grid item xs={12} sm={6} p={1}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Fecha expiración
            </SuiTypography>
            <SuiDatePicker
              options={
                props.anualPlanification && {
                  minDate: obtenerPrimerUltimoDiaMes(props.month, props.year).primerDia,
                  maxDate: obtenerPrimerUltimoDiaMes(props.month, props.year).ultimoDia,
                }
              }
              onChange={(e) => setend(e[0].toISOString().split('T')[0])}
            />
          </Grid>
          <Grid xs={12} sm={6} item p={1}>
            {' '}
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Proveedor
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              onChange={(e) => setproveedor(e.value)}
              placeholder="Proveedor"
              value={proveedor}
              options={providers}
            />
          </Grid>
          {/* <Grid xs={12} md={4} item p={1}>
            {" "}
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Miembro del equipo
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              onChange={(e) => setselectedUser(e.value)}
              placeholder="Equipo"
              options={users}
            />
          </Grid> */}
          <Grid xs={12} sm={6} item mt={5}>
            <SuiButton size="small" variant="gradient" onClick={handleSubmission} color="success">
              Guardar
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
    );
  };
  return (
    <SuiBox minHeight={20}>
      <Card style={{ marginBottom: 20, overflow: 'visible', width: '100%' }}>
        <Grid container>
          <SuiBox p={2} pt={0} width="100%">
            {renderInputs()}
          </SuiBox>
        </Grid>
      </Card>
    </SuiBox>
  );
}

export default CreateVisit;
