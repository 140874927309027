import React, { FC, JSX } from 'react';
import { Box, CircularProgress, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import useMediaScreen from 'utils/Hooks/useMedia';
import { ExportIcons, QontoConnector } from './Connectors';
import { SetState } from 'types/common';

export interface ExportFileStepperProps {
  steps: { label: string; component: JSX.Element; icon: JSX.Element }[];
  activeStep: number;
  setActiveStep: SetState<number>;
}

const centerdedProps = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const ExportFileStepper: FC<ExportFileStepperProps> = ({ steps, activeStep, setActiveStep }) => {
  const { isMobile } = useMediaScreen();
  const percent: number = ((activeStep + 1) / steps.length) * 100;

  if (isMobile)
    return (
      <Grid container sx={{ pl: 2, mb: 2 }}>
        <Grid item xs>
          <Box>
            <Typography variant="h4" fontWeight={'bold'}>
              {steps[activeStep].label}
            </Typography>
            <Typography variant="h6" fontWeight={'light'}>
              <b>{activeStep + 1 !== steps.length && 'Siguiente:'}</b>{' '}
              {steps[activeStep + 1]?.label}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex' }} justifyContent={'end'}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              height: 75,
              width: 75,
            }}
          >
            <CircularProgress
              variant="determinate"
              size={75}
              value={100}
              sx={{ ...centerdedProps, color: '#eaeaf0' }}
            />
            <CircularProgress
              variant="determinate"
              size={75}
              value={percent}
              sx={{ ...centerdedProps, color: '#2164ff' }}
            />
            <Box sx={{ ...centerdedProps }}>
              <Typography variant="h6" fontWeight="medium" component="div" color="text.secondary">
                {activeStep + 1} de {steps.length}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );

  return (
    <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
      {steps.map(({ label, icon }, index) => {
        const color = index <= activeStep ? '#2164ff' : '#A3A3A0';

        return (
          <Step key={label} className="item-pointer" onClick={() => setActiveStep(index)}>
            <StepLabel StepIconComponent={(props) => <ExportIcons {...props} labelIcon={icon} />}>
              <span style={{ color }}>{label}</span>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default ExportFileStepper;
