import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import BaseDialog, { DialogHandle } from "components/ui/BaseDialog";
import useEventListener from "lib/events/useEventListener";
import { SuiButtonTs } from "components/SuiButton";
import CompleteVisitForm from "./CompleteVisitForm";

const CompleteVisitButton: React.FC<{
  visit: VisitModel;
}> = ({ visit }) => {
  const completeDialogRef = useRef<DialogHandle>(null);

  const triggerCompleteDialog = () => completeDialogRef.current?.open();

  useEventListener("visitCompleted", () => {
    completeDialogRef.current?.close();
  });
  return (
    <>
      <BaseDialog ref={completeDialogRef}>
        <Box sx={{ minWidth: 500 }}>
          <Typography variant="h4" mb={2}>
            Confirmar visita
          </Typography>
          <CompleteVisitForm visit={visit} />
        </Box>
      </BaseDialog>

      <SuiButtonTs
        size="small"
        color="success"
        variant="gradient"
        fullWidth
        onClick={triggerCompleteDialog}
      >
        Marcar como visitado
      </SuiButtonTs>
    </>
  );
};

export default CompleteVisitButton;
