import PageLayout from 'examples/LayoutContainers/PageLayout';
import './style.css'
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <PageLayout>
    <div style={{padding: '30px'}}>
      <h1>Política de Privacidad de Swappi</h1>

      <h2>1. Introducción</h2>
      <p>
        Swappi se compromete a proteger su privacidad. 
        Esta Política de Privacidad explica cómo recolectamos, usamos, divulgamos y protegemos su 
        información cuando utiliza nuestra aplicación móvil Swappi.
      </p>

      <h2>2. Información que Recolectamos</h2>
      <p>Recolectamos la siguiente información a través de formularios en la aplicación:</p>
      <ul>
        <li>Nombre</li>
        <li>Dirección de correo electrónico</li>
        <li>Número de teléfono</li>
        <li>Dirección</li>
      </ul>

      <h2>3. Uso de la Información</h2>
      <p>Usamos la información recolectada para:</p>
      <ul>
        <li>Proveer y mejorar nuestros servicios.</li>
        <li>Mostrarle al usuario información relevante sobre las personas en su comunidad.</li>
      </ul>

      <h2>4. Compartición de la Información</h2>
      <p>No compartimos su información personal con terceros.</p>

      <h2>5. Almacenamiento y Seguridad de los Datos</h2>
      <p>Almacenamos los datos en la nube y tomamos las siguientes medidas para protegerlos:</p>
      <ul>
        <li>Encriptación punto a punto</li>
        <li>Control de acceso</li>
      </ul>

      <h2>6. Retención de Datos</h2>
      <p>
        Retenemos sus datos personales hasta que solicite su eliminación. 
        Puede solicitar la eliminación de sus datos en cualquier momento a través de la aplicación 
        o enviando un correo a <a href="mailto:ian@swappi.cl">ian@swappi.cl</a>.
      </p>

      <h2>7. Derechos del Usuario</h2>
      <p>Usted tiene derecho a:</p>
      <ul>
        <li>Acceder a sus datos personales.</li>
        <li>Rectificar datos inexactos o incompletos.</li>
        <li>Solicitar la eliminación de sus datos personales.</li>
      </ul>
      <p>
        Para ejercer estos derechos, puede contactarnos a través de la aplicación 
        o enviando un correo a <a href="mailto:ian@swappi.cl">ian@swappi.cl</a>.
      </p>

      <h2>8. Cookies y Tecnologías Similares</h2>
      <p>Usamos cookies para el almacenamiento de la sesión del usuario en la aplicación.</p>

      <h2>9. Cambios en la Política de Privacidad</h2>
      <p>
        Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. 
        Le notificaremos de cualquier cambio mediante correo electrónico.
      </p>

      <h2>10. Contacto</h2>
      <p>
        Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad, 
        puede contactarnos a través de <a href="mailto:ian@swappi.cl">ian@swappi.cl</a>.
      </p>

      <h2>11. Menores de Edad</h2>
      <p>
        Nuestra aplicación no está diseñada para menores de edad. 
        No recolectamos deliberadamente información de menores de 18 años.
      </p>
    </div>
    </PageLayout>
  );
}

export default PrivacyPolicy;
