import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { UUID } from 'crypto';
import { API_URL } from 'redux/actions/types';
import { CommentaryModel } from '../models';
import { TicketQueryHelper } from '../queries/GetTicket';

export interface AddTicketCommentParams {
  ticketUUID: UUID;
}
export interface AddTicketCommentInput {
  text: string;
}
const useAddTicketCommentary = (params: AddTicketCommentParams) => {
  const { ticketUUID } = params;
  return useMutation({
    mutationFn: async (input: AddTicketCommentInput) => {
      const url = `${API_URL}/tickets/comment/add/ticket/${ticketUUID}`;
      return await axios.post<CommentaryModel>(url, input).then((res) => res.data);
    },
    onSuccess(data) {
      TicketQueryHelper.addCommentary(data, params.ticketUUID);
    },
  });
};

export default useAddTicketCommentary;
