import React, { FC } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import CommunityCard from './CommunityCard';
import TicketTitleCard from './TitleCard';
import Timeline from '../Timeline';
import DescriptionCard from './DescriptionCard';
import TicketDocumentsImagesCard from './ImagesSlider';
import ActivitiesChecklist from './ActivitiesChecklist';
import TicketCommentaries from 'components/Tickets/TicketView/TicketDetail/TicketCommentsSection';
import TicketDocumentsTable from './DocumentsTable';
import DocumentsPricing from './DocumentsPricing';
import ActivePolls from './ActivePolls';
import CreatePollFormDrawer from 'components/Polls/CreatePollFormDrawer';
import DocumentEditorDrawner from 'components/EditFileDrawer/new';
import { GroupRole } from 'routes/types';
import { useAuthenticationStore } from 'stores/authentication';
import VideoCarousel from 'components/VideoCarrousel';

interface Props {
  ticket: TicketModel;
}

const TicketDetail: FC<Props> = ({ ticket }) => {
  const user = useAuthenticationStore((state) => state.userProfile);
  const hasImages = ticket.documents.some(({ type }) => type.slug === 'imagen');
  const hasActivePolls = ticket.polls.length > 0;
  const hasChecklists = ticket.checklists.length > 0;
  const hasDocumentPricing = ticket.documents.some(({ type }) => type.slug === 'cotizacion');
  const hasVideos = ticket.documents.some(({ type }) => type.slug === 'video');
  const showCommentaries = !(
    ticket.commentaries_visible === false &&
    user.groups.some((item) => item.name === GroupRole.Comite)
  );
  return (
    <Box mb={2}>
      <CreatePollFormDrawer />
      <DocumentEditorDrawner />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Stack spacing={2}>
            <CommunityCard ticket={ticket} />
            <Timeline ticket={ticket} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack spacing={2}>
            <TicketTitleCard ticket={ticket} />
            <DescriptionCard ticket={ticket} />
            {hasImages && <TicketDocumentsImagesCard ticket={ticket} />}
            {hasVideos && <VideoCarousel videoUrls={ticket.documents.map((document) => document.type.slug === 'video' && document)} />}
            {hasChecklists && <ActivitiesChecklist ticket={ticket} />}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {hasDocumentPricing && <DocumentsPricing ticket={ticket} />}
            {hasActivePolls && <ActivePolls ticket={ticket} />}
            <TicketDocumentsTable ticket={ticket} />
            {showCommentaries && <TicketCommentaries ticket={ticket} />}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketDetail;
