import apiClient from "api/config/axios";
import { types, API_URL } from "./types";
import Axios from "axios";
import { GET_PROFILE_KEY } from "api/services/user/queries/getUserImage";
import { queryClient } from "api/config/queryClient";


export const getUsers = (d) =>
{
    const url = `${API_URL}/users/user/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url, { params: d }).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetUsers(outputData));

        })
    }
}

export const setGetUsers = (data) => ({
    type: types.setGetUsers,
    payload: data,
});

export const createUser = (d) =>
{
    const url = `${API_URL}/users/`;

    return (dispatch) =>
    {
        let outputData = {};

        Axios.post(url, d).then((res) =>
        {
            if (res.data.error)
            {
                let notification = { status: "error", message: res.data.message, title: "Usuario no creado" };
                dispatch(setNotification(notification));
            } else
            {
                let notification = { status: "success", message: "El usuario " + d.email + " ha sido creado exitosamente!", title: "Usuario creado" };
                outputData["data"] = res.data;
                outputData["message"] = res.message;
                outputData["status"] = res.status;
                dispatch(setCreateUser(outputData));
                dispatch(setNotification(notification));
            }
        }).catch(err =>
        {
            if (err.status == 500)
            {
                let notification = { status: "error", message: "Se produjo un error interno, comunicate con el soporte!", title: "Usuario no creado" };
                dispatch(setNotification(notification));
            }
            let data = err.data;
            //maap data by keys
            if (data)
            {
                let keys = Object.keys(data);
                let message = "";
                keys.map((key) =>
                {
                    message += key + ": " + data[key];
                })
                let notification = { status: "error", message: message, title: "Usuario no creado" };
                dispatch(setNotification(notification));
            }
        })
    };
};

export const setCreateUser = (data) => ({
    type: types.setCreateUser,
    payload: data,
});


export const updateUser = (d) =>
{
    const url = `${API_URL}/users/user/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setUpdateUser(outputData));
        }).catch(err =>
        {
            outputData["data"] = err.response.data;
            dispatch(setUpdateUser(outputData));
        })
    }
}

export const setUpdateUser = (data) => ({
    type: types.setUpdateUser,
    payload: data,
})


export const deleteUser = (d) =>
{
    const url = `${API_URL}/users/user/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.delete(url, { params: d }).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setDeleteUser(outputData));
        }).catch(err =>
        {
            outputData["data"] = err.response.data;
            dispatch(setDeleteUser(outputData));
        })
    }
}

export const setDeleteUser = (data) => ({
    type: types.setDeleteUser,
    payload: data,
})


export const setNotification = (data) => ({
    type: types.setNotification,
    payload: data,
});


export const getUserProfile = () =>
{
    return (dispatch) =>
    {
        const url = `${API_URL}/users/profile/`;
        let outputData = {};
        return Axios.get(url)
            .then(res =>
            {
                outputData["data"] = res.data;
                outputData["status"] = res.status;
                outputData["message"] = res.message;
                dispatch(serGetUserProfile(outputData));
            })
    }
}


export const serGetUserProfile = (data) => ({
    type: types.setGetUserProfile,
    payload: data,
});

export const getUsersByManagement = (d) =>
{
    const url = `${API_URL}/users/manager/${d}/staff`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then(({ data }) =>
        {
            outputData["data"] = data;
            dispatch(setGetUsersByManagement(outputData));
        })
    }
}

export const setGetUsersByManagement = (data) => ({
    type: types.setGetUsersByManagement,
    payload: data,
});


export const addCommunityToSupervisor = (d) =>
{
    const url = `${API_URL}/users/supervisor/communities`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then((res) =>
        {
            let notification = { status: "success", message: "Las comunidades fueron asignadas correctamente", title: "Comunidades asignadas" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setAddCommunityToSupervisor(outputData));
        })
    }
}

export const setAddCommunityToSupervisor = (data) => ({
    type: types.setAddCommunityToSupervisor,
    payload: data,
})

export const updateUserProfile = (d) =>
{
    const url = `${API_URL}/users/edit-profile/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El usuario " + d.email + " ha sido actualizado exitosamente!", title: "Usuario actualizado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setUpdateUserProfile(outputData));
            queryClient.invalidateQueries(GET_PROFILE_KEY, d.uuid);
        })
    }
}

export const setUpdateUserProfile = (data) => ({
    type: types.setUpdateUserProfile,
    payload: data,
})

export const configNotificactions = (d) => 
{
    const url = `${API_URL}/users/config-notifications/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then((res) =>
        {
            let notification = { status: "success", message: "Las notificaciones han sido configuradas correctamente", title: "Notificaciones configuradas" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setConfigNotificactions(outputData));
        })
    }
}

export const setConfigNotificactions = (data) => ({
    type: types.setConfigNotificactions,
    payload: data,
})


export const deactiveUser = (d) => 
{
    const url = `${API_URL}/users/deactivate/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El usuario " + d.email + " ha sido desactivado exitosamente!", title: "Usuario desactivado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setDeactiveUser(outputData));
        })
    }
}

export const setDeactiveUser = (data) => ({
    type: types.setDeactiveUser,
    payload: data,
})


export const updateUserInAdmin = (d) =>
{
    const url = `${API_URL}/users/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El usuario " + d.email + " ha sido actualizado exitosamente!", title: "Usuario actualizado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setUpdateUserWithAdmin(outputData));
        }).
            catch(() =>
            {
                let notification = { status: "error", message: "El usuario " + d.email + " no ha sido actualizado, ingreso campos no validos", title: "Usuario no actualizado" };
                dispatch(setNotification(notification));
            }
            )
    }
}

export const setUpdateUserWithAdmin = (data) => ({
    type: types.setUpdateUserWithAdmin,
    payload: data,
})


export const getTicketCrewUsers = (d) =>
{
    const url = `${API_URL}/management/staff/${d}/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetTicketCrewUsers(outputData));
        })
    }
}

export const setGetTicketCrewUsers = (data) => ({
    type: types.setGetTicketCrewUsers,
    payload: data,
})


export const updateAdminLogo = (slug, d) =>
{
    const url = `${API_URL}/users/management/${slug}/edit-images/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El logo ha sido actualizado correctamente", title: "Logo actualizado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setNotification(notification));
            dispatch(setUpdateAdminLogo(outputData));
        })
    }
}

export const setUpdateAdminLogo = (data) => ({
    type: types.setUpdateAdminLogo,
    payload: data,
})

export const getTicketUsers = (d) =>
{
    const url = `${API_URL}/tickets/${d}/staff/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetTicketUsers(outputData));
        })
    }
}

export const setGetTicketUsers = (data) => ({
    type: types.setGetTicketUsers,
    payload: data,
})


export const getComiteByCommunitySlug = (d) =>
{
    const url = `${API_URL}/users/community/${d}/board/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetComiteByCommunitySlug(outputData));
        })
    }
}

export const setGetComiteByCommunitySlug = (data) => ({
    type: types.setGetComiteByCommunitySlug,
    payload: data,
})


export const getUsersCommunities = (d) =>
{
    const url = `${API_URL}/users/assigned-communities/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetUsersCommunities(outputData));
        })
    }
}

export const setGetUsersCommunities = (data) => ({
    type: types.setGetUsersCommunities,
    payload: data,
})


export const weeklyReportConfig = (d) =>
// /users/weekly-report/config/
{
    const url = `${API_URL}/users/weekly-report/config/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then((res) =>
        {
            let notification = { status: "success", message: "La configuracion ha sido actualizada correctamente", title: "Configuracion actualizada" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            outputData["notification"] = notification;
            dispatch(setWeeklyReportConfig(outputData));
            dispatch(setNotification(notification));
        })
    }
}

export const setWeeklyReportConfig = (data) => ({
    type: types.setWeeklyReportConfig,
    payload: data,
})


export const getFAQ = (d) =>
{
    const url = `${API_URL}/users/faq/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url, { params: d }).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetFAQ(outputData));
        })
    }
}

export const setGetFAQ = (data) => ({
    type: types.setGetFAQ,
    payload: data,
})


export const getDTEs = (d) =>
{
    const url = `${API_URL}/management/get-dtes/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url, { params: d }).then((res) =>
        {
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            dispatch(setGetDTEs(outputData));
        })
    }
}

export const setGetDTEs = (data) => ({
    type: types.setGetDTEs,
    payload: data,
})



export const createReminder = (d) =>
{
    const url = `${API_URL}/users/create-reminder/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.post(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El recordatorio ha sido creado correctamente", title: "Recordatorio creado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            outputData["notification"] = notification;
            dispatch(setCreateReminder(outputData));
            dispatch(setNotification(notification));
        })
    }
}

export const setCreateReminder = (data) => ({
    type: types.setCreateReminder,
    payload: data,
})


export const getReminders = (d) =>
{
    const url = `${API_URL}/users/get-reminders/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.get(url, { params: d }).then((res) =>
        {
            outputData["data"] = res.data;
            dispatch(setGetReminders(outputData));
        })
    }
}


export const setGetReminders = (data) => ({
    type: types.setGetReminders,
    payload: data,
})


export const updateReminder = (d, uuid) =>
{
    const url = `${API_URL}/users/update-reminder/${uuid}/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.put(url, d).then((res) =>
        {
            let notification = { status: "success", message: "El recordatorio ha sido actualizado correctamente", title: "Recordatorio actualizado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            outputData["notification"] = notification;
            dispatch(setUpdateReminder(outputData));
            dispatch(setNotification(notification));
        })
    }
}

export const setUpdateReminder = (data) => ({
    type: types.setUpdateReminder,
    payload: data,
})


export const deleteReminder = (uuid) =>
{
    const url = `${API_URL}/users/delete-reminder/${uuid}/`;
    return (dispatch) =>
    {
        let outputData = {};
        Axios.delete(url).then((res) =>
        {
            let notification = { status: "success", message: "El recordatorio ha sido eliminado correctamente", title: "Recordatorio eliminado" };
            outputData["data"] = res.data;
            outputData["message"] = res.message;
            outputData["status"] = res.status;
            outputData["notification"] = notification;
            dispatch(setDeleteReminder(outputData));
            dispatch(setNotification(notification));
        })
    }
}

export const setDeleteReminder = (data) => ({
    type: types.setDeleteReminder,
    payload: data,
})