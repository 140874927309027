// creat  react  component with hooks

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getActivity } from 'redux/actions/activity';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Header from 'layouts/pages/profile/components/Header';
import { useSelector } from 'react-redux';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { Card, Divider, Grid } from '@mui/material';
import { ActivityRow } from './ActivityRow';
import SuiButton from 'components/SuiButton';
import { markAsRead } from 'redux/actions/activity';
import { markAllAsRead } from 'redux/actions/activity';
import { getReadActivity } from 'redux/actions/activity';
import { ReadActivity } from './ReadActivity';
import { UnReadActivity } from './UnReadActivity';
import { getCommunitiesByUser } from 'redux/actions/communities';
import SuiSelect from 'components/SuiSelect';
import { getUsersByManagement } from 'redux/actions/users';
import LoadingView from 'components/Loading';
import { getAuthUserProfile } from 'stores/authentication/helpers';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
export const Activity = () => {
  const dispatch = useDispatch();
  const communitiesResponse = useGetCommunities();
  const usersManagementResponse = useSelector((state) => state.users.getUsersByManagement);
  const [unreadLoading, setUnreadLoading] = useState(true);
  const [readedLoading, setReadedLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [member, setMember] = useState(null);

  useEffect(() => {
    let profile = getAuthUserProfile();
    dispatch(getUsersByManagement(profile.management.slug));
  }, []);

  useEffect(() => {
    if (usersManagementResponse?.data) {
      setMembers(usersManagementResponse.data);
    }
  }, [usersManagementResponse]);

  return (
    <>
      <SuiTypography variant="h3" fontWeight="medium" textAlign="center" pt={2}>
        Historial de Actividad
      </SuiTypography>
      <Card sx={{ padding: 2, marginBottom: 2, overflow: 'visible' }}>
        <SuiTypography variant="h5" fontWeight="medium" style={{ marginBottom: 3 }}>
          Filtros
        </SuiTypography>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ overflow: 'visible' }}>
            <SuiTypography variant="h6" fontWeight="medium" ml={2}>
              Comunidad
            </SuiTypography>
            <SuiBox pl={2}>
              <SuiSelect
                size="small"
                value={communities}
                placeholder="Comunidad"
                style={{ zIndex: 1000, width: '100%' }}
                isMulti={true}
                options={
                  communitiesResponse.data &&
                  communitiesResponse.data.length > 0 &&
                  communitiesResponse.data.map((community) => {
                    return { value: community.slug, label: community.name };
                  })
                }
                onChange={(e) => {
                  setCommunities(e);
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <SuiTypography variant="h6" fontWeight="medium">
              Colaborador
            </SuiTypography>
            <SuiSelect
              size="small"
              value={member}
              placeholder="Colaborador"
              style={{ zIndex: 1000, width: '100%' }}
              options={[
                { value: '', label: 'Todos' },
                ...members.map((member) => {
                  return { value: member.email, label: member.first_name };
                }),
              ]}
              onChange={(e) => setMember(e.value)}
            />
          </Grid>
        </Grid>
      </Card>

      {unreadLoading && readedLoading && <LoadingView />}
      <UnReadActivity
        communities={communities}
        member={member}
        setLoading={setUnreadLoading}
        loading={unreadLoading}
      />
      <ReadActivity
        communities={communities}
        member={member}
        setLoading={setReadedLoading}
        loading={readedLoading}
      />
    </>
  );
};
