import React, { useCallback, useEffect, useState } from 'react';
import logoiso from 'assets/images/logo-iso.png';
import logo from 'assets/images/logo-blue.png';
import Sidenav from 'examples/Sidenav';
import { useSoftUIController, setMiniSidenav } from 'context';
import routes from 'routes';
import { create } from 'zustand';
import useMediaScreen from 'utils/Hooks/useMedia';
interface StoreState {
  isOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}
export const useSidebarStore = create<StoreState>((set) => ({
  isOpen: false,
  openSidebar: () => set({ isOpen: true }),
  closeSidebar: () => set({ isOpen: false }),
  toggleSidebar: () => set((state) => ({ isOpen: !state.isOpen })),
}));

const AppSidebar = () => {
  const { isMobile } = useMediaScreen();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { isOpen, closeSidebar } = useSidebarStore();
  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  }, [miniSidenav, onMouseEnter]);

  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  }, [onMouseEnter]);

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  return (
    <Sidenav
      color={sidenavColor}
      brand={logo}
      brandIso={logoiso}
      brandName=""
      routes={routes}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      open={isOpen}
      onClose={closeSidebar}
      variant={isMobile ? 'temporary' : 'permanent'}
    />
  );
};

export default AppSidebar;
