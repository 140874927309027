import { useParams } from 'react-router-dom';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'examples/Footer';
import { useGetTicket } from 'api/services/tickets/queries/GetTicket';
import { UUID } from 'crypto';
import LoadingView from 'components/Loading';
import TicketDetailPublic from 'components/Tickets/TicketView/TicketDetail/public';

function PublicTicket() {
  const { uuid: ticketUUID } = useParams();
  const { data: ticket, isLoading } = useGetTicket({ uuid: ticketUUID as UUID });
  if (isLoading) return <LoadingView />;
  if (!ticket) return 'no encontrado';

  return (
    <PageLayout>
      <TicketDetailPublic ticket={ticket} />
      <Footer />
    </PageLayout>
  );
}

export default PublicTicket;
