import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { UUID } from 'crypto';
import { API_URL } from 'redux/actions/types';
import { useAuthenticationStore } from 'stores/authentication';

interface Params {
  ticketUUID: UUID;
  downloadName?: string;
}
export const useGetTicketPDF = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${params.ticketUUID}/export/`;
      const managementSlug = useAuthenticationStore.getState().userProfile.management.slug;
      return await axios
        .post(
          url,
          {
            management: managementSlug,
            type: 'pdf',
          },
          { responseType: 'arraybuffer' }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', params.downloadName || 'file' + '.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    },
  });
};



export const useGetComparativeTable = (params: Params) => {
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${params.ticketUUID}/export-comparative-table/`;
      const managementSlug = useAuthenticationStore.getState().userProfile.management.slug;
      return await axios  
        .post(
          url,
          {
            management: managementSlug,
            type: 'pdf',
          },
          { responseType: 'arraybuffer' }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', params.downloadName || params.ticketUUID + '.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    },
  });
};


