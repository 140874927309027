import { Box, Card } from "@mui/material";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import TableFilters, { IFormValues } from "./TableFilters";
import useDebounce from "utils/Hooks/useDebounce";
import { TicketsTableViewDisplay, createColumnsHelper } from "./table.helper";
import SwappiDataTable from "components/SwappiTable";
import {
  GetTicketsListParams,
  useGetTicketsPage,
} from "api/services/tickets/queries/getTicketsPage";
import { parsePriority, parseStatus } from "api/services/tickets/helpers";
import { useTicketModalStore } from "components/Tickets/TicketModal/store";
import { UUID } from "crypto";
import useEventListener from "lib/events/useEventListener";
import { getPermission } from "utils";
import { useAuthenticationStore } from "stores/authentication";

const DEFAULT_TABLE_PARAMS = {
  page: 1,
  page_size: 20,
  search: "",
  communities: null,
  priority__in: "",
  status__in: "",
  o: "none",
  is_inactive: undefined,
  assignees: "",
  responsible: "",
  service_provider: null,
  created_after: "",
  created_before: "",
};

interface PropsTicketTable {
  params?: GetTicketsListParams;
  columnsView?: TicketsTableViewDisplay;
  hideFilters?: boolean;
}

type LoadActions = "loadPrev" | "loadNext";
const TicketsTable: FC<PropsTicketTable> = ({
  params = {},
  hideFilters = false,
  columnsView = TicketsTableViewDisplay.detailed,
}) => {
  const tableColumns = createColumnsHelper(columnsView);
  const [tableParams, setTableParams] =
    useState<GetTicketsListParams>(DEFAULT_TABLE_PARAMS);
  const {
    data: tableData,
    refetch,
    isFetching,
    isSuccess,
  } = useGetTicketsPage({ ...tableParams, ...params });

  const selectedTicketUUID = useTicketModalStore((state) => state.ticketUUID);
  const fetchAction = useRef<LoadActions>();

  const onChangePage = (newPage: number) => {
    setTableParams((prevState) => {
      return { ...prevState, page: newPage };
    });
  };
  const onChangePageSize = (newSize: number) =>
    setTableParams((prevState) => {
      return { ...prevState, page_size: newSize, page: 1 };
    });

  const debounceHandleFiltersData = useDebounce((data: IFormValues) => {
    setTableParams((prevState) => {
      return {
        page: 1,
        page_size: prevState.page_size,
        search: data.title,
        communities: data.community,
        assignees: data.member,
        o: data.orderBy,
        priority__in: parsePriority(data.priorty),
        status__in: parseStatus(data.status),
        is_inactive: data.inactive || undefined,
        responsible: data.responsible,
        service_provider: params.service_provider,
        created_after: data.created_after,
        created_before: data.created_before,
      };
    });
  }, 1000);
  useEffect(() => {
    // Handle prev & next button in the modal
    if (selectedTicketUUID) {
      const index = tableData.results.findIndex(
        (item) => item.uuid === selectedTicketUUID
      );
      const prev = tableData.results[index - 1]?.uuid || tableData.previous;
      const next = tableData.results[index + 1]?.uuid || tableData.next;
      const nextFn = !next
        ? null
        : () => {
            if (next === tableData.next) {
              fetchAction.current = "loadNext";
              setTableParams((prevState) => ({
                ...prevState,
                page: prevState.page + 1,
              }));
              useTicketModalStore.setState({ ticketUUID: null });
            } else {
              useTicketModalStore.setState({ ticketUUID: next as UUID });
            }
          };
      const prevFn = !prev
        ? null
        : () => {
            if (prev === tableData.previous) {
              fetchAction.current = "loadPrev";
              setTableParams((prevState) => ({
                ...prevState,
                page: prevState.page - 1,
              }));
              useTicketModalStore.setState({ ticketUUID: null });
            } else {
              useTicketModalStore.setState({ ticketUUID: prev as UUID });
            }
          };
      useTicketModalStore.setState({
        handleNext: nextFn,
        handlePrev: prevFn,
      });
    }
  }, [selectedTicketUUID, tableData.results]);

  useEffect(() => {
    // handle next & prev buttons when is the last or first item of the page
    if (isSuccess && fetchAction.current && tableData.results.length > 0) {
      const actions = {
        loadPrev: () => tableData.results.slice(-1)[0],
        loadNext: () => tableData.results[0],
      };
      const item = actions[fetchAction.current]?.();
      useTicketModalStore.setState({ ticketUUID: item.uuid });
      fetchAction.current = undefined;
    }
  }, [isSuccess, tableParams.page]);

  const user = useAuthenticationStore((state) => state.userProfile);
  const hideFilterRole = useMemo(() => {
    if (!user) return true;
    return getPermission(["comite", "conserje", "mayordomo"]);
  }, []);
  useEventListener("ticketCreated", () => {
    if (tableParams.page === 1) refetch();
    else {
      setTableParams((prev) => ({ ...prev, page: 1 }));
    }
  });
  return (
    <Box>
      {!hideFilters && (
        <Card sx={{ p: 2, mb: 2, overflow: "visible" }}>
          <TableFilters
            onChange={debounceHandleFiltersData}
            hideFilter={{
              community: !!params.communities || !!hideFilterRole,
              responsible: !!params.responsible || !!hideFilterRole,
              member: !!params.responsible || !!hideFilterRole,
            }}
          />
        </Card>
      )}
      <Card sx={{ p: 2 }}>
        <SwappiDataTable
          columns={tableColumns}
          data={tableData.results}
          isLoading={isFetching}
          defaultPageSize={tableParams.page_size}
          onChangePageSize={onChangePageSize}
          pagination={{
            count: tableData?.count,
            previous: tableData?.previous,
            next: tableData?.next,
            currentPage: tableParams.page,
            onChangePage: onChangePage,
          }}
        />
      </Card>
    </Box>
  );
};

export default TicketsTable;
