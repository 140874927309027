import { MaintenanceModel } from "api/services/maintenances/models";
import SwappiDataTable from "components/SwappiTable";
import React from "react";
import { createColumnsHelper } from "./table.helper";
import EditBudgetModal from "./EditBudgetModal";

const BudgetsTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const columns = createColumnsHelper(maintenance);
  return (
    <div>
      <EditBudgetModal maintenance={maintenance} />
      <SwappiDataTable columns={columns} data={maintenance.budgets} />
    </div>
  );
};

export default BudgetsTable;
