import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import useCreatePoll from 'api/services/polls/mutations/CreatePoll';
import { TicketModel } from 'api/services/tickets/models';
import DatePickerControlled from 'components/forms/controlled/DatePickerControlled';
import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { scheme } from './form.helper';

interface Props {
  ticket: TicketModel;
  onSuccess?: () => void;
}

const CreatePollForm: FC<Props> = ({ ticket, onSuccess }) => {
  const { mutate: createPoll, isPending } = useCreatePoll({
    ticketUUID: ticket.uuid,
    communitySlug: ticket.community.slug,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(scheme),
    defaultValues: { question: '', start_date: [], end_date: [] },
  });
  const onSubmit = handleSubmit((data) => {
    const startDate = new Date(data.start_date[0]);
    const startYear = startDate.getFullYear();
    const startMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Los meses son base 0
    const startDay = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    const date = new Date(data.end_date[0]);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son base 0
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    createPoll(
      {
        question: data.question,
        start_date: formattedStartDate,
        end_date: formattedDate,
      },
      {
        onSuccess: () => {
          onSuccess?.();
        },
      }
    );
  });

  return (
    <div>
      <Typography fontWeight="bold" variant="h5" mb={4}>
        Crear Votación
      </Typography>
      <Box mb={2}>
        <InputControlled label="Pregunta" name="question" control={control} />
      </Box>
      <Box mb={2}>
        <DatePickerControlled
          label="Fecha de Inicio"
          name="start_date"
          control={control}
          options={{
            minDate: 'today',
            maxDate: watch('end_date')[0] && new Date(watch('end_date')[0]),
          }}
        />
      </Box>
      <Box mb={2}>
        <DatePickerControlled
          disabled={!watch('start_date')[0]}
          label="Fecha de Término"
          name="end_date"
          control={control}
          options={{
            minDate: watch('start_date')[0] && new Date(watch('start_date')[0]),
          }}
        />
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <SuiButtonTs
          color="success"
          size="small"
          variant="gradient"
          onClick={onSubmit}
          disabled={!isValid || !isDirty || isPending}
        >
          Crear Votación
        </SuiButtonTs>
      </Box>
    </div>
  );
};

export default CreatePollForm;
