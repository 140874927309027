import React, { useMemo } from "react";
import { useCreateColumns } from "./table.helpers";
import { MaintenanceModel } from "api/services/maintenances/models";
import SwappiDataTable from "components/SwappiTable";
import { Box } from "@mui/material";

const ContractsTable: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const columns = useCreateColumns(maintenance);
  const data = useMemo(() => {
    return maintenance.documents
      .filter((document) => document.type?.slug === "contrato")
      .sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1));
  }, [maintenance]);

  return (
    <Box>
      <SwappiDataTable data={data} columns={columns} />
    </Box>
  );
};

export default ContractsTable;
