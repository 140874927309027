import axios from "axios";
import { API_URL } from "redux/actions/types";
import { CommunityModel } from "../models";
import { useQuery } from "@tanstack/react-query";
import { SelectOptionForm } from "types/forms";
import QUERY_KEYS from "api/utils/keys";
import { getPermission } from "utils";
import { useMemo } from "react";
import { queryClient } from "api/config/queryClient";

export const useGetCommunities = (enabled = true) => {
  const query = useQuery<CommunityModel[]>({
    enabled,
    queryKey: [QUERY_KEYS.GET_COMMUNITIES],
    staleTime: 1000 * 60 * 60,
    queryFn: async () => {
      if (!getPermission(["superadmin", "administrador", "supervisor"]))
        return [];
      const url = `${API_URL}/users/communities/`;
      return await axios.get<CommunityModel[]>(url).then((res) => res.data);
    },
  });

  const data = query.data || [];

  const dataAsOptions = useMemo<SelectOptionForm[]>(() => {
    return data.map((community) => ({
      value: community.slug,
      label: community.name,
    }));
  }, [data]);

  return { ...query, dataAsOptions, data };
};


export const useGetCommunitiesByUser = (enabled = true, userUUID: string) => {
  const query = useQuery<CommunityModel[], Error>({
    enabled,
    queryKey: ['QUERY_KEYS.GET_COMMUNITIES_BY_USER', userUUID],
    queryFn: async () => {
      const url = `${API_URL}/users/assigned-communities/${userUUID}`;
      return await axios.get<CommunityModel[]>(url).then((res) => res.data);
    },
  });
  return { ...query };
}

export interface Params {
  slug: string;
}
