import {
  BudgetModel,
  MaintenanceModel,
} from "api/services/maintenances/models";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import React, { useRef, useState } from "react";
import EditBudgetForm from "./EditBudgetForm";
import { Box } from "@mui/material";

const EditBudgetModal: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const modalRef = useRef<ModalRefHandle>();
  const [item, setItem] = useState<BudgetModel>();

  useEventListener("editBudget", (data: BudgetModel) => {
    modalRef.current.open();
    setItem(data);
  });
  useEventListener("budgetUpdated", () => {
    modalRef.current.close();
  });

  return (
    <BaseModal ref={modalRef}>
      <Box p={2}>
        <h4>Editar Presupuesto</h4>
        <EditBudgetForm budget={item} maintenance={maintenance} />
      </Box>
    </BaseModal>
  );
};

export default EditBudgetModal;
