import React, { useRef } from "react";
import CreateBudgetForm from "./CreateBudgetForm";
import {
  BudgetModel,
  MaintenanceModel,
} from "api/services/maintenances/models";
import { SuiButtonTs } from "components/SuiButton";
import BaseModal, { ModalRefHandle } from "components/ui/BaseModal";
import useEventListener from "lib/events/useEventListener";
import { Box } from "@mui/material";

const CreateBudgetButton: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const modalRef = useRef<ModalRefHandle>();
  const handleClick = () => {
    modalRef.current.open();
  };
  useEventListener<BudgetModel>("budgetCreated", () => {
    modalRef.current.close();
  });
  return (
    <div>
      <SuiButtonTs
        color={"success"}
        variant={"gradient"}
        size="small"
        onClick={handleClick}
      >
        Crear presupuesto
      </SuiButtonTs>

      <BaseModal ref={modalRef}>
        <Box p={2}>
          <h4>Nuevo Presupuesto</h4>
          <CreateBudgetForm maintenance={maintenance} />
        </Box>
      </BaseModal>
    </div>
  );
};

export default CreateBudgetButton;
