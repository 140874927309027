import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiButton from "components/SuiButton";
import { getPermission } from "utils";
import { mobileMaxWidth } from "utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflow: "visible",
  boxShadow: 24,
};

const styleMobile = {
  position: "absolute",
  width: "100%",
  height: "80vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "visible",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

export default function CreateTicket(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ float: "right", overflow: "visible" }}>
      <SuiButton variant="gradient" color="success" size="small" onClick={handleOpen}>
        Crear Reporte Visita
      </SuiButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <Form
            handleClose={handleClose}
            community={props.community}
            visitReport={props.visitReport}
            areaUuid={props.areaUuid}
            maintenance={props.maintenance}
            maintenanceUuid={props.maintenanceUuid}
          />
        </Box>
      </Modal>
    </div>
  );
}
