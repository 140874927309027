/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import { createProviderByManagement } from 'redux/actions/providers';
// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import { useDispatch, useSelector } from 'react-redux';
// NewProduct page components
import GeneralInfo from './components/GeneralInfo';
import BankAccountInfo from './components/BankAccountInfo';

import { validate, clean, format, getCheckDigit } from 'rut.js';
import { validadorEmail } from 'utils';
import SuiInput from 'components/SuiInput';
import SuiTypography from 'components/SuiTypography';
import { importProviders } from 'redux/actions/providers';
import { CircularProgress } from '@mui/material';
import { getAuthUserProfile } from 'stores/authentication/helpers';

function getSteps() {
  return ['1. Información General', '2. Datos Bancarios'];
}

function Form(props) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const importProvidersResponse = useSelector((state) => state.providers.importProviders);

  useEffect(() => {
    if (new Date() - importProvidersResponse.time < 1000)
      if (importProvidersResponse.data) {
        setLoading(false);
        props.handleClose();
      }
  });

  const handleCreateProvider = () => {
    console.log('file', file);
    let form = new FormData();

    form.append('file', file);
    form.append('management', getAuthUserProfile().management.slug);
    dispatch(importProviders(form));
  };

  return (
    <SuiBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Card sx={{ overflow: 'visible' }}>
            <SuiBox p={2}>
              <SuiBox>
                <SuiTypography variant="h4" fontWeight="medium">
                  Importar Proveedores en formato .xlsx
                </SuiTypography>
                <a
                  style={{ fontSize: 12 }}
                  href="https://swappi-backend-bucket.s3.us-east-2.amazonaws.com/importa_proveedores.xlsx"
                  target="_blank"
                >
                  Descargar plantilla
                </a>
                <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  <SuiInput
                    type="file"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      setLoading(true);
                      if (!loading) handleCreateProvider();
                    }}
                  >
                    {loading ? <CircularProgress size="10px" color="primary" /> : 'Importar'}
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Form;
