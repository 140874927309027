import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'api/utils/keys';
import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { ChecklistItemModel, ChecklistModel } from '../models';
import { queryClient } from 'api/config/queryClient';
import { UUID } from 'crypto';
import { deleteChecklist } from 'redux/actions/tickets';
import { filterHash } from '@fullcalendar/core/internal';

const useGetChecklistTemplates = (enabled = true) => {
  return useQuery({
    enabled,
    staleTime: 1000 * 60 * 5,
    queryKey: [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
    queryFn: async () => {
      const url = `${API_URL}/tickets/templates/checklists/all/`;
      return await axios.get<ChecklistModel[]>(url).then((res) => res.data);
    },
  });
};

export const checklistTemplatesHelper = {
  addChecklist: async (newChecklist: ChecklistModel) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        return [...prev, newChecklist] as ChecklistModel[];
      }
    );
  },

  deleteChecklist: async (uuid: UUID) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        return prev.filter((item) => item.uuid !== uuid);
      }
    );
  },

  updateChecklist: async (newChecklist: ChecklistModel) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        return prev.map((checklist) => {
          if (checklist.uuid === newChecklist.uuid) return newChecklist;
          return checklist;
        });
      }
    );
  },
  addChecklistItem: async (newItem: ChecklistItemModel, checklistUUID: UUID) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        const udpatedList = prev.map((checklist) => {
          if (checklist.uuid === checklistUUID) {
            return {
              ...checklist,
              items: [...checklist.items, newItem],
            };
          }
          return checklist;
        });

        return udpatedList;
      }
    );
  },
  updateChecklistItem: async (newItem: ChecklistItemModel, checklistUUID: UUID) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        const udpatedList = prev.map((checklist) => {
          if (checklist.uuid === checklistUUID) {
            return {
              ...checklist,
              items: checklist.items.map((item) => {
                if (item.uuid === newItem.uuid) return newItem;
                return item;
              }),
            };
          }
          return checklist;
        });

        return udpatedList;
      }
    );
  },
  deleteChecklistItem: async (itemUUID: UUID, checklistUUID: UUID) => {
    return await queryClient.setQueryData(
      [QUERY_KEYS.GET_CHECKLIST_TEMPLATE],
      (prev: ChecklistModel[]) => {
        if (!prev) return;
        const updatedList = prev.map((checklist) => {
          if (checklist.uuid === checklistUUID) {
            return {
              ...checklist,
              items: checklist.items.filter((item) => item.uuid != itemUUID),
            };
          }
          return checklist;
        });

        return updatedList;
      }
    );
  },
};

export default useGetChecklistTemplates;
