import { Box, Stack, Typography } from '@mui/material';
import { PollModel } from 'api/services/polls/models';
import useUpdatePoll from 'api/services/polls/mutations/UpdatePoll';
import { TicketModel } from 'api/services/tickets/models';
import { SuiButtonTs } from 'components/SuiButton';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { scheme } from './form.helper';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePickerControlled from 'components/forms/controlled/DatePickerControlled';

interface Props {
  ticket: TicketModel;
  poll: PollModel;
  onClose: () => void;
}
const EditPollForm: FC<Props> = ({ ticket, poll, onClose }) => {
  const updatePoll = useUpdatePoll({
    pollUUID: poll.uuid,
    ticketUUID: ticket.uuid,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    reset({
      start_date: [poll.start_date],
      end_date: [poll.end_date],
    });
  }, [ticket, poll]);

  const onSubmit = handleSubmit((data) => {
    updatePoll.mutate(
      {
        start_date: new Date(data.start_date[0]),
        end_date: new Date(data.end_date[0]),
      },
      {
        onSuccess: () => {
          onClose?.();
        },
      }
    );
  });
  const handleOnCancel = () => {
    reset();
    onClose?.();
  };
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <Typography>Modificar periodo de votación</Typography>
        <DatePickerControlled label="Inición de votación" control={control} name="start_date" />
        <DatePickerControlled label="Cierre de votación" control={control} name="end_date" />

        <Box display={'flex'} justifyContent={'end'} gap={1}>
          <SuiButtonTs variant="gradient" color="secondary" size="small" onClick={handleOnCancel}>
            Cancelar
          </SuiButtonTs>
          <SuiButtonTs
            disabled={!isValid || !isDirty}
            variant="gradient"
            color="success"
            size="small"
            type="submit"
          >
            Guardar cambios
          </SuiButtonTs>
        </Box>
      </Stack>
    </form>
  );
};

export default EditPollForm;
