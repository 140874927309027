import { Box } from "@mui/material";
import { TicketItemModel } from "api/services/tickets/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiProgressTs } from "components/SuiProgress";
import MemberList from "components/Tickets/MemberList";
import { TicketModalHelper } from "../TicketModal/store";
import TicketTableActions from "components/Tickets/TicketTableActions";
import { ColumnTable } from "components/SwappiTable/types";
import { parseDate, ticketPriorityColor, ticketStatusColor } from "utils";
import { useMemo } from "react";

interface Builder {
  [key: string]: ColumnTable<TicketItemModel>;
}
const columnsBuilder: Builder = {
  title: {
    header: "Título",
    accessorKey: "uuid",
    cell: ({ row }) => {
      return (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => TicketModalHelper.openTicket(row.original.uuid)}
        >
          {row.original.title}
        </Box>
      );
    },
    width: 80,
  },
  community: {
    header: "Comunidad",
    accessorKey: "uuid",
    cell: ({ row }) => row.original.community.name,
    width: 80,
  },
  responsible: {
    header: "Responsable",
    accessorKey: "responsible",

    cell: ({ row }) => (
      <MemberList
        members={row.original.responsible ? [row.original.responsible] : []}
      />
    ),
    width: 75,
  },
  assignees: {
    header: "Asignados",
    accessorKey: "assignees",
    cell: ({ row }) => <MemberList members={row.original.assignees} />,
    width: 60,
  },
  priority: {
    header: "Prioridad",
    accessorKey: "priority",
    cell: ({ row }) => (
      <SuiBadgeTs
        style={{ float: "left" }}
        badgeContent={row.original.priority}
        color={ticketPriorityColor[row.original.priority]}
        size="xs"
        container
      />
    ),
    width: 60,
  },
  status: {
    header: "Estado",
    accessorKey: "status",
    width: 60,
    cell: ({ row }) => (
      <SuiBadgeTs
        style={{ float: "left" }}
        badgeContent={row.original.status}
        color={ticketStatusColor[row.original.status]}
        size="xs"
        container
      />
    ),
  },
  created: {
    header: "Fecha inicio",
    accessorKey: "created",
    width: 50,

    cell: ({ row }) =>
      row.original.started_on
        ? parseDate(row.original.started_on)
        : row.original.created && parseDate(row.original.created),
  },
  end_date: {
    header: "Fecha término",
    accessorKey: "end_date",
    width: 50,
    cell: ({ row }) =>
      row.original.due_date && parseDate(row.original.due_date),
  },
  actions: {
    header: "Acciones",
    accessorKey: "actions",
    cell: ({ row }) => (
      <TicketTableActions
        ticket={row.original}
        refetch={() => null}
        //    refetch={refetch}
      />
    ),
    width: 55,
  },
  code: {
    header: "Código",
    accessorKey: "code",
    cell: ({ row }) => row.original.code,
  },
  progress: {
    header: "Progreso",
    accessorKey: "progress",
    width: 60,
    cell: ({ row }) => (
      <SuiProgressTs
        value={(Number(row.original.progress) * 100).toFixed()}
        variant="gradient"
        label
      />
    ),
  },
  checklist: {
    header: "Checklist",
    accessorKey: "checklists_progress",
    width: 60,
    cell: ({ row }) => (
      <SuiProgressTs
        value={(Number(row.original.checklists_progress) * 100).toFixed()}
        variant="gradient"
        label
      />
    ),
  },
  area: {
    header: "Area",
    accessorKey: "area",
    cell: ({ row }) => row.original.area?.name || "",
  },
  documents_number: {
    header: "N° de documentos",
    accessorKey: "document_number",
  },
};

const detailed: ColumnTable<TicketItemModel>[] = [
  columnsBuilder.title,
  columnsBuilder.community,
  columnsBuilder.responsible,
  // columnsBuilder.assignees,
  columnsBuilder.priority,
  columnsBuilder.status,
  columnsBuilder.progress,
  // columnsBuilder.checklist,
  columnsBuilder.created,
  columnsBuilder.end_date,
  columnsBuilder.actions,
];
const communities: ColumnTable<TicketItemModel>[] = [
  columnsBuilder.title,
  columnsBuilder.responsible,
  columnsBuilder.assignees,
  columnsBuilder.priority,
  columnsBuilder.status,
  columnsBuilder.progress,
  columnsBuilder.checklist,
  columnsBuilder.created,
  columnsBuilder.end_date,
  columnsBuilder.actions,
];
const minTicket: ColumnTable<TicketItemModel>[] = [
  columnsBuilder.title,
  columnsBuilder.responsible,
  columnsBuilder.assignees,
  columnsBuilder.status,
  columnsBuilder.end_date,
  columnsBuilder.priority,
  columnsBuilder.progress,
  columnsBuilder.area,
  columnsBuilder.actions,
];

export enum TicketsTableViewDisplay {
  detailed = "detailed",
  minTicket = "minTicket",
  communities = "communities",
}
export const createColumnsHelper = (
  name: TicketsTableViewDisplay = TicketsTableViewDisplay.detailed
): ColumnTable<TicketItemModel>[] => {
  return useMemo(() => {
    const items = {
      [TicketsTableViewDisplay.minTicket]: () => minTicket,
      [TicketsTableViewDisplay.communities]: () => communities,
    };
    return items[name]?.() || detailed;
  }, [name]);
};

export const SUB_TABLE_COLUMNS: ColumnTable<TicketItemModel>[] = [
  columnsBuilder.code,
  columnsBuilder.area,
  columnsBuilder.documents_number,
];
