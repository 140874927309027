import React from 'react';
import LoginForm from 'components/pages/login/LoginForm';
import CoverLayout from 'layouts/authentication/components/CoverLayout';
import img from 'assets/images/principal-login.svg';

const LoginPage = () => {
  return (
    <>
      <CoverLayout
        title="Bienvenido"
        description="Ingresa tu email y contraseña para ingresar a la App"
        image={img}
      >
        <LoginForm />
      </CoverLayout>
    </>
  );
};

export default LoginPage;
