import { ISODateString } from 'types/models';
import * as yup from 'yup';
interface FormValues {
  provider: string;
  start_date: ISODateString[];
  end_date: ISODateString[];
  periodicity: string;
}
export const scheme: yup.SchemaOf<FormValues> = yup.object().shape({
  provider: yup.string(),
  periodicity: yup.string().required(),
  start_date: yup.array().of(yup.string().required()).required(),
  end_date: yup.array().of(yup.string()).required(),
  // .test(
  //   'is-greater',
  //   'La fecha de cierre no puede ser menor que la fecha de inicio',
  //   function (value) {
  //     const { start_date } = this.parent;
  //     return !value || !start_date || new Date(value[0]) >= new Date(start_date[0]);
  //   }
  // ),
});
