import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { getPermission } from 'utils';
const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ImportAreas(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ display: 'inline-block', marginLeft: 4 }}>
      {getPermission(['superadmin', 'supervisor', 'administrador']) && (
        <SuiButton
          variant="gradient"
          size="small"
          color="info"
          style={{ marginRight: 5 }}
          onClick={handleOpen}
        >
          Importar Areas
        </SuiButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={''}
      >
        <Box sx={style}>
          <Form close={handleClose} buildings={props.buildings} />
        </Box>
      </Modal>
    </div>
  );
}
