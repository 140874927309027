import { Icon } from "@mui/material";
import { SuiBoxTs } from "components/SuiBox";
import { UUID } from "crypto";
import FilePreviewModal from "layouts/pages/documents/View";
import React, { FC } from "react";
import { MdOpenInNew } from "react-icons/md";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { deleteDocument } from "redux/actions/documents";

import Swal from "sweetalert2";
import { getPermission, isPermissionEnabled } from "utils";
import { DocumentActionsProps } from "./types";
import EditFileDrawer from "components/EditFileDrawer";

const DocumentActions: FC<DocumentActionsProps> = ({ document }) => {
  const dispatch = useDispatch();
  const handleDeleteDocument = (uuid: UUID) => {
    dispatch(deleteDocument(uuid) as unknown as AnyAction);
  };
  return (
    <SuiBoxTs>
      <FilePreviewModal document={document} />
      {getPermission(["superadmin", "administrador", "supervisor"]) &&
        isPermissionEnabled("documents-editDocument") && (
          <EditFileDrawer
            file={document}
            community={document.community && document.community.slug}
            slugType={document.type.slug}
          />
        )}
      <a target="_blank" href={document.file} style={{ cursor: "pointer" }}>
        <MdOpenInNew />
      </a>
      {getPermission(["superadmin", "administrador", "supervisor"]) &&
        isPermissionEnabled("documents-deleteDocument") && (
          <Icon
            sx={{ cursor: "pointer" }}
            fontSize="small"
            onClick={() =>
              Swal.fire({
                title: "¿Quieres eliminar este documento?",
                showDenyButton: true,
                confirmButtonText: "Si",
                denyButtonText: `No`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleDeleteDocument(document.uuid);
                  Swal.fire("Eliminado", "", "success");
                }
              })
            }
          >
            delete
          </Icon>
        )}
    </SuiBoxTs>
  );
};

export default DocumentActions;
