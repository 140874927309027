import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { CommentaryModel, TicketModel } from "../models";
import { TicketQueryHelper } from "../queries/GetTicket";
import { IMessage } from "api/utils/queries";
import { toastNotification } from "components/ui/ToastNotification";

export interface Params {
  ticketUUID: UUID;
}
type Response = IMessage<TicketModel>;

const useToggleShowTicketToCommittee = (params: Params) => {
  const { ticketUUID } = params;
  return useMutation({
    mutationFn: async () => {
      const url = `${API_URL}/tickets/${ticketUUID}/show-to-comitee/`;
      return await axios.put<Response>(url).then((res) => res.data);
    },
    onSuccess(data) {
      TicketQueryHelper.updateTicket(data.data);
      toastNotification.success({
        message: "La visualización del ticket ha sido modificada",
        title: "Visualización modificada",
      });
    },
  });
};

export default useToggleShowTicketToCommittee;
