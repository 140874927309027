import { DocumentModel } from "api/services/documents/models";
import { MaintenanceModel } from "api/services/maintenances/models";

import { TicketItemModel } from "api/services/tickets/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiProgressTs } from "components/SuiProgress";
import MemberList from "components/Tickets/MemberList";
import { ColumnTable } from "components/SwappiTable/types";
import TicketModal from "components/Tickets/TicketDetailModal";

import { parseDate, ticketPriorityColor, ticketStatusColor } from "utils";
import { typeColorMapping } from "../SelectDocumentsTable/constants";
import { Box, Tooltip } from "@mui/material";
import DocumentActions from "components/DocumentsTable/DocumentActions";
import { VisitReportModel } from "api/services/visits/types";
import { ExportCenterState } from "../types";
import TicketTableActions from "components/Tickets/TicketTableActions";
import { Remove } from "@mui/icons-material";
import MaintenanceModal from "layouts/pages/maintenance/View/MaintenanceCard";
import VisitsReportsActions from "components/VisitsReports/VisistsReportsActions";
export const getTicketColumns = (
  exportFormStore: ExportCenterState
): ColumnTable<TicketItemModel>[] => {
  const { removeTickets } = exportFormStore;
  return [
    {
      header: "Opciones",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Tooltip title={"Deseleccionar"} placement="top">
          <Box>
            <SuiBadgeTs
              onClick={() => removeTickets([row.original])}
              style={{ cursor: "pointer", marginTop: 2 }}
              color={"warning"}
              container
              badgeContent={<Remove />}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      header: "Título",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <TicketModal
          uuid={row.original.uuid}
          title={row.original.title}
          community={row.original.community}
        />
      ),
    },
    {
      header: "Comunidad",
      accessorKey: "uuid",
      cell: ({ row }) => row.original.community.name,
    },
    {
      header: "Responsable",
      accessorKey: "responsible",
      cell: ({ row }) => <MemberList members={row.original.responsible ? [row.original.responsible] : []} />,
    },
    {
      header: "Colaboradores",
      accessorKey: "assignees",
      cell: ({ row }) => <MemberList members={row.original.assignees} />,
    },
    {
      header: "Prioridad",
      accessorKey: "priority",
      cell: ({ row }) => (
        <SuiBadgeTs
          style={{ float: "left" }}
          badgeContent={row.original.priority}
          color={ticketPriorityColor[row.original.priority]}
          size="xs"
          container
        />
      ),
    },
    {
      header: "Estado",
      accessorKey: "status",
      cell: ({ row }) => (
        <SuiBadgeTs
          style={{ float: "left" }}
          badgeContent={row.original.status}
          color={ticketStatusColor[row.original.status]}
          size="xs"
          container
        />
      ),
    },
    {
      header: "Progreso",
      accessorKey: "progress",
      cell: ({ row }) => (
        <SuiProgressTs
          value={(Number(row.original.progress) * 100).toFixed()}
          variant="gradient"
          label
        />
      ),
    },
    {
      header: "Fecha inicio",
      accessorKey: "created",

      cell: ({ row }) =>
        row.original.started_on
          ? parseDate(row.original.started_on)
          : row.original.created && parseDate(row.original.created),
    },
    {
      header: "Fecha término",
      accessorKey: "end_date",
      cell: ({ row }) =>
        row.original.due_date && parseDate(row.original.due_date),
    },
    {
      header: "Acciones",
      accessorKey: "actions",

      cell: ({ row }) => (
        <TicketTableActions ticket={row.original} refetch={() => {}} />
      ),
    },
  ];
};

export const getMaintenancesColumns = (
  exportFormStore: ExportCenterState
): ColumnTable<MaintenanceModel>[] => {
  const { removeMaintenances } = exportFormStore;
  return [
    {
      header: "Opciones",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Tooltip title={"Deseleccionar"} placement="top">
          <Box>
            <SuiBadgeTs
              onClick={() => removeMaintenances([row.original])}
              style={{ cursor: "pointer", marginTop: 2 }}
              color={"warning"}
              container
              badgeContent={<Remove />}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community.name,
    },
    {
      header: "Area común",
      accessorKey: "area",
      cell: ({ row }) => row.original.area.name,
    },
    {
      header: "Proveedor",
      accessorKey: "provider",

      cell: ({ row }) =>
        row.original.service_provider?.executive_name ||
        row.original.service_provider?.razon_social ||
        "Sin proveedor",
    },
    {
      header: "Fecha expiración",
      accessorKey: "end_date",
      cell: ({ row }) => parseDate(row.original.end_date),
    },
    {
      header: "Contrato",
      accessorKey: "contract",

      cell: ({ row }) => {
        const [contract] = row.original.contract
          .filter((item) => item.is_active)
          .slice(-1);
        if (contract?.file)
          return (
            <a target="_blank" href={contract?.file}>
              <SuiBadgeTs
                style={{ float: "left", cursor: "pointer" }}
                badgeContent={"Contrato"}
                size="xs"
                container
                color={
                  new Date(contract.expires_on) < new Date()
                    ? "warning"
                    : "success"
                }
              />
            </a>
          );

        return (
          <SuiBadgeTs
            style={{ float: "left" }}
            badgeContent={"Sin Contrato"}
            size="xs"
            container
            color="error"
          />
        );
      },
    },
    {
      header: "Certificación",
      accessorKey: "certification",

      cell: ({ row }) => {
        const certification = row.original.certifications[0]?.documents.filter(
          (item) => item.is_active
        )[0];
        if (certification?.file)
          return (
            <a target="_blank" href={certification && certification.file}>
              <SuiBadgeTs
                style={{ float: "left", cursor: "pointer" }}
                badgeContent={"Certificación"}
                size="xs"
                container
                color={
                  new Date(certification.expires_on) < new Date()
                    ? "warning"
                    : "success"
                }
              />
            </a>
          );
        return (
          <SuiBadgeTs
            style={{ float: "left" }}
            badgeContent={"Sin Certificación"}
            size="xs"
            container
            color="error"
          />
        );
      },
    },
    {
      header: "Acciones",
      accessorKey: "actions",

      cell: ({ row }) => (
        <MaintenanceModal modal={true} uuid={row.original.uuid} />
      ),
    },
  ];
};

export const getVisitsReportsColumns = (
  exportFormStore: ExportCenterState
): ColumnTable<VisitReportModel>[] => {
  const { removeVisits } = exportFormStore;

  return [
    {
      header: "Opciones",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Tooltip title={"Deseleccionar"} placement="top">
          <Box>
            <SuiBadgeTs
              onClick={() => removeVisits([row.original])}
              style={{ cursor: "pointer", marginTop: 2 }}
              color={"warning"}
              container
              badgeContent={<Remove />}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      header: "Titulo",
      accessorKey: "title",
      cell: ({ row }) => row.original.title,
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community.name,
    },
    {
      header: "Usuario",
      accessorKey: "usuario",
      cell: ({ row }) => <MemberList members={[row.original.author]} />,
    },
    {
      header: "Creado",
      accessorKey: "created",
      cell: ({ row }) => parseDate(row.original.created),
    },
    {
      header: "Acciones",
      accessorKey: "uuid",
      cell: ({ row }) => {
        return (
          <Box>
            <VisitsReportsActions report={row.original} />
          </Box>
        );
      },
    },
  ];
};

export const getDocumentsColumns = (
  exportFormStore: ExportCenterState
): ColumnTable<DocumentModel>[] => {
  const { removeDocuments } = exportFormStore;

  return [
    {
      header: "Opciones",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Tooltip title={"Deseleccionar"} placement="top">
          <Box>
            <SuiBadgeTs
              onClick={() => removeDocuments([row.original])}
              style={{ cursor: "pointer", marginTop: 2 }}
              color={"warning"}
              container
              badgeContent={<Remove />}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      header: "Nombre",
      accessorKey: "name",
    },
    {
      header: "Comunidad",
      accessorKey: "community",
      cell: ({ row }) => row.original.community?.name,
    },
    {
      header: "Tipo",
      accessorKey: "type",
      cell: ({ row }) => (
        <SuiBadgeTs
          color={typeColorMapping[row.original.type.name] || "error"}
          size="xs"
          badgeContent={row.original.type.name}
        />
      ),
    },
    {
      header: "Etiquetas",
      accessorKey: "tags",
      cell: ({ row }) => (
        <Box>
          {row.original.tags.map((tag, i) => {
            return (
              <SuiBadgeTs
                key={i}
                color="light"
                size="xs"
                badgeContent={tag.name}
              />
            );
          })}
        </Box>
      ),
    },
    {
      header: "Acciones",
      accessorKey: "actions",
      cell: ({ row }) => <DocumentActions document={row.original} />,
    },
  ];
};
