import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { UserModel } from "../models";
import { KEY_GET_USER_STAFF } from "../queries/getUsersStaff";

interface Input {
  first_name: string;
  phone: string;
  rut: string;
  weekly_report_notification: boolean;
  profile_picture: any;
  groups: { name: string }[];
}
const useUpdateUser = (userUUID: UUID) => {
  const queryClient = useQueryClient();
  const formData = new FormData();
  return useMutation({
    mutationFn: async (input: Input) => {
      formData.append("first_name", input.first_name);
      formData.append("phone", input.phone);
      formData.append("rut", input.rut);
      formData.append("weekly_report_notification", String(input.weekly_report_notification));
      formData.append("groups", JSON.stringify(input.groups));
      if(input.profile_picture) formData.append("profile_picture", input.profile_picture);
      console.log(input)
      const url = `${API_URL}/users/${userUUID}/update/`;
      return axios.put<UserModel>(url, formData).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario actualizado",
        message: "Usuario actualizado con éxito",
      });
      queryClient.invalidateQueries({ queryKey: [KEY_GET_USER_STAFF] });
      queryClient.invalidateQueries({ queryKey: ['get_user_image', userUUID] });
    },
  });
};

export default useUpdateUser;
