// SuiInputTs.tsx
import React, { forwardRef } from 'react';
import SuiInput from '../SuiInput'; // Adjust the import path as necessary

interface SuiInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'small' | 'medium' | 'large';
  icon?: {
    component?: React.ReactNode | boolean;
    direction?: 'none' | 'left' | 'right';
  };
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  multiline?: boolean;
  rows?: number;
  label?: string;
  // Add any other custom props you need
}

const SuiInputAny = SuiInput as any;

const SuiInputTs = forwardRef<HTMLInputElement, SuiInputProps>((props, ref) => {
  return <SuiInputAny ref={ref} {...props} />;
});

SuiInputTs.displayName = 'SuiInputTs';

export default SuiInputTs;
