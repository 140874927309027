import { dateToLocale } from "utils";
import CertificationActions from "./CertificationActions";
import { CertificationModel } from "api/services/certifications/models";
import { ColumnTable } from "components/SwappiTable/types";
import { usePermissions } from "stores/authentication/helpers";
import { useMemo } from "react";

export const createColumns = () => {
  const canEditDocuments = usePermissions().documents.edit;
  return useMemo(() => {
    const cols: ColumnTable<CertificationModel>[] = [
      {
        header: "Título",
        accessorKey: "title",
        cell: ({ row }) => row.original.documents[0].name,
      },

      {
        header: "Fecha expiración",
        accessorKey: "dates",
        cell: ({ row }) => dateToLocale(row.original.documents[0].expires_on),
        width: "30%",
      },
    ];
    if (canEditDocuments) {
      cols.push({
        header: "Acciones",
        accessorKey: "actions",
        cell: ({ row }) => (
          <CertificationActions certification={row.original} />
        ),
        width: "20%",
      });
    }
    return cols;
  }, [canEditDocuments]);
};
