import React, { FC, useMemo, useState } from "react";
import { TicketModel } from "api/services/tickets/models";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { Launch, Straight } from "@mui/icons-material";
import RecommendedBadge from "components/Tickets/TicketView/Files/View/BudgetCard/Recommended";
import { useGetUF } from "api/services/others/queries/GetUf";
import { useNavigate } from "react-router-dom";
import { PollChoiceModel, PollModel } from "api/services/polls/models";
import { documentValueInClp } from "utils/helpers/documents/currency";
import colors from "assets/theme/base/colors";
import VotesCount from "./VotesCount";

import { SuiButtonTs } from "components/SuiButton";
import useApproveDocument from "api/services/documents/mutations/ApproveDocument";
import VotesResults from "./VotesResults";
import { usePermissions } from "stores/authentication/helpers";
import { useGetDocumentUrl } from "api/services/documents/queries/getDocumentUrl";

const badgeStyle = {
  top: -30,
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 0%)",
};
interface Props {
  poll: PollModel;
  pollOption: PollChoiceModel;
  ticket?: TicketModel;
  referenceValueCLP: number;
  mostVoted: boolean;
}

const DocumentPricingOption: FC<Props> = ({
  poll,
  pollOption,
  ticket,
  referenceValueCLP,
  mostVoted,
}) => {
  const getDocumentUrl = useGetDocumentUrl(pollOption.document.uuid, true);
  const navigate = useNavigate();
  const document = pollOption.document;

  const { canEditDocument, canEditTask } = usePermissions();

  const { data: UF } = useGetUF();

  const [isExpaned, setIsExpanded] = useState<boolean>(false);

  const canAproveOption =
    canEditDocument && canEditTask && ticket.status === "en votación";
  const { valueCLP, isGoodOption, valueDiferenceCLP } = useMemo(() => {
    if (!UF)
      return {
        valueCLP: 0,
        valueDiferenceCLP: 0,
        isGoodOption: false,
      };
    const valueCLP = documentValueInClp(document, UF.valor);
    return {
      valueCLP,
      isGoodOption: valueCLP <= referenceValueCLP,
      valueDiferenceCLP: valueCLP - referenceValueCLP,
    };
  }, [document, UF]);
  const approveDocument = useApproveDocument({
    documentUUID: document.uuid,
    ticketUUID: ticket.uuid,
  });

  const approveOption = () => {
    approveDocument.mutate();
  };

  if (!UF) return null;
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        border: `2px solid ${document.is_recommended ? "#22bb33" : "#ffffff"}`,
      }}
    >
      <Box p={2}>
        <Box position="relative">
          {document.is_recommended && <RecommendedBadge style={badgeStyle} />}
        </Box>
        <Box display="flex">
          <Box flex={1}>
            <Typography variant="h5" textTransform="capitalize">
              {document.name}
            </Typography>
          </Box>
        </Box>
        <VotesCount
          poll={poll}
          pollOption={pollOption}
          isMostVoted={mostVoted}
        />
        <VotesResults pollOption={pollOption} />

        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              color={colors.text.main}
              component={Box}
            >
              Valor
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              ${valueCLP.toLocaleString("es-CL", { maximumFractionDigits: 0 })}
              CLP
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {document.currency && (
              <>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="none"
                  color={colors.text.main}
                  component={Box}
                >
                  Valor detalle
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {document.currency !== "UF" && "$"}
                  {document.price.toLocaleString("es-CL")}
                  {document.currency} {document.tax !== "null" && document.tax}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              color={colors.text.main}
              component={Box}
            >
              Diferencia
            </Typography>
            <Typography
              variant="h6"
              fontWeight="regular"
              color={isGoodOption ? "success.main" : "error.main"}
            >
              {valueDiferenceCLP === 0
                ? "-"
                : `$${valueDiferenceCLP.toLocaleString("es-CL")}CLP`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              color={colors.text.main}
              component={Box}
            >
              Variación
            </Typography>
            <Typography
              variant="h6"
              fontWeight="regular"
              color={isGoodOption ? "success.main" : "error.main"}
            >
              {valueDiferenceCLP === 0 ? (
                "-"
              ) : (
                <>
                  {((valueCLP * 100) / referenceValueCLP - 100)
                    .toFixed(0)
                    .toLocaleString()}
                  % <Straight />
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              color={colors.text.main}
            >
              Proveedor:{" "}
            </Typography>
            <Typography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
            >
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  document.service_provider &&
                    navigate(`/providers/${document.service_provider.uuid}`, {
                      state: { provider: document.service_provider },
                    });
                }}
              >
                {document.service_provider?.executive_name ||
                  document.service_provider?.razon_social ||
                  ""}{" "}
                <Launch />
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Box>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              <a href={`https://api.swappi.cl/documents/${document.uuid}/url/redirect/`} target="_blank">
                Ver archivo adjunto
              </a>
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography
              variant="button"
              fontWeight="bold"
              onClick={() => setIsExpanded((prev) => !prev)}
              textTransform="none"
              sx={{
                cursor: "pointer",
              }}
            >
              {isExpaned ? "Ocultar" : "Ver"} observación
            </Typography>
            <Collapse in={isExpaned}>
              <Typography variant="caption" textTransform="none">
                {document.observations}
              </Typography>
            </Collapse>
          </Box>
          <Box>
            {canAproveOption && (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <SuiButtonTs
                  color="success"
                  onClick={approveOption}
                  variant="gradient"
                  size="small"
                >
                  Aprobar
                </SuiButtonTs>
              </Box>
            )}
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Typography
              variant="h5"
              fontWeight="medium"
              color="success.main"
              textTransform="none"
            >
              {document.approved && "Aprobado"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default DocumentPricingOption;
