import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { mobileMaxWidth } from 'utils';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '70vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 1,
  paddingTop: 3,
};

const mobileStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  bgcolor: 'background.paper',
  maxHeight: '90vh',
  p: 0,
  width: '100%',
};

function handleKeyPress(event) {
  event.stopPropagation();
}

export default function VisitsModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [visit, setVisit] = useState(props.visit);
  const handleClose = () => {
    setOpen(false);
    setVisit({});
  };

  const handlePrev = () => {
    // Lógica para ir al ticket anterior
    props.handlePrev();
  };

  const handleNext = () => {
    // Lógica para ir al siguiente ticket
    props.handleNext();
  };

  const navButtonStyle = {
    position: 'absolute',
    top: '50%',
    zIndex: 1200, // Asegúrate de que está sobre cualquier otro elemento dentro del modal
  };

  useEffect(() => {
    if (props.visit) {
      if (visit) {
        if (props.visit.uuid != visit.uuid) {
          handleOpen();
          setVisit(props.visit);
        }
      } else {
        handleOpen();
        setVisit(props.visit);
      }
    }
  }, [props.visit]);

  return (
    <div style={{ float: 'right' }}>
      <Modal
        onKeyDown={handleKeyPress}
        style={{ overflow: 'scroll' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={''}
      >
        <Box sx={window.innerWidth <= mobileMaxWidth ? mobileStyle : style}>
          {props.handlePrev && (
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handlePrev}
              sx={{ ...navButtonStyle, left: -30 }}
              startIcon={<AiOutlineLeft size="20px" />}
            ></SuiButton>
          )}
          <Form visit={props.visit} close={handleClose} />
          {props.handleNext && (
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleNext}
              sx={{ ...navButtonStyle, right: -30, color: 'black' }}
              endIcon={<AiOutlineRight size="20px" />}
            ></SuiButton>
          )}
        </Box>
      </Modal>
    </div>
  );
}
