import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/config/queryClient";
import axios from "axios";
import { API_URL } from "redux/actions/types";
import { ISODateString } from "types/models";
import { GET_MAINTENANCE_KEY } from "../queries/getMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";

interface Inputs {
  start_date: ISODateString;
  end_date: ISODateString;
  maintenanceUUID: UUID;
}
const useCreateMaintenanceRegenerateVisit = () => {
  return useMutation({
    mutationFn: async (input: Inputs) => {
      const url = `${API_URL}/maintentance/regenerate-visits/`;
      const { maintenanceUUID } = input;
      return await axios
        .post<MaintenanceModel>(url, {
          ...input,
          uuid: maintenanceUUID,
        })
        .then((res) => res.data);
    },
    onSuccess: (_data, input) => {
      queryClient.invalidateQueries({
        queryKey: [GET_MAINTENANCE_KEY, input.maintenanceUUID],
      });
      toastNotification.success({
        message: "Las visitas han sido regeneradas con éxito",
        title: "Visitas regeneradas",
      });
    },
  });
};

export default useCreateMaintenanceRegenerateVisit;
